import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .middleInner {
    display: flex;
    gap: 40px;
    padding-top: 80px;

    & .contentsSection {
      width: 1130px;

      & .sectionTitle {
        margin-bottom: 80px;
        font-size: 26px;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .middleInner {
      gap: calc(100vw * (40 / 1600));
      padding-top: calc(100vw * (80 / 1600));

      & .contentsSection {
        width: calc(100vw * (1130 / 1600));

        & .sectionTitle {
          margin-bottom: calc(100vw * (80 / 1600));
          font-size: calc(100vw * (26 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .middleInner {
      flex-direction: column;
      gap: calc(100vw * (30 / 370));
      padding-top: calc(100vw * (30 / 370));

      & .contentsSection {
        width: 100%;

        & .sectionTitle {
          display: none;
        }
      }
    }
  }
`;

export { Container };

export default { Container };
