import { date } from "utils";
import Styles from "./styles";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_circle.svg";
import IconArrowLeft from "resources/image/icon/icon_arrow_left_circle.svg";
import { useEffect } from "react";

export default function Calendar({ calendar }) {
  
  useEffect(() => {
    calendar.onClickDate(new Date)
  },[])

  function getDayRender(day, i) {
    let scheduleCheck = false;
    calendar?.updateData?.map((item) => {
      if (item.date === date.changeDate(day.date, "-")) {
        scheduleCheck = true;
      }
    })

    return (
      <div className=
        {`date
          ${day.curDay === 0 ? " sunday" : ""}
          ${calendar.value && date.equalTwoDate(calendar.value, day.date)
            ? !day.isBefore && !day.isAfter && "active"
            : scheduleCheck
              ? "schedule"
              : ""
          }`
        }
        key={"day_info_" + "_" + i}
        onClick={(e) => {
          if (!day.isBefore && !day.isAfter && calendar.value) {
            calendar.onClickDate(day.date)
          };
        }}
      >
        <>
          {!day.isBefore && !day.isAfter ? (
            <p>{day.day}</p>
          ) : (
            <p className="emptyDate"></p>
          )}
        </>
      </div>
    );
  }

  function renderCalendar(dateObject) {
    let days = dateObject.days;
    let resultTag = [];
    let dayTags = [];

    resultTag.push(
      <div key={"calendar_tag_00"} className="dayContainer">
        <p className="day">일</p>
        <p className="day">월</p>
        <p className="day">화</p>
        <p className="day">수</p>
        <p className="day">목</p>
        <p className="day">금</p>
        <p className="day">토</p>
      </div>
    );

    for (let i = 0; i < days.length; i++) {
      //개행
      if (i != 0 && i % 7 == 0) {
        resultTag.push(
          <div key={"calendar_tag_" + i} className="row">
            {dayTags}
          </div>
        );
        dayTags = [];
      }
      //element 요소
      dayTags.push(getDayRender(days[i], i));

      //개행
      if (days.length === i + 1) {
        resultTag.push(
          <div className="row" key={"calendar_tag_" + i}>
            {dayTags}
          </div>
        );
      }
    }

    return resultTag;
  }

  return (
    <Styles.Container>
      <div className="dateTopContainer">
        <h2>
          {calendar.currentDate.getFullYear()}.
          {calendar.currentDate.getMonth() + 1 < 10
            ? "0" + (calendar.currentDate.getMonth() + 1)
            : calendar.currentDate.getMonth() + 1}
        </h2>

        <div className="buttonContainer">
          <button
            type="button"
            onClick={()=>{
              calendar.prevMonthEvent()
            }}>
            <img src={IconArrowLeft} alt="prev" />
          </button>
          <button
            type="button"
            onClick={()=>{
              calendar.nextMonthEvent()
            }}>
            <img src={IconArrowRight} alt="next" />
          </button>
        </div>
      </div>

      <div className="dateBottomContainer">
        {renderCalendar(calendar.getDaysObject())}
      </div>
    </Styles.Container>
  );
}
