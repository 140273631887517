import Styles from "./styles";
import utils from "utils";
import { useBoard } from "hooks";
import { common, layout, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function CommunityDetailPage() {
  const boardInfo = useBoard({ options: { communityDetail: true } });
  const location = useLocation();
  const navigate = useNavigate();

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    nowText: "지역 커뮤니티",
    title: "지역 커뮤니티",
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={boardInfo.communityDetail}>
                <h2 className="title">
                  [{boardInfo.communityDetail.category_title}] {boardInfo.communityDetail.title}
                </h2>
                <div className="detailInfo">
                  <span>{boardInfo.communityDetail.nickname}</span>
                  <span>{utils.date.getDateType(boardInfo.communityDetail.created_at)}</span>
                </div>
              </layout.DetailContainer>
            </section>
          </div>
        </div>

        <div className="anotherListContainer middleInner">
          <layout.AnotherContents data={boardInfo.communityDetail} />
        </div>

        <div className="goListButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"blackOutline"}
            size={"small"}
            onClick={() => {
              navigate(location.state || "/community");
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CommunityDetailPage;
