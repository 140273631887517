import qs from 'qs';

//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }catch(e){
    return "0";
  }
}

function getFilterStr(parameter){
  let filter = qs.stringify(parameter, { encode: false });
  return decodeURIComponent(filter);
}

function getSearchParam(){
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
}

function getSearchParamNew() {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        ) +
        '"}'
    );
  } catch (e) {
    return {};
  }
}

function getQueryParamUrl(obj, isClear, isParamOnly) {
  try {
    obj = obj ? obj : {};
    let searchParam = isClear ? {} : getSearchParamNew();
    for (let key in obj) {
      if (obj[key] == null) {
        delete searchParam[key];
        continue;
      }
      searchParam[key] = decodeURIComponent(obj[key]);
    }
    if (isParamOnly) {
      return getFilterStr(searchParam);
    }
    return `${window.location.pathname}?${getFilterStr(searchParam)}`;
  } catch (e) {
    return {};
  }
}

export {
  numberWithCommas,
  getFilterStr,
  getSearchParam,
  getSearchParamNew,
  getQueryParamUrl
};

export default {
  numberWithCommas,
  getFilterStr,
  getSearchParam,
  getSearchParamNew,
  getQueryParamUrl
};