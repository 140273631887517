import {
  DefaultModal,
  CopyModal,
  DeleteModal,
  BellModal,
  ReportModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

export default function ModalGeneratePage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      modalOption.copyModal.show ||
      modalOption.deleteModal.show ||
      modalOption.bellModal.show ||
      modalOption.reportModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // url 복사 완료 모달
        modalOption.copyModal.show ? (
          <CopyModal
            modalOption={modalOption.copyModal}
            modalClose={() => {
              modalOption.setCopyModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 회원 탈퇴 모달
        modalOption.deleteModal.show ? (
          <DeleteModal
            modalOption={modalOption.deleteModal}
            modalClose={() => {
              modalOption.setDeleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 신고 모달
        modalOption.reportModal.show ? (
          <ReportModal
            modalOption={modalOption.reportModal}
            modalClose={() => {
              modalOption.setReportModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 알람 모달
        modalOption.bellModal.show ? (
          <BellModal
            modalOption={modalOption.bellModal}
            modalClose={() => {
              modalOption.setBellModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
