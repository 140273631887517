import { useNavigate } from "react-router-dom";
import Styles from "./styles";

function ContentsList({ data, boardInfo, likeInfo, myBoard }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <div className="left">
        <p className="title">
          {
            data?.category_title && `[${data?.category_title}] `
          }
          {data.title}
        </p>
        <p className="text">{data.description_text}</p>
      </div>

      <div className="buttonContainer">
        {myBoard ? (
          <>
            <button
              type="button"
              className="editButton"
              onClick={(e) => {
                e.stopPropagation();
                navigate(boardInfo.boardLocation(data.type, true) + "?id=" + data.id, { state: "/mypage/my-list" });
              }}
            >
              수정
            </button>
            <button
              type="button"
              className="deleteButton"
              onClick={(e) => {
                e.stopPropagation();
                boardInfo.deleteBoard(data.id)
              }}
            >
              삭제
            </button>
          </>
        ) : (
          <button
            type="button"
            className="deleteButton"
            onClick={(e) => {
              e.stopPropagation();
              likeInfo.deleteLike(data.id, 1)
            }}
          >
            삭제
          </button>
        )}
      </div>
    </Styles.Container>
  );
}

export default ContentsList;
