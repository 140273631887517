import styled from "styled-components";

const Container = styled.div`
  & .failityDetailContainer {
    margin-bottom: 40px;

    &:not(:last-child) {
      padding-bottom: 40px;
      border-bottom: 1px solid #eff0f2;
    }
  }

  & .failityDetailTitle {
    margin-bottom: 39px;
    font-size: 24px;
    font-weight: bold;
  }

  & .detailList {
    display: flex;
    align-items: center;
    font-size: 18px;

    &:not(:last-child) {
      margin-bottom: 31px;
    }

    & .listTitle {
      display: flex;
      align-items: center;
      font-weight: bold;
      width: 248px;

      & .iconWrap {
        display: inline-block;
        width: 26px;
        height: auto;
        margin-right: 20px;
      }
    }

    & .listText {
      font-weight: 500;
    }
  }

  @media (max-width: 1600px) {
    & .failityDetailContainer {
      margin-bottom: calc(100vw * (40 / 1600));

      &:not(:last-child) {
        padding-bottom: calc(100vw * (40 / 1600));
      }
    }

    & .failityDetailTitle {
      margin-bottom: calc(100vw * (39 / 1600));
      font-size: calc(100vw * (24 / 1600));
    }

    & .detailList {
      font-size: calc(100vw * (18 / 1600));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (31 / 1600));
      }

      & .listTitle {
        width: calc(100vw * (248 / 1600));

        & .iconWrap {
          width: calc(100vw * (26 / 1600));
          margin-right: calc(100vw * (20 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .failityDetailContainer {
      margin-bottom: calc(100vw * (30 / 370));

      &:not(:last-child) {
        padding-bottom: calc(100vw * (20 / 370));
      }
    }

    & .failityDetailTitle {
      margin-bottom: calc(100vw * (20 / 370));
      font-size: calc(100vw * (18 / 370));
    }

    & .detailList {
      align-items: flex-start;
      font-size: calc(100vw * (14 / 370));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .listTitle {
        flex-shrink: 0;
        align-items: flex-start;
        width: calc(100vw * (140 / 370));

        & .iconWrap {
          width: calc(100vw * (26 / 370));
          margin-right: calc(100vw * (10 / 370));
        }

        & .listTitleText {
          padding-top: calc(100vw * (3 / 370));
        }
      }

      & .listText {
        padding-top: calc(100vw * (3 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
