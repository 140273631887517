import utils from "utils";
import Styles from "./styles";
import { useBoard } from "hooks";
import { common, layout, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgBlog from "resources/image/img_blog.png";
import ImgBlogMb from "resources/image/img_blog_mb.png";

function BlogDetailPage() {
  const boardInfo = useBoard({ options: { enableDetail: true } });
  const location = useLocation();
  const navigate = useNavigate();

  const data = {
    img: ImgBlog,
    mbImg: ImgBlogMb,
    nowText: "블로그",
    title: "블로그",
  };

  const detailData = boardInfo.detail

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={detailData}>
                <layout.UserProfileImg profileImg={detailData.profile_image} />
                <h2 className="title">{detailData.title}</h2>
                <div className="detailInfo">
                  <span>{detailData.nickname}</span>
                  <span>{utils.date.getHourMinStrByMin(detailData.created_at)}</span>
                </div>
              </layout.DetailContainer>
            </section>
          </div>
        </div>

        <div className="anotherListContainer middleInner">
          <layout.AnotherContents data={detailData} />
        </div>

        <div className="goListButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"blackOutline"}
            size={"small"}
            onClick={() => {
              navigate(location.state || "/blog");
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BlogDetailPage;
