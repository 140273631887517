import utils from "utils";
import Styles from "./styles";
import { useAlarm, useAuth } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// img
import IconSchool from "resources/image/icon/icon_school.svg";
import IconPlus from "resources/image/icon/icon_plus01.svg";
import IconPlusOn from "resources/image/icon/icon_plus_on.svg";

function EducationInfoList({ data }) {
  const navigate = useNavigate();
  const location = useLocation();

  const authInfo = useAuth();
  const alarmInfo = useAlarm();
  const [isBell, setIsBell] = useState(false);

  useEffect(()=>{
    setIsBell(data.alarm_yn === "Y" ? true : false)
  },[data.alarm_yn])

  if (data) {
    return (
      <Styles.Container
        onClick={() => {
          if (authInfo.certification(false)) {
            navigate("/education-info/month?id=" + data.id, { state: location.state });
          }
        }}
      >
        <div className="iconWrap">
          <img src={IconSchool} alt="IconSchool" />
        </div>

        <div className="timeWrap">
          <div className="tag">{data.school}</div>
          <p className="time">{utils.date.getTimeKr(data.target_time, ":")}</p>
        </div>

        <div className="titleWrap">
          <p className="listTitle">{data.title}</p>

          <p className="address">{data.location}</p>
        </div>

        <button
          type="button"
          className="moreLink"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (authInfo.certification()) {
              alarmInfo.alarmToggle(setIsBell, isBell, data.id);
            }
          }}
        >
          <img src={isBell ? IconPlusOn : IconPlus} alt="IconPlus" />
        </button>
      </Styles.Container>
    );
  }
}

export default EducationInfoList;
