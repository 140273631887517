import styled from "styled-components";

const Container = styled.div`
  padding-top: 80px;
  background-color: #f9fafc;

  & .writingSection {
    min-height: calc(100vh - 231px);
    padding: 80px 0 200px;
    background-color: #fff;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);

    & .titleContainer {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 2px solid var(--black-color-01);

      & .sectionTitle {
        margin-bottom: 10px;
        font-size: 46px;
        font-weight: bold;
      }

      & .guideText {
        font-size: 16px;
      }
    }

    & .categoryContainer {
      width: 203px;
      margin-bottom: 30px;
    }

    & .thumbnailContainer {
      display: flex;

      & .imgWrap {
        width: 116px;
        height: 80px;
        margin-right: 20px;
        background-color: #f9fafc;
        border-radius: 10px;
        overflow: hidden;
      }

      & .uploadContainer {
        flex-grow: 1;
        width: calc(100% - 136px);
        margin-bottom: 30px;

        & .inputWrap {
          display: flex;
          margin-bottom: 10px;
          overflow: hidden;

          & input[type="file"] {
            display: none;
          }

          & .fileText {
            flex-grow: 1;
            display: flex;
            align-items: center;
            width: calc(100% - 106px);
            height: 50px;
            padding: 0 20px;
            border: 1px solid var(--gray-color-04);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;

            & p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            & .deleteButton {
              flex-shrink: 0;
              width: 14px;
              height: 14px;
              margin-left: 5px;
            }
          }

          & label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 106px;
            height: 50px;
            font-weight: 500;
            color: #fff;
            background-color: var(--black-color-01);
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            cursor: pointer;
          }
        }

        & .grayText {
          color: var(--gray-color-03);
        }
      }
    }

    & .titleInputWrap {
      margin-bottom: 10px;

      & input {
        width: 100%;
        height: 100px;
        padding: 0 20px;
        font-size: 36px;
        font-weight: 700;
        background-color: #f9fafc;

        &::placeholder {
          color: var(--gray-color-03);
        }
      }
    }

    & .keywordContainer {
      margin-top: 10px;

      & .enterContainer {
        display: flex;
        gap: 10px;

        & .inputWrap {
          position: relative;
          flex-grow: 1;

          &.on {
            &::before {
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
              content: "#";
            }

            & input {
              padding-left: 33px;
            }
          }

          & .keywordListContainer {
            position: absolute;
            top: 51px;
            left: 0;
            width: 100%;
            max-height: 210px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow: auto;
            filter: drop-shadow(4px 10px 20px rgba(0, 0, 0, 0.06));
            z-index: 1;

            & .listWrap {
              background-color: #fff;

              & .list {
                display: flex;
                align-items: center;
                height: 40px;
                padding: 0 20px;
                cursor: pointer;

                &:not(:last-child) {
                  border-bottom: 1px solid #eff0f2;
                }
              }
            }
          }
        }

        & .submitButton {
          flex-shrink: 0;
          width: 140px;
        }
      }

      & .keywordListWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
      }
    }

    & .bottomButtonListWrap {
      display: flex;
      gap: 10px;
      justify-content: center;
      margin-top: 80px;

      & .bottomButtonList {
        width: 250px;
      }
    }
  }

  @media (max-width: 1600px) {
    padding-top: calc(100vw * (80 / 1600));

    & .writingSection {
      min-height: calc(100vh - calc(100vw * (231 / 1600)));
      padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .titleContainer {
        margin-bottom: calc(100vw * (30 / 1600));
        padding-bottom: calc(100vw * (30 / 1600));
        border-bottom: calc(100vw * (2 / 1600)) solid var(--black-color-01);

        & .sectionTitle {
          margin-bottom: calc(100vw * (10 / 1600));
          font-size: calc(100vw * (46 / 1600));
        }

        & .guideText {
          font-size: calc(100vw * (16 / 1600));
        }
      }

      & .categoryContainer {
        width: calc(100vw * (203 / 1600));
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .thumbnailContainer {
        & .imgWrap {
          width: calc(100vw * (116 / 1600));
          height: calc(100vw * (80 / 1600));
          margin-right: calc(100vw * (20 / 1600));
          border-radius: calc(100vw * (10 / 1600));
        }

        & .uploadContainer {
          width: calc(100% - calc(100vw * (136 / 1600)));
          margin-bottom: calc(100vw * (30 / 1600));

          & .inputWrap {
            margin-bottom: calc(100vw * (10 / 1600));

            & .fileText {
              width: calc(100% - calc(100vw * (106 / 1600)));
              height: calc(100vw * (50 / 1600));
              padding: 0 calc(100vw * (20 / 1600));
              border-top-left-radius: calc(100vw * (2 / 1600));
              border-bottom-left-radius: calc(100vw * (2 / 1600));

              & .deleteButton {
                width: calc(100vw * (14 / 1600));
                height: calc(100vw * (14 / 1600));
                margin-left: calc(100vw * (5 / 1600));
              }
            }

            & label {
              width: calc(100vw * (106 / 1600));
              height: calc(100vw * (50 / 1600));
              border-top-right-radius: calc(100vw * (2 / 1600));
              border-bottom-right-radius: calc(100vw * (2 / 1600));
            }
          }
        }
      }

      & .titleInputWrap {
        margin-bottom: calc(100vw * (10 / 1600));

        & input {
          height: calc(100vw * (100 / 1600));
          padding: 0 calc(100vw * (20 / 1600));
          font-size: calc(100vw * (36 / 1600));
        }
      }

      & .keywordContainer {
        margin-top: calc(100vw * (10 / 1600));

        & .enterContainer {
          gap: calc(100vw * (10 / 1600));

          & .inputWrap {
            &.on {
              &::before {
                left: calc(100vw * (20 / 1600));
              }

              & input {
                padding-left: calc(100vw * (33 / 1600));
              }
            }

            & .keywordListContainer {
              top: calc(100vw * (51 / 1600));
              max-height: calc(100vw * (210 / 1600));
              border-bottom-left-radius: calc(100vw * (5 / 1600));
              border-bottom-right-radius: calc(100vw * (5 / 1600));
              filter: drop-shadow(
                calc(100vw * (4 / 1600)) calc(100vw * (10 / 1600))
                  calc(100vw * (20 / 1600)) rgba(0, 0, 0, 0.06)
              );

              & .listWrap {
                & .list {
                  height: calc(100vw * (40 / 1600));
                  padding: 0 calc(100vw * (20 / 1600));
                }
              }
            }
          }

          & .submitButton {
            width: calc(100vw * (140 / 1600));
          }
        }

        & .keywordListWrap {
          gap: calc(100vw * (10 / 1600));
          margin-top: calc(100vw * (10 / 1600));
        }
      }

      & .bottomButtonListWrap {
        gap: calc(100vw * (10 / 1600));
        margin-top: calc(100vw * (80 / 1600));

        & .bottomButtonList {
          width: calc(100vw * (250 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (50 / 370));

    & .writingSection {
      min-height: calc(100vh - calc(100vw * (273 / 370)));
      padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));
      box-shadow: 0px calc(100vw * (19 / 370)) calc(100vw * (35 / 370)) 0px
        rgba(15, 40, 102, 0.1);

      & .middleInner {
        padding: 0 calc(100vw * (15 / 370));
      }

      & .titleContainer {
        margin-bottom: calc(100vw * (20 / 370));
        padding-bottom: calc(100vw * (20 / 370));
        border-bottom: calc(100vw * (2 / 370)) solid var(--black-color-01);

        & .sectionTitle {
          margin-bottom: calc(100vw * (5 / 370));
          font-size: calc(100vw * (24 / 370));
        }

        & .guideText {
          font-size: calc(100vw * (14 / 370));
        }
      }

      & .categoryContainer {
        width: calc(100vw * (140 / 370));
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .thumbnailContainer {
        flex-direction: column;

        & .imgWrap {
          width: 100%;
          height: calc(100vw * (200 / 370));
          margin: 0 0 calc(100vw * (30 / 370));
          border-radius: calc(100vw * (10 / 370));
        }

        & .uploadContainer {
          width: 100%;
          margin-bottom: calc(100vw * (30 / 370));

          & .inputWrap {
            margin-bottom: calc(100vw * (5 / 370));

            & .fileText {
              width: calc(100% - calc(100vw * (106 / 370)));
              height: calc(100vw * (50 / 370));
              padding: 0 calc(100vw * (10 / 370));
              border-top-left-radius: calc(100vw * (2 / 370));
              border-bottom-left-radius: calc(100vw * (2 / 370));

              & .deleteButton {
                width: calc(100vw * (14 / 370));
                height: calc(100vw * (14 / 370));
                margin-left: calc(100vw * (5 / 370));
              }
            }

            & label {
              width: calc(100vw * (106 / 370));
              height: calc(100vw * (50 / 370));
              border-top-right-radius: calc(100vw * (2 / 370));
              border-bottom-right-radius: calc(100vw * (2 / 370));
            }
          }
        }
      }

      & .titleInputWrap {
        margin-bottom: calc(100vw * (20 / 370));

        & input {
          height: calc(100vw * (50 / 370));
          padding: 0 calc(100vw * (10 / 370));
          font-size: calc(100vw * (20 / 370));
        }
      }

      & .keywordContainer {
        margin-top: calc(100vw * (20 / 370));

        & .enterContainer {
          flex-direction: column;
          gap: calc(100vw * (10 / 370));

          & .inputWrap {
            &.on {
              &::before {
                left: calc(100vw * (15 / 370));
              }

              & input {
                padding-left: calc(100vw * (22 / 370));
              }
            }

            & .keywordListContainer {
              top: calc(100vw * (50 / 370));
              max-height: calc(100vw * (210 / 370));
              border-bottom-left-radius: calc(100vw * (5 / 370));
              border-bottom-right-radius: calc(100vw * (5 / 370));
              filter: drop-shadow(
                calc(100vw * (4 / 370)) calc(100vw * (10 / 370))
                  calc(100vw * (20 / 370)) rgba(0, 0, 0, 0.06)
              );

              & .listWrap {
                & .list {
                  height: calc(100vw * (40 / 370));
                  padding: 0 calc(100vw * (20 / 370));
                }
              }
            }
          }

          & .submitButton {
            width: 100%;
          }
        }

        & .keywordListWrap {
          gap: calc(100vw * (5 / 370));
          margin-top: calc(100vw * (20 / 370));
        }
      }

      & .bottomButtonListWrap {
        flex-direction: column-reverse;
        align-items: center;
        gap: calc(100vw * (10 / 370));
        margin-top: calc(100vw * (80 / 370));

        & .bottomButtonList {
          width: calc(100vw * (155 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
