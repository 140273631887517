import styled from "styled-components";

// img
import IconLogo from "resources/image/icon/logo02.svg";

const Container = styled.div`
  & .wrap {
    display: flex;
    gap: 108px;

    & .faqContainer {
      flex-shrink: 0;
      width: 428px;
      height: 512px;
      padding: 80px 0;
      font-weight: 500;
      background: url(${IconLogo}) no-repeat left bottom / 187px 184px #f9fafc;
      border-radius: 10px;

      & .text {
        margin-bottom: 30px;
        text-align: center;
        line-height: 1.71;

        & span {
          color: var(--main-color);
        }
      }

      & .faqButton {
        width: 250px;
        margin: 0 auto;
      }
    }

    & .formContainer {
      width: calc(100% - 536px);

      & .list {
        &:not(:last-child) {
          margin-bottom: 30px;
        }

        & .inputContainer label:not(.fileUploadButton),
        .inputTitle {
          width: 127px;
          font-size: 18px;
        }

        &.textarea .inputContainer {
          align-items: flex-start;
        }

        &.file {
          & .inputContainer {
            display: flex;
            align-items: center;

            & .inputTitle {
              display: flex;
              align-items: center;
              gap: 12px;
              font-weight: 500;

              & .iconWrap {
                position: relative;
                width: 20px;
                height: 20px;
                cursor: pointer;
              }

              & .questionModal {
                position: absolute;
                bottom: calc(100% + 10px);
                right: -51px;
                width: max-content;
                padding: 23px 29px;
                font-size: 12px;
                color: #fff;
                background-color: var(--black-color-01);
                border-radius: 2px;

                &::after {
                  position: absolute;
                  bottom: -5px;
                  right: 57px;
                  border-top: 9px solid var(--black-color-01);
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  content: "";
                }
              }
            }

            & input[type="file"] {
              display: none;
            }

            & .fileUploadButton {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              width: 220px;
              height: 50px;
              font-weight: 500;
              color: var(--gray-color-02);
              background-color: var(--gray-color-04);
              border-radius: 2px;
              cursor: pointer;
            }
          }

          & .fileListWrap {
            display: flex;
            flex-wrap: wrap;
            gap: 9px;
            margin: 20px 0 0 127px;

            & .fileList {
              display: flex;
              align-items: center;
              gap: 8px;
              max-width: 100%;

              & p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              & .deleteButton {
                flex-shrink: 0;
                display: block;
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        & .errorText {
          margin: 5px 0 0 127px;
          color: var(--red-color);
        }
      }
    }
  }

  & .askButton {
    width: 250px;
    margin: 80px auto 0;
  }

  @media (max-width: 1600px) {
    & .wrap {
      gap: calc(100vw * (108 / 1600));

      & .faqContainer {
        width: calc(100vw * (428 / 1600));
        height: calc(100vw * (512 / 1600));
        padding: calc(100vw * (80 / 1600)) calc(100vw * (89 / 1600));
        background: url(${IconLogo}) no-repeat left bottom /
          calc(100vw * (187 / 1600)) calc(100vw * (184 / 1600)) #f9fafc;
        border-radius: calc(100vw * (10 / 1600));

        & .text {
          margin-bottom: calc(100vw * (30 / 1600));
        }

        & .faqButton {
          width: calc(100vw * (250 / 1600));
        }
      }

      & .formContainer {
        width: calc(100% - calc(100vw * (536 / 1600)));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 1600));
          }

          & .inputContainer label:not(.fileUploadButton),
          .inputTitle {
            width: calc(100vw * (127 / 1600));
            font-size: calc(100vw * (18 / 1600));
          }

          &.file {
            & .inputContainer {
              & .inputTitle {
                gap: calc(100vw * (12 / 1600));

                & .iconWrap {
                  width: calc(100vw * (20 / 1600));
                  height: calc(100vw * (20 / 1600));
                }

                & .questionModal {
                  bottom: calc(100% + calc(100vw * (10 / 1600)));
                  right: calc(100vw * (-51 / 1600));
                  padding: calc(100vw * (23 / 1600)) calc(100vw * (29 / 1600));
                  font-size: calc(100vw * (12 / 1600));
                  border-radius: calc(100vw * (2 / 1600));

                  &::after {
                    bottom: calc(100vw * (-5 / 1600));
                    right: calc(100vw * (57 / 1600));
                    border-top: calc(100vw * (9 / 1600)) solid
                      var(--black-color-01);
                    border-left: calc(100vw * (5 / 1600)) solid transparent;
                    border-right: calc(100vw * (5 / 1600)) solid transparent;
                  }
                }
              }

              & .fileUploadButton {
                gap: calc(100vw * (8 / 1600));
                width: calc(100vw * (220 / 1600));
                height: calc(100vw * (50 / 1600));
                border-radius: calc(100vw * (2 / 1600));
              }
            }

            & .fileListWrap {
              gap: calc(100vw * (9 / 1600));
              margin: calc(100vw * (20 / 1600)) 0 0 calc(100vw * (127 / 1600));

              & .fileList {
                gap: calc(100vw * (8 / 1600));

                & .deleteButton {
                  width: calc(100vw * (16 / 1600));
                  height: calc(100vw * (16 / 1600));
                }
              }
            }
          }

          & .errorText {
            margin: calc(100vw * (5 / 1600)) 0 0 calc(100vw * (127 / 1600));
          }
        }
      }
    }

    & .askButton {
      width: calc(100vw * (250 / 1600));
      margin: calc(100vw * (80 / 1600)) auto 0;
    }
  }

  @media (max-width: 768px) {
    & .wrap {
      flex-direction: column;
      gap: calc(100vw * (50 / 370));

      & .faqContainer {
        position: relative;
        width: 100%;
        height: calc(100vw * (237 / 370));
        padding: calc(100vw * (60 / 370)) 0;
        background: none;
        border-radius: 0;

        &::before {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;
          height: 100%;
          background: url(${IconLogo}) no-repeat left bottom /
            calc(100vw * (80 / 370)) calc(100vw * (79 / 370)) #f9fafc;
          content: "";
        }

        & .text {
          position: relative;
          margin-bottom: calc(100vw * (20 / 370));
        }

        & .faqButton {
          position: relative;
          width: calc(100vw * (155 / 370));
        }
      }

      & .formContainer {
        width: 100%;

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 370));
          }

          & .inputContainer label:not(.fileUploadButton),
          .inputTitle {
            width: 100%;
            font-size: calc(100vw * (16 / 370));
          }

          &.file {
            & .inputContainer {
              flex-direction: column;
              align-items: flex-start;

              & .inputTitle {
                gap: calc(100vw * (6 / 370));
                margin-bottom: calc(100vw * (10 / 370));

                & .iconWrap {
                  width: calc(100vw * (19 / 370));
                  height: calc(100vw * (19 / 370));
                }

                & .questionModal {
                  bottom: calc(100% + calc(100vw * (7 / 370)));
                  right: calc(100vw * (-132 / 370));
                  padding: calc(100vw * (18 / 370)) calc(100vw * (17 / 370));
                  font-size: calc(100vw * (12 / 370));
                  border-radius: calc(100vw * (2 / 370));

                  &::after {
                    bottom: calc(100vw * (-5 / 370));
                    right: calc(100vw * (136 / 370));
                    border-top: calc(100vw * (9 / 370)) solid
                      var(--black-color-01);
                    border-left: calc(100vw * (5 / 370)) solid transparent;
                    border-right: calc(100vw * (5 / 370)) solid transparent;
                  }
                }
              }

              & .fileUploadButton {
                gap: calc(100vw * (8 / 370));
                width: calc(100vw * (165 / 370));
                height: calc(100vw * (41 / 370));
                border-radius: 0;
              }
            }

            & .fileListWrap {
              flex-direction: column;
              gap: calc(100vw * (10 / 370));
              margin: calc(100vw * (20 / 370)) 0 0;

              & .fileList {
                gap: calc(100vw * (8 / 370));

                & .deleteButton {
                  width: calc(100vw * (16 / 370));
                  height: calc(100vw * (16 / 370));
                }
              }
            }
          }

          & .errorText {
            margin: calc(100vw * (5 / 370)) 0 0;
          }
        }
      }
    }

    & .askButton {
      width: calc(100vw * (155 / 370));
      margin: calc(100vw * (50 / 370)) auto 0;
    }
  }
`;

export { Container };

export default { Container };
