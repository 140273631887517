import Styles from "./styles";
import { useRef } from "react";
import { IMAGEPATH } from "service/connector";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useBanner, useBoard, useCalendar } from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right01.svg";
import IconArrowLeftWhite from "resources/image/icon/icon_arrow_left_white.svg";
import IconArrowRightWhite from "resources/image/icon/icon_arrow_right_white.svg";

function MainPage() {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const authInfo = useAuth();
  const bannerInfo = useBanner();
  const boardInfo = useBoard({
    options: {
      mainState: 31,
    },
    blogBest: true,
  });
  const calendarInfo = useCalendar({
    options: {
      schedule: true,
      scheduleList: true,
    },
  });

  const blogBest = boardInfo.getBlogBest?.data || [];

  const navigate = useNavigate();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const educationList = [
    {
      title: authInfo.userData.location + " 지역커뮤니티",
      moreUrl: "/community",
      detailUrl: "/community/detail",
      list: boardInfo.mainCommunityList,
    },
    {
      title: authInfo.userData.location + " 교육커뮤니티",
      moreUrl: "/education-info",
      detailUrl: "/education-info/share",
      list: boardInfo.mainEducationShareList.list,
    },
  ];

  const infoList = [
    {
      title: "학원 정보",
      moreUrl: "facility",
      list: boardInfo.mainFacilityList,
    },
    {
      title: "독서실 정보",
      moreUrl: "reading-room",
      list: boardInfo.mainBookList,
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="mainBannerSection">
          <Swiper
            pagination={true}
            modules={[Autoplay, Pagination]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mainBannerSlider"
          >
            {bannerInfo.getBannerTop?.data?.map((item, index) => {
              return (
                <SwiperSlide key={"slideList" + index}>
                  <Link to={item.url}>
                    <img
                      src={IMAGEPATH + (isMobile ? item.m_path : item.path)}
                      alt={item.title}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>

        <section className="calendarSection middleInner">
          <layout.CalendarContainer main calendarInfo={calendarInfo} />
        </section>

        <section className="locationSection">
          <div className="middleInner">
            {educationList.map((item, index) => {
              return (
                <div
                  className="locationContainer"
                  key={"locationContainer" + index}
                >
                  <div className="titleContainer">
                    <h2 className="title">{item.title}</h2>

                    <Link to={item.moreUrl} className="moreButton">
                      더보기
                      <span className="iconWrap">
                        <img src={IconArrowRight} alt="IconArrowRight" />
                      </span>
                    </Link>
                  </div>

                  <ul className="listWrap">
                    {item.list?.slice(0, 5)?.map((current, idx) => {
                      return (
                        <li
                          className="list"
                          key={"list" + idx}
                          onClick={() => {
                            if (authInfo.certification(false)) {
                              navigate(item.detailUrl + "?id=" + current.id);
                            }
                          }}
                        >
                          <layout.EducationList min data={current} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </section>

        <section className="recommendedBlogSection">
          <h2 className="title">추천 블로그</h2>

          <div className="middleInner">
            <Link to={"/blog?category=1&page=1"} className="moreButton">
              더보기
              <span className="iconWrap">
                <img src={IconArrowRightWhite} alt="IconArrowRight" />
              </span>
            </Link>

            <Swiper
              loop={true}
              slidesPerView={"auto"}
              spaceBetween={35}
              speed={1500}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, Navigation]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              breakpoints={{
                768: {
                  spaceBetween: 25,
                },
              }}
              className="blogSlider"
            >
              {blogBest.map((item, index) => {
                return (
                  <SwiperSlide
                    key={"blogSlider" + index}
                    onClick={() => {
                      if (authInfo.certification(false)) {
                        navigate("/blog/detail?id=" + item.id);
                      }
                    }}
                  >
                    <layout.BlogCardList data={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <button
              type="button"
              className="prevButton"
              ref={navigationPrevRef}
            >
              <img src={IconArrowLeftWhite} alt="IconArrowLeftWhite" />
            </button>

            <button
              type="button"
              className="nextButton"
              ref={navigationNextRef}
            >
              <img src={IconArrowRightWhite} alt="IconArrowRightWhite" />
            </button>
          </div>
        </section>

        <section className="subBannerSection">
          <div className="middleInner">
            <Swiper
              navigation={true}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="subBannerSlider"
            >
              {bannerInfo.getBannerBottom?.data?.map((item, index) => {
                return (
                  <SwiperSlide key={"slideList" + index}>
                    <Link to={item.url}>
                      <img
                        src={IMAGEPATH + (isMobile ? item.m_path : item.path)}
                        alt={item.title}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>

        <section className="infoSection">
          <div className="middleInner">
            <h2 className="title">지역 교육시설 검색</h2>

            <div className="infoArea">
              {infoList?.map((item, index) => {
                return (
                  <div className="infoContainer" key={"infoContainer" + index}>
                    <div className="titleContainer">
                      <p className="infoTitle">{item.title}</p>

                      <Link
                        to={"/facility-info/" + item.moreUrl}
                        className="moreButton"
                      >
                        더보기
                        <span className="iconWrap">
                          <img src={IconArrowRight} alt="IconArrowRight" />
                        </span>
                      </Link>
                    </div>

                    <ul className="listWrap">
                      {item.list?.slice(0, 4)?.map((current, idx) => {
                        return (
                          <li
                            className="list"
                            key={"list" + idx}
                            onClick={() => {
                              navigate(
                                "/facility-info/" +
                                  item.moreUrl +
                                  "/detail?id=" +
                                  current.id
                              );
                            }}
                          >
                            <layout.FacilityInfoList data={current} main />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default MainPage;
