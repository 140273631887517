import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 394px;
    height: 370px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;

    &.big {
      height: 455px;
    }

    & .iconWrap {
      width: 45px;
      height: 45px;
      margin-bottom: 30px;
    }

    & .title {
      margin-bottom: 6px;
      font-size: 22px;
      font-weight: 700;
    }

    & .text {
      font-size: 16px;
      line-height: 1.37;
      letter-spacing: -0.03em;
    }

    & .buttonListWrap {
      width: 274px;
      margin-top: 50px;

      & .buttonList:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 1600px) {
    & .modalContainer {
      width: calc(100vw * (394 / 1600));
      height: calc(100vw * (370 / 1600));
      border-radius: calc(100vw * (10 / 1600));

      &.big {
        height: calc(100vw * (455 / 1600));
      }

      & .iconWrap {
        width: calc(100vw * (45 / 1600));
        height: calc(100vw * (45 / 1600));
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (6 / 1600));
        font-size: calc(100vw * (22 / 1600));
      }

      & .text {
        font-size: calc(100vw * (16 / 1600));
      }

      & .buttonListWrap {
        width: calc(100vw * (274 / 1600));
        margin-top: calc(100vw * (50 / 1600));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (5 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalContainer {
      width: calc(100vw * (320 / 370));
      height: calc(100vw * (355 / 370));
      border-radius: calc(100vw * (10 / 370));

      &.big {
        height: calc(100vw * (355 / 370));
      }

      & .iconWrap {
        width: calc(100vw * (46 / 370));
        height: calc(100vw * (46 / 370));
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .title {
        margin-bottom: calc(100vw * (6 / 370));
        font-size: calc(100vw * (18 / 370));
      }

      & .text {
        font-size: calc(100vw * (14 / 370));
      }

      & .buttonListWrap {
        width: calc(100vw * (230 / 370));
        margin-top: calc(100vw * (50 / 370));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (5 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
