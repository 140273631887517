import MainPage from "./MainPage";
import SearchPage from "./SearchPage";
import ParentsConfirmPage from "./ParentsConfirmPage";
import ParentsConfirmCompletePage from "./ParentsConfirmCompletePage";
import LoginPage from "./LoginPage";
import FindPasswordPage from "./FindPasswordPage";
import SignupPage from "./SignupPage";
import SignupStepAPage from "./SignupStepAPage";
import SignupStepBPage from "./SignupStepBPage";
import SignupCompletePage from "./SignupCompletePage";
import EdusourcePage from "./EdusourcePage";
import CustomerSupportPage from "./CustomerSupportPage";
import NoticeDetailPage from "./NoticeDetailPage";
import TermsPage from "./TermsPage";
import EducationInfoPage from "./EducationInfoPage";
import ThisMonthInfoPage from "./ThisMonthInfoPage";
import AcademyInfoPage from "./AcademyInfoPage";
import ShareInfoPage from "./ShareInfoPage";
import BlogPage from "./BlogPage";
import BlogDetailPage from "./BlogDetailPage";
import CommunityPage from "./CommunityPage";
import CommunityDetailPage from "./CommunityDetailPage";
import FacilityInfoPage from "./FacilityInfoPage";
import FacilityInfoListPage from "./FacilityInfoListPage";
import FacilityInfoDetailPage from "./FacilityInfoDetailPage";
import WritingPage from "./WritingPage";
import EditPage from "./EditPage";
import MyPage from "./MyPage";
import ChangePasswordPage from "./ChangePasswordPage";
import ChangePasswordCompletePage from "./ChangePasswordCompletePage";
import ModalGeneratePage from "./ModalGeneratePage";

export default {
  MainPage,
  SearchPage,
  ParentsConfirmPage,
  ParentsConfirmCompletePage,
  LoginPage,
  FindPasswordPage,
  SignupPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupCompletePage,
  EdusourcePage,
  CustomerSupportPage,
  NoticeDetailPage,
  TermsPage,
  EducationInfoPage,
  ThisMonthInfoPage,
  AcademyInfoPage,
  ShareInfoPage,
  BlogPage,
  BlogDetailPage,
  CommunityPage,
  CommunityDetailPage,
  FacilityInfoPage,
  FacilityInfoListPage,
  FacilityInfoDetailPage,
  WritingPage,
  EditPage,
  MyPage,
  ChangePasswordPage,
  ChangePasswordCompletePage,
  ModalGeneratePage,
};

export {
  MainPage,
  SearchPage,
  ParentsConfirmPage,
  ParentsConfirmCompletePage,
  LoginPage,
  FindPasswordPage,
  SignupPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupCompletePage,
  EdusourcePage,
  CustomerSupportPage,
  NoticeDetailPage,
  TermsPage,
  EducationInfoPage,
  ThisMonthInfoPage,
  AcademyInfoPage,
  ShareInfoPage,
  BlogPage,
  BlogDetailPage,
  CommunityPage,
  CommunityDetailPage,
  FacilityInfoPage,
  FacilityInfoListPage,
  FacilityInfoDetailPage,
  WritingPage,
  EditPage,
  MyPage,
  ChangePasswordPage,
  ChangePasswordCompletePage,
  ModalGeneratePage,
};
