import connector from "service/connector"

// 회원가입
const signUp = async (data) => {
  return await connector.authConnector("sign", "POST", data);
};
// 닉네임 중복체크
const getCheckName = async (path) => {
  return await connector.connectFetchController("check/nickname?nickname=" + path, "GET");
};
// 로그인
const login = async (data) => {
  return await connector.authConnector("login", "POST", data);
};
// 소셜 로그인
const socialLogin = async (path) => {
  return await connector.authConnector("social/login?type=" + path, "GET");
};
// 소셜 회원가입
const socialSignUp = async (data) => {
  return await connector.authConnector("social/sign", "POST", data);
};
// 현재 로그인 된 아이디 정보 호출
const getUser = async () => {
  return await connector.authConnector("user", "GET");
};
const getNice = async () => {
  return await connector.authConnector("social/nice", "POST");
}
// 회원정보 변경
const userUpdate = async (data) => {
  return await connector.authConnector("user/update", "POST", data)
}
// 비밀번호 변경 ( 이메일 )
const changeByEmail = async (path) => {
  return await connector.connectFetchController("pwd/change?email=" + path, "GET");
};
// 비밀번호 변경 ( 직접 )
const changeByDirectly = async (data) => {
  return await connector.authConnector("user/pwd", "PUT", data);
};
// 회원탈퇴
const resign = async (data) => {
  return await connector.authConnector("user/withdraw", "POST", data);
};

export {
  signUp,
  getCheckName,
  login,
  socialLogin,
  socialSignUp,
  getUser,
  getNice,
  userUpdate,
  changeByEmail,
  changeByDirectly,
  resign
}

export default {
  signUp,
  getCheckName,
  login,
  socialLogin,
  socialSignUp,
  getUser,
  getNice,
  userUpdate,
  changeByEmail,
  changeByDirectly,
  resign
}