import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding-top: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--black-color-01);
    content: "";
  }

  & .topContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    & .listNumber {
      font-weight: 500;
      color: #9d9d9d;

      & span {
        color: var(--black-color-01);
      }
    }

    & .allDeleteButton {
      font-weight: 500;
    }
  }

  & .listWrap {
    margin-bottom: 50px;
    cursor: pointer;

    & .list {
      border-bottom: 1px solid var(--gray-color-04);

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  & .emptyContainer {
    border-bottom: 1px solid var(--gray-color-04);
    padding: 200px 0;
    font-size: 18px;
    font-weight: 500;
    color: #cfd2d7;
    text-align: center;
  }

  @media (max-width: 1600px) {
    padding-top: calc(100vw * (23 / 1600));

    &::before {
      height: calc(100vw * (3 / 1600));
    }

    & .topContainer {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .listWrap {
      margin-bottom: calc(100vw * (50 / 1600));

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 1600));
        }
      }
    }

    & .emptyContainer {
      padding: calc(100vw * (200 / 1600)) 0;
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (13 / 370));

    &::before {
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: calc(100vw * (3 / 370));
    }

    & .topContainer {
      margin-bottom: calc(100vw * (30 / 370));
    }

    & .listWrap {
      margin-bottom: calc(100vw * (50 / 370));

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 370));
        }
      }
    }

    & .emptyContainer {
      padding: calc(100vw * (72 / 370)) 0;
      font-size: calc(100vw * (14 / 370));
    }
  }
`;

export { Container };

export default { Container };
