import useModals from "../useModals"
import { api } from "service";
import { useState } from "react";

//img
import IconCheck from "resources/image/icon/icon_check.svg";
import IconReport from "resources/image/icon/icon_report02.svg";

export default function useReport (){
  const nextModalOption = useModals();

  const [reason, setReason] = useState("");
  const [textarea, setTextarea] = useState("");

  const report = (modalOption) => {
    nextModalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = IconReport;
      e.title = "신고하시겠습니까?";
      e.text = "신고 완료 시 취소가 불가능합니다.";
      e.confirmButtonText = "신고하기";
      
      e.confirmButtonClick = async () => {
        const postData = new FormData();
        postData.append("reason", reason === "기타" ? textarea : reason);
        postData.append("relate_info_id", modalOption.id);
        postData.append("type", modalOption.contents ? 1 : 2);

        let result = await api.report.report(postData)
        if (result.success) {
          nextModalOption.setDefaultModal((e) => {
            e.show = true;
            e.icon = IconCheck;
            e.title = "신고가 완료되었습니다.";
            e.text = (
              <>
                신고하신 {modalOption.contents ? "게시물" : "댓글"}은
                에듀소스에서 검토 후<br />
                운영 정책에 따라 처리됩니다.
              </>
            );
            e.confirmButtonText = "확인";
            e.confirmButtonClick = false;
            e.cancelButtonText = false;

            return { ...e };
          });
        }
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  return {
    report,

    reason,
    setReason,
    
    textarea,
    setTextarea
  }
}
