import Styles from "./styles";

function BasicInput({
  id,
  labelText,
  essential,
  type,
  value,
  setValue,
  placeholder,
  isDisabled,
  readonly,
  onClick,
  guideText,
  errorText,
  buttonText,
  onFocus,
  onBlur,
}) {
  return (
    <Styles.Container>
      <div className="inputContainer">
        {labelText && (
          <label htmlFor={id} className={essential ? "essential" : ""}>
            <span>{labelText}</span>
          </label>
        )}

        <input
          id={id}
          type={type ? type : "text"}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {buttonText && <button type="button" onClick={onClick}>{buttonText}</button>}
      </div>

      {guideText && <p className="guideText">{guideText}</p>}

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default BasicInput;
