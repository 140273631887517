import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #f0f2f4;
  }

  & .iconWrap {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }

  & .timeWrap {
    margin-right: 10px;
    font-weight: 500;

    & .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 73px;
      height: 22px;
      margin-bottom: 7px;
      color: var(--gray-color-01);
      background-color: var(--gray-color-04);
      border-radius: 500px;
    }

    & .time {
      color: var(--gray-color-03);
    }
  }

  & .titleWrap {
    width: calc(100% - 201px);

    & .listTitle {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .address {
      color: var(--gray-color-03);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  & .moreLink {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (30 / 1600)) 0;

    & .iconWrap {
      width: calc(100vw * (48 / 1600));
      height: calc(100vw * (48 / 1600));
      margin-right: calc(100vw * (20 / 1600));
    }

    & .timeWrap {
      margin-right: calc(100vw * (10 / 1600));

      & .tag {
        width: calc(100vw * (73 / 1600));
        height: calc(100vw * (22 / 1600));
        margin-bottom: calc(100vw * (7 / 1600));
        border-radius: calc(100vw * (500 / 1600));
      }
    }

    & .titleWrap {
      width: calc(100% - calc(100vw * (201 / 1600)));

      & .listTitle {
        margin-bottom: calc(100vw * (5 / 1600));
        font-size: calc(100vw * (18 / 1600));
      }
    }

    & .moreLink {
      width: calc(100vw * (30 / 1600));
      height: calc(100vw * (30 / 1600));
      margin-left: calc(100vw * (20 / 1600));
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: calc(100vw * (15 / 370)) 0;

    & .iconWrap {
      order: 1;
      width: calc(100vw * (44 / 370));
      height: calc(100vw * (44 / 370));
      margin-right: calc(100vw * (11 / 370));
    }

    & .timeWrap {
      order: 2;
      margin-right: 0;

      & .tag {
        width: calc(100vw * (73 / 370));
        height: calc(100vw * (20 / 370));
        margin-bottom: calc(100vw * (3 / 370));
        border-radius: calc(100vw * (500 / 370));
      }
    }

    & .titleWrap {
      order: 4;
      width: 100%;
      margin-top: calc(100vw * (12 / 370));

      & .listTitle {
        margin-bottom: calc(100vw * (6 / 370));
        font-size: calc(100vw * (16 / 370));
      }
    }

    & .moreLink {
      order: 3;
      align-self: flex-start;
      width: calc(100vw * (21 / 370));
      height: calc(100vw * (21 / 370));
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

export { Container };

export default { Container };
