import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #fff;
    background-color: var(--main-color);
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  font-weight: 500;
  color: #ced4df;
  background-color: #f9fafc;
  border-radius: 2px;
  cursor: pointer;

  @media (max-width: 1600px) {
    width: calc(100vw * (100 / 1600));
    height: calc(100vw * (50 / 1600));
    border-radius: calc(100vw * (2 / 1600));
  }

  @media (max-width: 768px) {
    width: calc(100vw * (89 / 370));
    height: calc(100vw * (40 / 370));
    border-radius: calc(100vw * (2 / 370));
  }
`;

export { Input, Label };

export default { Input, Label };
