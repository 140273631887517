
  
  function checkZero(checkString){
    return checkString.toString().length == 1 ?  "0" + checkString : checkString;
  }
  
  //날짜 출력 폼 형태 
  //d = date
  //type = "구분 값"
  //showLevel = "어디까지 보여지는지"
  function getDateType(d,type,showLevel){
  
  let date = null;
  
    if(date == null){
     try{ date = new Date(d); }catch(e){ }
    }
    if(date == null){
     try{ date = new Date(d.replace(/-/gi,"/")); }catch(e){ }
    }
    if(date == null){
     try{ date = new Date(); }catch(e){ }
    }
  
    let temp = checkZero(date.getFullYear()) + (type ? type : ".") + checkZero(date.getMonth() + 1) + (type ? type : ".") + checkZero(date.getDate()) 
    + (showLevel && showLevel > 0 ? " " + checkZero(date.getHours()): "")+ (showLevel && showLevel > 1 ? "." + checkZero(date.getMinutes()): "")
    return temp;
  }
  
  //데이터 상태 벨리데이션
  function getDateValidate(d){
  
    let date = null;
   
    if(!date || !date.getDate()){
     try{ date = new Date(d); }catch(e){ date = null; }
    }
    if(!date || !date.getDate()){
     try{ date = new Date(d.replace(/-/gi,"/")); }catch(e){ date = null; }
    }
    if(!date || !date.getDate()){
     try{ date = new Date(); }catch(e){ date = null; }
    }
  
    return date;
  }
  
  //날짜 비교
  function equalTwoDate(oDate,tDate){
      return oDate.getFullYear() == tDate.getFullYear() && oDate.getMonth() == tDate.getMonth() && oDate.getDate() == tDate.getDate();
  }

  //날짜 new Date > YYYY-MM-DD 값으로 변경
  function changeDate(date, type, pivotDate){
    return checkZero(date?.getFullYear()) + (type ? type : ".") + (pivotDate ? checkZero(date.getMonth() + 1 + pivotDate) : checkZero(date.getMonth() + 1)) + (type ? type : ".") + checkZero(date.getDate())
  }

  //날짜 관련 비교식 
  function compareNowDate(_targetDate){
    let targetDate = getDateValidate(_targetDate);

    if(_targetDate.getTime() < new Date().getTime())
      return -1;
    else if(equalTwoDate(new Date(),_targetDate))
      return 0;
    else if(_targetDate.getTime() > new Date().getTime())
      return 1;
  }
  
  //오전 오후인지 파악해주는 코드 
  function getTimeKr(time,type){
    let hour = Number(time?.split(":")[0]);
    let min = Number(time?.split(":")[1]);
  
    return (hour > 12 ? "오후 " : "오전 ") + checkZero(hour) + (type ? type : " ") + checkZero(min);
    // return (hour > 12 ? "오후 " : "오전 ") + checkZero(hour > 12 ? hour - 12 : hour) + (type ? type : " ") + checkZero(min);
  }
  
  
  //시작하는 날로 부터 남은 날 
  function getDateDiff(createdAt){
    createdAt = getDateValidate(createdAt);
    const milliSeconds = createdAt - new Date()
    const seconds = milliSeconds / 1000
    let result = {};
    
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = hours / 24
    const weeks = days / 7
    const months = days / 30
    const years = days / 365

    result = {
      seconds : Math.floor(seconds % 60),
      minutes : Math.floor(minutes % 60),
      days : Math.floor(days),
      weeks : Math.floor(weeks),
      months : Math.floor(months),
      years : Math.floor(years),
    }

    return result;
  }
  
  //해당 생성일이 현재로 부터 얼마나 지난건지에 대한 확인 스크립트
  function getHourMinStrByMin(createdAt){
    createdAt = getDateValidate(createdAt);
    const milliSeconds = new Date() - createdAt
    const seconds = milliSeconds / 1000
    if (seconds < 60) return `방금 전`
    const minutes = seconds / 60
    if (minutes < 60) return `${Math.floor(minutes)}분 전`
    const hours = minutes / 60
    if (hours < 24) return `${Math.floor(hours)}시간 전`
    const days = hours / 24
    if (days < 7) return `${Math.floor(days)}일 전`
    const weeks = days / 7
    if (weeks < 5) return `${Math.floor(weeks)}주 전`
    const months = days / 30
    if (months < 12) return `${Math.floor(months)}개월 전`
    const years = days / 365
    return `${Math.floor(years)}년 전`
  }
  
  export { 
    getTimeKr, getDateType, equalTwoDate, getDateDiff, getHourMinStrByMin, changeDate
  };

  export default { 
    getTimeKr, getDateType, equalTwoDate, getDateDiff, getHourMinStrByMin, compareNowDate, changeDate
  };