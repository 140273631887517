import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  padding: 30px 0;
  border-top: 1px solid #eff0f2;

  & .topContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & .listTitle {
      position: relative;
      min-width: 133px;
      padding-left: 20px;
      font-size: 20px;
      font-weight: bold;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: var(--main-color);
        border-radius: 50%;
        content: "";
      }
    }

    & .buttonContainer {
      display: flex;
      gap: 10px;

      & button {
        display: block;
        width: 29px;
        height: 29px;
      }
    }
  }

  & .inputListWrap .inputList {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.gender {
      display: flex;
      align-items: center;

      & .inputTitle {
        flex-shrink: 0;
        width: 133px;
        font-size: 16px;
        font-weight: 500;

        & span {
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            right: -9px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background: url(${IconEssential}) no-repeat center / cover;
            content: "";
          }
        }
      }

      & .radioListWrap {
        display: flex;
        gap: 15px;
      }
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (30 / 1600)) 0;

    & .topContainer {
      margin-bottom: calc(100vw * (30 / 1600));

      & .listTitle {
        min-width: calc(100vw * (133 / 1600));
        padding-left: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (20 / 1600));

        &::before {
          width: calc(100vw * (10 / 1600));
          height: calc(100vw * (10 / 1600));
        }
      }

      & .buttonContainer {
        gap: calc(100vw * (10 / 1600));

        & button {
          width: calc(100vw * (29 / 1600));
          height: calc(100vw * (29 / 1600));
        }
      }
    }

    & .inputListWrap .inputList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 1600));
      }

      &.gender {
        & .inputTitle {
          width: calc(100vw * (133 / 1600));
          font-size: calc(100vw * (16 / 1600));

          & span {
            &::after {
              right: calc(100vw * (-9 / 1600));
              width: calc(100vw * (5 / 1600));
              height: calc(100vw * (5 / 1600));
            }
          }
        }

        & .radioListWrap {
          gap: calc(100vw * (15 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (30 / 370)) 0;

    & .topContainer {
      margin-bottom: calc(100vw * (30 / 370));

      & .listTitle {
        min-width: auto;
        margin-right: calc(100vw * (15 / 370));
        padding-left: calc(100vw * (16 / 370));
        font-size: calc(100vw * (16 / 370));

        &::before {
          width: calc(100vw * (6 / 370));
          height: calc(100vw * (6 / 370));
        }
      }

      & .buttonContainer {
        gap: calc(100vw * (10 / 370));

        & button {
          width: calc(100vw * (29 / 370));
          height: calc(100vw * (29 / 370));
        }
      }
    }

    & .inputListWrap .inputList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 370));
      }

      &.gender {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: calc(100vw * (50 / 370));

        & .inputTitle {
          width: auto;
          margin-bottom: calc(100vw * (10 / 370));
          font-size: calc(100vw * (16 / 370));

          & span {
            &::after {
              right: calc(100vw * (-8 / 370));
              width: calc(100vw * (5 / 370));
              height: calc(100vw * (5 / 370));
            }
          }
        }

        & .radioListWrap {
          gap: calc(100vw * (10 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
