import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;
  background-color: #f9fafc;

  & .formContainer {
    width: 616px;
    margin: 80px auto 0;
    background-color: #fff;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);

    & .narrowInner {
      display: flex;
      flex-direction: column;
      height: 673px;
      padding: 50px 0;
    }
  }

  & .title {
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: bold;
  }

  & .list:not(:last-child) {
    margin-bottom: 15px;
  }

  & .errorText,
  .guideText {
    margin: 5px 0 0;
  }

  & .buttonContainer {
    display: flex;
    gap: 20px;
    margin-top: auto;

    & > div {
      flex: 1 0;
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .formContainer {
      width: calc(100vw * (616 / 1600));
      margin: calc(100vw * (80 / 1600)) auto 0;
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .narrowInner {
        height: calc(100vw * (673 / 1600));
        padding: calc(100vw * (50 / 1600)) 0;
      }
    }

    & .title {
      margin-bottom: calc(100vw * (50 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .list:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1600));
    }

    & .errorText,
    .guideText {
      margin: calc(100vw * (5 / 1600)) 0 0;
    }

    & .buttonContainer {
      gap: calc(100vw * (20 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (157 / 370));
    background-color: #fff;

    & .formContainer {
      width: 100%;
      margin: calc(100vw * (50 / 370)) auto 0;
      box-shadow: none;

      & .narrowInner {
        height: auto;
        padding: 0;
      }
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (22 / 370));
    }

    & .list:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 370));
    }

    & .errorText,
    .guideText {
      margin: calc(100vw * (5 / 370)) 0 0;
    }

    & .buttonContainer {
      gap: calc(100vw * (10 / 370));
      margin-top: calc(100vw * (80 / 370));
    }
  }
`;

export { Container };

export default { Container };
