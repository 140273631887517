import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  font-size: 16px;
  font-weight: 500;

  & .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 29px;
    margin-right: 30px;
    background-color: var(--gray-color-05);
    border-radius: 50px;
  }

  & .state {
    margin-right: 50px;
    color: var(--gray-color-02);

    &.on {
      color: var(--main-color);
    }
  }

  & .title {
    width: 629px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .date {
    margin-left: auto;
    font-size: 14px;
    color: var(--gray-color-02);
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (30 / 1600)) 0;
    font-size: calc(100vw * (16 / 1600));

    & .number {
      width: calc(100vw * (58 / 1600));
      height: calc(100vw * (29 / 1600));
      margin-right: calc(100vw * (30 / 1600));
      border-radius: calc(100vw * (50 / 1600));
    }

    & .state {
      margin-right: calc(100vw * (50 / 1600));
    }

    & .title {
      width: calc(100vw * (629 / 1600));
    }

    & .date {
      font-size: calc(100vw * (14 / 1600));
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: calc(100vw * (16 / 370)) 0;
    font-size: calc(100vw * (14 / 370));

    & .number {
      width: calc(100vw * (46 / 370));
      height: calc(100vw * (20 / 370));
      margin-right: calc(100vw * (5 / 370));
      border-radius: calc(100vw * (50 / 370));
    }

    & .state {
      margin-right: 0;
    }

    & .title {
      order: 1;
      width: 100%;
      margin-top: calc(100vw * (5 / 370));
    }

    & .date {
      font-size: calc(100vw * (12 / 370));
    }
  }
`;

export { Container };

export default { Container };
