import { api } from "service";
import { useQuery } from "react-query";
import { useState } from "react";

export default function useFooter({
  options = {
    enableVisitCount: false,
    enableTerms: false
  }
}) {
  const [type, setType] = useState();

  const visitCount = useQuery(["visit_count"], () => {
    return api.footer.visitCount()
  },{
    refetchOnWindowFocus: false,
    enabled: !!options.enableVisitCount
  });
  const getTerms = useQuery(["terms", type], () => {
    return api.footer.getTerms(type)
  },{
    refetchOnWindowFocus: false,
    enabled: !!options.enableTerms
  });

  const ipCheck = async () => {
    return await api.footer.ipCheck();
  }

  return {
    visitInfo: visitCount.data ? visitCount.data : [],
    termsInfo: getTerms.data ? getTerms.data : [],

    setType,
    ipCheck
  }
}