import Styles from "./styles";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_square.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_square.svg";

function Pagination({ list, page, setPage, maxPage }) {
  const onClickPrev = () => {
    if (page == 1) return;
    setPage(parseInt(page) - 1);
  };

  const onClickNext = () => {
    if (page == maxPage) return;
    setPage(parseInt(page) + 1);
  };

  if (Pagination == null) {
    return null;
  }

  return (
    <Styles.Container>
      <div
        className="iconArrow"
        onClick={(e) => {
          onClickPrev(e);
        }}
      >
        <img src={IconArrowLeft} alt="IconArrowLeft" />
      </div>
      <div className="paginationList">
        {list?.map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? " on" : ""}
              onClick={() => { setPage(item) }}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>
      <div
        className="iconArrow"
        onClick={(e) => {
          onClickNext(e);
        }}
      >
        <img src={IconArrowRight} alt="IconArrowRight" />
      </div>
    </Styles.Container>
  );
}

export { Pagination };

export default Pagination;
