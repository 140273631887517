import { api } from "service";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export default function useCategory() {
  const location = useLocation()
  let lastPathName = location.pathname.split("/")[location.pathname.split("/").length-1]

  const renderTitleInfo = (type) => {
    switch (type) {
      case "education-info":
        return { id: 1, title: "교육 정보", type: 7 }
      case "blog":
        return { id: 2, title: "블로그", type: 2 }
      case "community":
        return { id: 3, title: "지역 커뮤니티", type: 3 }
      case "facility-info":
        return { id: 4, title: "시설 정보", type: 4 }
    };
  };

  const getCategory = useQuery(["education_detail",renderTitleInfo(lastPathName).id], () => {
    return api.category.getCategory(renderTitleInfo(lastPathName).id);
  }, { refetchOnWindowFocus: false });
   
  
  return {
    getCategory,
    renderTitleInfo
  }
}
