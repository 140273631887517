import Styles from "./styles";
import { BellList } from "./components";
import { useAlarm } from "hooks";

//img
import IconArrowTop from "resources/image/icon/icon_arrow_top01.svg";

function BellModal({ modalClose }) {
  const alarmInfo = useAlarm();

  const alarmUrl = (type, id, boardType) => {
    switch (type) {
      case 1: return "/customer-support/notice/detail?id=" + id;
      case 2:
        switch (boardType) {
          case 1: return "/education-info/month?id=" + id;
          case 2: return "/blog/detail?id=" + id;
          case 3: return "/community/detail?id=" + id;
          case 4: return "/facility-info/facility/detail?id=" + id;
          case 5: return "/education-info/academy?id=" + id;
          case 6: return "/facility-info/reading-room/detail?id=" + id;
          case 7: return "/education-info/share?id=" + id;
        }
      case 3:
      case 4: return "/education-info/month?id=" + id;
      case 5: return "/education-info";
    }
  };

  return (
    <Styles.Container>
      <div className="bellTitle">내 소식</div>

      {alarmInfo.getAlarm?.data?.length > 0 ? (
        <ul className="listWrap">
          {alarmInfo.getAlarm.data.map((item, index) => {
            return (
              <li
                className="list"
                key={"list" + index}
                onClick={() => {
                  window.location.assign(
                    alarmUrl(item.type, item.relate_info_id, item.board_type)
                  );
                  alarmInfo.alarmRead(item.id);
                }}
              >
                <BellList data={item} />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="emptyContainer">알림이 없습니다.</div>
      )}

      <button type="button" className="bellCloseButton" onClick={modalClose}>
        <span className="iconWrap">
          <img src={IconArrowTop} alt="IconArrowTop" />
        </span>
      </button>
    </Styles.Container>
  );
}

export default BellModal;
