import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  & .iconArrow {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  & .paginationList {
    display: flex;
    gap: 5px;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      font-weight: bold;
      color: var(--black-color-01);
      background-color: var(--gray-color-05);
      border-radius: 2px;

      &.on {
        color: #fff;
        background-color: var(--black-color-01);
      }
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (5 / 1600));

    & .iconArrow {
      width: calc(100vw * (35 / 1600));
      height: calc(100vw * (35 / 1600));
    }

    & .paginationList {
      gap: calc(100vw * (5 / 1600));

      & button {
        width: calc(100vw * (35 / 1600));
        height: calc(100vw * (35 / 1600));
        border-radius: calc(100vw * (2 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (5 / 370));

    & .iconArrow {
      width: calc(100vw * (25 / 370));
      height: calc(100vw * (25 / 370));
    }

    & .paginationList {
      gap: calc(100vw * (5 / 370));

      & button {
        width: calc(100vw * (25 / 370));
        height: calc(100vw * (25 / 370));
        border-radius: calc(100vw * (2 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
