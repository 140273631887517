import styled from "styled-components";

const Container = styled.div`
  cursor: pointer;

  & .imgWrap {
    height: 229px;
    overflow: hidden;
  }

  & .academyName {
    margin: 16px 0 7px;
    font-size: 18px;
    font-weight: bold;
  }

  & .address {
    color: var(--gray-color-03);
  }

  @media (max-width: 1600px) {
    & .imgWrap {
      height: calc(100vw * (229 / 1600));
    }

    & .academyName {
      margin: calc(100vw * (16 / 1600)) 0 calc(100vw * (7 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .imgWrap {
      height: calc(100vw * (96 / 370));
    }

    & .academyName {
      margin: calc(100vw * (10 / 370)) 0 calc(100vw * (6 / 370));
      font-size: calc(100vw * (14 / 370));
    }

    & .address {
      font-size: calc(100vw * (12 / 370));
    }
  }
`;

export { Container };

export default { Container };
