import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_gray.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e0e4ed;
  background-color: #fff;
  z-index: 9;

  & .inner {
    display: flex;
    align-items: center;
    height: 80px;

    & .logo {
      margin-right: 30px;

      & a {
        width: 130px;
        height: 22px;
      }
    }

    & .searchContainer {
      width: 346px;

      & .keywordContainer {
        position: fixed;
        top: 81px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 23px 26px 0px rgba(0, 0, 0, 0.05);

        & .inner {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          height: 120px;
        }

        & .keywordTitle {
          margin-bottom: 10px;
          font-weight: 700;
        }

        & .keywordListWrap {
          display: flex;
          gap: 10px;
        }
      }
    }

    & .utilListWrap {
      display: flex;
      gap: 34px;
      margin: 0 56px 0 auto;

      & .utilList {
        font-size: 16px;
        font-weight: 500;
      }
    }

    & .buttonListWrap {
      display: flex;
      gap: 29px;

      & .buttonList {
        position: relative;
      }

      & .buttonList > button,
      & .profileImgWrap {
        display: block;
        width: 36px;
        height: 36px;
      }

      & .profileImgWrap {
        border-radius: 10px;
      }
    }

    & .buttonList {
      position: relative;
    }

    & .loginButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 101px;
      height: 36px;
      font-weight: 700;
      color: #fff;
      background-color: var(--black-color-01);
      border-radius: 5px;

      & .iconWrap {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1600px) {
    & .inner {
      height: calc(100vw * (80 / 1600));

      & .logo {
        margin-right: calc(100vw * (30 / 1600));

        & a {
          width: calc(100vw * (130 / 1600));
          height: calc(100vw * (22 / 1600));
        }
      }

      & .searchContainer {
        width: calc(100vw * (346 / 1600));

        & .keywordContainer {
          top: calc(100vw * (81 / 1600));
          box-shadow: 0px calc(100vw * (23 / 1600)) calc(100vw * (26 / 1600))
            0px rgba(0, 0, 0, 0.05);

          & .inner {
            height: calc(100vw * (120 / 1600));
          }

          & .keywordTitle {
            margin-bottom: calc(100vw * (10 / 1600));
          }

          & .keywordListWrap {
            gap: calc(100vw * (10 / 1600));
          }
        }
      }

      & .utilListWrap {
        gap: calc(100vw * (34 / 1600));
        margin: 0 calc(100vw * (56 / 1600)) 0 auto;

        & .utilList {
          font-size: calc(100vw * (16 / 1600));
        }
      }

      & .buttonListWrap {
        gap: calc(100vw * (29 / 1600));

        & .buttonList > button,
        & .profileImgWrap {
          width: calc(100vw * (36 / 1600));
          height: calc(100vw * (36 / 1600));
        }

        & .profileImgWrap {
          border-radius: calc(100vw * (10 / 1600));
        }
      }

      & .loginButton {
        width: calc(100vw * (101 / 1600));
        height: calc(100vw * (36 / 1600));
        border-radius: calc(100vw * (5 / 1600));

        & .iconWrap {
          width: calc(100vw * (14 / 1600));
          height: calc(100vw * (14 / 1600));
          margin-right: calc(100vw * (8 / 1600));
        }
      }
    }
  }
`;

const MbContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e0e4ed;
  background-color: #fff;
  z-index: 9;

  & .inner {
    display: flex;
    align-items: center;
    height: calc(100vw * (50 / 370));

    & .menuButton {
      width: calc(100vw * (26 / 370));
      height: calc(100vw * (26 / 370));
      margin-right: calc(100vw * (10 / 370));
    }

    & .logo a {
      width: calc(100vw * (107 / 370));
      height: calc(100vw * (18 / 370));
    }

    & .buttonListWrap {
      display: flex;
      gap: calc(100vw * (15 / 370));
      margin-left: auto;

      & .buttonList > button {
        display: block;
        width: calc(100vw * (26 / 370));
        height: calc(100vw * (26 / 370));
      }
    }

    & .searchContainer {
      position: fixed;
      top: calc(100vw * (50 / 370));
      left: 0;
      width: 100%;
      padding: calc(100vw * (20 / 370)) calc(100vw * (25 / 370));
      background-color: #fff;
      border-top: 1px solid #e0e4ed;
      box-shadow: 0 calc(100vw * (13 / 370)) calc(100vw * (16 / 370)) 0
        rgba(0, 0, 0, 0.05);

      & .searchWrap {
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .keywordTitle {
        margin-bottom: calc(100vw * (10 / 370));
        font-weight: bold;
      }

      & .keywordListWrap {
        display: flex;
        flex-wrap: wrap;
        gap: calc(100vw * (5 / 370));
      }
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 10;

  & .topContainer {
    border-bottom: 1px solid #e0e4ed;

    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100vw * (50 / 370));

      & .logo {
        width: calc(100vw * (107 / 370));
        height: calc(100vw * (18 / 370));
      }

      & .closeButton {
        width: calc(100vw * (26 / 370));
        height: calc(100vw * (26 / 370));
      }
    }
  }

  & .myContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(100vw * (15 / 370));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (30 / 370));
    border-bottom: 1px solid #e0e4ed;

    & .text {
      font-size: calc(100vw * (20 / 370));
      font-weight: bold;

      & span {
        color: var(--main-color);
      }
    }

    & .loginButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100vw * (101 / 370));
      height: calc(100vw * (36 / 370));
      color: #fff;
      font-weight: bold;
      border-radius: calc(100vw * (5 / 370));
      background-color: var(--black-color-01);

      & .iconWrap {
        width: calc(100vw * (14 / 370));
        height: calc(100vw * (14 / 370));
        margin-right: calc(100vw * (8 / 370));
      }
    }

    & .profileImgWrap {
      width: calc(100vw * (80 / 370));
      height: calc(100vw * (80 / 370));
    }
  }

  & .menuList {
    padding: calc(100vw * (15 / 370)) 0;
    font-size: calc(100vw * (16 / 370));
    font-weight: 500;
    background: url(${IconArrowRight}) no-repeat right center /
      calc(100vw * (14 / 370));
  }
`;

export { Container, MbContainer, Menu };

export default { Container, MbContainer, Menu };
