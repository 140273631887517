import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 358px;
  padding: 30px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 12px 12px 25px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;

  & .text {
    margin-bottom: 30px;
    font-size: 16px;
  }

  & .closeButton {
    font-size: 16px;
    color: var(--gray-color-02);
  }

  @media (max-width: 1600px) {
    width: calc(100vw * (358 / 1600));
    padding: calc(100vw * (30 / 1600)) 0;
    border-radius: calc(100vw * (10 / 1600));
    box-shadow: calc(100vw * (12 / 1600)) calc(100vw * (12 / 1600))
      calc(100vw * (25 / 1600)) 0 rgba(0, 0, 0, 0.1);

    & .text {
      margin-bottom: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (16 / 1600));
    }

    & .closeButton {
      font-size: calc(100vw * (16 / 1600));
    }
  }

  @media (max-width: 768px) {
    right: auto;
    left: 0;
    width: calc(100vw * (255 / 370));
    padding: calc(100vw * (16 / 370)) 0;
    border-radius: calc(100vw * (10 / 370));
    box-shadow: calc(100vw * (12 / 370)) calc(100vw * (12 / 370))
      calc(100vw * (25 / 370)) 0 rgba(0, 0, 0, 0.1);

    & .text {
      margin-bottom: calc(100vw * (20 / 370));
      font-size: calc(100vw * (14 / 370));
    }

    & .closeButton {
      font-size: calc(100vw * (14 / 370));
    }
  }
`;

export { Container };

export default { Container };
