import Styles from "./styles";
import { DOMAIN } from "service/connector";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useNavigate, Link, useParams, useSearchParams, useLocation } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right01.svg";

function SignupStepAPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [serchParams] = useSearchParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkList, setCheckList] = useState(sessionStorage.getItem("signUp") ? ["check1", "check2", "check3"] : []);  

  const validate = () => {
    if (checkList.length === 3) return true;
    return false;
  }

  useEffect(() => {
    if (validate()) setIsDisabled(false);
    else setIsDisabled(true);
    document.cookie = `jwt=${serchParams.get("token")};path=/;domain=${DOMAIN}`
  }, [checkList]);

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheckList(checkList.concat(id));
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheckList(checkList.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];
      checkListData.forEach((el) => idArray.push(el.id));
      setCheckList(idArray);
    } else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckList([]);
    }
  };

  // map
  const checkListData = [
    { 
      id: "check1",
      labelText: (<><span className="essential">[필수]</span> 만 14세 이상입니다.</>)
    },
    { 
      id: "check2",
      labelText: (<><span className="essential">[필수]</span> 서비스 이용약관에 동의합니다.</>),
      url: "/terms/service"
    },
    { 
      id: "check3",
      labelText: (<><span className="essential">[필수]</span> 개인정보처리방침에 동의합니다.</>),
      url: "/terms/privacy"
    },
  ];

  return (
    ((id === "signup" && location.state) ||
    (id === "social" && serchParams.get("token"))) &&
    <>
      <common.Header />
      <Styles.Container>
        <div className="narrowInner">
          <layout.SignupTitle
            text={"원활한 서비스 이용을 위해 약관동의가 필요합니다."}
            step={0}
          />

          <ul className="listWrap">
            <li className="list all">
              <ui.input.BasicCheckbox
                id={"all"}
                labelText={"전체 동의"}
                checked={
                  checkList.length === checkListData.length ? true : false
                }
                onChange={(e) => {
                  handleAllCheck(e.target.checked);
                }}
              />
            </li>

            {checkListData?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <ui.input.BasicCheckbox
                    id={item.id}
                    labelText={item.labelText}
                    onChange={(e) =>
                      handleSingleCheck(e.target.checked, e.target.id)
                    }
                    checked={checkList.includes(item.id) ? true : false}
                  />
                  {item.url && (
                    <Link to={item.url} className="arrowIconWrap">
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>

          <div className="nextButton">
            <ui.button.BasicButton
              buttonType={isDisabled ? "gray" : "basic"}
              buttonText={"다음으로"}
              disabled={isDisabled}
              onClick={() => {
                navigate("/" + id + "/stepB", { state: checkList.length });
                sessionStorage.setItem("signUp", "Y");
              }}
            />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SignupStepAPage;
