import styled from "styled-components";

// img
import ImgPrev from "resources/image/img_list01.png";
import ImgNext from "resources/image/img_list02.png";

const Container = styled.div`
  display: flex;

  & > div {
    width: 50%;
  }

  & a {
    display: flex;
    gap: 80px;

    height: 149px;
    padding: 48px 50px;
    font-weight: bold;
    cursor: pointer;
  }

  & .prevList a {
    background: url(${ImgPrev}) no-repeat calc(100% - 50px) center / 113px
      #f9fafc;
  }

  & .nextList a {
    justify-content: flex-end;
    background: url(${ImgNext}) no-repeat 50px center / 113px
      var(--black-color-01);
    color: #fff;
  }

  & .text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;

    & .iconWrap {
      width: 30px;
      height: 30px;
    }
  }

  & .title {
    display: -webkit-box;
    max-width: 250px;
    font-size: 18px;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1600px) {
    & a {
      gap: calc(100vw * (80 / 1600));
      height: calc(100vw * (149 / 1600));
      padding: calc(100vw * (48 / 1600)) calc(100vw * (50 / 1600));
      cursor: pointer;
    }

    & .prevList a {
      background: url(${ImgPrev}) no-repeat
        calc(100% - calc(100vw * (50 / 1600))) center /
        calc(100vw * (113 / 1600)) #f9fafc;
    }

    & .nextList a {
      background: url(${ImgNext}) no-repeat calc(100vw * (50 / 1600)) center /
        calc(100vw * (113 / 1600)) var(--black-color-01);
    }

    & .text {
      gap: calc(100vw * (5 / 1600));
      font-size: calc(100vw * (16 / 1600));

      & .iconWrap {
        width: calc(100vw * (30 / 1600));
        height: calc(100vw * (30 / 1600));
      }
    }

    & .title {
      max-width: calc(100vw * (250 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    & a {
      flex-direction: column;
      gap: calc(100vw * (10 / 370));
      height: calc(100vw * (157 / 370));
      padding: calc(100vw * (10 / 370)) calc(100vw * (24 / 370))
        calc(100vw * (10 / 370)) calc(100vw * (40 / 370));
      cursor: pointer;
    }

    & .prevList a {
      background: url(${ImgPrev}) no-repeat right bottom /
        calc(100vw * (60 / 370)) #f9fafc;
    }

    & .nextList a {
      flex-direction: column-reverse;
      align-items: flex-end;
      background: url(${ImgNext}) no-repeat left bottom /
        calc(100vw * (60 / 370)) var(--black-color-01);
    }

    & .text {
      gap: calc(100vw * (6 / 370));
      font-size: calc(100vw * (12 / 370));

      & .iconWrap {
        width: calc(100vw * (13 / 370));
        height: calc(100vw * (13 / 370));
      }
    }

    & .title {
      max-width: 100%;
      font-size: calc(100vw * (14 / 370));
      -webkit-line-clamp: 3;
    }
  }
`;

export { Container };

export default { Container };
