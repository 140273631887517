import Styles from "./styles";

function SignupTitle({ text, step }) {
  return (
    <Styles.Container>
      <h2 className="title">회원가입</h2>

      <div className="signupTextContainer">
        <p className="text">{text}</p>

        <ul className="stepListWrap">
          {[1, 2, 3].map((item, index) => {
            return (
              <li
                className={"stepList" + (step === index ? " on" : "")}
                key={"stepList" + index}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default SignupTitle;
