import { useAuth } from "hooks";
import Styles from "./styles";

function CommentInput({replyInfo, onContentEdit, onContentSubmit }) {
  const authInfo = useAuth();

  return (
    <Styles.Container>
      <textarea
        ref={replyInfo.replyFocus}
        placeholder="댓글을 입력하세요."
        value={replyInfo.content}
        onChange={(e) => {
          replyInfo.setContent(e.target.value);
        }}
        onFocus={() => {
          if (authInfo.certification() === false) {
            replyInfo.setContent("");
            document.activeElement.blur();
          }
        }}
        onBlur={(e) => {
          e.target.value === "" && replyInfo.setReplyInfoId("");
        }}
      >
      </textarea>

      <button
        type="button"
        className="submitButton"
        onClick={() => {
          if (authInfo.certification()) {
            onContentEdit
              ? onContentEdit()
              : onContentSubmit()
          }
        }}
      >
        등록
      </button>
    </Styles.Container>
  );
}

export default CommentInput;
