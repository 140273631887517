import Styles from "./styles";
import layout from "components/layout";
import { useNavigate } from "react-router-dom";
import { useModals, useAuth } from "hooks";

// img
import IconLogOut from "resources/image/icon/icon_logout.svg";

function SideMenu({ tabIndex, setTabIndex }) {
  const authInfo = useAuth();
  const modalOption = useModals();
  const navigate = useNavigate();

  const onLogout = () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = IconLogOut;
      e.title = "로그아웃 하시겠습니까?";
      e.text = false;
      e.confirmButtonText = "로그아웃";
      e.confirmButtonClick = () => { authInfo.logout(); };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  const list = [
    {
      text: "회원정보 관리",
      url: "info",
    },
    {
      text: "공감 게시글",
      url: "like-list",
    },
    {
      text: "내 게시글",
      url: "my-list",
    },
    {
      text: "문의 내역",
      url: "ask",
    },
    {
      text: "로그아웃",
      logout: onLogout
    }
  ];

  return (
    <Styles.Container>
      <h2 className="title">마이페이지</h2>

      <div className="userInfo">
        <layout.UserProfileImg profileImg={authInfo.userData.image}/>

        <p className="userInfoText">
          <b>{authInfo.userData.nickname}</b>님, <br />
          환영합니다
        </p>
      </div>

      <ul className="menuListWrap">
        {list.map((item, index) => {
          return (
            <li
              className={"menuList" + (item.url === tabIndex ? " on" : "")}
              key={"menuList" + index}
              onClick={() => {
                item.logout ? item.logout() : setTabIndex(item.url);
                item.url && navigate(`/mypage/${item.url}`);
              }}
            >
              {item.text}
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default SideMenu;
