import Styles from "./styles";
import { useAuth } from "hooks";
import { IMAGEPATH } from "service/connector";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgExceptionDefault from "resources/image/img_facility_default.png";

function AcademyList({ data }) {
  const authInfo = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Styles.Container
      onClick={()=>{
        if (authInfo.certification(false)) {
          navigate("/education-info/academy?id=" + data.id, { state: location.state })
        }
      }}
    >
      <div className="imgWrap">
        <img src={data.thumbnail ? IMAGEPATH + data.thumbnail : ImgExceptionDefault} alt={data.thumbnail} />
      </div>

      <p className="academyName">{data.title}</p>

      <p className="address">{data.address}</p>
    </Styles.Container>
  );
}

export default AcademyList;
