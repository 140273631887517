import { api } from "service";
import { useQuery } from "react-query";
import { useState } from "react";

export default function useFacility({ detail }) {
  const [updateData, setUpdateData] = useState()

  const selectQuery = useQuery(["selectList"], () => {
    return api.facility.getSelectList();
  }, {
    refetchOnWindowFocus: false,
    enabled: true
  });

  const selectDetailQuery = useQuery(["selectDetailList", detail], () => {
    return api.facility.getSelectDetail(detail);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!detail,
    onSuccess: (data) => {
      setUpdateData(data);
    }
  });

  return {
    selectQuery,
    selectDetailQuery,
    updateData,
  }
}