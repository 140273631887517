import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useAuth, useBoard, useCalendar } from "hooks";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function EducationInfoPage() {
  const navigate = useNavigate();
  const authInfo = useAuth();
  const boardInfo = useBoard({ options: {
    shareList: true,
    academyList: true,
    recordSize: 10
  } })
  const calendarInfo = useCalendar({ options: {
    scheduleList: true,
    schedule: true,
    recordSize: 5
  } });

  const tabList = [
    {
      id: 0,
      title: "유치원",
    },
    {
      id: 1,
      title: "초등학교",
    },
    {
      id: 2,
      title: "중학교",
    },
    {
      id: 3,
      title: "고등학교",
    }
  ]
  
  const [calendarTab, setCalendarTab] = useState(1);
  const [academyTab, setAcademyTab] = useState(1);
  const [academyLocation, setAcademyLocation] = useState([]);
  const [shareLocation, setShareLocation] = useState([]);

  useEffect(()=>{
    calendarInfo.setFilter((e) => { return { ...e, type: tabList[calendarTab].title } });
  },[calendarTab]);

  useEffect(()=>{
    boardInfo.setAcademyFilter((e) => { return { ...e, type: tabList[academyTab].title }  });
  },[academyTab]);
  
  useEffect(()=>{
    setAcademyLocation({ title: authInfo.userData.location })
    setShareLocation({ title: authInfo.userData.location })
  },[authInfo.userData.location])

  useEffect(()=>{
    boardInfo.setFilter((e) => { return { ...e, location: shareLocation.title }})
  },[shareLocation])

  useEffect(()=>{
    boardInfo.setAcademyFilter((e) => { return { ...e, location: academyLocation.title }})
  },[academyLocation])

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    prevText: "교육 정보",
    nowText: "이번 달 주요 교육 정보",
    title: "주요 교육 정보",
  };

  const optionList = [
    { title: "강남구" },
    { title: "강동구" },
    { title: "노원구" },
    { title: "송파구" },
    { title: "양천구" },
    { title: "동탄동" },
    { title: "제주도" },
    { title: "그외 지역" },
  ];
  
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="middleInner">
          <section className="calendarSection">
            {/* 가이드 추가 **/}
            <p className="guideText">
              원하는 교육 과정을 선택해주세요.
            </p>
            <div className="tabContainer">
              <layout.Tab
                tabList={tabList}
                tabIndex={calendarTab}
                setTabIndex={setCalendarTab}
                />
            </div>

            <layout.CalendarContainer 
              calendarInfo={calendarInfo}
            />
          </section>

          <section className="academySection">
            <h2 className="sectionTitle">{academyLocation.title ? academyLocation.title + "의" : "전체"} 학원 정보</h2>
            <div className="tabContainer">
              <layout.Tab
                tabList={tabList}
                tabIndex={academyTab}
                setTabIndex={setAcademyTab}
              />

              <div className="selectContainer">
                <ui.input.SecondSelect
                  optionList={optionList}
                  selectCategory={academyLocation?.title ? academyLocation?.title : "전체"}
                  setSelectCategory={setAcademyLocation}
                />
              </div>
            </div>

            <ul className="listWrap">
              {boardInfo.academyList?.list?.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <layout.AcademyList data={item} />
                  </li>
                );
              })}
            </ul>

            <div className="paginationContainer">
              <ui.button.Pagination
                page={boardInfo.academyFilter?.page}
                setPage={(v) =>
                  boardInfo.setAcademyFilter(e => { return { ...e, page: v } })
                }
                maxPage={boardInfo.academyList?.pagination?.endPage}
                list={boardInfo.academyList?.pagination?.page_list}
              />
            </div>
          </section>

          <section className="educationInfoSection">
            <div className="sectionTitleContainer">
              <h2 className="sectionTitle">{shareLocation.title ? shareLocation.title + "" : "전체"} 교육커뮤니티</h2>

              <div className="selectContainer">
                <ui.input.SecondSelect
                  optionList={optionList}
                  selectCategory={shareLocation?.title ? shareLocation?.title : "전체"}
                  setSelectCategory={setShareLocation}
                  multiple={true}
                />
              </div>
            </div>

            <ul className="listWrap">
              {boardInfo.shareList?.list?.slice(0, 5)?.map((item, index) => {
                return (
                  <li
                    className="list"
                    key={"list" + index}
                    onClick={() => {
                      if (authInfo.certification(false)) {
                        navigate("/education-info/share?id=" + item.id)
                      }
                    }}
                  >
                    <layout.EducationList data={item} />
                  </li>
                );
              })}
            </ul>

            <div className="paginationContainer">
              <ui.button.Pagination
                page={boardInfo.filter?.page}
                setPage={(v) =>
                  boardInfo.setFilter(e => { return { ...e, page: v } })
                }
                maxPage={boardInfo.mainEducationShareList?.pagination?.endPage}
                list={boardInfo.mainEducationShareList?.pagination?.page_list}
              />
            </div>
          </section>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EducationInfoPage;