import utils from "utils";
import Styles from "./styles";

function NoticeList({ data, index }) {
  return (
    <Styles.Container className={data.hold_yn ? "Y" : ""}>
      {data.hold_yn === "Y" ? (
        <div className="importantText">중요</div>
      ) : (
        <div className="number">{ index + 1 < 10 ? "0" + (index + 1) : index + 1}</div>
      )}
      <p className="title">{data.title}</p>
      <p className="date">{utils.date?.getDateType(data?.created_at)}</p>
    </Styles.Container>
  );
}

export default NoticeList;
