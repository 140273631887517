import Styles from "./styles";

function BasicTextarea({ labelText, value, setValue, placeholder, disabled = false }) {
  return (
    <Styles.Container>
      <div className="inputContainer">
        {labelText && (
          <label>
            <span>{labelText}</span>
          </label>
        )}

        <textarea
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={disabled}
        ></textarea>
      </div>
    </Styles.Container>
  );
}

export default BasicTextarea;
