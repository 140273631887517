import Styles from "./styles";
import NoticeList from "../NoticeList";
import { ui } from "components";
import { useNavigate } from "react-router-dom";
import { useAuth, useNotice } from "hooks";

function Notice() {
  const noticeInfo = useNotice({ options: { enableList: true } });
  const authInfo = useAuth();

  const navigate = useNavigate();
  const yLength = noticeInfo.value?.list?.filter(v => v.hold_yn == "Y").length;
  
  return (
    <Styles.Container>
      {noticeInfo.value?.list?.length > 0 ? (
        <ul className="listWrap">
           {noticeInfo.value?.list?.map((item, index) => {
            return (
              <li className="list" key={"list" + index}>
                <div onClick={()=>{
                  navigate("/customer-support/notice/detail?id=" + noticeInfo.value?.list[index]?.id)
                }}>
                  <NoticeList data={item} index={index - yLength}/>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="emptyText">등록된 게시글이 없습니다.</p>
      )}

      <div className="paginationContainer">
        <ui.button.Pagination
          page={noticeInfo.page}
          setPage={noticeInfo.setPage}
          list={noticeInfo.value?.pagination?.page_list}
          maxPage={noticeInfo.value?.pagination?.endPage}
        />
      </div>
    </Styles.Container>
  );
}

export default Notice;
