import styled from "styled-components";

// img
import ImgEdusource from "resources/image/img_edusource_banner.png";
import ImgEdusourceMb from "resources/image/img_edusource_banner_mb.png";
import ImgReasonSubBanner from "resources/image/img_edusource_sub_banner.png";
import ImgReasonSubBannerMb from "resources/image/img_edusource_sub_banner_mb.png";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .mainBannerSection {
    margin-bottom: 80px;
    color: #fff;
    background: url(${ImgEdusource}) no-repeat center / cover;

    & .inner {
      height: 800px;
      padding-top: 139px;
    }

    & .storyTitle {
      width: fit-content;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
      border-bottom: 2px solid var(--gray-color-04);
    }

    & .title {
      margin-bottom: 40px;
      font-size: 42px;
      font-weight: bold;
    }

    & .text {
      font-weight: 500;
      line-height: 1.71;
    }
  }

  & .reasonSection {
    margin-bottom: 150px;

    & .imgWrap {
      width: 361px;
      height: 140px;
      margin: 0 auto 15px;
    }

    & .title {
      margin-bottom: 80px;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
    }

    & .list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(2) {
        flex-direction: row-reverse;
      }

      &:not(:last-child) {
        margin-bottom: 80px;
      }

      & .listImgWrap {
        width: 50%;
        height: 430px;
      }

      & .listTitle {
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: bold;

        & span {
          position: relative;
          display: inline-block;

          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 22px;
            background-color: #b9e0fe;
            content: "";
            z-index: -1;
          }
        }
      }

      & .listText {
        font-size: 16px;
        color: var(--gray-color-01);
        line-height: 1.5;
      }
    }
  }

  & .subBannerSection {
    & .subBanner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 257px;
      color: #fff;
      text-align: center;
      border-radius: 20px;
      background: url(${ImgReasonSubBanner}) no-repeat center / cover;

      & .title {
        margin-bottom: 25px;
        font-size: 32px;
        font-weight: bold;
      }

      & .text {
        line-height: 1.42;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (80 / 1600));

      & .inner {
        height: calc(100vw * (800 / 1600));
        padding-top: calc(100vw * (139 / 1600));
      }

      & .storyTitle {
        margin-bottom: calc(100vw * (20 / 1600));
        padding-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (22 / 1600));
        border-bottom: calc(100vw * (2 / 1600)) solid var(--gray-color-04);
      }

      & .title {
        margin-bottom: calc(100vw * (40 / 1600));
        font-size: calc(100vw * (42 / 1600));
      }
    }

    & .reasonSection {
      margin-bottom: calc(100vw * (150 / 1600));

      & .imgWrap {
        width: calc(100vw * (361 / 1600));
        height: calc(100vw * (140 / 1600));
        margin: 0 auto calc(100vw * (15 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (80 / 1600));
        font-size: calc(100vw * (26 / 1600));
      }

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (80 / 1600));
        }

        & .listImgWrap {
          height: calc(100vw * (430 / 1600));
        }

        & .listTitle {
          margin-bottom: calc(100vw * (30 / 1600));
          font-size: calc(100vw * (32 / 1600));

          & span {
            &::before {
              height: calc(100vw * (22 / 1600));
            }
          }
        }

        & .listText {
          font-size: calc(100vw * (16 / 1600));
        }
      }
    }

    & .subBannerSection {
      & .subBanner {
        height: calc(100vw * (257 / 1600));
        border-radius: calc(100vw * (20 / 1600));

        & .title {
          margin-bottom: calc(100vw * (25 / 1600));
          font-size: calc(100vw * (32 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (80 / 370));
      background-image: url(${ImgEdusourceMb});

      & .inner {
        height: calc(100vw * (451 / 370));
        padding-top: calc(100vw * (30 / 370));
      }

      & .storyTitle {
        margin-bottom: calc(100vw * (20 / 370));
        padding-bottom: calc(100vw * (12 / 370));
        font-size: calc(100vw * (16 / 370));
        border-bottom: calc(100vw * (2 / 370)) solid var(--gray-color-04);
      }

      & .title {
        margin-bottom: calc(100vw * (30 / 370));
        font-size: calc(100vw * (22 / 370));
      }

      & .text {
        line-height: 1.42;
      }
    }

    & .reasonSection {
      margin-bottom: calc(100vw * (80 / 370));

      & .imgWrap {
        width: calc(100vw * (248 / 370));
        height: calc(100vw * (90 / 370));
        margin: 0 auto calc(100vw * (15 / 370));
      }

      & .title {
        margin-bottom: calc(100vw * (42 / 370));
        font-size: calc(100vw * (18 / 370));
      }

      & .list {
        flex-direction: column;

        &:nth-child(2) {
          flex-direction: column;
        }

        &:not(:last-child) {
          margin-bottom: calc(100vw * (80 / 370));
        }

        & .listImgWrap {
          width: 100%;
          height: calc(100vw * (179 / 370));
          margin-bottom: calc(100vw * (30 / 370));
        }

        & .listTitle {
          margin-bottom: calc(100vw * (30 / 370));
          font-size: calc(100vw * (24 / 370));

          & span {
            &::before {
              height: calc(100vw * (15 / 370));
            }
          }
        }

        & .listText {
          font-size: calc(100vw * (14 / 370));
          line-height: 1.42;
          letter-spacing: -0.03em;
        }
      }
    }

    & .subBannerSection {
      & .subBanner {
        height: calc(100vw * (384 / 370));
        border-radius: calc(100vw * (10 / 370));
        background-image: url(${ImgReasonSubBannerMb});

        & .title {
          margin-bottom: calc(100vw * (30 / 370));
          font-size: calc(100vw * (26 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
