import Styles from "./styles";
import { layout } from "components";
import { IMAGEPATH } from "service/connector";
import utils from "utils";

// img
import IconLike from "resources/image/icon/icon_like.svg";
import IconComment from "resources/image/icon/icon_comment.svg";
import ImgExceptionDefault from "resources/image/img_facility_default.png";

function BlogList({ data }) {
  return (
    <Styles.Container>
      <div className="left">
        <div className="userInfo">
          <layout.UserProfileImg profileImg={data.profile_image} />

          <p className="nickname">{data.nickname}</p>
        </div>

        <div className="listInfo">
          <div className="listTitle">
            <p>{data.title}</p> <span className="time">{utils.date.getHourMinStrByMin(data.created_at)}</span>
          </div>

          <p className="text">{data.description_text}</p>

          <div className="reactionContainer">
            <div className="like">
              <div className="iconWrap">
                <img src={IconLike} alt="IconLike" />
              </div>
              {data.like_count}
            </div>

            <div className="comment">
              <div className="iconWrap">
                <img src={IconComment} alt="IconComment" />
              </div>
              {data.reply_count}
            </div>
          </div>
        </div>
      </div>

      <div className="imgWrap">
        <img src={data.thumbnail ? IMAGEPATH + data.thumbnail : ImgExceptionDefault} alt={data.thumbnail} />
      </div>
    </Styles.Container>
  );
}

export default BlogList;
