import utils from "utils";
import { api } from "service";
import { useQuery } from "react-query";
import { useModals, useAuth } from "hooks"
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

//img
import IconCheck from "resources/image/icon/icon_check.svg";
import IconReport from "resources/image/icon/icon_report02.svg";
import IconTrash from "resources/image/icon/icon_trash.svg";

export default function useBoard({
  options = {
    enableDetail: false,
    // bookList: false,
    bookDetail: false,
    academyList: false,
    academyDetail: false,
    communityList: false,
    communityDetail: false,
    // facilityList: false,
    facilityDetail: false,
    shareList: false,
    shareDetail: false,
    educationList: false,
    educationDetail: false,
    bestBoard: false,

    recordSize: 0,
    type: 0,
    like_yn: false,
    my_yn: false,
    mainState: 0
  },
  searchOption,

  blogOption,
  blogBest,
  
  facilityOption,
  bookOption,
  keywordOption,

  communityOption,

  boardAllOption,
}) {
  const authInfo = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  const params = utils.etc.getSearchParam();
  const modalOption = useModals();
  
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({});
  const [academyFilter, setAcademyFilter] = useState({ page: 1, recordSize: options.recordSize -2 });
  const [searchFilter, setSearchFilter] = useState({ page: 1, recordSize: 10 });

  const boardLocation = (type, edit = false) => {
    switch (type) {
      case 1: return "/education-info/month"
      case 2: return edit ? "/edit/blog" : "/blog/detail"
      case 3: return edit ? "/edit/community" : "/community/detail"
      case 4: return edit ? "/facility-info/facility" : "/facility-info/facility/detail"
      case 5: return "/education-info/academy"
      case 6: return edit ? "/facility-info/reading-room" : "/facility-info/reading-room/detail"
      case 7: return edit ? "/edit/education-info" : "/education-info/share"
    };
  };
  
  // url 부분
  useEffect(()=>{
    let queryParam = {
      page: params.has("page") ? params.get("page") : 1,
    };
    if (options.recordSize) {
      queryParam.recordSize = options.recordSize;
    }
    if (options.type) {
      queryParam.type = options.type;
    }
    if (options.like_yn) {
      queryParam.like_yn = options.like_yn;
    }
    if (options.my_yn) {
      queryParam.my_yn = options.my_yn;
    }
    if (params.has("category")) {
      queryParam.category_info_id = params.get("category");
    }
    
    setFilter(queryParam);
  },[location])

  // 검색
  const getBoardSearch = useQuery(["board_search", searchOption], () => {
    return api.board.getBoardSearch(searchOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!searchOption?.keyword
  });
  
  // 블로그
  const getBlogList = useQuery(["blogList", blogOption], () => {
    return api.board.getBlogList(blogOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!blogOption
  });
  const getBlogBest = useQuery(["blogBest"], () => {
    return api.board.getBlogBest();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!blogBest
  });
  const getBlogDetail = useQuery(["blogDetail",searchParams?.get("id")], () => {
    return api.board.getBlogDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.enableDetail
  });

  // 시설 정보
  const getFacilityList = useQuery(["facilityList", facilityOption], () => {
    return api.board.getFacilityList(facilityOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!facilityOption
  });
  const getBookList = useQuery(["bookList", bookOption], () => {
    return api.board.getBookList(bookOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!bookOption
  });
  const getFacilityDetail = useQuery(["facilityDetail", searchParams?.get("id")], () => {
    return api.board.getFacilityDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.facilityDetail
  });
  const getBookDetail = useQuery(["bookDetail", searchParams?.get("id")], () => {
    return api.board.getBookDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.bookDetail
  });
  const getAgeList = useQuery(["ageList", keywordOption], () => {
    return api.board.getAgeList(keywordOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!keywordOption
  });
  const getNumberList = useQuery(["numberList", keywordOption], () => {
    return api.board.getNumberList(keywordOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!keywordOption
  });
  const getSubjectList = useQuery(["subjectList", keywordOption], () => {
    return api.board.getSubjectList(keywordOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!keywordOption
  });

  // 지역 커뮤니티
  const getCommunityList = useQuery(["communityList", communityOption], () => {
    return api.board.getCommunityList(communityOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!communityOption
  });
  const getCommunityDetail = useQuery(["communityDetail", searchParams?.get("id")], () => {
    return api.board.getCommunityDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.communityDetail
  });

  // 마이페이지
  const getBoardAll = useQuery(["board_all", boardAllOption], () => {
    return api.board.getBoardAll(boardAllOption);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!boardAllOption
  });

  const getBoardKeyword = async (keyword) => {
    return await api.board.getBoardKeyword(keyword);
  }

  const getAcademyList = useQuery(["academy_list", academyFilter], () => {
    return api.board.getAcademyList(academyFilter);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.academyList
  });
  const getAcademyDetail = useQuery(["academy_detail", searchParams?.get("id")], () => {
    return api.board.getAcademyDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.academyDetail
  });
  // const getCommunityList = useQuery(["community_list", filter], () => {
  //   // [ Community ] Pagination
  //   params.has("category") && params.has("page")
  //     ? (window.history.pushState("", "board_url", location.pathname + "?category=" + filter.category_info_id + "&page=" + filter.page))
  //     : location.pathname === "/community" && setSearchParams({ category: 8, page: 1 })

  //   return api.board.getCommunityList(filter);
  // }, {
  //   refetchOnWindowFocus: false,
  //   enabled: !!options.communityList
  // });
  // const getCommunityDetail = useQuery(["community_detail", searchParams?.get("id")], () => {
  //   return api.board.getCommunityDetail(searchParams?.get("id"));
  // }, {
  //   refetchOnWindowFocus: false,
  //   enabled: !!options.communityDetail
  // });
  const getShareList = useQuery(["share_list", filter], () => {
    return api.board.getShareList(filter);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.shareList
  });
  const getShareDetail = useQuery(["share_detail", searchParams?.get("id")], () => {
    return api.board.getShareDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.shareDetail
  });

  // const getEducationList = useQuery(["education_list",date], () => {
  //   return api.board.getEducationList(date);
  // }, {
  //   refetchOnWindowFocus: false,
  //   enabled: !!options.educationList
  // });
  const getEducationDetail = useQuery(["education_detail",searchParams?.get("id")], () => {
    return api.board.getEducationDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.educationDetail
  });

  
  // 메인 페이지
  const mainState = (index) => {
    return parseInt(options.mainState).toString(2).split("").reverse()[index] == 1;
  }
  const getMainCommunityList = useQuery(["main_community_list"], () => {
    return api.board.getMainCommunityList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!mainState(0)
  });
  const getMainFacilityList = useQuery(["main_facility_list"], () => {
    return api.board.getMainFacilityList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!mainState(1)
  });
  const getMainBookList = useQuery(["main_book_list"], () => {
    return api.board.getMainBookList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!mainState(2)
  });
  const getMainEducationList = useQuery(["main_education_list"], () => {
    return api.board.getMainEducationList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!mainState(3)
  });
  const getMainEducationShareList = useQuery(["main_education_share_list", filter], () => {
    return api.board.getMainEducationShareList(filter);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!mainState(4)
  });

  // 모달
  const onWriteCancelModal = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = IconReport;
      e.title = "페이지를 나가시겠습니까?";
      e.text = "페이지를 나가시면 내용이 전체 삭제됩니다.";
      e.confirmButtonText = "나가기";
      e.confirmButtonClick = () => {
        navigate(-1);
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }
  const createBoard = async (postData, blockClicks) => {
    let result = await api.board.boardWrite(postData);
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconCheck;
        e.title = "게시글이 등록되었습니다.";
        e.text = "마이페이지에서 확인해보세요.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;

        navigate(`/${location.pathname.split("/")[2]}/${location.pathname.split("/")[2] === "education-info" ? "share" : "detail"}?id=${result.data}`);
        return { ...e };
      });
    } else {
      blockClicks = false
      console.log("전송 실패")
    }
  }
  const onEditCancelModal = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = IconReport;
      e.title = "페이지를 나가시겠습니까?";
      e.text = "페이지를 나가시면 수정이 취소됩니다.";
      e.confirmButtonText = "나가기";
      e.confirmButtonClick = () => {
        navigate(-1);
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }
  const editBoard = async (postData, blockClicks) => {
    postData.append("id",searchParams?.get("id"))
    let result = await api.board.boardEdit(postData);
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconCheck;
        e.title = "수정이 완료되었습니다.";
        e.text = "게시물이 수정되었습니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          navigate(-1);
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    } else {
      blockClicks = false
      console.log("전송 실패")
    }
  }
  const deleteBoard = (id, url) => {
    if (authInfo.certification()) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconTrash;
        e.title = "게시글을 삭제하시겠습니까?";
        e.text = "삭제하시면 복구가 불가능합니다.";
        e.confirmButtonText = "삭제하기";
        e.confirmButtonClick = async () => {
          const postData = new FormData();
          postData.append("id",id);
          
          let result = await api.board.boardDelete(postData);
          if (result.success) {
            modalOption.setDefaultModal((e) => {
              e.show = true;
              e.icon = IconCheck;
              e.title = "삭제가 완료되었습니다.";
              e.text = "게시글이 삭제되었습니다.";
              e.confirmButtonText = "확인";
              e.confirmButtonClick = () => { navigate(url ? url : 0) };
              e.cancelButtonText = false;
              return { ...e };
            });
          }
        };
        e.cancelButtonText = "취소";
        e.cancelButtonClick = false;
        return { ...e };
      });
    }
  }
  const allDeleteBoard = (idList) => {
    if (authInfo.certification()) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconTrash;
        e.title = "게시글을 전체 삭제하시겠습니까?";
        e.text = "삭제 시 복구가 불가능합니다.";
        e.confirmButtonText = "삭제하기";
        e.confirmButtonClick = () => {
          idList?.map(async (item) => {
            const postData = new FormData();
            postData.append("id",item.id);
            
            let result = await api.board.boardDelete(postData);
            if(result.success === false){
              console.log(`${item.id} : ${item.title} 게시글 삭제 오류`);
            }
          });
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.icon = IconCheck;
            e.title = "삭제가 완료되었습니다.";
            e.text = "게시글이 삭제되었습니다.";
            e.confirmButtonText = "확인";
            e.confirmButtonClick = () => { navigate(0) };
            e.cancelButtonText = false;
            navigate(null);
            return { ...e };
          });
        };
        e.cancelButtonText = "취소";
  
        return { ...e };
      });
    }
  }

  return {
    getBoardSearch,

    getBlogList,
    detail: getBlogDetail.data ? getBlogDetail.data : {},
    getBlogBest,

    getFacilityList,
    facilityDetail: getFacilityDetail.data ? getFacilityDetail.data : [],
    getBookList,
    bookDetail: getBookDetail.data ? getBookDetail.data : [],
    getAgeList,
    getNumberList,
    getSubjectList,

    getCommunityList,
    communityDetail: getCommunityDetail.data ? getCommunityDetail.data : [],
    
    getBoardAll,

    // searchList: getBoardSearch.data ? getBoardSearch.data : {},
    // education: getEducationList.data ? getEducationList.data : {},
    educationDetail: getEducationDetail.data ? getEducationDetail.data : {},
    academyList: getAcademyList.data ? getAcademyList.data : [],
    academyDetail: getAcademyDetail.data ? getAcademyDetail.data : [],
    shareList: getShareList.data ? getShareList.data : [],
    shareDetail: getShareDetail.data ? getShareDetail.data : [],
    
    mainBookList: getMainBookList.data ? getMainBookList.data : [],
    mainCommunityList: getMainCommunityList.data ? getMainCommunityList.data : [],
    mainFacilityList: getMainFacilityList.data ? getMainFacilityList.data : [],
    mainEducationList: getMainEducationList.data ? getMainEducationList.data : [],
    mainEducationShareList: getMainEducationShareList.data ? getMainEducationShareList.data : [],
    
    onWriteCancelModal,
    createBoard,
    onEditCancelModal,
    editBoard,
    deleteBoard,
    allDeleteBoard,

    getBoardKeyword,
    
    filter, setFilter,
    academyFilter, setAcademyFilter,
    searchFilter, setSearchFilter,

    boardLocation
  }
}
