// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.
import connector from "service/connector";
import "./styles.css"

// The editor creator to use.
import  Editor  from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useAuth } from "hooks";
import { useParams } from "react-router-dom";

const CustomCkEditor = ({ text, setText, blog }) => {
	const authInfo = useAuth({});
	const { id } = useParams();

	const customUploadAdapter = (loader) => {
		return {
			upload() {
				return new Promise((resolve) => {
					const data = new FormData();
					loader.file.then((file) => {
						data.append("upload", file);
						connector.connectFetchController("image/drag", "POST", data).then((e) => {
							if (e.uploaded == true) { resolve({ default: e.url }) }
						})
					})
				})
			}
		}
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return customUploadAdapter(loader);
		}
	}

	const items = [
		"heading",
		"|", "bold", "italic", "bulletedlist", "numberedlist",
		"|", "undo", "redo", "blockQuote", "selectAll", "insertTable",
	]
	if (blog) {
    items.push("|", "link", "imageUpload", "mediaEmbed");
	}

	const edrtorConfiguration = {
		extraPlugins: [uploadPlugin],
		mediaEmbed: {
			previewsInData: true
		},
		language: "ko",
		placeholder: id !== "blog" && `회원가입 시 설정한 “활동지역”에만 글쓰기가 가능합니다. [ 회원님의 설정지역은 : "${authInfo.userData?.location}" 입니다. ]`,
		toolbar: { items: items }
	};

	return (
		<div id="ckeditor-body" >
			<CKEditor
				editor={Editor}
				config={edrtorConfiguration}
				data={text ? text : ""}
				onBlur={(e, editor) => {
					setText(editor.getData()); 
				}}
			/>
		</div>
	)
}

export default CustomCkEditor;