import { api } from "service";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth, useModals, useReply } from "hooks";

//img
import IconCheck from "resources/image/icon/icon_check.svg";
import IconTrash from "resources/image/icon/icon_trash.svg";

export default function useLike() {
  const [searchParams] = useSearchParams();
  const authInfo = useAuth()
  const replyInfo = useReply({})
  
  const modalOption = useModals();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  
  const toggleLike = async (id, type, index, subIndex) => {
    if (authInfo.certification()) {
      doLike({
        id: id,
        type: type,
        index: index,
        subIndex: subIndex
      });
    }
  }

  const optionValue = () => {
    switch (location.pathname) {
      case "/education-info/month": return "education_detail";
      case "/education-info/academy": return "academy_detail";
      case "/community/detail": return "communityDetail";
      default: return "blogDetail";
    }
  };

  const { mutate: doLike } = useMutation(
    (e) => {
      const postData = new FormData();
      postData.append("relate_info_id", e.id);
      postData.append("type", e.type);

      return api.like.toggleLike(postData);
    },
    {
      async onMutate(variables) {
        // 게시글 공감하기
        if (variables.type === 1) {
          queryClient.setQueryData([optionValue(), searchParams?.get("id")],
            e => {
              e.like_yn = e.like_yn === "Y" ? "N" : "Y";
              e.like_count = parseInt(e.like_count);
              e.like_count += e.like_yn === "Y" ? 1 : -1;
              return { ...e }
            }
          )
        }
        // 댓글, 대댓글 공감하기
        if (variables.type === 2) {
          queryClient.setQueryData(["get_reply", replyInfo.filter],
            e => {
              let item = e.list[variables.index];
              let replyItem = item.reply_list[variables.subIndex];

              if(variables.subIndex == -1){
                item.like_yn = item.like_yn === "Y" ? "N" : "Y";
                item.like_count = parseInt(item.like_count);
                item.like_count += item.like_yn === "Y" ? 1 : -1;
              }else{
                replyItem.like_yn = replyItem.like_yn === "Y" ? "N" : "Y";
                replyItem.like_count = parseInt(replyItem.like_count);
                replyItem.like_count += replyItem.like_yn === "Y" ? 1 : -1;
              }
              return { ...e }
            }  
          );
        }
      },
      onError(error, variables, context) {
        context && context();
      },
    }
  );

  // 좋아요 해제
  const deleteLike = (likeId) => {
    if (authInfo.certification()) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconTrash;
        e.title = "게시글을 삭제하시겠습니까?";
        e.text = "삭제하시면 복구가 불가능합니다.";
        e.confirmButtonText = "삭제하기";
        e.confirmButtonClick = async () => {
          const postData = new FormData();
          postData.append("relate_info_id", likeId);
          postData.append("type", 1);

          let result = await api.like.toggleLike(postData);
          if (result.success) {
            modalOption.setDefaultModal((e) => {
              e.show = true;
              e.icon = IconCheck;
              e.title = "삭제가 완료되었습니다.";
              e.text = "게시글이 삭제되었습니다.";
              e.confirmButtonText = "확인";
              e.confirmButtonClick = () => { navigate(0) };
              e.cancelButtonText = false;
              return { ...e };
            });
          }
        };
        e.cancelButtonText = "취소";
        e.cancelButtonClick = false;
        return { ...e };
      });
    }
  }
  const allDeleteLike = async (likeIdList) => {
    if (authInfo.certification()) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconTrash;
        e.title = "게시글을 전체 삭제하시겠습니까?";
        e.text = "삭제 시 복구가 불가능합니다.";
        e.confirmButtonText = "삭제하기";
        e.confirmButtonClick = () => {
          likeIdList?.map(async (item) => {
            const postData = new FormData();
            postData.append("relate_info_id", item.id);
            postData.append("type", 1);
            
            let result = await api.like.toggleLike(postData);
            if(result.success === false){
              console.log(`${item.id} : ${item.title} 좋아요 삭제 오류`);
            }
          });
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.icon = IconCheck;
            e.title = "삭제가 완료되었습니다.";
            e.text = "게시글이 삭제되었습니다.";
            e.confirmButtonText = "확인";
            e.confirmButtonClick = () => { navigate(0) };
            e.cancelButtonText = false;
            return { ...e };
          });
        };
        e.cancelButtonText = "취소";
  
        return { ...e };
      });
    }
  }

  return {
    toggleLike,
    deleteLike,
    allDeleteLike
  }
}