import Styles from "./styles";
import { useChildren, useModals } from "hooks";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

//img
import IconReport from "resources/image/icon/icon_report02.svg";

function ParentsConfirmPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const childrenInfo = useChildren();
  const modalOption = useModals();
  
  const [nice, setNice] = useState();
  useEffect(() => {
    if (nice) {
      navigate("", "_self", 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.target = "_self";
      document.form_chk.submit();
    }
  }, [nice]);

  useEffect(() => {
    if (searchParams?.get("duplicate") === "Y") {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconReport;
        e.title = "중복 인증입니다.";
        e.text = "동일한 정보로 재인증이 불가합니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;
        navigate("/mypage/info");
        return { ...e };
      });
    }
    if (searchParams?.get("error") === "Y") {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconReport;
        e.title = "본인인증에 실패했습니다.";
        e.text = "다시 시도해 주세요.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;
        navigate("/parents-confirm");
        return { ...e };
      });
    }
    if (searchParams?.get("age-error") === "Y") {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconReport;
        e.title = "본인인증에 실패했습니다.";
        e.text = "20세 ~ 60세까지 인증이 가능합니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;
        navigate("/mypage/info");
        return { ...e };
      });
    }
  }, [searchParams])

  return (
    <>
      <common.Header />
      <Styles.Container>
        <form>
          <div className="narrowInner">
            <h2 className="title">학부모 인증</h2>
            <p className="text">
              학부모 인증 후 에듀소스의 <br className="mbOnly" />
              다양한 서비스를 이용해 보세요.
            </p>
            <ul className="listWrap">
              {childrenInfo.childrenList?.map((item, index) => {
                const changeValue = (key, value) => {
                  childrenInfo.setChildrenList((e) => {
                    item[key] = value
                    let newItem = [ ...e ]
                    newItem[index] = item
                    return newItem
                  })
                }
                return (
                  <li className="list" key={"list" + index}>
                    <layout.ChildrenInfoForm
                      index={index + 1}
                      changeValue={changeValue}
                      item={item}
                      plusClick={childrenInfo.handlePlusClick}
                      minusClick={() => { childrenInfo.handleMinusClick(index) }}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="bottomButtonContainer">
              <p className="errorText">{childrenInfo.errorText}</p>
              
              <div className="buttonWrap">
                <ui.button.BasicButton
                  buttonText={"취소"}
                  buttonType={"gray"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>

              <div className="buttonWrap">
                <div className="perents_check">
                  <form name="form_chk" method="post">
                    <input type="hidden" name="m" value="checkplusService" />
                    <input type="hidden" name="EncodeData" value={nice} />
                  </form>

                  <ui.button.BasicButton
                    buttonText={"실명 인증"}
                    buttonType={"basic"}
                    onClick={() => {
                      childrenInfo.onInfoUpdate(setNice)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ParentsConfirmPage;
