import Styles from "./styles";
import { useNavigate } from "react-router-dom";

function Tab({ tabList, tabIndex, setTabIndex }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      {
        tabList?.map((item,index) => {
          return (
            <li
              className={"tabList" + (item.id == tabIndex ? " on" : "")}
              key={"tabList" + item.title}
              onClick={() => {
                setTabIndex(item.id);
                item.url && navigate(item.url)
              }}
            >
              {item.title}
            </li>
          );
        })
      }
    </Styles.Container>
  );
}

export default Tab;
