import Styles from "./styles";
import { useState } from "react";

function SecondSelect({ optionList, placeholder, selectCategory, setSelectCategory}) {
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  return (
    <Styles.Container className={isOptionOpen ? "on" : ""}>
      <div
        className="nowSelect"
        onClick={() => {
          setIsOptionOpen(!isOptionOpen);
        }}
      >
        {selectCategory !== undefined ? selectCategory : placeholder ? placeholder : "전체"}
      </div>

      {isOptionOpen && (
        <ul className="optionListWrap">
          {optionList?.map((item, index) => {
            return (
              <li
                className="optionList"
                key={"optionList" + index}
                onClick={() => {
                  setSelectCategory(item);
                  setIsOptionOpen(false);
                }}
              >
                {item.title ? item.title : item}
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default SecondSelect;
