import utils from "utils";
import Styles from "./styles";

//img
import IconGlasses from "resources/image/icon/icon_glasses02.svg";
import IconUser from "resources/image/icon/icon_user03.svg";
import IconBook from "resources/image/icon/icon_book02.svg";
import IconHand from "resources/image/icon/icon_hand.svg";
import IconSchool from "resources/image/icon/icon_school02.svg";
import IconLocation from "resources/image/icon/icon_location02.svg";
import IconCall from "resources/image/icon/icon_call.svg";
import IconBus from "resources/image/icon/icon_bus.svg";
import IconWeb from "resources/image/icon/icon_web.svg";

function FacilityDetailInfo({data}) {
  const facilityList = [
    {
      title: "학원 교육 정보",
      detailList: [
        {
          icon: IconGlasses,
          listTitle: "수강 인원",
          listText: data.member || "-",
        },
        {
          icon: IconUser,
          listTitle: "수강 연령",
          listText: data.age || "-"
        },
        {
          icon: IconBook,
          listTitle: "수강 과목",
          listText: data.subject || "-"
        },
        {
          icon: IconHand,
          listTitle: (<> 교육비 범위 <br className="mbOnly" /> (최소) </>),
          listText: utils.etc.numberWithCommas(data.price) || "-"
        },
        {
          icon: IconSchool,
          listTitle: "특목고 실적",
          listText: data.goal || "-"
        }
      ]
    },
    {
      title: "학원 기본 정보",
      detailList: [
        {
          icon: IconLocation,
          listTitle: "주소",
          listText: data.address + data.address_detail || "-"
        },
        {
          icon: IconCall,
          listTitle: "전화번호",
          listText: data.contact || "-"
        },
        {
          icon: IconBus,
          listTitle: "교통편",
          listText: data.transfer || "-"
        },
        {
          icon: IconWeb,
          listTitle: "웹사이트",
          listText: data.url || "-"
        }
      ]
    }
  ];
  return (
    <Styles.Container>
      {facilityList?.map((item, index) => {
        return (
          <div
            className="failityDetailContainer"
            key={"failityDetailContainer" + index}
          >
            <p className="failityDetailTitle">{item.title}</p>

            <ul className="detailListWrap">
              {item.detailList?.map((current, idx) => {
                return (
                  <li className="detailList" key={"detailList" + idx}>
                    <p className="listTitle">
                      <span className="iconWrap">
                        <img src={current.icon} alt={current.icon} />
                      </span>
                      <span className="listTitleText">{current.listTitle}</span>
                    </p>
                    <p className="listText">{current.listText}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </Styles.Container>
  );
}

export default FacilityDetailInfo;
