import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding-top: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--black-color-01);
    content: "";
  }

  & .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & .state {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 39px;
      font-size: 16px;
      font-weight: 500;
      color: var(--gray-color-02);
      border: 1px solid var(--gray-color-02);
      border-radius: 50px;

      &.on {
        color: var(--main-color);
        border-color: var(--main-color);
      }
    }

    & .date {
      font-weight: 500;
      color: var(--gray-color-02);
    }
  }

  & .title {
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: bold;
  }

  & .text {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.55;
  }

  & .fileContainer {
    margin-bottom: 60px;

    & .fileText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 39px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      color: var(--main-color);
      border: 1px solid var(--main-color);
      border-radius: 50px;
    }

    & .fileListWrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 9px;
    }

    & .emptyText {
      font-size: 18px;
      font-weight: 500;
      color: #cfd2d7;
    }
  }

  & .answerContainer {
    margin-bottom: 80px;
    padding: 30px;
    font-size: 18px;
    font-weight: 500;
    background-color: var(--gray-color-05);
    border-top: 1px solid var(--gray-color-03);

    & .answerTitle {
      margin-bottom: 16px;
      font-weight: bold;
      color: var(--main-color);
    }

    & .answer {
      line-height: 1.55;
    }

    & .emptyText {
      color: var(--gray-color-02);
    }
  }

  & .listButton {
    width: 250px;
    margin: 0 auto;
  }

  @media (max-width: 1600px) {
    padding-top: calc(100vw * (23 / 1600));

    &::before {
      height: calc(100vw * (3 / 1600));
    }

    & .topContainer {
      margin-bottom: calc(100vw * (20 / 1600));

      & .state {
        width: calc(100vw * (96 / 1600));
        height: calc(100vw * (39 / 1600));
        font-size: calc(100vw * (16 / 1600));
        border-radius: calc(100vw * (50 / 1600));
      }
    }

    & .title {
      margin-bottom: calc(100vw * (50 / 1600));
      font-size: calc(100vw * (22 / 1600));
    }

    & .text {
      margin-bottom: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }

    & .fileContainer {
      margin-bottom: calc(100vw * (60 / 1600));

      & .fileText {
        width: calc(100vw * (96 / 1600));
        height: calc(100vw * (39 / 1600));
        margin-bottom: calc(100vw * (20 / 1600));
        font-size: calc(100vw * (16 / 1600));
        border-radius: calc(100vw * (50 / 1600));
      }

      & .fileListWrap {
        gap: calc(100vw * (9 / 1600));
      }

      & .emptyText {
        font-size: calc(100vw * (18 / 1600));
      }
    }

    & .answerContainer {
      margin-bottom: calc(100vw * (80 / 1600));
      padding: calc(100vw * (30 / 1600));
      font-size: calc(100vw * (18 / 1600));

      & .answerTitle {
        margin-bottom: calc(100vw * (16 / 1600));
      }
    }

    & .listButton {
      width: calc(100vw * (250 / 1600));
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (16 / 370));

    &::before {
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: calc(100vw * (3 / 370));
    }

    & .topContainer {
      margin-bottom: calc(100vw * (30 / 370));

      & .state {
        width: calc(100vw * (76 / 370));
        height: calc(100vw * (34 / 370));
        font-size: calc(100vw * (14 / 370));
        border-radius: calc(100vw * (50 / 370));
      }
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (18 / 370));
    }

    & .text {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (14 / 370));
      line-height: 1.42;
    }

    & .fileContainer {
      margin-bottom: calc(100vw * (30 / 370));

      & .fileText {
        width: calc(100vw * (76 / 370));
        height: calc(100vw * (34 / 370));
        margin-bottom: calc(100vw * (10 / 370));
        font-size: calc(100vw * (14 / 370));
        border-radius: calc(100vw * (50 / 370));
      }

      & .fileListWrap {
        flex-direction: column;
        gap: calc(100vw * (9 / 370));
      }

      & .emptyText {
        font-size: calc(100vw * (14 / 370));
      }
    }

    & .answerContainer {
      margin-bottom: calc(100vw * (50 / 370));
      padding: calc(100vw * (20 / 370)) calc(100vw * (15 / 370));
      font-size: calc(100vw * (14 / 370));

      & .answerTitle {
        margin-bottom: calc(100vw * (16 / 370));
      }
    }

    & .listButton {
      width: calc(100vw * (155 / 370));
    }
  }
`;

export { Container };

export default { Container };
