import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 98px;
  padding: 30px 0;

  & .infoWrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 268px);

    & .listTitleContainer {
      margin-bottom: 8px;

      & .listTitle {
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    & .listText {
      display: -webkit-box;
      color: var(--gray-color-02);
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & .detailContainer {
      margin-top: auto;
      font-weight: 500;
      color: var(--gray-color-02);

      & .comment {
        display: flex;
        align-items: center;

        & .iconWrap {
          width: 18px;
          height: 18px;
          margin-right: 3px;
        }
      }

      /* 스타일 추가 **/
      & .detailListWrap {
        display: flex;
        gap: 30px;

        & .detailList {
          position: relative;
          &:not(:last-child)::after {
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
            width: 1px;
            height: 10px;
            background-color: var(--gray-color-02);
            content: "";
          }
        }
      }
    }
  }

  & .imgWrap {
    width: 170px;
    height: 121px;
    overflow: hidden;
  }

  &.detail {
    align-items: center;
    gap: 112px;
    padding: 20px 0;

    & .infoWrap {
      width: calc(100% - 338px);
      height: 120px;

      & .listTitleContainer {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;

        & .listTitle {
          font-size: 18px;
        }

        & .time {
          flex-shrink: 0;
        }
      }

      & .detailContainer {
        display: flex;
        align-items: center;
        gap: 30px;

        & .comment .iconWrap {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        & .detailListWrap {
          display: flex;
          gap: 30px;

          & .detailList {
            position: relative;

            &:not(:last-child)::after {
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translateY(-50%);
              width: 1px;
              height: 10px;
              background-color: var(--gray-color-02);
              content: "";
            }
          }
        }
      }
    }

    & .imgWrap {
      width: 226px;
      height: 156px;
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (98 / 1600));
    padding: calc(100vw * (30 / 1600)) 0;

    & .infoWrap {
      width: calc(100% - calc(100vw * (268 / 1600)));

      & .listTitleContainer {
        margin-bottom: calc(100vw * (8 / 1600));

        & .listTitle {
          font-size: calc(100vw * (16 / 1600));
        }
      }

      & .detailContainer {
        & .comment {
          & .iconWrap {
            width: calc(100vw * (18 / 1600));
            height: calc(100vw * (18 / 1600));
            margin-right: calc(100vw * (3 / 1600));
          }
        }
      }
    }

    & .imgWrap {
      width: calc(100vw * (170 / 1600));
      height: calc(100vw * (121 / 1600));
    }

    &.detail {
      gap: calc(100vw * (112 / 1600));
      padding: calc(100vw * (20 / 1600)) 0;

      & .infoWrap {
        width: calc(100% - calc(100vw * (338 / 1600)));
        height: calc(100vw * (120 / 1600));

        & .listTitleContainer {
          gap: calc(100vw * (15 / 1600));
          margin-bottom: calc(100vw * (10 / 1600));

          & .listTitle {
            font-size: calc(100vw * (18 / 1600));
          }
        }

        & .detailContainer {
          gap: calc(100vw * (30 / 1600));

          & .comment .iconWrap {
            width: calc(100vw * (24 / 1600));
            height: calc(100vw * (24 / 1600));
            margin-right: calc(100vw * (5 / 1600));
          }

          & .detailListWrap {
            gap: calc(100vw * (30 / 1600));

            & .detailList {
              &:not(:last-child)::after {
                right: calc(100vw * (-15 / 1600));
                height: calc(100vw * (10 / 1600));
              }
            }
          }
        }
      }

      & .imgWrap {
        width: calc(100vw * (226 / 1600));
        height: calc(100vw * (156 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: calc(100vw * (16 / 370));
    padding: calc(100vw * (20 / 370)) 0;

    & .infoWrap {
      width: 100%;

      & .listTitleContainer {
        margin-bottom: calc(100vw * (10 / 370));

        & .listTitle {
          font-size: calc(100vw * (16 / 370));
        }
      }

      & .detailContainer {
        margin-top: calc(100vw * (18 / 370));

        & .comment {
          & .iconWrap {
            width: calc(100vw * (18 / 370));
            height: calc(100vw * (18 / 370));
            margin-right: calc(100vw * (3 / 370));
          }
        }
      }
    }

    & .imgWrap {
      width: 100%;
      height: calc(100vw * (200 / 370));
    }

    &.detail {
      flex-direction: column;
      gap: calc(100vw * (20 / 370));
      padding: calc(100vw * (20 / 370)) 0;

      & .infoWrap {
        width: 100%;
        height: auto;

        & .listTitleContainer {
          flex-direction: column;
          align-items: flex-start;
          gap: calc(100vw * (6 / 370));
          margin-bottom: calc(100vw * (10 / 370));

          & .listTitle {
            font-size: calc(100vw * (16 / 370));
          }

          & .time {
            font-size: calc(100vw * (12 / 370));
          }
        }

        & .detailContainer {
          flex-direction: column;
          align-items: flex-start;
          gap: calc(100vw * (10 / 370));
          font-size: calc(100vw * (12 / 370));

          & .comment .iconWrap {
            width: calc(100vw * (18 / 370));
            height: calc(100vw * (18 / 370));
            margin-right: calc(100vw * (3 / 370));
          }

          & .detailListWrap {
            gap: calc(100vw * (20 / 370));

            & .detailList {
              &:not(:last-child)::after {
                right: calc(100vw * (-10 / 370));
                height: calc(100vw * (10 / 370));
              }
            }
          }
        }
      }

      & .imgWrap {
        width: 100%;
        height: calc(100vw * (200 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
