import connector from "service/connector"

const getAlarm = async () => {
  return await connector.authConnector("alarm/list", "GET");
}
const alarmRead = async (data) => {
  return await connector.authConnector("alarm/read/" + data, "POST");
};
const alarmToggle = async (data) => {
  return await connector.authConnector("board/alarm/toggle/" + data, "POST");
};

export { getAlarm, alarmRead, alarmToggle }
export default { getAlarm, alarmRead, alarmToggle }