import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;
  background-color: #f9fafc;

  & .wrap {
    width: 616px;
    margin: 80px auto 0;
    background-color: #fff;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);

    & .narrowInner {
      height: 719px;
      padding: 50px 0;
    }
  }

  & .title {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: bold;
  }

  & .text {
    margin-bottom: 37px;
    font-size: 16px;
    color: var(--gray-color-02);
  }

  & .textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 117px;
    margin-bottom: 52px;
    background-color: #f9fafc;
    border-radius: 2px;
    text-align: center;
  }

  .imgWrap {
    width: 332px;
    height: 198px;
    margin: 0 auto 52px;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .wrap {
      width: calc(100vw * (616 / 1600));
      margin: calc(100vw * (80 / 1600)) auto 0;
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .narrowInner {
        height: calc(100vw * (719 / 1600));
        padding: calc(100vw * (50 / 1600)) 0;
      }
    }

    & .title {
      margin-bottom: calc(100vw * (10 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .text {
      margin-bottom: calc(100vw * (37 / 1600));
      font-size: calc(100vw * (16 / 1600));
    }

    & .textContainer {
      height: calc(100vw * (117 / 1600));
      margin-bottom: calc(100vw * (52 / 1600));
      border-radius: 2pxcalc (100vw * (2 / 1600));
    }

    & .imgWrap {
      width: calc(100vw * (332 / 1600));
      height: calc(100vw * (198 / 1600));
      margin: 0 auto calc(100vw * (52 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (157 / 370));
    background-color: #fff;

    & .wrap {
      width: 100%;
      margin: calc(100vw * (50 / 370)) auto 0;
      box-shadow: none;

      & .narrowInner {
        height: auto;
        padding: 0;
      }
    }

    & .title {
      margin-bottom: calc(100vw * (5 / 370));
      font-size: calc(100vw * (22 / 370));
    }

    & .text {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (14 / 370));
      word-break: keep-all;
    }

    & .textContainer {
      height: calc(100vw * (117 / 370));
      margin-bottom: calc(100vw * (50 / 370));
      border-radius: 2 px calc (100vw * (2 / 370));
    }

    & .imgWrap {
      width: calc(100vw * (267 / 370));
      height: calc(100vw * (159 / 370));
      margin: 0 auto calc(100vw * (80 / 370));
    }
  }
`;

export { Container };

export default { Container };
