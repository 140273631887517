import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & input {
    width: 100%;
    height: 36px;
    padding: 0 55px 0 20px;
    font-weight: 500;
    background-color: #f9fafc;
    border-radius: 2px;
  }

  & button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
  }

  @media (max-width: 1600px) {
    & input {
      height: calc(100vw * (36 / 1600));
      padding: 0 calc(100vw * (55 / 1600)) 0 calc(100vw * (20 / 1600));
      border-radius: calc(100vw * (2 / 1600));
    }

    & button {
      right: calc(100vw * (20 / 1600));
      width: calc(100vw * (15 / 1600));
      height: calc(100vw * (15 / 1600));
    }
  }

  @media (max-width: 768px) {
    & input {
      height: calc(100vw * (48 / 370));
      padding: 0 calc(100vw * (47 / 370)) 0 calc(100vw * (15 / 370));
      border-radius: calc(100vw * (4 / 370));
    }

    & button {
      right: calc(100vw * (15 / 370));
      width: calc(100vw * (17 / 370));
      height: calc(100vw * (17 / 370));
    }
  }
`;

export { Container };

export default { Container };
