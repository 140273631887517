import Styles from "./styles";
import { useState } from "react";

function BasicSelect({ optionList, select, setSelect, placeholder }) {
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  return (
    <Styles.Container>
      <div
        tabIndex={0}
        className={
          "nowSelect" +
          (select !== "" || isOptionOpen ? " on" : "") +
          (isOptionOpen ? " open" : "")
        }
        onClick={() => setIsOptionOpen(!isOptionOpen)}
        onBlur={() => setIsOptionOpen(false)}
      >
        {select ? select : placeholder || "선택해주세요."}
      </div>

      {isOptionOpen && (
        <ul className="optionListWrap">
          {optionList?.map((item, index) => {
            return (
              <li
                className="optionList"
                key={"optionList" + index}
                onMouseDown={() => {
                  setSelect(item);
                  setIsOptionOpen(false);
                }}
              >
                {item}
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default BasicSelect;
