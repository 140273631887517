import Styles from "./styles";
import { ui } from "components";
import { useNavigate } from "react-router-dom";
import { useAuth, useChildren } from "hooks";

// img
import IconUser from "resources/image/icon/icon_user04.svg";
import IconLock from "resources/image/icon/icon_lock02.svg";
import IconSetting from "resources/image/icon/icon_setting.svg";

function ManagementInfo() {
  const navigate = useNavigate();
  const childrenInfo = useChildren();
  const authInfo = useAuth();
  
  // map
  const list = [
    {
      title: "이메일",
      text: authInfo.userData.username,
    },
    {
      title: "활동지역",
      text: authInfo.userData.location,
    },
  ];
  const parentsList = [
    {
      title: "이름",
      text: authInfo.userData.name,
    },
    {
      title: "연락처",
      text: authInfo.userData.phone,
    },
  ];
  const childList = [
    {
      title: "성별",
      gender: "gender"
    },
    {
      title: "현재 학교명",
      text: "current_school"
    },
    {
      title: "희망 학교명",
      text: "target_school"
    },
    {
      title: "현재 학원명",
      text: "current_academy"  
    },
    {
      title: "희망 학원명",
      text: "target_academy"
    }
  ]

  return (
    <Styles.Container>
      <div className="titleContainer">
        <h3 className="title">기본 정보</h3>

        <ul className="buttonListWrap">
          <li className="buttonList">
            <ui.button.BasicButton
              onClick={() => { navigate("/parents-confirm") }}
              icon={IconUser}
              size={"thin"}
              buttonType={"grayBlackFont"}
              buttonText={
                authInfo.userData.pass_yn === "N"
                  ? "학부모 인증"
                  : "재인증"
              }
            />
          </li>

          {authInfo.userData.social_yn === "N" && (
            <li className="buttonList">
              <ui.button.BasicButton
                icon={IconLock}
                size={"thin"}
                buttonType={"grayBlackFont"}
                buttonText={"비밀번호 변경"}
                onClick={() => {
                  navigate("/change-password", { state: true });
                }}
              />
            </li>
          )}
          <li className="buttonList">
            <ui.button.BasicButton
              icon={IconSetting}
              size={"thin"}
              buttonType={"grayBlackFont"}
              buttonText={"회원정보 수정"}
              onClick={() => {
                navigate("/mypage/info/edit");
              }}
            />
          </li>
        </ul>
      </div>

      <ul className="listWrap">
        {list?.map((item, index) => {
          return (
            <li className="list" key={"list" + index}>
              <p className="title">{item.title}</p>

              <p className="text">{item.text}</p>
            </li>
          );
        })}
        {authInfo.userData.pass_yn === "Y" && (
          <>
            {parentsList.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <p className="title">{item.title}</p>

                  <p className="text">{item.text}</p>
                </li>
              );
            })}
            <li className="list childrenInfo">
              {childrenInfo.getChildInfo?.data?.map((item, index) => {
                return (
                  <div
                    className="childrenInfoContainer"
                    key={"childrenInfoContainer" + index}
                  >
                    <p className="title">자녀 {index + 1}</p>

                    <ul className="detailListWrap">
                      {childList.map((current, idx) => {
                        return (
                          <li className="detailList" key={"detailList" + idx}>
                            <p className="detailTitle">{current.title}</p>
                            <p className="detailText">
                              {
                                item[current.text]
                                  ? item[current.text]
                                  : item[current.gender] === "M" ? "남" : "여"
                              }
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </li>
          </>
        )}
      </ul>
    </Styles.Container>
  );
}

export default ManagementInfo;
