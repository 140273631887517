import { common, layout } from "components";
import Styles from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useBoard } from "hooks";

// img
import ImgFacilityInfo from "resources/image/img_facility_info.png";
import ImgFacilityInfoMb from "resources/image/img_facility_info_mb.png";
import IconArrowRight from "resources/image/icon/icon_arrow_right01.svg";

function FacilityInfoPage() {
  const authInfo = useAuth();
  const boardInfo = useBoard({
    options: {
      mainState: 6,
      recordSize: 8,
      type: 4,
    },
  });

  const navigate = useNavigate();

  const data = {
    img: ImgFacilityInfo,
    mbImg: ImgFacilityInfoMb,
    nowText: "시설 정보",
    title: "시설 정보",
  };

  const list = [
    {
      check: "학원정보",
      title: "학원 정보",
      moreUrl: "facility",
      api: boardInfo.mainFacilityList,
    },
    {
      check: "독서실",
      title: "독서실 정보",
      moreUrl: "reading-room",
      api: boardInfo.mainBookList,
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <section className="infoSection middleInner">
            {list.map((item, index) => {
              return (
                <div className="infoContainer" key={"infoContainer" + index}>
                  <div className="titleContainer">
                    <p className="sectionTitle">{item.title}</p>

                    <Link to={item.moreUrl} className="moreButton">
                      더보기{" "}
                      <div className="iconWrap">
                        <img src={IconArrowRight} alt="IconArrowRight" />
                      </div>
                    </Link>
                  </div>

                  <ul className="listWrap">
                    {item.api?.map((current, idx) => {
                      return (
                        <li
                          className="list"
                          key={"list" + idx}
                          onClick={() => {
                            navigate(item.moreUrl + "/detail?id=" + current.id);
                          }}
                        >
                          <layout.FacilityInfoList data={current} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </section>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default FacilityInfoPage;
