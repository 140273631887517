import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;

  &.on {
    background-color: #ecf0f8;
  }

  & .iconWrap {
    width: 18px;
    height: 18px;
    margin-right: 13px;
  }

  & p {
    display: flex;
    width: calc(100% - 31px);

    & span {
      max-width: 48%;
      display: block;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (20 / 1600)) calc(100vw * (30 / 1600));

    & .iconWrap {
      width: calc(100vw * (18 / 1600));
      height: calc(100vw * (18 / 1600));
      margin-right: calc(100vw * (13 / 1600));
    }

    & p {
      width: calc(100% - calc(100vw * (31 / 1600)));
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (20 / 370)) calc(100vw * (25 / 370));

    & .iconWrap {
      width: calc(100vw * (18 / 370));
      height: calc(100vw * (18 / 370));
      margin-right: calc(100vw * (13 / 370));
    }

    & p {
      width: calc(100% - calc(100vw * (31 / 370)));
    }
  }
`;

export { Container };

export default { Container };
