import utils from "utils";
import Styles from "./styles";
import DatePicker from "react-datepicker";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useBoard, useCalendar } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function ThisMonthInfoPage() {
  const location = useLocation();

  const calendarInfo = useCalendar({ options: {
    scheduleList: true,
    recordSize: 5
  } })
  const boardInfo = useBoard({ options: {
    educationDetail: true,
    mainState: 16,
    type: 1,
    recordSize: 4
  } });
  const navigate = useNavigate();

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    prevText: "교육 정보",
    nowText: "이번 달 주요 교육 정보",
    title: "주요 교육 정보",
  };

  const [startDate, setStartDate] = useState(new Date());

  useEffect(()=>{
    calendarInfo.setFilter((e) => { return { ...e, date: utils.date.getDateType(startDate) } })
  },[startDate])

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={boardInfo.educationDetail} bell>
                <h2 className="title">
                  {
                    boardInfo.educationDetail?.category_title &&
                    `[${boardInfo.educationDetail?.category_title}] `
                  }
                  {boardInfo.educationDetail?.title}
                </h2>
                <div className="detailInfo">
                  <span className="date">{utils.date.getDateType(boardInfo.educationDetail?.target_date)}</span>
                  <span className="time">{utils.date.getTimeKr(boardInfo.educationDetail?.target_time)}</span>
                  <span className="address">{boardInfo.educationDetail?.location}</span>
                </div>
              </layout.DetailContainer>
            </section>

            <section className="listSection">
              <div className="listContainer key">
                <div className="titleContainer">
                  <p className="title">주요 교육 정보</p>

                  <div className="dateContainer">
                    <DatePicker
                      dateFormat="yyyy.MM.dd"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>

                <ul className="listWrap">
                  {calendarInfo.scheduleList?.list?.map((item, index) => {
                    return (
                      <li className="list" key={"keyList" + index}>
                        <layout.EducationInfoList data={item} />
                      </li>
                    );
                  })}
                </ul>

                {calendarInfo.scheduleList?.list?.length
                  ?
                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={calendarInfo.filter?.page}
                      setPage={(v) =>
                        calendarInfo.setFilter(e => { return { ...e, page: v } })
                      }
                      maxPage={calendarInfo.scheduleList?.pagination?.endPage}
                      list={calendarInfo.scheduleList?.pagination?.page_list}
                    />
                  </div>
                  :
                  <>
                    {/* 해당 날짜에는 주요 교육 정보가 없습니다. */}
                  </>
                }
              </div>

              <div className="listContainer share">
                <p className="title">교육커뮤니티</p>
                <ul className="listWrap">
                  {boardInfo.mainEducationShareList?.list?.map((item, index) => {
                    return (
                      <li
                        className="list"
                        key={"educationList" + index}
                        onClick={()=>{
                          navigate("/education-info/share?id=" + item.id, { state: location.state });
                        }}
                      >
                        <layout.EducationList data={item} />
                      </li>
                    );
                  })}
                </ul>

                <div className="paginationContainer">
                  <ui.button.Pagination
                    page={boardInfo.filter?.page}
                    setPage={(v) =>
                      boardInfo.setFilter(e => { return { ...e, page: v } })
                    }
                    maxPage={boardInfo.mainEducationShareList?.pagination?.endPage}
                    list={boardInfo.mainEducationShareList?.pagination?.page_list}
                  />
                </div>
              </div>
            </section>

            <div className="goListButton">
              <ui.button.BasicButton
                buttonText={"목록으로"}
                buttonType={"blackOutline"}
                size={"small"}
                onClick={() => {
                  navigate(location.state || "/education-info");
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ThisMonthInfoPage;
