import Styles from "./styles";

function CopyModal({ modalClose }) {
  const handleCopyClipBoard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      return (
        <>
          <p className="text">
            URL이 복사되었습니다. <br />
            원하는 곳에 붙여넣기(Ctrl+V) 해주세요.
          </p>

          <button
            type="button"
            className="closeButton"
            onClick={() => {
              modalClose();
            }}
          >
          닫기
          </button>
        </>
      )
    } catch (error) {
      alert("클립보드 복사에 실패하였습니다.");
    }
  };

  return (
    <Styles.Container>
      {handleCopyClipBoard(window.location.href)}
    </Styles.Container>
  );
}

export default CopyModal;
