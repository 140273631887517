import connector from "service/connector"

const getChildInfo = async () => {
  return await connector.authConnector("user/child", "get");
}

const childInfoInsert = async (data) => {
  return await connector.jsonConnector("user/child/insert", "POST", data)
}

const childInfoUpdate = async (data) => {
  return await connector.jsonConnector("user/child", "POST", data)
}

export {
  getChildInfo,
  childInfoInsert,
  childInfoUpdate,
}

export default {
  getChildInfo,
  childInfoInsert,
  childInfoUpdate,
}