import Styles from "./styles";
import { IMAGEPATH } from "service/connector";

// img
import ImgExceptionDefault from "resources/image/img_facility_default.png";

function BlogCardList({ data }) {
  return (
    <Styles.Container>
      <div className="imgWrap">
        <img
          src={
            data.thumbnail ? IMAGEPATH + data.thumbnail : ImgExceptionDefault
          }
          alt={data.thumbnail}
        />
      </div>

      <div className="listTitle">
        <p>{data.title}</p>
      </div>
    </Styles.Container>
  );
}

export default BlogCardList;
