import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;

  & .left {
    flex-grow: 1;
    max-width: calc(100% - 92px);

    & .title,
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .title {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
    }

    & .text {
      display: -webkit-box;
      color: var(--gray-color-02);
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  & .buttonContainer {
    flex-shrink: 0;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 30px;
      font-weight: 500;
      color: var(--gray-color-02);
      background-color: var(--gray-color-05);
      border-radius: 2px;

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (30 / 1600));
    padding-bottom: calc(100vw * (30 / 1600));

    & .left {
      max-width: calc(100% - calc(100vw * (92 / 1600)));

      & .title {
        margin-bottom: calc(100vw * (8 / 1600));
        font-size: calc(100vw * (16 / 1600));
      }
    }

    & .buttonContainer {
      & button {
        width: calc(100vw * (62 / 1600));
        height: calc(100vw * (30 / 1600));
        border-radius: calc(100vw * (2 / 1600));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (7 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: calc(100vw * (20 / 370));
    padding-bottom: calc(100vw * (30 / 370));

    & .left {
      max-width: 100%;

      & .title {
        margin-bottom: calc(100vw * (10 / 370));
        font-size: calc(100vw * (16 / 370));
      }
    }

    & .buttonContainer {
      display: flex;

      & button {
        width: calc(100vw * (62 / 370));
        height: calc(100vw * (30 / 370));
        border-radius: calc(100vw * (2 / 370));

        &:not(:last-child) {
          margin: 0 calc(100vw * (8 / 370)) 0 0;
        }
      }
    }
  }
`;

export { Container };

export default { Container };
