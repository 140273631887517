import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .narrowInner {
    padding-top: 80px;

    & .listWrap {
      margin: 50px 0 82px;
      font-size: 16px;

      & .list:not(:last-child, .all) {
        margin-bottom: 24px;
      }

      & .list:not(.all) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 30px;

        & .arrowIconWrap {
          width: 14px;
          height: 14px;
        }

        & .essential {
          color: var(--main-color);
        }
      }

      & .all {
        margin-bottom: 40px;
        & label {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .narrowInner {
      padding-top: calc(100vw * (80 / 1600));

      & .listWrap {
        margin: calc(100vw * (50 / 1600)) 0 calc(100vw * (82 / 1600));
        font-size: calc(100vw * (16 / 1600));

        & .list:not(:last-child, .all) {
          margin-bottom: calc(100vw * (24 / 1600));
        }

        & .list:not(.all) {
          padding: 0 calc(100vw * (20 / 1600)) 0 calc(100vw * (30 / 1600));

          & .arrowIconWrap {
            width: calc(100vw * (14 / 1600));
            height: calc(100vw * (14 / 1600));
          }
        }

        & .all {
          margin-bottom: calc(100vw * (40 / 1600));

          & label {
            font-size: calc(100vw * (18 / 1600));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 370));

      & .listWrap {
        margin: calc(100vw * (30 / 370)) 0 calc(100vw * (80 / 370));
        font-size: calc(100vw * (14 / 370));

        & .list:not(:last-child, .all) {
          margin-bottom: calc(100vw * (20 / 370));
        }

        & .list:not(.all) {
          padding: 0 0 0 calc(100vw * (30 / 370));

          & .arrowIconWrap {
            width: calc(100vw * (16 / 370));
            height: calc(100vw * (16 / 370));
          }
        }

        & .all {
          margin-bottom: calc(100vw * (20 / 370));

          & label {
            font-size: calc(100vw * (16 / 370));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
