function getCurrentUrl() {
  const mainUrl = window.location.pathname.split("/");
  return mainUrl;
}
function getUrlCopy() {
  var url = "";
  var textarea = document.createElement("textarea");

  document.body.appendChild(textarea);
  url = window.document.location.href;
  textarea.value = url;
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);

  return true;
}
function getDetailCopy(url) {
  window.navigator.clipboard.writeText(url);
}

export { getCurrentUrl, getUrlCopy, getDetailCopy };

export default {
  getCurrentUrl,
  getUrlCopy,
  getDetailCopy,
};
