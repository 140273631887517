import { api } from "service";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

export default function useNotice(
  {
    options = {
      enableList: false,
      enableDetail: false
    }
  }) {
  const [searchParams] = useSearchParams();
  const [isCopyModal, setIsCopyModal] = useState(false);
  const [page, setPage] = useState(1);


  useEffect(() => {
    if (searchParams.get("page") === null) setPage(1);
    else setPage(searchParams?.get("page"));
  }, [searchParams]);


  const noticeQuery = useQuery(["support_notice", page], () => {
    window.history.pushState("", "notice_url", "/customer-support/notice?page=" + page)
    return api.support.getNoticeList(page);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.enableList
  });

  const noticeDetail = useQuery(["support_notice_detail", searchParams?.get("id")], () => {
    return api.support.getNoticeDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.enableDetail
  });

  return {
    value: noticeQuery.data ? noticeQuery.data : {},
    detail: noticeDetail.data ? noticeDetail.data : [],

    page,
    setPage,
    
    isCopyModal,
    setIsCopyModal,
  }
}