import { useQuery } from "react-query";
import { api } from "service";

export default function useBanner() {

  const getBannerTop = useQuery(["bannerTop"], () => {
    return api.banner.getBanner(0);
  }, {
    refetchOnWindowFocus: false,
  });

  const getBannerBottom = useQuery(["bannerBottom"], () => {
    return api.banner.getBanner(1);
  }, {
    refetchOnWindowFocus: false,
  });

  return {
    getBannerTop,
    getBannerBottom
  }
}