import styled from "styled-components";

const Container = styled.div`
  padding: 35px 65px;
  border-bottom: 1px solid var(--gray-color-04);
  cursor: pointer;

  & .questionContainer {
    display: flex;
    align-items: center;

    & .questionIconWrap {
      width: 30px;
      height: 30px;
      margin-right: 25px;
    }

    & .question {
      width: 1124px;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .arrowIconWrap {
      margin-left: auto;
      width: 20px;
      height: 20px;
    }
  }

  &.on {
    padding: 45px 65px 55px;
    background-color: var(--black-color-01);

    & .questionContainer {
      align-items: flex-start;

      & .questionIconWrap {
        margin-top: 1px;
      }

      & .question {
        font-size: 20px;
        color: var(--main-color);
        white-space: normal;
      }

      & .arrowIconWrap {
        margin-top: 3px;
      }
    }

    & .answer {
      width: 1124px;
      margin: 30px 0 0 55px;
      color: #fff;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (35 / 1600)) calc(100vw * (65 / 1600));

    & .questionContainer {
      & .questionIconWrap {
        width: calc(100vw * (30 / 1600));
        height: calc(100vw * (30 / 1600));
        margin-right: calc(100vw * (25 / 1600));
      }

      & .question {
        width: calc(100vw * (1124 / 1600));
        font-size: calc(100vw * (16 / 1600));
      }

      & .arrowIconWrap {
        width: calc(100vw * (20 / 1600));
        height: calc(100vw * (20 / 1600));
      }
    }

    &.on {
      padding: calc(100vw * (45 / 1600)) calc(100vw * (65 / 1600))
        calc(100vw * (55 / 1600));

      & .questionContainer {
        & .questionIconWrap {
          margin-top: 1px;
        }

        & .question {
          font-size: calc(100vw * (20 / 1600));
        }

        & .arrowIconWrap {
          margin-top: calc(100vw * (3 / 1600));
        }
      }

      & .answer {
        width: calc(100vw * (1124 / 1600));
        margin: calc(100vw * (30 / 1600)) 0 0 calc(100vw * (55 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (20 / 370)) calc(100vw * (10 / 370));

    & .questionContainer {
      & .questionIconWrap {
        width: calc(100vw * (20 / 370));
        height: calc(100vw * (20 / 370));
        margin-right: calc(100vw * (5 / 370));
      }

      & .question {
        width: calc(100vw * (214 / 370));
        font-size: calc(100vw * (14 / 370));
      }

      & .arrowIconWrap {
        width: calc(100vw * (16 / 370));
        height: calc(100vw * (16 / 370));
      }
    }

    &.on {
      padding: calc(100vw * (21 / 370)) calc(100vw * (10 / 370));
      max-height: 400px;
      overflow: auto;

      & .questionContainer {
        & .question {
          font-size: calc(100vw * (16 / 370));
        }

        & .arrowIconWrap {
          margin-top: calc(100vw * (3 / 1600));
        }
      }

      & .answer {
        width: calc(100vw * (214 / 370));
        margin: calc(100vw * (20 / 370)) 0 0 calc(100vw * (25 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
