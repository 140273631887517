import Styles from "./styles";

// img
import IconNotice from "resources/image/icon/icon_notice.svg";
import IconList from "resources/image/icon/icon_list03.svg";

function BellList({ data }) {
  
  const renderBell = (type) => {
    switch (type) {
      case 1: return (<><span>공지사항</span>이 등록되었습니다.</>);
      case 2: return (<><span>{data.nickname}</span>님이 댓글을 남겼습니다.</>);
      case 3: return (<><span>{data.title}</span>이/가 업데이트 되었습니다.</>);
      case 4: return (<><span>{data.title}</span>이/가 8일 남았습니다.</>);
      case 5: return "이번 주 주요 교육 정보가 업데이트 되었습니다.";
    }
  };

  return (
    <Styles.Container className={data.read_yn === "Y" ? "" : "on"}>
      <div className="iconWrap">
        <img src={data.type == 1 ? IconNotice : IconList} alt="icon" />
      </div>

      <p>{renderBell(data.type)}</p>
    </Styles.Container>
  );
}

export default BellList;
