import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .hotTopicSection {
    margin-top: -80px;

    & .inner {
      padding: 56px 0 31px;
      background-color: #f9fafc;
      border-radius: 10px;

      & .sectionTitle {
        margin-bottom: 29px;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }

      & .sliderContainer {
        position: relative;
        width: fit-content;
        margin: 0 auto;

        & .blogSlider {
          width: 1277px;
          padding: 25px;
        }

        & .swiper-slide {
          width: fit-content;
          cursor: pointer;
        }

        & .prevButton,
        .nextButton {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          z-index: 1;

          &::after {
            display: none;
          }
        }

        & .prevButton {
          left: -74px;
        }

        & .nextButton {
          right: -74px;
        }
      }
    }
  }

  & .blogListSection {
    & .tabContainer {
      margin-bottom: 85px;
    }

    & .listWrap {
      border-top: 1px solid var(--gray-color-04);
      cursor: pointer;
    }
  }

  & .paginationContainer {
    margin-top: 50px;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1600));
    }

    & .hotTopicSection {
      margin-top: calc(100vw * (-80 / 1600));

      & .inner {
        padding: calc(100vw * (56 / 1600)) 0 calc(100vw * (31 / 1600));
        border-radius: calc(100vw * (10 / 1600));

        & .sectionTitle {
          margin-bottom: calc(100vw * (29 / 1600));
          font-size: calc(100vw * (36 / 1600));
        }

        & .sliderContainer {
          & .blogSlider {
            width: calc(100vw * (1277 / 1600));
            padding: calc(100vw * (25 / 1600));
          }

          & .prevButton,
          .nextButton {
            width: calc(100vw * (40 / 1600));
            height: calc(100vw * (40 / 1600));
          }

          & .prevButton {
            left: calc(100vw * (-74 / 1600));
          }

          & .nextButton {
            right: calc(100vw * (-74 / 1600));
          }
        }
      }
    }

    & .blogListSection {
      & .tabContainer {
        margin-bottom: calc(100vw * (85 / 1600));
      }
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 370));
    }

    & .hotTopicSection {
      margin-top: calc(100vw * (-30 / 370));

      & .inner {
        padding: calc(100vw * (30 / 370)) 0 0;
        border-radius: calc(100vw * (5 / 370));

        & .sectionTitle {
          margin-bottom: 0;
          font-size: calc(100vw * (20 / 370));
        }

        & .sliderContainer {
          width: 100%;

          & .blogSlider {
            width: 100%;
            padding: calc(100vw * (25 / 370)) 0;
          }

          & .prevButton,
          .nextButton {
            display: none;
          }
        }
      }
    }

    & .blogListSection {
      & .middleInner {
        width: calc(100vw * (290 / 370));
      }

      & .tabContainer {
        margin-bottom: 0;
        padding-bottom: calc(100vw * (7 / 370));
        overflow: auto;
      }

      & .listWrap {
        border: 0;
      }
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 370));
    }
  }
`;

export { Container };

export default { Container };
