import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding-top: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--black-color-01);
    content: "";
  }

  & .askButton {
    width: 110px;
    margin: 0 0 50px auto;
  }

  & .listWrap {
    margin-bottom: 50px;

    & .list {
      border-bottom: 1px solid var(--gray-color-04);
    }
  }

  & .emptyContainer {
    border-bottom: 1px solid var(--gray-color-04);
    padding: 200px 0;
    font-size: 18px;
    font-weight: 500;
    color: #cfd2d7;
    text-align: center;
  }

  @media (max-width: 1600px) {
    padding-top: calc(100vw * (23 / 1600));

    &::before {
      height: calc(100vw * (3 / 1600));
    }

    & .askButton {
      width: calc(100vw * (110 / 1600));
      margin: 0 0 calc(100vw * (50 / 1600)) auto;
    }

    & .listWrap {
      margin-bottom: calc(100vw * (50 / 1600));
    }

    & .emptyContainer {
      padding: calc(100vw * (200 / 1600)) 0;
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (16 / 370));

    &::before {
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: calc(100vw * (3 / 370));
    }

    & .askButton {
      width: calc(100vw * (89 / 370));
      margin: 0 0 calc(100vw * (30 / 370)) auto;
    }

    & .listWrap {
      margin-bottom: calc(100vw * (50 / 370));
    }

    & .emptyContainer {
      padding: calc(100vw * (72 / 370)) 0;
      font-size: calc(100vw * (14 / 370));
    }
  }
`;

export { Container };

export default { Container };
