import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0 20px;
  border-bottom: 1px solid var(--gray-color-04);

  & .left {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 402px);

    & .userInfo {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 123px;
      margin-right: 30px;

      & .profileImgWrap {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }

      & .nickname {
        font-size: 16px;
        font-weight: bold;
      }
    }

    & .listInfo {
      width: calc(100% - 153px);
      padding-top: 5px;

      & .listTitle {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;

        & p {
          font-size: 18px;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & span {
          flex-shrink: 0;
        }
      }

      & .text {
        display: -webkit-box;
        margin-bottom: 20px;
        color: #cfd2d7;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .reactionContainer {
        display: flex;
        align-items: center;

        & .like,
        .comment {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--gray-color-02);

          & .iconWrap {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }

        & .like {
          margin-right: 15px;
        }
      }
    }
  }

  & .imgWrap {
    flex-shrink: 0;
    width: 226px;
    height: 156px;
    margin-left: 176px;
    overflow: hidden;
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (30 / 1600)) 0 calc(100vw * (20 / 1600));

    & .left {
      width: calc(100% - calc(100vw * (402 / 1600)));

      & .userInfo {
        width: calc(100vw * (123 / 1600));
        margin-right: calc(100vw * (30 / 1600));

        & .profileImgWrap {
          width: calc(100vw * (36 / 1600));
          height: calc(100vw * (36 / 1600));
          margin-right: calc(100vw * (10 / 1600));
        }

        & .nickname {
          font-size: calc(100vw * (16 / 1600));
        }
      }

      & .listInfo {
        width: calc(100% - calc(100vw * (153 / 1600)));
        padding-top: calc(100vw * (5 / 1600));

        & .listTitle {
          gap: calc(100vw * (15 / 1600));
          margin-bottom: calc(100vw * (10 / 1600));

          & p {
            font-size: calc(100vw * (18 / 1600));
          }
        }

        & .text {
          margin-bottom: calc(100vw * (20 / 1600));
        }

        & .reactionContainer {
          & .like,
          .comment {
            & .iconWrap {
              width: calc(100vw * (18 / 1600));
              height: calc(100vw * (18 / 1600));
              margin-right: calc(100vw * (5 / 1600));
            }
          }

          & .like {
            margin-right: calc(100vw * (15 / 1600));
          }
        }
      }
    }

    & .imgWrap {
      width: calc(100vw * (226 / 1600));
      height: calc(100vw * (156 / 1600));
      margin-left: calc(100vw * (176 / 1600));
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: calc(100vw * (30 / 370)) 0;

    & .left {
      flex-direction: column;
      width: 100%;

      & .userInfo {
        width: 100%;
        margin-right: 0;

        & .profileImgWrap {
          width: calc(100vw * (26 / 370));
          height: calc(100vw * (26 / 370));
          margin-right: calc(100vw * (10 / 370));
        }

        & .nickname {
          font-size: calc(100vw * (14 / 370));
        }
      }

      & .listInfo {
        width: 100%;
        margin-top: calc(100vw * (5 / 370));
        padding-top: 0;

        & .listTitle {
          display: block;
          margin-bottom: calc(100vw * (5 / 370));

          & p {
            margin-bottom: calc(100vw * (5 / 370));
            font-size: calc(100vw * (16 / 370));
          }
        }

        & .text {
          margin-bottom: calc(100vw * (21 / 370));
        }

        & .reactionContainer {
          & .like,
          .comment {
            & .iconWrap {
              width: calc(100vw * (18 / 370));
              height: calc(100vw * (18 / 370));
              margin-right: calc(100vw * (3 / 370));
            }
          }

          & .like {
            margin-right: calc(100vw * (15 / 370));
          }
        }
      }
    }

    & .imgWrap {
      width: 100%;
      height: calc(100vw * (200 / 370));
      margin: 0 0 calc(100vw * (10 / 370));
    }
  }
`;

export { Container };

export default { Container };
