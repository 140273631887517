import Styles from "./styles";
import { Link } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Calendar } from "./components";
import EducationInfoList from "../EducationInfoList";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right01.svg";

function CalendarContainer({ main, calendarInfo }) {
  return (
    <Styles.Container>
      <div className="calendarWrap">
        <div className="titleContainer">
          <p className="calendarTitle">이번 달 주요 교육 정보</p>
        </div>
        <Calendar calendar={calendarInfo} />
      </div>

      <div className="educationInfoContainer">
        {main && (
          <Link to={"/education-info"} className="moreButton">
            더보기
            <span className="iconWrap">
              <img src={IconArrowRight} alt="IconArrowRight" />
            </span>
          </Link>
        )}
        <div className="titleContainer">
          <h2 className="infoTitle">{calendarInfo?.filter?.date}</h2>

          <div className="today">
            TODAY{" "}
            <span className="todayNumber">
              {calendarInfo.scheduleList?.list?.length}
            </span>
          </div>
        </div>

        <OverlayScrollbarsComponent
          className={"listContainer"}
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <ul className="listWrap">
            {calendarInfo.scheduleList?.list?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <EducationInfoList data={item} />
                </li>
              );
            })}
          </ul>
        </OverlayScrollbarsComponent>
      </div>
    </Styles.Container>
  );
}

export default CalendarContainer;
