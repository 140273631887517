import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 30px;
  padding: 0 20px;
  border-radius: 500px;
  background-color: var(--gray-color-04);
  cursor: pointer;

  &:not(.del):hover {
    color: #fff;
    background-color: var(--black-color-01);
  }

  & .deleteButton {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (30 / 1600));
    padding: 0 calc(100vw * (20 / 1600));
    border-radius: calc(100vw * (500 / 1600));

    & .deleteButton {
      width: calc(100vw * (14 / 1600));
      height: calc(100vw * (14 / 1600));
      margin-left: calc(100vw * (10 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (27 / 370));
    padding: 0 calc(100vw * (20 / 370));
    font-size: calc(100vw * (12 / 370));
    border-radius: calc(100vw * (500 / 370));

    & .deleteButton {
      width: calc(100vw * (14 / 370));
      height: calc(100vw * (14 / 370));
      margin-left: calc(100vw * (10 / 370));
    }
  }
`;

export { Container };

export default { Container };
