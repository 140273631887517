import Styles from "./styles";
import utils from "utils";

//img
import IconLike from "resources/image/icon/icon_like.svg";
import IconComment from "resources/image/icon/icon_comment.svg";

function EducationList({ data, big, min }) {
  return (
    <Styles.Container className={big ? "big" : min ? "min" : ""}>
      <p className="listTitle">
        {data?.category_title && `[${data?.category_title}] `}
        {data.title}
      </p>

      <p className="listText">{data.description_text}</p>

      <div className="listInfo">
        <div className="nickname">{data.nickname}</div>

        <div className="time">
          {utils.date.getHourMinStrByMin(data.created_at)}
        </div>

        <div className="reactionContainer">
          <div className="like">
            <div className="iconWrap">
              <img src={IconLike} alt="IconLike" />
            </div>
            {data.like_count}
          </div>

          <div className="comment">
            <div className="iconWrap">
              <img src={IconComment} alt="IconComment" />
            </div>
            {data.reply_count}
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}

export default EducationList;
