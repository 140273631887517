import utils from "utils";
import Styles from "./styles";
import { useNotice } from "hooks"
import { useNavigate } from "react-router-dom";
import { common, layout, ui, modal } from "components";

// img
import ImgCustomerSupport from "resources/image/img_customer_support.png";
import ImgCustomerSupportMb from "resources/image/img_customer_support_mb.png";
import IconShare from "resources/image/icon/icon_share.svg";

function NoticeDetailPage() {
  const noticeDetailInfo = useNotice({ options: { enableDetail: true } });
  const navigate = useNavigate();

  const data = {
    img: ImgCustomerSupport,
    mbImg: ImgCustomerSupportMb,
    prevText: "고객지원",
    nowText: "공지사항",
    title: "고객지원",
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <section className="contentsSection">
          <div className="inner">
            <div className="middleInner">
              <div className="titleContainer">
                {noticeDetailInfo?.detail?.hold_yn === "Y" && (
                  <div className="important">중요</div>
                )}

                <p className="title">{noticeDetailInfo?.detail?.title}</p>

                <div className="bottomContainer">
                  <p className="date">{utils.date?.getDateType(noticeDetailInfo?.detail?.created_at,"-")}</p>

                  <div className="shareButton">
                    <ui.button.BasicButton
                      buttonText={"공유"}
                      buttonType={"grayBlackFont"}
                      icon={IconShare}
                      size={"smaller"}
                      onClick={() => {
                        noticeDetailInfo?.setIsCopyModal(true);
                      }}
                    />
                    {noticeDetailInfo?.isCopyModal && (
                      <modal.CopyModal
                        modalClose={() => {
                          noticeDetailInfo?.setIsCopyModal(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: noticeDetailInfo?.detail?.description }}
              ></div>

              <div className="listButton">
                <ui.button.BasicButton
                  buttonText={"목록으로"}
                  buttonType={"blackOutline"}
                  size={"small"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default NoticeDetailPage;
