import { api } from "service";
import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

export default function useFaq() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  const faqQuery = useQuery(["support_FAQ",page],() => {
    window.history.pushState("", "faq_page_url", "/customer-support/faq?page=" + page)
    return api.support.getFAQList(page);
  }, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (searchParams.get("page") === null) { setPage(1) }
    else { setPage(searchParams?.get("page")) }
  }, [searchParams]);

  return {
    faqQuery,
    page,

    setPage
  }
}