import connector from "service/connector"


// Notice
const getNoticeList = async (path) => {
  return await connector.connectFetchController("notice?page=" + path + "&recordSize=15", "GET");
}
const getNoticeDetail = async (path) => {
  return await connector.connectFetchController("notice/detail?id=" + path, "GET");
}


// FAQ
const getFAQList = async (path) => {
  return await connector.connectFetchController("faq?page=" + path + "&recordSize=15", "GET");
}


// Inquiry
const getAsk = async (path) => {
  return await connector.authConnector("inquiry?page=" + path + "&recordSize=10", "GET");
};
const postAsk = async (data) => {
  return await connector.authConnector("inquiry", "POST", data);
};
const getAskDetail = async (path) => {
  return await connector.authConnector("inquiry/detail?id=" + path, "GET");
};


export {
  getNoticeList,
  getNoticeDetail,

  getFAQList,
  
  getAsk,
  postAsk,
  getAskDetail
};

export default {
  getNoticeList,
  getNoticeDetail,

  getFAQList,
  
  getAsk,
  postAsk,
  getAskDetail
};