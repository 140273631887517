import Styles from "./styles";
import { common, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgChangePassword from "resources/image/img_change_password.png";

function ChangePasswordCompletePage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    location.state &&
    <>
      <common.Header />
      <Styles.Container>
        <div className="wrap">
          <div className="narrowInner">
            <h2 className="title">비밀번호 변경</h2>
            <p className="text">
              비밀번호 변경이 완료되었습니다. <br />
              소중한 개인정보 보호를 위해 비밀번호를 주기적으로 변경해주세요.
            </p>

            <div className="textContainer">
              비밀번호 변경 후 기존 계정은 로그아웃 됩니다. <br />
              새로운 비밀번호로 다시 로그인해주세요.
            </div>

            <div className="imgWrap">
              <img src={ImgChangePassword} alt="ImgChangePassword" />
            </div>

            <div className="loginButton">
              <ui.button.BasicButton
                buttonText={"로그인"}
                buttonType={"basic"}
                onClick={() => {
                  navigate("/login");
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ChangePasswordCompletePage;
