import BasicCheckbox from "./BasicCheckbox";
import BasicInput from "./BasicInput";
import LoginInput from "./LoginInput";
import CommentInput from "./CommentInput";
import BasicTextarea from "./BasicTextarea";
import BasicSelect from "./BasicSelect";
import SecondSelect from "./SecondSelect";
import GenderRadio from "./GenderRadio";
import BasicRadio from "./BasicRadio";

export {
  BasicCheckbox,
  BasicInput,
  LoginInput,
  CommentInput,
  BasicTextarea,
  BasicSelect,
  SecondSelect,
  GenderRadio,
  BasicRadio,
};

export default {
  BasicCheckbox,
  BasicInput,
  LoginInput,
  CommentInput,
  BasicTextarea,
  BasicSelect,
  SecondSelect,
  GenderRadio,
  BasicRadio,
};
