import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  & .inputContainer {
    display: flex;
    align-items: center;

    & label {
      flex-shrink: 0;
      width: 133px;
      font-size: 16px;
      font-weight: 500;
    }

    & textarea {
      flex-grow: 1;
      height: 148px;
      padding: 14px 20px;
      background-color: #f9fafc;
      border-radius: 2px;

      &::placeholder {
        color: #ced4df;
      }
    }
  }

  @media (max-width: 1600px) {
    & .inputContainer {
      & label {
        width: calc(100vw * (133 / 1600));
        font-size: calc(100vw * (16 / 1600));
      }

      & textarea {
        height: calc(100vw * (148 / 1600));
        padding: calc(100vw * (14 / 1600)) calc(100vw * (20 / 1600));
        border-radius: calc(100vw * (2 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    & .inputContainer {
      flex-direction: column;
      align-items: flex-start;

      & label {
        width: auto;
        margin-bottom: calc(100vw * (10 / 370));
        font-size: calc(100vw * (16 / 370));
      }

      & textarea {
        width: 100%;
        height: calc(100vw * (200 / 370));
        padding: calc(100vw * (16 / 370)) calc(100vw * (15 / 370));
        border-radius: calc(100vw * (2 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
