import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .narrowInner {
    padding-top: 80px;

    & .formContainer {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #eff0f2;

      & .title {
        position: relative;
        margin-bottom: 30px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--main-color);
          content: "";
        }
      }

      & .listWrap {
        margin-bottom: 80px;

        & .list {
          &:not(:last-child) {
            margin-bottom: 15px;
          }

          & .selectContainer {
            display: flex;
            align-items: center;

            & .inputTitle {
              flex-shrink: 0;
              width: 133px;
              font-size: 16px;
              font-weight: 500;

              & span {
                position: relative;

                &::after {
                  position: absolute;
                  top: 50%;
                  right: -9px;
                  transform: translateY(-50%);
                  width: 5px;
                  height: 5px;
                  background: url(${IconEssential}) no-repeat center / cover;
                  content: "";
                }
              }
            }

            & > div {
              width: 250px;
            }
          }

          & .guideText {
            margin: 5px 0 0 133px;
            color: var(--main-color);
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .narrowInner {
      padding-top: calc(100vw * (80 / 1600));

      & .formContainer {
        margin-top: calc(100vw * (30 / 1600));
        padding-top: calc(100vw * (30 / 1600));

        & .title {
          margin-bottom: calc(100vw * (30 / 1600));
          padding-left: calc(100vw * (20 / 1600));
          font-size: calc(100vw * (20 / 1600));

          &::before {
            width: calc(100vw * (10 / 1600));
            height: calc(100vw * (10 / 1600));
          }
        }

        & .listWrap {
          margin-bottom: calc(100vw * (80 / 1600));

          & .list {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (15 / 1600));
            }

            & .selectContainer {
              & .inputTitle {
                width: calc(100vw * (133 / 1600));
                font-size: calc(100vw * (16 / 1600));

                & span {
                  &::after {
                    right: calc(100vw * (-9 / 1600));
                    width: calc(100vw * (5 / 1600));
                    height: calc(100vw * (5 / 1600));
                  }
                }
              }

              & > div {
                width: calc(100vw * (250 / 1600));
              }
            }

            & .guideText {
              margin: calc(100vw * (5 / 1600)) 0 0 calc(100vw * (133 / 1600));
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 370));

      & .formContainer {
        margin-top: calc(100vw * (30 / 370));
        padding-top: calc(100vw * (30 / 370));

        & .title {
          margin-bottom: calc(100vw * (30 / 370));
          padding-left: calc(100vw * (20 / 370));
          font-size: calc(100vw * (18 / 370));

          &::before {
            width: calc(100vw * (8 / 370));
            height: calc(100vw * (8 / 370));
          }
        }

        & .listWrap {
          margin-bottom: calc(100vw * (80 / 370));

          & .list {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 370));
            }

            &.nickname .inputContainer {
              flex-direction: row;
              flex-wrap: wrap;

              & label {
                width: 100%;
              }

              & input {
                width: calc(100vw * (200 / 370));
              }
            }

            & .selectContainer {
              flex-direction: column;
              align-items: flex-start;

              & .inputTitle {
                width: 100%;
                margin-bottom: calc(100vw * (10 / 370));
                font-size: calc(100vw * (16 / 370));

                & span {
                  &::after {
                    right: calc(100vw * (-8 / 370));
                    width: calc(100vw * (5 / 370));
                    height: calc(100vw * (5 / 370));
                  }
                }
              }

              & > div {
                width: calc(100vw * (250 / 370));
              }
            }

            & .guideText {
              margin: calc(100vw * (5 / 370)) 0 0;
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
