import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 394px;
    padding: 50px 22px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;

    & .iconWrap {
      width: 46px;
      height: 46px;
      margin-bottom: 30px;
    }

    & .title {
      margin-bottom: 6px;
      font-size: 22px;
      font-weight: 700;
    }

    & .text {
      margin-bottom: 20px;
    }

    & .listWrap {
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid var(--gray-color-04);
      border-radius: 5px;

      & .list {
        padding: 10px 20px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--gray-color-04);
        }

        & .textareaContainer {
          margin-top: 6px;

          & textarea {
            height: 181px;
            padding: 10px;
          }
        }
      }
    }

    & .buttonListWrap {
      width: 274px;
      margin-top: 50px;

      & .buttonList:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 1600px) {
    & .modalContainer {
      width: calc(100vw * (394 / 1600));
      padding: calc(100vw * (50 / 1600)) calc(100vw * (22 / 1600));
      border-radius: calc(100vw * (10 / 1600));

      & .iconWrap {
        width: calc(100vw * (46 / 1600));
        height: calc(100vw * (46 / 1600));
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .title {
        margin-bottom: calc(100vw * (6 / 1600));
        font-size: calc(100vw * (22 / 1600));
      }

      & .text {
        margin-bottom: calc(100vw * (20 / 1600));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (20 / 1600));
        border-radius: calc(100vw * (5 / 1600));

        & .list {
          padding: calc(100vw * (10 / 1600)) calc(100vw * (20 / 1600));

          & .textareaContainer {
            margin-top: calc(100vw * (6 / 1600));

            & textarea {
              height: calc(100vw * (181 / 1600));
              padding: calc(100vw * (10 / 1600));
            }
          }
        }
      }

      & .buttonListWrap {
        width: calc(100vw * (274 / 1600));
        margin-top: calc(100vw * (50 / 1600));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (5 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .modalContainer {
      width: calc(100vw * (320 / 370));
      max-height: 85vh;
      padding: calc(100vw * (30 / 370)) calc(100vw * (20 / 370));
      border-radius: calc(100vw * (10 / 370));
      justify-content: flex-start;
      overflow: auto;

      & .iconWrap {
        width: calc(100vw * (46 / 370));
        height: calc(100vw * (46 / 370));
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .title {
        margin-bottom: calc(100vw * (6 / 370));
        font-size: calc(100vw * (18 / 370));
      }

      & .text {
        margin-bottom: calc(100vw * (30 / 370));
        font-size: calc(100vw * (14 / 370));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (20 / 370));
        border-radius: calc(100vw * (5 / 370));

        & .list {
          padding: calc(100vw * (10 / 370)) calc(100vw * (20 / 370));

          & .textareaContainer {
            margin-top: calc(100vw * (10 / 370));

            & textarea {
              height: calc(100vw * (181 / 370));
              padding: calc(100vw * (10 / 370));
            }
          }
        }
      }

      & .buttonListWrap {
        width: calc(100vw * (230 / 370));
        margin-top: calc(100vw * (50 / 370));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (5 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
