import Styles from "./styles";
import utils from "utils";
import { BlogList } from "./components";
import { useMediaQuery } from "react-responsive";
import { common, layout, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth, useBoard, useCategory } from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// img
import ImgBlog from "resources/image/img_blog.png";
import ImgBlogMb from "resources/image/img_blog_mb.png";
import IconArrowLeft from "resources/image/icon/icon_arrow_left.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right01.svg";

function BlogPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState();

  const authInfo = useAuth();
  const categoryInfo = useCategory();
  const boardInfo = useBoard({
    blogOption: filter,
    blogBest: true,
  });

  const blogList = boardInfo.getBlogList?.data || [];
  const blogBest = boardInfo.getBlogBest?.data || [];

  useEffect(() => {
    let param = utils.etc.getSearchParamNew();
    let f = {
      page: param.page ? param.page : 1,
      category_info_id: param.category ? param.category : 1,
      recordSize: 7,
      type: 2,
    };
    setFilter({ ...f });
  }, [location]);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const data = {
    img: ImgBlog,
    mbImg: ImgBlogMb,
    nowText: "블로그",
    title: "블로그",
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <section className="hotTopicSection">
          <div className="inner">
            <h2 className="sectionTitle">HOT TOPIC</h2>
            <div className="sliderContainer">
              <Swiper
                loop={true}
                slidesPerView={"auto"}
                spaceBetween={20}
                speed={1500}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                centeredSlides={isMobile && true}
                modules={[Autoplay, Navigation]}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                breakpoints={{
                  768: {
                    spaceBetween: 25,
                    slidesPerView: 4,
                  },
                }}
                className="blogSlider"
              >
                {blogBest.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={"blogSlider" + index}
                      onClick={() => {
                        if (authInfo.certification(false)) {
                          navigate("/blog/detail?id=" + item.id);
                        }
                      }}
                    >
                      <layout.BlogCardList data={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <button
                type="button"
                className="prevButton"
                ref={navigationPrevRef}
              >
                <img src={IconArrowLeft} alt="IconArrowLeft" />
              </button>

              <button
                type="button"
                className="nextButton"
                ref={navigationNextRef}
              >
                <img src={IconArrowRight} alt="IconArrowRight" />
              </button>
            </div>
          </div>
        </section>

        <section className="blogListSection">
          <div className="middleInner">
            <div className="tabContainer">
              <layout.Tab
                tabIndex={filter?.category_info_id}
                setTabIndex={(v) =>
                  navigate(
                    utils.etc.getQueryParamUrl({ page: 1, category: v }, false)
                  )
                }
                tabList={categoryInfo.getCategory?.data}
              />
            </div>

            <ul className="listWrap">
              {blogList.list?.map((item, index) => {
                return (
                  <li
                    className="list"
                    key={"list" + index}
                    onClick={() => {
                      if (authInfo.certification(false)) {
                        navigate("/blog/detail?id=" + item.id, {
                          state: location.pathname + location.search,
                        });
                      }
                    }}
                  >
                    <BlogList data={item} />
                  </li>
                );
              })}
            </ul>

            <div className="paginationContainer">
              <ui.button.PaginationNew
                page={filter?.page}
                maxPage={blogList.pagination?.endPage}
                list={blogList.pagination?.page_list}
              />
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default BlogPage;
