import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { ui } from "components";
import { useEffect, useState } from "react";
import { useAuth, useInquiry } from "hooks";

// img
import IconQuestionOff from "resources/image/icon/icon_question_off02.svg";
import IconQuestionOn from "resources/image/icon/icon_question_on02.svg";
import IconDownload from "resources/image/icon/icon_download.svg";
import IconDelete from "resources/image/icon/icon_delete.svg";

function Ask() {
  const authInfo = useAuth();
  const askInfo = useInquiry({});
  const navigate = useNavigate();
  let blockClicks = false

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [textarea, setTextarea] = useState("");
  const [fileList, setFileList] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);

  const removeInquiryData = () => {
    setTitle("")
    setTextarea("")
    setFileList([])
  }

  const validate = () => {
    const phoneRegex = /^\d{2,3}\d{3,4}\d{4}$/;

    if (phoneRegex.test(phone.replace(/-/g, "")) === false) return false;
    if (textarea.trim() === "") return false;
    if (title.trim() === "") return false;
    if (name.trim() === "") return false;
    if (blockClicks) return false;
    return true;
  }

  const doAsk = () => {
    if (validate()) {
      blockClicks = true;
      setErrorText("");

      const postData = new FormData();
      postData.append("name", name);
      postData.append("contact", phone.replace(/-/g, ""));
      postData.append("title", title);
      postData.append("content", textarea);
      fileList.length && fileList.map((item) => { postData.append("fileList", item) })
              
      askInfo.onCreateAsk(postData, blockClicks, removeInquiryData);
    } else if (blockClicks) {
      alert("등록중입니다. 잠시만 기다려 주세요.");
    } else {
      setErrorText("입력하지 않은 정보가 있습니다.");
    }
  }

  useEffect(()=>{
    authInfo.isLoad === false && authInfo.certification(false)
    setName(authInfo.userData?.name);
    setPhone(authInfo.userData?.phone);
  },[authInfo.userData])

  return (
    authInfo.isLogin &&
    <Styles.Container>
      <div className="wrap">
        <div className="faqContainer">
          <p className="text">
            문의 전 <span>FAQ</span>를 확인하시면 빠르고 <br />
            정확한 답을 얻으실 수 있습니다.
          </p>

          <div className="faqButton">
            <ui.button.BasicButton
              buttonText={"FAQ 바로가기"}
              buttonType={"blackOutline"}
              size={"small"}
              onClick={() => {
                navigate("/customer-support/faq");
              }}
            />
          </div>
        </div>

        <div className="formContainer">
          <form>
            <ul className="listWrap">
              <li className="list">
                <ui.input.BasicInput
                  labelText={"이름"}
                  placeholder={"이름을 입력하세요."}
                  value={name}
                  setValue={setName}
                />
              </li>

              <li className="list">
                <ui.input.BasicInput
                  labelText={"연락처"}
                  placeholder={"연락처를 입력하세요."}
                  value={phone}
                  setValue={setPhone}
                />
              </li>

              <li className="list">
                <ui.input.BasicInput
                  labelText={"제목"}
                  placeholder={"제목을 입력하세요."}
                  value={title}
                  setValue={setTitle}
                />
              </li>

              <li className="list textarea">
                <ui.input.BasicTextarea
                  labelText={"내용"}
                  placeholder={"문의 내용을 입력하세요."}
                  value={textarea}
                  setValue={setTextarea}
                />

                <p className="errorText">{errorText}</p>
              </li>

              <li className="list file">
                <div className="inputContainer">
                  <div className="inputTitle">
                    첨부파일{" "}
                    <div
                      className="iconWrap"
                      onClick={() => {
                        setIsQuestionOpen(!isQuestionOpen);
                      }}
                    >
                      <img
                        src={isQuestionOpen ? IconQuestionOn : IconQuestionOff}
                        alt="IconQuestion"
                      />

                      {isQuestionOpen && (
                        <div className="questionModal">
                          첨부파일은 최대 3개까지 가능합니다.{" "}
                        </div>
                      )}
                    </div>
                  </div>

                  <input
                    type="file"
                    id="file"
                    multiple
                    onChange={(e) => {
                      let fileData = []
                      for (let i = 0; i < e.target.files.length && i < 3 - fileList.length; i++) {
                        fileData[i] = e.target.files[i]
                      }
                      setFileList(e => { return [...e, ...fileData] });
                    }}
                  />

                  <label htmlFor="file" className="fileUploadButton">
                    <span className="iconWrap">
                      <img src={IconDownload} alt="IconDownload" />
                    </span>
                    파일 첨부하기
                  </label>
                </div>

                <ul className="fileListWrap">
                  {fileList?.map((item, index) => {
                    return (
                      <li className="fileList" key={"fileList" + index}>
                        <p>{item.name}</p>
                        <button
                          type="button"
                          className="deleteButton"
                          onClick={()=>{
                            setFileList(fileList.filter((e, idx) => idx !== index))
                          }}>
                          <img src={IconDelete} alt="IconDelete" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </form>
        </div>
      </div>

      <div className="askButton">
        <ui.button.BasicButton
          buttonText={"문의하기"}
          buttonType={"blackOutline"}
          size={"small"}
          onClick={doAsk}
        />
      </div>
    </Styles.Container>
  );
}

export default Ask;
