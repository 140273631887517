import Styles from "./styles";
import { IMAGEPATH } from "service/connector";

// img
import IconUser from "resources/image/icon/icon_user.svg";

function UserProfileImg({ profileImg, imageUrl }) {
  return (
    <Styles.Container className="profileImgWrap">
      <img
        src={
          imageUrl
            ? imageUrl
            : profileImg
              ? IMAGEPATH + profileImg
              : IconUser
        }
        alt="profileImg"
      />
    </Styles.Container>
  );
}

export default UserProfileImg;
