import Styles from "./styles";
import { useFooter } from "hooks";
import { common, layout } from "components";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

// img
import ImgTerms from "resources/image/img_terms.png";
import ImgTermsMb from "resources/image/img_terms_mb.png";

function TermsPage() {
  const footerInfo = useFooter({ options: { enableTerms: true }});
  const { id } = useParams();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const renderPageName = (type) => {
    switch (type) {
      case 0: return "서비스 이용약관";
      case 1: return "개인정보 처리방침";
      case 2: return "탈퇴 약관";
    }
  };
  const data = {
    img: ImgTerms,
    mbImg: ImgTermsMb,
    prevText: "이용약관",
    nowText: renderPageName(tabIndex),
    title: "이용약관",
  };
  const tabList = [
    {
      url: "/terms/service",
      title: "서비스 이용약관",
      id: 0
    },
    {
      url: "/terms/privacy",
      title: "개인정보 처리방침",
      id: 1
    },
    {
      url: "/terms/delete",
      title: "탈퇴 약관",
      id: 2
    },
  ];

  // 새로고침 해도 tab active 유지
  useEffect(() => {
    setTabIndex(tabList.findIndex((e) => e.url === location.pathname));
    id === "service" && footerInfo.setType(0)
    id === "privacy" && footerInfo.setType(1)
    id === "delete" && footerInfo.setType(2)
  }, [tabIndex]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <section className="contentsSection">
          <div className="inner">
            <div className="tabContainer">
              <layout.Tab
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={tabList}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: footerInfo.termsInfo?.content }}></div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default TermsPage;
