import Styles from "./styles";
import ui from "components/ui";
import CommentList from "../CommentList";

function Comment({ replyInfo, likeInfo }) {

  const onFocus = (id) => {
    replyInfo.setReplyInfoId(id)
    replyInfo.replyFocus.current.focus();
  }

  const postReply = () => {
    if ( replyInfo.content.trim() !== "" ) {
      replyInfo.postReply(1);
    }
  }

  const doLike = (id, index, subIndex) => {
    likeInfo.toggleLike(id, 2, index, subIndex);
  }

  return (
    <Styles.Container>
      <ui.input.CommentInput
        replyInfo={replyInfo}
        onContentSubmit={postReply}
      />
      <div className="commentListContainer">
        {replyInfo?.getReply?.data?.list?.length > 0 ? (
          <ul className="commentListWrap">
            {replyInfo.getReply.data.list.map((item,index) => {
              return (
                <div className="commentList" key={"commentList" + item.id}>
                  <CommentList
                    data={item}
                    onFocus={onFocus}
                    doLike={() => { doLike(item.id, index, -1); }}
                  />
                  {item.reply_list.map((replyItem, replyIndex) => {
                    return (
                      <li className="replyList" key={"replyList" + replyItem.id}>
                        <CommentList
                          data={replyItem}
                          reply={true}
                          doLike={() => { doLike(replyItem.id, index, replyIndex); }}
                        />
                      </li>
                    );
                  })}
                </div>
              );
            })}
          </ul>
        ) : (
          <div className="emptyContainer">아직 댓글이 없습니다.</div>
        )}
      </div>
    </Styles.Container>
  );
}

export default Comment;
