import Styles from "./styles";
import { useBoard } from "hooks";
import { useEffect } from "react";
import { common, layout, ui } from "components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function AcademyInfoPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const boardInfo = useBoard({
    options: {
      academyList: true,
      academyDetail: true,

      recordSize: 12,
    },
  });

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    prevText: "교육 정보",
    nowText: "학원 정보",
    title: "주요 교육 정보",
  };

  useEffect(()=>{
    boardInfo.setAcademyFilter((e) => { return { ...e, type: "유치원,초등학교,중학교,고등학교" } })
  },[]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={boardInfo.academyDetail}>
                <h2 className="title">
                  {boardInfo.academyDetail?.category_title &&
                    `[${boardInfo?.academyDetail?.category_title}] `}
                  {boardInfo.academyDetail.title}
                </h2>
                <div className="detailInfo">
                  {boardInfo.academyDetail.address}
                </div>
              </layout.DetailContainer>
            </section>

            <section className="listSection">
              <p className="sectionTitle">다른 학원 정보</p>

              <Swiper className="mySwiper listWrap" slidesPerView={"auto"}>
                {boardInfo.academyList?.list?.map((item, index) => {
                  return (
                    <SwiperSlide className="list" key={"list" + index}>
                      <layout.AcademyList data={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>

            <div className="goListButton">
              <ui.button.BasicButton
                buttonText={"목록으로"}
                buttonType={"blackOutline"}
                size={"small"}
                onClick={() => {
                  navigate(location.state || "/education-info");
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default AcademyInfoPage;
