import Styles from "./styles";
import utils from "utils";

function AskDetailList({ data, listCount }) {

  return (
    <Styles.Container>
      <div className="number">{listCount}</div>

      <p className={"state" + (data.state === "0" ? "" : " on")}>
        {data.state === "0" ? "답변대기" : "답변완료"}
      </p>

      <p className="title">{data.title}</p>

      <p className="date">{utils.date.getDateType(data.created_at)}</p>
    </Styles.Container>
  );
}

export default AskDetailList;
