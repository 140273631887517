import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 87px;
  padding: 0 30px;
  border-bottom: 1px solid var(--gray-color-04);
  cursor: pointer;

  &.important {
    background-color: #f9fafc;
  }

  & .importantText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 28px;
    font-weight: 500;
    color: #fff;
    background-color: var(--main-color);
    border-radius: 5px;
  }

  & .number,
  .title {
    font-size: 16px;
    font-weight: 500;
  }

  & .number {
    margin-right: 17px;
  }

  & .title {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .date {
    font-weight: 500;
    color: #cfd2d7;
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (30 / 1600));
    height: calc(100vw * (87 / 1600));
    padding: 0 calc(100vw * (30 / 1600));

    & .importantText {
      width: calc(100vw * (50 / 1600));
      height: calc(100vw * (28 / 1600));
      border-radius: calc(100vw * (5 / 1600));
    }

    & .number,
    .title {
      font-size: calc(100vw * (16 / 1600));
    }

    & .number {
      margin-right: calc(100vw * (17 / 1600));
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
    height: calc(100vw * (94 / 370));
    padding: 0 calc(100vw * (10 / 370));

    & .importantText {
      width: calc(100vw * (42 / 370));
      height: calc(100vw * (22 / 370));
      font-size: calc(100vw * (12 / 370));
      border-radius: calc(100vw * (2 / 370));
      line-height: 1;
    }

    & .number,
    .title {
      font-size: calc(100vw * (14 / 370));
    }

    & .title {
      flex-grow: initial;
      width: 100%;
      margin: calc(100vw * (5 / 370)) 0 calc(100vw * (8 / 370));
    }

    & .number {
      margin-right: 0;
    }

    & .date {
      font-size: calc(100vw * (12 / 370));
    }
  }
`;

export { Container };

export default { Container };
