import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .contentsSection {
    margin-top: -80px;

    & .inner {
      padding-top: 80px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #fff;

      & .titleContainer {
        position: relative;
        margin-bottom: 60px;
        padding-bottom: 64px;
        border-bottom: 2px solid var(--black-color-01);

        & .important {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 61px;
          height: 34px;
          margin: 0 auto 10px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          background-color: var(--main-color);
          border-radius: 2px;
        }

        & .title {
          max-width: 750px;
          margin: 0 auto;
          font-size: 36px;
          font-weight: bold;
          text-align: center;
        }

        & .bottomContainer {
          position: absolute;
          bottom: 20px;
          left: 0;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;

          & .date {
            font-size: 18px;
            font-weight: 500;
            color: #cfd2d7;
          }

          & .shareButton {
            position: relative;
            width: 80px;
          }
        }
      }

      & .listButton {
        width: 250px;
        margin: 50px auto 0;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .contentsSection {
      margin-top: calc(100vw * (-80 / 1600));

      & .inner {
        padding-top: calc(100vw * (80 / 1600));
        border-top-left-radius: calc(100vw * (10 / 1600));
        border-top-right-radius: calc(100vw * (10 / 1600));

        & .titleContainer {
          margin-bottom: calc(100vw * (60 / 1600));
          padding-bottom: calc(100vw * (64 / 1600));
          border-bottom: calc(100vw * (2 / 1600)) solid var(--black-color-01);

          & .important {
            width: calc(100vw * (61 / 1600));
            height: calc(100vw * (34 / 1600));
            margin: 0 auto calc(100vw * (10 / 1600));
            font-size: calc(100vw * (18 / 1600));
            border-radius: calc(100vw * (2 / 1600));
          }

          & .title {
            max-width: calc(100vw * (750 / 1600));
            font-size: calc(100vw * (36 / 1600));
          }

          & .bottomContainer {
            bottom: calc(100vw * (20 / 1600));

            & .date {
              font-size: calc(100vw * (18 / 1600));
            }

            & .shareButton {
              width: calc(100vw * (80 / 1600));
            }
          }
        }

        & .listButton {
          width: calc(100vw * (250 / 1600));
          margin: calc(100vw * (50 / 1600)) auto 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .contentsSection {
      margin-top: calc(100vw * (-30 / 370));

      & .inner {
        padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
        border-top-left-radius: calc(100vw * (5 / 370));
        border-top-right-radius: calc(100vw * (5 / 370));

        & .middleInner {
          width: 100%;
        }

        & .titleContainer {
          margin-bottom: calc(100vw * (20 / 370));
          padding-bottom: calc(100vw * (10 / 370));
          border-bottom: calc(100vw * (2 / 370)) solid var(--black-color-01);

          & .important {
            width: calc(100vw * (42 / 370));
            height: calc(100vw * (22 / 370));
            margin: 0 auto calc(100vw * (5 / 370));
            font-size: calc(100vw * (12 / 370));
            border-radius: calc(100vw * (5 / 370));
          }

          & .title {
            max-width: 100%;
            font-size: calc(100vw * (16 / 370));
          }

          & .bottomContainer {
            position: static;
            margin-top: calc(100vw * (20 / 370));

            & .date {
              font-size: calc(100vw * (12 / 370));
            }

            & .shareButton {
              width: calc(100vw * (67 / 370));

              & > button {
                line-height: 1;
              }
            }
          }
        }

        & .listButton {
          width: calc(100vw * (155 / 370));
          margin: calc(100vw * (50 / 370)) auto 0;
        }
      }
    }
  }
`;

export { Container };

export default { Container };
