import styled from "styled-components";

const Container = styled.div`
  & .title {
    margin-bottom: 4px;
    font-size: 26px;
    font-weight: bold;
  }

  & .signupTextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .text {
      font-size: 16px;
      font-weight: 500;
    }

    & .stepListWrap {
      position: relative;
      display: flex;
      gap: 15px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: #eff0f2;
        content: "";
        z-index: -1;
      }

      & .stepList {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        font-weight: bold;
        line-height: 1.2;
        border-radius: 50%;
        background-color: #eff0f2;

        &.on {
          color: #fff;
          background-color: var(--main-color);
        }
      }
    }
  }

  @media (max-width: 1600px) {
    & .title {
      margin-bottom: calc(100vw * (4 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .signupTextContainer {
      & .text {
        font-size: calc(100vw * (16 / 1600));
      }

      & .stepListWrap {
        gap: calc(100vw * (15 / 1600));

        & .stepList {
          width: calc(100vw * (22 / 1600));
          height: calc(100vw * (22 / 1600));
          line-height: 1.4;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .title {
      margin-bottom: calc(100vw * (6 / 370));
      font-size: calc(100vw * (22 / 370));
    }

    & .signupTextContainer {
      position: relative;

      & .text {
        font-size: calc(100vw * (14 / 370));
      }

      & .stepListWrap {
        position: absolute;
        top: calc(100vw * (-33 / 370));
        right: 0;
        gap: calc(100vw * (15 / 370));

        & .stepList {
          width: calc(100vw * (22 / 370));
          height: calc(100vw * (22 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
