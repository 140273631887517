import { api } from "service";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth, useModals } from "hooks"
import { useEffect, useState } from "react";

//img
import IconCheck from "resources/image/icon/icon_check.svg";

export default function useChildren() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const authInfo = useAuth();
  
  const getChildInfo = useQuery(["get_child_info"], () => {
    return api.children.getChildInfo()
  });

  const initValue = {
    gender: "",
    current_school: "",
    target_school: "",
    current_academy: "",
    target_academy: ""
  }
  const [errorText, setErrorText] = useState("");
  const [childrenList, setChildrenList] = useState(null);

  useEffect(()=>{
    setChildrenList(
      getChildInfo.data?.length === 0
        ? [initValue]
        : getChildInfo.data
    )
  },[getChildInfo.isSuccess])

  const validate = () => {
    for (let i = 0; i < childrenList.length; i++) {
      if (childrenList[i].gender === ""
        || childrenList[i].current_school === ""
        || childrenList[i].target_academy === ""
        || childrenList[i].target_school === ""
        || childrenList[i].current_academy === ""
      ) {
        return false;
      }
    }
    return true;
  }

  const handlePlusClick = () => {
    setChildrenList([...childrenList, initValue])
  }
  const handleMinusClick = (index) => {
    setChildrenList(childrenList.filter((e, idx) => idx !== index));
  };
  
  const onInfoUpdate = async (setNice) => {
    if (validate()) {
      const result = await api.children.childInfoInsert(JSON.stringify(childrenList));
      result.success && setNice(await authInfo.getNice())
    }else{
      setErrorText('입력하지 않은 정보가 있습니다.')
    }
  }
  const editInfo = async (postData) => {
    const updateModal = () => {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconCheck;
        e.title = "수정이 완료되었습니다.";
        e.text = "수정하신 내용으로 업데이트됩니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;
        
        return { ...e };
      });
      navigate("/mypage/info");
    }
    
    const userResult = await api.auth.userUpdate(postData)
    if (authInfo.userData.pass_yn === "Y") {
      const parentsResult = await api.children.childInfoUpdate(JSON.stringify(childrenList))
      parentsResult.success && updateModal()
    } else {
      userResult.success && updateModal()
    }
  }

  return {
    errorText,
    setErrorText,

    childrenList,
    setChildrenList,

    handlePlusClick,
    handleMinusClick,
    validate,

    getChildInfo,
    onInfoUpdate,
    editInfo,
  }
}