import utils from "utils";
import Styles from "./styles";
import { useBoard } from "hooks";
import { common, layout, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function ShareInfoPage() {
  const boardInfo = useBoard({ options: { enableDetail: true } })
  const location = useLocation();
  const navigate = useNavigate();

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    prevText: "교육 정보",
    nowText: "교육 정보",
    title: "주요 교육 정보",
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={boardInfo.detail}>
                <h2 className="title">
                  {
                    boardInfo.detail?.category_title &&
                    `[${boardInfo.detail?.category_title}] `
                  }
                  {boardInfo.detail?.title}
                </h2>
                <div className="detailInfo">
                  <span>{boardInfo.detail?.nickname}</span>
                  <span>{utils.date.getHourMinStrByMin(boardInfo.detail?.created_at)}</span>
                </div>
              </layout.DetailContainer>
            </section>
          </div>
        </div>

        <div className="anotherListContainer middleInner">
          <layout.AnotherContents data={boardInfo.detail} />
        </div>

        <div className="goListButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"blackOutline"}
            size={"small"}
            onClick={() => {
              navigate(location.state || "/education-info");
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ShareInfoPage;
