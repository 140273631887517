import Styles from "./styles";
import { ui } from "components";
import AskDetailList from "../AskDetailList";
import { Link, useNavigate } from "react-router-dom";
import { useInquiry } from "hooks";

function AskList() {
  const askInfo = useInquiry({ options: { askList: true } })
  const navigate = useNavigate();
  const recordCountAll = askInfo.value?.pagination?.totalRecordCountAll

  return (
    <Styles.Container>
      <div className="askButton">
        <ui.button.BasicButton
          buttonText={"문의하기"}
          buttonType={"black"}
          size={"thin"}
          onClick={() => {
            navigate("/customer-support/ask");
          }}
        />
      </div>

      {askInfo.value?.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {askInfo.value.list.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <Link to={"/mypage/ask-detail?id=" + item.id}>
                    <AskDetailList
                      data={item}
                      listCount={recordCountAll - index - ((askInfo.page - 1) * 10)}
                      my
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
          <ui.button.Pagination
            page={askInfo.page}
            setPage={askInfo.setPage}
            list={askInfo.value.pagination?.page_list}
            maxPage={askInfo.value.pagination?.endPage}
          />
        </>
      ) : (
        <div className="emptyContainer">등록된 문의가 없습니다.</div>
      )}
    </Styles.Container>
  );
}

export default AskList;
