import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & section:not(:last-child) {
    margin-bottom: 80px;
  }

  & .sectionTitle {
    font-size: 36px;
    font-weight: bold;
  }

  & .selectContainer {
    width: 203px;
  }

  & .paginationContainer {
    margin-top: 50px;
  }

  & .calendarSection {
    position: relative;
    margin: -80px auto 0;
    padding: 60px 48px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);
    z-index: 1;

    /* 가이드 추가 **/
    & .guideText {
      font-size: 16px;
      margin-bottom: 20px;
    }


    & .tabContainer {
      margin-bottom: 45px;
    }
  }

  & .academySection {
    & .sectionTitle {
      margin-bottom: 50px;
    }

    & .tabContainer {
      display: flex;
      gap: 40px;
      margin-bottom: 50px;
    }

    & .listWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 31px;

      & .list {
        width: 330px;
      }
    }
  }

  & .educationInfoSection {
    & .sectionTitleContainer {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-bottom: 30px;
    }

    & .list {
      border-bottom: 1px solid var(--gray-color-04);

      & a > div {
        padding: 30px 0;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1600));
    }

    & .sectionTitle {
      font-size: calc(100vw * (36 / 1600));
    }

    & .selectContainer {
      width: calc(100vw * (203 / 1600));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 1600));
    }

    & .calendarSection {
      margin: calc(100vw * (-80 / 1600)) auto 0;
      padding: calc(100vw * (60 / 1600)) calc(100vw * (48 / 1600));
      border-radius: calc(100vw * (20 / 1600));
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .tabContainer {
        margin-bottom: calc(100vw * (45 / 1600));
      }
    }

    & .academySection {
      & .sectionTitle {
        margin-bottom: calc(100vw * (50 / 1600));
      }

      & .tabContainer {
        gap: calc(100vw * (40 / 1600));
        margin-bottom: calc(100vw * (50 / 1600));
      }

      & .listWrap {
        gap: calc(100vw * (50 / 1600)) calc(100vw * (31 / 1600));

        & .list {
          width: calc(100vw * (330 / 1600));
        }
      }
    }

    & .educationInfoSection {
      & .sectionTitleContainer {
        gap: calc(100vw * (40 / 1600));
        margin-bottom: calc(100vw * (30 / 1600));
      }

      & .list {
        & a > div {
          padding: calc(100vw * (30 / 1600)) 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & section:not(:last-child) {
      margin-bottom: calc(100vw * (60 / 370));
    }

    & .sectionTitle {
      font-size: calc(100vw * (20 / 370));
    }

    & .selectContainer {
      width: calc(100vw * (148 / 370));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 370));
    }

    & .calendarSection {
      margin: calc(100vw * (-30 / 370)) auto 0;
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370));
      border-radius: calc(100vw * (5 / 370));
      box-shadow: 0px calc(100vw * (19 / 370)) calc(100vw * (35 / 370)) 0px
        rgba(15, 40, 102, 0.1);

      & .tabContainer {
        margin-bottom: calc(100vw * (37 / 370));
      }
    }

    & .academySection {
      padding: 0 calc(100vw * (15 / 370));

      & .sectionTitle {
        margin-bottom: calc(100vw * (30 / 370));
      }

      & .tabContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (27 / 370));
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .listWrap {
        gap: calc(100vw * (30 / 370)) calc(100vw * (14 / 370));

        & .list {
          width: calc(100vw * (138 / 370));
        }
      }
    }

    & .educationInfoSection {
      & .sectionTitleContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (20 / 370));
        margin-bottom: 0;
      }

      & .list {
        & a > div {
          padding: calc(100vw * (30 / 370)) 0;
        }
      }
    }
  }
`;

export { Container };

export default { Container };
