import Styles from "./styles";
import { ui } from "components";
import { useInquiry } from "hooks";
import { useNavigate } from "react-router-dom";
import utils from "utils";

function AskDetail() {
  const askInfo = useInquiry({ options: { askDetail: true } })
  const navigate = useNavigate();

  const data = askInfo.detail || []

  return (
    <Styles.Container>
      <div className="topContainer">
        <div className={"state" + (data.state === "0" ? "" : " on")}>
          {data.state === "0" ? "답변 대기" : "답변 완료"}
        </div>

        <p className="date">{utils.date.getDateType(data.created_at)}</p>
      </div>

      <p className="title">{data.title}</p>

      <p className="text">{data.content}</p>

      <div className="fileContainer">
        <div className="fileText">첨부 파일</div>

        {data.file_list?.length > 0 ? (
          <ul className="fileListWrap">
            {data.file_list?.map((item, index) => {
              return (
                <li className="fileList" key={"fileList" + index}>
                  {item.origin_name}
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="emptyText">첨부파일이 없습니다.</p>
        )}
      </div>

      <div className="answerContainer">
        {data.answer ? (
          <>
            <p className="answerTitle">담당자 답변</p>

            <p className="answer">{data.answer}</p>
          </>
        ) : (
          <span className="emptyText">-</span>
        )}
      </div>

      <div className="listButton">
        <ui.button.BasicButton
          buttonText={"목록으로"}
          buttonType={"blackOutline"}
          size={"small"}
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </Styles.Container>
  );
}

export default AskDetail;
