import { useEffect, useState } from "react";
import { api } from "service";
import { useQuery } from "react-query";
import utils from "utils";

export default function useCalendar({
  options = {
    schedule: false,
    scheduleList: false,
    recordSize: 0
  }
}) {  
  const [pivotDate, setPivotDate] = useState(0);
  const [value, setValue] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [date, setDate] = useState("");
  const [filter, setFilter] = useState({ page: 1, type: "유치원,초등학교,중학교,고등학교" });
  const [updateData, setUpdateData] = useState();


  useQuery(["calendar_schedule", date + filter.type], () => {
    return api.board.getBoardCalendar(date, filter.type);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.schedule,
    onSuccess: (data) => {
      setUpdateData(data);
    }
  })
  const getCalendarList = useQuery(["calendar_list", filter], () => {
    return api.board.getCalendarList(filter, options.recordSize);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.scheduleList
  })


  function getDaysObject() {

    //현재 달 달력 만들기
    let lastDate = new Date();
    lastDate.setDate(1);
    lastDate.setMonth(lastDate.getMonth() + pivotDate);

    let days = ["일", "월", "화", "수", "목", "금", "토"];
    let totalCount = 0;
    let dateList = {
      years: lastDate.getFullYear(),
      month: lastDate.getMonth() + 1,
      days: [],
    };

    let prevDate = new Date(lastDate.getTime());
    prevDate.setDate(prevDate.getDate() - lastDate.getDay() - 1);
    for (let i = 0; i < lastDate.getDay(); i++) {
      totalCount++;
      prevDate.setDate(prevDate.getDate() + 1);
      dateList.days.push({
        date: new Date(prevDate),
        day: prevDate.getDate(),
        title: days[i],
        weekend: prevDate.getDay() == 0 || prevDate.getDay() == 6,
        curDay: prevDate.getDay(),
        isBefore: true,
      });
    }

    for (let i = 1; i <= lastDate.getDate(); i++) {
      totalCount++;
      dateList.days.push({
        date: new Date(lastDate),
        day: i,
        title: days[lastDate.getDay()],
        weekend: lastDate.getDay() == 0 || lastDate.getDay() == 6,
        curDay: lastDate.getDay(),
      });
      lastDate.setDate(lastDate.getDate() + 1);
    }

    for (let i = 0; i <= 41 - totalCount; i++) {
      dateList.days.push({
        date: new Date(lastDate),
        day: i + 1,
        title: days[i],
        weekend: lastDate.getDay() == 0 || lastDate.getDay() == 6,
        curDay: lastDate.getDay(),
        isAfter: true,
      });
      lastDate.setDate(lastDate.getDate() + 1);
    }

    return dateList;
  }

  function getPivotDate(currentPivot) {
    let date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + currentPivot);
    return date;
  }

  function nextMonthEvent() {
    setPivotDate((p) => {
      return p + 1;
    });
  }

  function prevMonthEvent() {
    setPivotDate((p) => {
      return p - 1;
    });
  }

  function moveToDate(date) {
    let today = new Date();
    setPivotDate(
      date.getFullYear() * 12 +
        (date.getMonth() + 1) -
        (today.getFullYear() * 12 + (today.getMonth() + 1))
    );
  }
  
  function onClickDate(date) {
    setValue(date);
    setDate(utils.date.changeDate(date));
    setFilter((e) => { return { ...e, date: utils.date.changeDate(date) } })
  }

  useEffect(() => {
    setDate(utils.date.changeDate(new Date(), ".", pivotDate));
    setCurrentDate(() => {
      return getPivotDate(pivotDate);
    });
  }, [pivotDate]);

  return {
    scheduleList: getCalendarList.data ? getCalendarList.data : [],
    
    // 현재 선택된 날짜
    value,
    
    // 변환된 날짜
    date, setDate,

    // 해당 일로 이동
    // moveToDate,
    onClickDate,

    // 다음달 로드 함수
    nextMonthEvent,
    // 이전달 로드 함수
    prevMonthEvent,

    // 캘린더 로드 함수
    getDaysObject,
    getPivotDate,

    // 타겟으로 설정된 데이트 ( pivot 기준 )
    currentDate,

    filter, setFilter,
    updateData, setUpdateData,
  };
}
