import Styles from "./styles";
import { common, layout } from "components";
import { Notice, Faq, Ask } from "./components";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

// img
import ImgCustomerSupport from "resources/image/img_customer_support.png";
import ImgCustomerSupportMb from "resources/image/img_customer_support_mb.png";

function CustomerSupportPage() {
  const location = useLocation();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const renderPageName = (type) => {
    switch (type) {
      case 0: return "공지사항";
      case 1: return "FAQ";
      case 2: return "문의하기";
    }
  };
  const data = {
    img: ImgCustomerSupport,
    mbImg: ImgCustomerSupportMb,
    prevText: "고객지원",
    nowText: renderPageName(tabIndex),
    title: "고객지원",
  };
  const tabList = [
    {
      id: 0,
      url: "/customer-support/notice",
      title: "공지사항",
    },
    {
      id: 1,
      url: "/customer-support/faq",
      title: "FAQ",
    },
    {
      id: 2,
      url: "/customer-support/ask",
      title: "문의하기",
    },
  ];

  // 새로고침 해도 tab active 유지
  useEffect(() => {
    setTabIndex(tabList.findIndex((e) => e.url == location.pathname));
  }, [tabIndex, location.pathname]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <section className="contentsSection">
          <div className="inner">
            <div className="tabContainer">
              <layout.Tab
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabList={tabList}
              />
            </div>

            {id === "notice" && <Notice />}
            {id === "faq" && <Faq />}
            {id === "ask" && <Ask />}
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CustomerSupportPage;
