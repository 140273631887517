import { common, ui } from "components";
import Styles from "./styles";
import { useState, useEffect } from "react";
import { useAuth } from "hooks";

function FindPasswordPage() {
  const authInfo = useAuth();
  
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorText, setErrorText] = useState("");

  const onSendPassword = () => {
    authInfo.changeByEmail(email, setErrorText);
  }

  const validate = () => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) === false) return false;
    return true;
  };

  useEffect(() => {
    if (validate()) setIsDisabled(false);
    else setIsDisabled(true);
  }, [email]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="formContainer">
          <form>
            <div className="narrowInner">
              <h2 className="title">임시 비밀번호 발송</h2>

              <p className="text">
                입력하신 이메일로 임시 비밀번호가 발송됩니다.
              </p>

              <div className="emailContainer">
                <ui.input.BasicInput
                  placeholder={"이메일을 입력하세요."}
                  value={email}
                  setValue={setEmail}
                  errorText={errorText}
                />
              </div>

              <div className="submitButton">
                <ui.button.BasicButton
                  buttonType={isDisabled ? "gray" : "basic"}
                  buttonText={"임시 비밀번호 발송"}
                  disabled={isDisabled}
                  onClick={onSendPassword}
                />
              </div>
            </div>
          </form>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default FindPasswordPage;
