import styled from "styled-components";

const Container = styled.div`
  min-height: 370px;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.08);

  & .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    & .title {
      font-size: 26px;
      font-weight: bold;
    }

    & .buttonListWrap {
      display: flex;
      gap: 10px;

      & .buttonList {
        width: 132px;
      }
    }
  }

  & .list {
    display: flex;
    align-items: center;
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    & .title {
      width: 129px;
      font-weight: bold;
      color: var(--main-color);
    }

    &.childrenInfo {
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 20px 70px;

      & .childrenInfoContainer {
        display: flex;

        //* 동현 퍼블 수정된 코드
        /* width: 296px; */
        width: 446px;

        & .detailList {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          & .detailTitle {
            flex-shrink: 0;
            width: 108px;
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vw * (370 / 1600));
    padding: calc(100vw * (50 / 1600));
    border-radius: calc(100vw * (10 / 1600));
    box-shadow: 0px calc(100vw * (2 / 1600)) calc(100vw * (50 / 1600)) 0px
      rgba(0, 0, 0, 0.08);

    & .titleContainer {
      margin-bottom: calc(100vw * (48 / 1600));

      & .title {
        font-size: calc(100vw * (26 / 1600));
      }

      & .buttonListWrap {
        gap: calc(100vw * (10 / 1600));

        & .buttonList {
          width: calc(100vw * (132 / 1600));
        }
      }
    }

    & .list {
      font-size: calc(100vw * (16 / 1600));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 1600));
      }

      & .title {
        width: calc(100vw * (129 / 1600));
      }

      &.childrenInfo {
        gap: calc(100vw * (20 / 1600)) calc(100vw * (70 / 1600));

        & .childrenInfoContainer {
          //* 동현 퍼블 수정된 코드
          /* width: calc(100vw * (296 / 1600)); */
          width: calc(100vw * (446 / 1600));

          & .detailList {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (15 / 1600));
            }

            & .detailTitle {
              width: calc(100vw * (108 / 1600));
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vw * (346 / 370));
    padding: calc(100vw * (29 / 370)) calc(100vw * (15 / 370))
      calc(100vw * (50 / 370));
    border-radius: calc(100vw * (10 / 370));
    box-shadow: 0px calc(100vw * (2 / 370)) calc(100vw * (50 / 370)) 0px
      rgba(0, 0, 0, 0.08);

    & .titleContainer {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: calc(100vw * (30 / 370));

      & .title {
        margin-bottom: calc(100vw * (20 / 370));
        font-size: calc(100vw * (20 / 370));
      }

      & .buttonListWrap {
        gap: calc(100vw * (10 / 370));
        width: 100%;
        overflow: auto;

        & .buttonList {
          flex-shrink: 0;
          width: calc(100vw * (132 / 370));
        }
      }
    }

    & .list {
      flex-direction: column;
      align-items: flex-start;
      font-size: calc(100vw * (14 / 370));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .title {
        width: 100%;
        margin-bottom: calc(100vw * (6 / 370));
        font-size: calc(100vw * (16 / 370));
      }

      &.childrenInfo {
        flex-direction: column;
        gap: calc(100vw * (20 / 370));

        & .childrenInfoContainer {
          flex-direction: column;
          width: 100%;

          & .detailList {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (14 / 370));
            }

            & .detailTitle {
              width: calc(100vw * (108 / 370));
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
