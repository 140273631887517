import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth, useFooter } from "hooks";

// img
import Logo from "resources/image/icon/logo_white.svg";
import IconPen from "resources/image/icon/icon_pen.svg";
import IconTop from "resources/image/icon/icon_top.svg";
import IconClose from "resources/image/icon/icon_close02.svg";
import IconList01 from "resources/image/icon/icon_list.svg";
import IconList02 from "resources/image/icon/icon_list02.svg";
import IconChat from "resources/image/icon/icon_chat.svg";

function Footer() {
  const footerInfo = useFooter({ options: { enableVisitCount: true }});
  const authInfo = useAuth();
  const navigate = useNavigate();
  const [buttonModalOpen, setButtonModalOpen] = useState(false);

  const scrollTopClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // map
  const fnbList = [
    {
      url: "/edusource",
      text: "에듀소스",
    },
    {
      url: "/customer-support/notice",
      text: "고객지원",
    },
    {
      url: "/terms/service",
      text: "이용약관",
    },
  ];

  const infoList = [
    "회사명 : 아이오티플래닛 주식회사",
    "대표 : 유원식",
    "사업자등록번호 : 750-81-01520",
    <>
      주소 : 서울특별시 금천구 가산디지털1로 83, 204-54호{" "}
      <br className="mbOnly" />{" "}
      <span className="mbOnly">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      (가산동, 파트너스타워)
    </>,
  ];

  const buttonList = [
    {
      icon: IconList01,
      text: "교육커뮤니티 작성",
      url: "education-info",
      id: 1,
    },
    {
      icon: IconChat,
      text: "지역커뮤니티 작성",
      url: "community",
      id: 2,
    },
    {
      icon: IconList02,
      text: "블로그 작성",
      url: "blog",
      id: 3,
    },
  ];

  return (
    <Styles.Container>
      <ul className={"buttonListWrap" + (buttonModalOpen ? " on" : "")}>
        {buttonModalOpen &&
          buttonList?.map((item, index) => {
            return (
              <li className="buttonList">
                <button
                  key={"buttonList" + index}
                  onClick={() => {
                    setButtonModalOpen(false);
                    if (authInfo.certification()) {
                      window.location.replace("/writing/" + item.url);
                    }
                  }}
                >
                  <img src={item.icon} alt={item.icon} />
                  <p className="buttonText">{item.text}</p>
                </button>
              </li>
            );
          })}

        <li className="buttonList">
          <button
            type="button"
            onClick={() => {
              setButtonModalOpen(!buttonModalOpen);
            }}
          >
            <img src={buttonModalOpen ? IconClose : IconPen} alt="IconPen" />
          </button>
        </li>

        <li className="buttonList">
          <button
            type="button"
            onClick={() => {
              !buttonModalOpen && scrollTopClick();
            }}
          >
            <img src={IconTop} alt="IconTop" />
          </button>
        </li>
      </ul>

      <div className="inner">
        <div className="fnb">
          <ul className="fnbListWrap">
            {fnbList?.map((item, index) => {
              return (
                <li
                  className="fnbList"
                  key={"fnbList" + index}
                  onClick={() => {
                    navigate(item.url);
                  }}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>

          <p className="today">
            Today&nbsp; {footerInfo.visitInfo?.data}
          </p>
        </div>

        <div className="bottomContainer">
          <div className="bottomWrap">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>

            <ul className="info">
              {infoList.map((item, index) => {
                return (
                  <li className="infoList" key={"infoList" + index}>
                    {item}
                  </li>
                );
              })}
            </ul>

            <p className="copyright">
              © 2023. 아이오티플래닛 주식회사. all rights reserved.
            </p>
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}

export default Footer;
