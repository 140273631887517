
const getDetail = () => {
  return {
    title : "test"
  }
}

const getList = (params) => {
  return [
    {title:"a1"},
    {title:"a2"},
    {title:"a3"}
  ];
}



export {
  getDetail,
  getList
};


export default {
  getDetail,
  getList
};