import utils from "utils";
import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useBoard, useCategory } from "hooks";

// img
import ImgEducationInfo from "resources/image/img_education_info.png";
import ImgEducationInfoMb from "resources/image/img_education_info_mb.png";

function CommunityPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState();

  const authInfo = useAuth();
  const categoryInfo = useCategory();
  const boardInfo = useBoard({
    communityOption: filter
 });

  const data = {
    img: ImgEducationInfo,
    mbImg: ImgEducationInfoMb,
    nowText: "지역 커뮤니티",
    title: "지역 커뮤니티",
  };

  const optionList = [
    { title: "강남구" },
    { title: "강동구" },
    { title: "노원구" },
    { title: "송파구" },
    { title: "양천구" },
    { title: "동탄동" },
    { title: "제주도" },
    { title: "그외 지역" },
  ];

  const keyword = categoryInfo.getCategory?.data || []
  const communityList = boardInfo.getCommunityList?.data || []
  
  useEffect(() => {
    let param = utils.etc.getSearchParamNew();
    let f = {
      page: param.page ? param.page : 1,
      keyword: param.category ? param.category : "수다",
      location: (param.location ? param.location : authInfo.userData.location || "").replace(/_/g, " "),
      recordSize: 10,
      type: 3
    };
    setFilter(f);
  }, [location]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <section className="communitySection middleInner">
            <div className="titleContainer">
              <h2 className="sectionTitle">지역 커뮤니티</h2>

              <div className="selectContainer">
                <ui.input.SecondSelect
                  optionList={optionList}
                  selectCategory={filter?.location ? filter.location : "전체"}
                  setSelectCategory={(v)=>{
                    navigate(
                      utils.etc.getQueryParamUrl(
                        { page: 1, location: v.title.replace(/ /g, "_") }, false
                      )
                    )
                  }}
                />
              </div>
            </div>
            <div className="tabContainer">
              <layout.Tab
                tabIndex={keyword?.filter(v => { return v.title == filter?.keyword })[0]?.id}
                tabList={keyword}
                setTabIndex={(v)=>{
                  navigate(
                    utils.etc.getQueryParamUrl(
                      { page: 1, category: keyword.filter(e => { return e.id == v })[0].title }, false
                    )
                  )
                }}
              />
            </div>

            <ul className="listWrap">
              {communityList?.list?.map((item, index) => {
                return (
                  <li
                    className="list"
                    key={"list" + index}
                    onClick={() => {
                      if (authInfo.certification(false)) {
                        navigate("/community/detail?id=" + item.id, { state: location.pathname + location.search })
                      }
                    }}
                  >
                    <layout.EducationList big data={item} />
                  </li>
                );
              })}
            </ul>

            <div className="paginationContainer">
              <ui.button.PaginationNew
                page={filter?.page}
                maxPage={communityList?.pagination?.endPage}
                list={communityList?.pagination?.page_list}
              />
            </div>
          </section>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default CommunityPage;
