import styled from "styled-components";

// img
import IconArrowCurved from "resources/image/icon/icon_arrow_curved.svg";

const Container = styled.div`
  position: relative;
  padding: 30px 40px;
  background-color: #f9fafc;
  border-bottom: 1px solid #d2d6de;

  & .moreButtonContainer {
    position: absolute;
    top: 39px;
    right: 40px;

    & .moreButton {
      display: block;
      width: 18px;
      height: 18px;
    }

    & .moreListWrap {
      position: absolute;
      top: 0;
      right: 100%;
      padding: 6px 0;
      background-color: var(--black-color-01);
      border-radius: 5px;
      overflow: hidden;

      & .moreList button {
        width: max-content;
        padding: 6px 23px;
        color: #fff;
      }
    }
  }

  & .infoContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & .profileImgWrap {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    & .nickname {
      font-size: 16px;
      font-weight: bold;
    }

    & .writer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 51px;
      height: 24px;
      margin-left: 4px;
      font-weight: 500;
      color: var(--main-color);
      border: 1px solid var(--main-color);
      border-radius: 500px;
    }

    & .date {
      margin-left: 16px;
      font-weight: 500;
      color: #cfd2d7;
    }
  }

  & .comment {
    margin: 0 0 20px 46px;
    word-break: break-word;
  }

  & .reactionListWrap {
    display: flex;
    gap: 15px;
    margin-left: 46px;

    & button {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 500;
      color: var(--gray-color-02);

      & .iconWrap {
        display: inline-block;
        width: 18px;
        height: 18px;
      }
    }

    & .likeButton.on {
      color: var(--black-color-01);
    }
  }

  & .commentWrap {
    margin-left: 46px;
  }

  & .stateText {
    font-size: 16px;
    font-weight: bold;
  }

  &.reply {
    padding: 30px 80px;
    background: url(${IconArrowCurved}) no-repeat 30px 40px / 20px
      var(--gray-color-04);

    & .moreButtonContainer {
      top: 31px;
      right: 80px;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (30 / 1600)) calc(100vw * (40 / 1600));

    & .moreButtonContainer {
      top: calc(100vw * (39 / 1600));
      right: calc(100vw * (40 / 1600));

      & .moreButton {
        width: calc(100vw * (18 / 1600));
        height: calc(100vw * (18 / 1600));
      }

      & .moreListWrap {
        padding: calc(100vw * (6 / 1600)) 0;
        border-radius: calc(100vw * (5 / 1600));

        & .moreList button {
          padding: calc(100vw * (6 / 1600)) calc(100vw * (23 / 1600));
        }
      }
    }

    & .infoContainer {
      margin-bottom: calc(100vw * (10 / 1600));

      & .profileImgWrap {
        width: calc(100vw * (36 / 1600));
        height: calc(100vw * (36 / 1600));
        margin-right: calc(100vw * (10 / 1600));
      }

      & .nickname {
        font-size: calc(100vw * (16 / 1600));
      }

      & .writer {
        width: calc(100vw * (51 / 1600));
        height: calc(100vw * (24 / 1600));
        margin-left: calc(100vw * (4 / 1600));
        border-radius: calc(100vw * (500 / 1600));
      }

      & .date {
        margin-left: calc(100vw * (16 / 1600));
      }
    }

    & .comment {
      margin: 0 0 calc(100vw * (20 / 1600)) calc(100vw * (46 / 1600));
    }

    & .reactionListWrap {
      gap: calc(100vw * (15 / 1600));
      margin-left: calc(100vw * (46 / 1600));

      & button {
        gap: calc(100vw * (3 / 1600));

        & .iconWrap {
          width: calc(100vw * (18 / 1600));
          height: calc(100vw * (18 / 1600));
        }
      }
    }

    & .commentWrap {
      margin-left: calc(100vw * (46 / 1600));
    }

    & .stateText {
      font-size: calc(100vw * (16 / 1600));
    }

    &.reply {
      padding: calc(100vw * (30 / 1600)) calc(100vw * (80 / 1600));
      background: url(${IconArrowCurved}) no-repeat calc(100vw * (30 / 1600))
        calc(100vw * (40 / 1600)) / calc(100vw * (20 / 1600))
        var(--gray-color-04);

      & .moreButtonContainer {
        top: calc(100vw * (31 / 1600));
        right: calc(100vw * (80 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (15 / 370)) calc(100vw * (10 / 370));

    & .moreButtonContainer {
      top: calc(100vw * (16 / 370));
      right: calc(100vw * (10 / 370));

      & .moreButton {
        width: calc(100vw * (18 / 370));
        height: calc(100vw * (18 / 370));
      }

      & .moreListWrap {
        padding: calc(100vw * (2 / 370)) 0;
        border-radius: calc(100vw * (5 / 370));

        & .moreList button {
          padding: calc(100vw * (7 / 370)) calc(100vw * (16 / 370));
          font-size: calc(100vw * (12 / 370));
        }
      }
    }

    & .infoContainer {
      flex-wrap: wrap;
      margin-bottom: calc(100vw * (10 / 370));

      & .profileImgWrap {
        width: calc(100vw * (22 / 370));
        height: calc(100vw * (22 / 370));
        margin-right: calc(100vw * (10 / 370));
      }

      & .nickname {
        font-size: calc(100vw * (14 / 370));
      }

      & .writer {
        width: calc(100vw * (45 / 370));
        height: calc(100vw * (21 / 370));
        margin-left: calc(100vw * (5 / 370));
        font-size: calc(100vw * (12 / 370));
        border-radius: calc(100vw * (10 / 370));
      }

      & .date {
        width: 100%;
        margin: calc(100vw * (6 / 370)) 0 0;
        font-size: calc(100vw * (12 / 370));
      }
    }

    & .comment {
      margin: 0 0 calc(100vw * (15 / 370));
    }

    & .reactionListWrap {
      gap: calc(100vw * (10 / 370));
      margin-left: 0;

      & button {
        gap: calc(100vw * (3 / 370));
        font-size: calc(100vw * (12 / 370));

        & .iconWrap {
          width: calc(100vw * (18 / 370));
          height: calc(100vw * (18 / 370));
        }
      }
    }

    & .commentWrap {
      margin-left: 0;
    }

    & .stateText {
      font-size: calc(100vw * (14 / 370));
    }

    &.reply {
      padding: calc(100vw * (14 / 370)) calc(100vw * (10 / 370))
        calc(100vw * (14 / 370)) calc(100vw * (32 / 370));
      background: url(${IconArrowCurved}) no-repeat calc(100vw * (14 / 370))
        calc(100vw * (10 / 370)) / calc(100vw * (17 / 370)) var(--gray-color-04);

      & .moreButtonContainer {
        top: calc(100vw * (16 / 370));
        right: calc(100vw * (10 / 370));
      }

      & .submitButton {
        width: calc(100vw * (50 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
