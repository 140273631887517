import styled, { css } from "styled-components";

const sizes = {
  medium: {
    height: "55",
    // mbHeight: "32",
  },
  small: {
    height: "50",
    mbHeight: "50",
    // mbFontSize: "14",
  },
  smaller: {
    height: "50",
    mbHeight: "35",
    mbFontSize: "12",
  },
  thin: {
    height: "40",
    mbHeight: "40",
    // mbFontSize: "14",
  },
  bigFont: {
    fontSize: "16",
  },
};

const buttonTypes = {
  basic: {
    backgroundColor: "var(--main-color)",
    fontColor: "#fff",
  },
  black: {
    backgroundColor: "var(--black-color-01)",
    fontColor: "#fff",
  },
  gray: {
    backgroundColor: "var(--gray-color-04)",
    fontColor: "#CED4DF",
  },
  grayGrayFont: {
    borderColor: "1px solid var(--gray-color-02)",
    fontColor: "var(--gray-color-02)",
  },
  grayBlackFont: {
    backgroundColor: "var(--gray-color-04)",
    fontColor: "var(--black-color-01)",
  },
  blackGrayFont: {
    backgroundColor: "var(--black-color-01)",
    fontColor: "var(--gray-color-02)",
  },
  white: {
    backgroundColor: "#fff",
    fontColor: "var(--gray-color-02)",
  },
  blackOutline: {
    borderColor: "1px solid var(--black-color-01)",
    // backgroundColor: "#fff",
    fontColor: "var(--black-color-01)",
    borderRadius: "0",
  },
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const sizeStyles = css`
  ${({ size }) => css`
    height: ${sizes[size] && sizes[size].height
      ? sizes[size].height + "px"
      : "70px"};
    font-size: ${sizes[size] && sizes[size].fontSize
      ? sizes[size].fontSize + "px"
      : "14px"};

    @media (max-width: 1600px) {
      height: ${sizes[size] && sizes[size].height
        ? "calc(100vw * (" + sizes[size].height + "/ 1600))"
        : "calc(100vw * (70 / 1600))"};
      font-size: ${sizes[size] && sizes[size].fontSize
        ? "calc(100vw * (" + sizes[size].fontSize + "/ 1600))"
        : "calc(100vw * (14 / 1600))"};
    }

    @media (max-width: 768px) {
      height: ${sizes[size] && sizes[size].height
        ? "calc(100vw * (" + sizes[size].mbHeight + "/ 370))"
        : "calc(100vw * (60 / 370))"};
      font-size: ${sizes[size] && sizes[size].mbFontSize
        ? "calc(100vw * (" + sizes[size].mbFontSize + "/ 370))"
        : "calc(100vw * (14 / 370))"};
    }
  `}
`;

const buttonTypeStyles = css`
  ${({ buttonType }) => css`
    border: ${buttonTypes[buttonType] && buttonTypes[buttonType].borderColor};
    background: ${buttonTypes[buttonType] &&
    buttonTypes[buttonType].backgroundColor};
    color: ${buttonTypes[buttonType] && buttonTypes[buttonType].fontColor};
    border-radius: ${buttonTypes[buttonType] &&
    !buttonTypes[buttonType].borderRadius &&
    "2px"};

    @media (max-width: 1600px) {
      border-radius: ${buttonTypes[buttonType] &&
      !buttonTypes[buttonType].borderRadius &&
      "calc(100vw * (2 / 1600))"};
    }

    @media (max-width: 768px) {
      border-radius: ${buttonTypes[buttonType] &&
      !buttonTypes[buttonType].borderRadius &&
      "calc(100vw * (2 / 370))"};
    }
  `}
`;

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;

  ${sizeStyles}
  ${buttonTypeStyles}

  &:disabled {
    color: #ced4df;
    background-color: var(--gray-color-04);
  }

  & .iconWrap {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  @media (max-width: 1600px) {
    & .iconWrap {
      width: calc(100vw * (18 / 1600));
      height: calc(100vw * (18 / 1600));
      margin-right: calc(100vw * (5 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .iconWrap {
      width: calc(100vw * (18 / 370));
      height: calc(100vw * (18 / 370));
      margin-right: calc(100vw * (5 / 370));
    }
  }
`;

export { Container };

export default {
  Container,
};
