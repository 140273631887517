import Styles from "./styles";
import { Pc, Mobile } from "hooks/useResponsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useAlarm, useAuth, useKeyword } from "hooks";
import layout from "components/layout";
import ui from "components/ui";
import modal from "components/modal";

// img
import Logo from "resources/image/icon/logo.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconBellOn from "resources/image/icon/icon_bell_on.svg";
import IconBellOff from "resources/image/icon/icon_bell_off.svg";
import IconSearch from "resources/image/icon/icon_search_black.svg";
import IconClose from "resources/image/icon/icon_close.svg";
import IconLogin from "resources/image/icon/icon_login.svg";

function Header() {
  const authInfo = useAuth();
  const alarmInfo = useAlarm();
  const keywordInfo = useKeyword();

  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const [isBellOpen, setIsBellOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsFocus(false);
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");

    if (isMenuOpen) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [isMenuOpen]);

  const utilList = [
    {
      url: "/education-info",
      text: "교육 정보",
    },
    {
      url: "/community",
      text: "지역 커뮤니티",
    },
    {
      url: "/blog",
      text: "블로그",
    },
    {
      url: "/facility-info",
      text: "시설 정보",
    },
  ];

  return (
    <>
      <Pc>
        <Styles.Container>
          <div className="inner">
            <h1 className="logo">
              <Link to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
            </h1>

            <div className="searchContainer" ref={ref}>
              <layout.SearchContainer
                setIsFocus={setIsFocus}
                onFocus={(e) => {
                  setIsFocus(true);
                }}
              />

              {location.pathname !== "/search" && isFocus && (
                <div className="keywordContainer">
                  <div className="inner">
                    <p className="keywordTitle">인기 키워드</p>

                    <ul className="keywordListWrap">
                      {keywordInfo.getBestKeyword?.data?.map((item, index) => {
                        return (
                          <li
                            className="keywordList"
                            key={"keywordList" + index}
                            onClick={() => {
                              setIsFocus(false);
                              navigate(
                                "/search?keyword=" + item.title + "&page=1"
                              );
                            }}
                          >
                            <ui.button.KeywordTag keyword={item.title} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <ul className="utilListWrap">
              {utilList?.map((item, index) => {
                return (
                  <li className="utilList" key={"utilList" + index}>
                    <Link to={item.url}>{item.text}</Link>
                  </li>
                );
              })}
            </ul>

            {authInfo.isLogin ? (
              <ul className="buttonListWrap">
                <li className="buttonList">
                  <button
                    type="button"
                    onClick={() => {
                      setIsBellOpen(true);
                    }}
                  >
                    <img
                      src={
                        alarmInfo.getAlarm?.data
                          ?.map((item) => {
                            return item.read_yn;
                          })
                          .filter((e) => e === "N").length === 0
                            ? IconBellOff
                            : IconBellOn
                      }
                      alt="IconBell"
                    />
                  </button>
                  {isBellOpen && (
                    <modal.BellModal
                      modalClose={() => {
                        setIsBellOpen(false);
                      }}
                    />
                  )}
                </li>

                <li className="buttonList">
                  <Link to={"/mypage/info"}>
                    <layout.UserProfileImg
                      profileImg={authInfo.userData?.image}
                    />
                  </Link>
                </li>
              </ul>
            ) : (
              <button
                type="button"
                className="loginButton"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <span className="iconWrap">
                  <img src={IconLogin} alt="IconLogin" />
                </span>
                로그인
              </button>
            )}
          </div>
        </Styles.Container>
      </Pc>

      <Mobile>
        <Styles.MbContainer>
          <div className="inner">
            <button
              type="button"
              className="menuButton"
              onClick={() => {
                setIsMenuOpen(true);
              }}
            >
              <img src={IconMenu} alt="IconMenu" />
            </button>

            <h1 className="logo">
              <Link to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
            </h1>

            <ul className="buttonListWrap">
              <li className="buttonList">
                <button
                  type="button"
                  onClick={() => {
                    setIsBellOpen(true);
                  }}
                >
                  <img
                    src={
                      alarmInfo.getAlarm?.data
                        ?.map((item) => {
                          return item.read_yn;
                        })
                        .filter((e) => e === "N").length === 0
                        ? IconBellOff
                        : IconBellOn
                    }
                    alt="IconBell"
                  />
                </button>

                {isBellOpen && (
                  <modal.BellModal
                    modalClose={() => {
                      setIsBellOpen(false);
                    }}
                  />
                )}
              </li>

              <li className="buttonList">
                <button
                  type="button"
                  onClick={() => {
                    setIsFocus(true);
                  }}
                >
                  <img src={IconSearch} alt="IconSearch" />
                </button>
              </li>
            </ul>

            {location.pathname !== "/search" && isFocus && (
              <div className="searchContainer" ref={ref}>
                <div className="searchWrap">
                  <layout.SearchContainer
                    setIsFocus={setIsFocus}
                    onFocus={(e) => {
                      setIsFocus(true);
                    }}
                  />
                </div>

                <p className="keywordTitle">인기 키워드</p>

                <ul className="keywordListWrap">
                  {keywordInfo.getBestKeyword?.data?.map((item, index) => {
                    return (
                      <li
                        className="keywordList"
                        key={"keywordList" + index}
                        onClick={() => {
                          setIsFocus(false);
                          navigate("/search?keyword=" + item.title + "&page=1");
                        }}
                      >
                        <ui.button.KeywordTag keyword={item.title} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </Styles.MbContainer>

        {isMenuOpen && (
          <Styles.Menu>
            <div className="topContainer">
              <div className="inner">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>

                <button
                  type="button"
                  className="closeButton"
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <img src={IconClose} alt="IconClose" />
                </button>
              </div>
            </div>

            <div className="inner">
              <div
                className="myContainer"
                onClick={() => {
                  authInfo.isLogin && navigate("/mypage/info");
                  setIsMenuOpen(false);
                }}
              >
                <div className="text">
                  {authInfo.isLogin ? (
                    <>
                      <span>{authInfo.userData.nickname}</span>님, <br />
                      환영합니다.
                    </>
                  ) : (
                    <>
                      <span>에듀소스</span>에 오신 것을 <br />
                      환영합니다.
                    </>
                  )}
                </div>

                {authInfo.isLogin ? (
                  <layout.UserProfileImg profileImg={authInfo.userData.image} />
                ) : (
                  <button
                    type="button"
                    className="loginButton"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <span className="iconWrap">
                      <img src={IconLogin} alt="IconLogin" />
                    </span>
                    로그인
                  </button>
                )}
              </div>

              <ul className="menuListWrap">
                {utilList?.map((item, index) => {
                  return (
                    <li className="menuList" key={"menuList" + index}>
                      <Link to={item.url}>{item.text}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Styles.Menu>
        )}
      </Mobile>
    </>
  );
}

export default Header;
