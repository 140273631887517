import { ui, layout } from "components";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useModals, useAuth, useChildren, useImage } from "hooks";

// img
import IconCamera from "resources/image/icon/icon_camera.svg";

function EditInfo() {
  const authInfo = useAuth();
  const imageInfo = useImage();
  const childrenInfo = useChildren();

  const navigate = useNavigate();
  const modalOption = useModals();

  const [profileImg, setProfileImg] = useState("");
  const [nickname, setNickname] = useState(authInfo.userData.nickname);
  const [check, setCheck] = useState(false);
  const [errorText, setErrorText] = useState({ guideText: "", errorText: "", InfoError: "" });

  const validate = () => {
    if (check === false) {
      setErrorText(e => { return { ...e, InfoError: "닉네임 중복확인 부탁드립니다." } })
      return false
    }
    if(authInfo.userData.pass_yn === "Y" && childrenInfo.validate() === false){
      setErrorText(e => { return { ...e, InfoError: "입력하지 않은 정보가 있습니다." } })
      return false
    }
    return true
  }

  const nameValidate = async () => {
    setErrorText(e => { return { ...e, guideText: "", errorText: "" } })
    if (authInfo.userData.nickname !== nickname.trim()) {
      let result = await authInfo.findNickName(nickname.trim());
      if (result.success){
        setCheck(true);
        setErrorText(e => { return { ...e, guideText: "사용 가능한 닉네임입니다.", errorText: "" } })
      } else {
        setCheck(false);
        setErrorText(e => { return { ...e, errorText: "사용 불가능한 닉네임입니다.", guideText: "", } })
      }
    }
  }

  useEffect(()=>{
    authInfo.userData.nickname === nickname.trim()
      ? setCheck(true)
      : setCheck(false)
  },[nickname])

  const buttonList = [
    {
      text: "취소",
      onclick: () => {
        navigate("/mypage/info");
      },
    },
    {
      text: "저장하기",
      onclick: () => {
        if (validate()) {
          const postData = new FormData();
          postData.append("nickname", nickname);
          profileImg && postData.append("profile_image", profileImg);
          childrenInfo.editInfo(postData);
        }
      } 
    },
    {
      text: "회원탈퇴",
      onclick: () => {
        modalOption.setDeleteModal((e) => {
          e.show = true;
          return { ...e };
        });
      },
    },
  ];

  return (
    <Styles.Container>
      <div className="wrap">
        <div className="infoContainer">
          <h3 className="title">정보 수정</h3>

          <ul className="listWrap">
            <li className="list profile">
              <p className="listTitle">프로필</p>

              <div className="profileImgContainer">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  id="profileImg"
                  onChange={(e) => {
                    const maxSize = 20971520; // 20MB
                    if (e.target.files[0].size < maxSize){
                      imageInfo.imagePrint(e.target.files[0]);
                      setProfileImg(e.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="profileImg">
                  <span className="iconWrap">
                    <img src={IconCamera} alt="IconCamera" />
                  </span>
                </label>
                <layout.UserProfileImg
                  profileImg={authInfo.userData.image}
                  imageUrl={imageInfo.imageUrl}
                />
              </div>
            </li>

            <li className="list">
              <p className="listTitle">이메일</p>

              <p className="text">{authInfo.userData.username}</p>
            </li>

            <li className="list nickname">
              <ui.input.BasicInput
                labelText={"닉네임"}
                placeholder={"닉네임을 입력해 주세요."}
                buttonText={"중복확인"}
                value={nickname}
                setValue={setNickname}
                onClick={nameValidate}
                errorText={errorText.errorText}
                guideText={errorText.guideText}
              />
            </li>

            
            <li className="list">
              <p className="listTitle">활동지역</p>
              
              <p className="text">
                {authInfo.userData.location}
              </p>
            </li>
          </ul>
        </div>

        {authInfo.userData.pass_yn === "Y" && (
          <div className="childrenInfoContainer">
            <h3 className="title">자녀 정보</h3>

            <ul className="listWrap">
              {childrenInfo.childrenList?.map((item, index) => {
                const changeValue = (key, value) => {
                  childrenInfo.setChildrenList((e) => {
                    item[key] = value
                    let newItem = [...e]
                    newItem[index] = item
                    return newItem
                  })
                }
                return (
                  <li className="list" key={"list" + index}>
                    <layout.ChildrenInfoForm
                      index={index + 1}
                      item={item}
                      changeValue={changeValue}
                      plusClick={childrenInfo.handlePlusClick}
                      minusClick={() => { childrenInfo.handleMinusClick(index) }}
                    />
                  </li>
                );
              })}
            </ul>

            <p className="errorText">{errorText.InfoError}</p>
          </div>
        )}
      </div>

      <ul className="buttonListWrap">
        {buttonList?.map((item, index) => {
          return (
            <li className="buttonList" key={"buttonList" + index}>
              <ui.button.BasicButton
                buttonText={item.text}
                buttonType={"blackOutline"}
                size={"small"}
                onClick={item.onclick}
              />
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default EditInfo;
