import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & > .inner {
    margin-top: -80px;
    padding: 80px 63px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }

  & .titleContainer {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 50px;

    & .sectionTitle {
      font-size: 36px;
      font-weight: bold;
    }

    & .selectContainer {
      width: 203px;
    }
  }

  & .tabContainer {
    margin-bottom: 5px;
  }

  & .list {
    border-bottom: 1px solid #cfd2d7;
  }

  & .paginationContainer {
    margin-top: 50px;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & > .inner {
      margin-top: calc(100vw * (-80 / 1600));
      padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
      border-top-left-radius: calc(100vw * (10 / 1600));
      border-top-right-radius: calc(100vw * (10 / 1600));
    }

    & .titleContainer {
      gap: calc(100vw * (30 / 1600));
      margin-bottom: calc(100vw * (50 / 1600));

      & .sectionTitle {
        font-size: calc(100vw * (36 / 1600));
      }

      & .selectContainer {
        width: calc(100vw * (203 / 1600));
      }
    }

    & .tabContainer {
      margin-bottom: calc(100vw * (5 / 1600));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & > .inner {
      margin-top: calc(100vw * (-30 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
      border-top-left-radius: calc(100vw * (5 / 370));
      border-top-right-radius: calc(100vw * (5 / 370));
    }

    & .middleInner {
      width: 100%;
    }

    & .titleContainer {
      gap: calc(100vw * (10 / 370));
      margin-bottom: calc(100vw * (30 / 370));

      & .sectionTitle {
        font-size: calc(100vw * (20 / 370));
      }

      & .selectContainer {
        width: calc(100vw * (148 / 370));
      }
    }

    & .tabContainer {
      margin-bottom: calc(100vw * (7 / 370));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 370));
    }
  }
`;

export { Container };

export default { Container };
