import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .narrowInner {
    padding-top: 80px;
  }

  & .title {
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: bold;

    & span {
      color: var(--main-color);
    }
  }

  & .list {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:last-child {
      padding-top: 20px;
      border-top: 1px solid var(--gray-color-04);
    }

    & button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 70px;
      font-size: 16px;
      font-weight: 500;

      &.naver,
      &.apple,
      &.email {
        color: #fff;
      }

      &.google {
        background-color: #f7f7f7;
      }

      &.kakao {
        background-color: #ffe812;
      }

      &.naver {
        background-color: #0ccc61;
      }

      &.apple {
        background-color: var(--black-color-01);
      }

      &.email {
        background-color: var(--main-color);
      }

      & .iconWrap {
        position: absolute;
        top: 50%;
        left: 22px;
        transform: translateY(-50%);
        display: inline-block;
        width: 26px;
        height: 26px;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .narrowInner {
      padding-top: calc(100vw * (80 / 1600));
    }

    & .title {
      margin-bottom: calc(100vw * (50 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .list {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 1600));
      }

      &:last-child {
        padding-top: calc(100vw * (20 / 1600));
      }

      & button {
        height: calc(100vw * (70 / 1600));
        font-size: calc(100vw * (16 / 1600));

        & .iconWrap {
          left: calc(100vw * (22 / 1600));
          width: calc(100vw * (26 / 1600));
          height: calc(100vw * (26 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 370));
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (22 / 370));
    }

    & .list {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 370));
      }

      &:last-child {
        margin-top: calc(100vw * (20 / 370));
        padding-top: calc(100vw * (20 / 370));
      }

      & button {
        height: calc(100vw * (61 / 370));
        font-size: calc(100vw * (16 / 370));

        & .iconWrap {
          left: calc(100vw * (22 / 370));
          width: calc(100vw * (26 / 370));
          height: calc(100vw * (26 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
