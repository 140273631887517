import Styles from "./styles";
import { useAuth } from "hooks";
import { useState } from "react";
import { common, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";

function ChangePasswordPage() {
  const authInfo = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [nowPassword, setNowPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorText, setErrorText] = useState({});

  const validate = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{10,30}$/;
    setErrorText({ MyPasswordProblem: "", equalProblem: "" })

    if (nowPassword === newPassword.trim()) {
      setErrorText(e => { return { ...e, equalProblem: "기존의 비밀번호와 동일합니다." } })
      return false;
    }
    if (passwordRegex.test(newPassword) === false) {
      return false;
    }
    if (newPassword !== confirmPassword) {
      setErrorText(e => { return { ...e, equalProblem: "비밀번호가 일치하지 않습니다." } })
      return false;
    }
    return true;
  }
    
  const onChangePassword = () => {
    if (validate()) authInfo.changeByDirectly(nowPassword, newPassword, setErrorText)
    else if (nowPassword.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") {
      setErrorText(e => { return { ...e, equalProblem: "입력하지 않은 정보가 있습니다." } })
    }
  }

  return (
    location.state &&
    <>
      <common.Header />
      <Styles.Container>
        <div className="formContainer">
          <form>
            <div className="narrowInner">
              <h2 className="title">비밀번호 변경</h2>

              <ul className="listWrap">
                <li className="list">
                  <ui.input.BasicInput
                    placeholder={"현재 비밀번호를 입력해 주세요."}
                    value={nowPassword}
                    setValue={setNowPassword}
                    errorText={errorText.MyPasswordProblem}
                    type={"password"}
                  />
                </li>
                <li className="list">
                  <ui.input.BasicInput
                    placeholder={"새로운 비밀번호를 입력해 주세요."}
                    value={newPassword}
                    setValue={setNewPassword}
                    guideText={"영문 소문자, 숫자 포함 10자 이상"}
                    type={"password"}
                  />
                </li>
                <li className="list">
                  <ui.input.BasicInput
                    placeholder={"새로운 비밀번호를 다시 입력해 주세요."}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    errorText={errorText.equalProblem}
                    type={"password"}
                  />
                </li>
              </ul>

              <div className="buttonContainer">
                <div className="cancelButton">
                  <ui.button.BasicButton
                    buttonType={"gray"}
                    buttonText={"취소"}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>

                <div className="submitButton">
                  <ui.button.BasicButton
                    buttonType={"basic"}
                    buttonText={"변경하기"}
                    onClick={onChangePassword}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ChangePasswordPage;
