import utils from "utils";
import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useBoard, useFacility } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// img
import ImgFacilityInfo from "resources/image/img_facility_info.png";
import ImgFacilityInfoMb from "resources/image/img_facility_info_mb.png";
import IconLocate from "resources/image/icon/icon_location.svg";
import IconUser from "resources/image/icon/icon_user02.svg";
import IconBook from "resources/image/icon/icon_book.svg";
import IconGlasses from "resources/image/icon/icon_glasses.svg";
import IconReset from "resources/image/icon/icon_reset.svg";

function FacilityInfoListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const init = {
    location: "서울특별시",
    location_detail: "도봉구",
    age: "",
    member: "",
    subject: "",
  }

  const [filter, setFilter] = useState({});
  const [inputs, setInputs] = useState(init);

  const boardInfo = useBoard({
    bookOption: id === "reading-room" && filter,
    facilityOption: id === "facility" && filter,
    keywordOption: {
      location: filter.location,
      location_detail: filter.location_detail,
      type: id === "facility" ? 4 : 6,
    },
  })
  const facilityInfo = useFacility({
    detail: inputs.location || "서울특별시",
  });

  const facilityType = (type) => {
    switch (type) {
      case "facility": return boardInfo.getFacilityList?.data || [];
      case "reading-room": return boardInfo.getBookList?.data || [];
    }
  }

  // 지역 Select정보 리스트
  const listData = facilityInfo.selectQuery?.data || [];
  const detailData = facilityInfo.selectDetailQuery?.data || [];
  // 추가 Select정보 리스트
  const ageListData = boardInfo.getAgeList?.data || [];
  const numberListData = boardInfo.getNumberList?.data || [];
  const subjectListData = boardInfo.getSubjectList?.data || [];

  const nowPage = (id === "facility" && "시설 정보") || (id === "reading-room" && "독서실 정보");
  const data = {
    img: ImgFacilityInfo,
    mbImg: ImgFacilityInfoMb,
    prevText: "시설 정보",
    nowText: nowPage,
    title: nowPage,
  };
  
  const selectList = [
    {
      id: "location",
      icon: IconLocate,
      text: "지역",
      optionList: listData,
    },
    {
      id: "location_detail",
      icon: IconLocate,
      text: "시/군/구",
      optionList: detailData,
    },
    {
      id: "age",
      icon: IconUser,
      text: "연령",
      optionList: ageListData.map((v, index) => ({ title: v, id: index })),
      placeholder: "연령 선택",
    },
    {
      id: "subject",
      icon: IconBook,
      text: "과목",
      optionList: subjectListData.map((v, index) => ({ title: v, id: index })),
      placeholder: "과목 선택",
    },
    {
      id: "member",
      icon: IconGlasses,
      text: "수강인원",
      optionList: numberListData.map((v, index) => ({ title: v, id: index })),
      placeholder: "인원 선택",
    },
  ];
  
  useEffect(() => {
    const param = utils.etc.getSearchParamNew();
    let f = {
      pageSize: 5,
      page: param.page || 1,
      location: param.location || "서울특별시",
      location_detail: param.location_detail || "도봉구",
      age: param.age || "",
      subject: param.subject || "",
      member: param.member || "",
    };

    const { pageSize, ...rest } = f;
    setInputs(rest);
    setFilter(f);
  }, [location]);

  // 지역 Select정보 변경시 추가 Select정보 변경
  const [selectCheck, setSelectCheck] = useState({ location: false, location_detail: false });
  useEffect(() => {
    selectCheck.location && setInputs(e => ({ ...e, location_detail: facilityInfo.updateData?.[0]?.title }));
    setSelectCheck(e => ({ ...e, location: false }));
  }, [facilityInfo.updateData?.[0]?.title]);
  useEffect(() => {
    selectCheck.location_detail && setInputs(e => ({ ...e, age: "", member: "", subject: "" }));
    setSelectCheck(e => ({ ...e, location_detail: false }));
  }, [inputs.location_detail]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <section className="listSection">
          <div className="filterContainer inner">
            <ul className="selectListWrap">
              {selectList?.map((item, index) => {
                return (
                  <li className="selectList" key={"selectList" + index}>
                    <div className="iconWrap">
                      <img src={item.icon} alt={item.icon} />
                    </div>

                    <div className="selectText">{item.text}</div>
                    <div className="seletWrap">
                      <ui.input.BasicSelect
                        optionList={item.optionList?.map(item => item.title)}
                        select={inputs[item.id]}
                        setSelect={(e) => {
                          setSelectCheck(e => ({ ...e, location: true, location_detail: true }));
                          setInputs(v => ({ ...v, [item.id]: e }));
                        }}
                        placeholder={item.placeholder}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="buttonContainer">
              <div className="searchButton">
                <ui.button.BasicButton
                  buttonText={"검색"}
                  buttonType={"black"}
                  size={"thin"}
                  onClick={() => {
                    navigate(utils.etc.getQueryParamUrl({ ...inputs }, true))
                  }}
                />
              </div>

              <button
                type="button"
                className="resetButton"
                onClick={() => setInputs(init)}
              >
                초기화{" "}
                <span className="iconWrap">
                  <img src={IconReset} alt="IconReset" />
                </span>
              </button>
            </div>
          </div>

          <div className="middleInner">
            <ul className="listWrap">
              {facilityType(id)?.list?.map((item, index) => {
                return (
                  <li
                    className="list"
                    key={"list" + index}
                    onClick={() => {
                      navigate(location.pathname + "/detail?id=" + item.id, { state: location.pathname + location.search });
                    }}
                  >
                    <layout.FacilityInfoList data={item} detail />
                  </li>
                );
              })}
            </ul>

            {facilityType(id)?.pagination?.totalRecordCountAll > 10 && (
              <div className="paginationContainer">
                <ui.button.PaginationNew
                  page={filter.page}
                  maxPage={facilityType(id).pagination?.endPage}
                  list={facilityType(id).pagination?.page_list}
                />
              </div>
            )}
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default FacilityInfoListPage;
