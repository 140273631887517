import BasicButton from "./BasicButton";
import Pagination from "./Pagination";
import PaginationNew from "./PaginationNew";
import KeywordTag from "./KeywordTag";

export {
  BasicButton,
  Pagination,
  PaginationNew,
  KeywordTag,
};

export default {
  BasicButton,
  Pagination,
  PaginationNew,
  KeywordTag,
};
