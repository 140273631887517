import Styles from "./styles";
import { IMAGEPATH } from "service/connector";

// img
import ImgFacilityDefault from "resources/image/img_facility_default.png";

function FacilityInfoList({ data, detail }) {
  return (
    <Styles.Container className={detail ? "detail" : ""}>
      <div className="infoWrap">
        <div className="listTitleContainer">
          <p className="listTitle">{data.title}</p>
        </div>

        <p className="listText">{data.address + data.address_detail}</p>

        <div className="detailContainer">
          <ul className="detailListWrap">
            {detail ? <>
              <li className="detailList">{data.location || "-"}</li>
              <li className="detailList">{data.age || "-"}</li>
              <li className="detailList">{data.main_subject || "-"}</li>
              <li className="detailList">{data.member || "-"}</li>
            </> : <>
              <li className="detailList">{data.age || "-"}</li>
              <li className="detailList">{data.main_subject || "-"}</li>
            </>}
          </ul>
        </div>
      </div>

      <div className="imgWrap">
        <img src={data.thumbnail ? IMAGEPATH + data.thumbnail : ImgFacilityDefault} alt={data.thumbnail} />
      </div>
    </Styles.Container>
  );
}

export default FacilityInfoList;
