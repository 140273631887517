import Styles from "./styles";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_square.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_square.svg";
import { useNavigate } from "react-router-dom";
import utils from "utils";

function PaginationNew({ list, page, maxPage }) {
  const navigate = useNavigate();
  const onClickPage = (value) => {
    navigate(utils.etc.getQueryParamUrl({ page: value }))
  };

  const onClickPrev = () => {
    if (page == 1) return;
    navigate(utils.etc.getQueryParamUrl({ page: page - 1 }))
  };

  const onClickNext = () => {
    if (page == maxPage) return;
    navigate(utils.etc.getQueryParamUrl({ page: page + 1 }))
  };

  return (
    <Styles.Container>
      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickPrev(e);
        }}
      >
        <img src={IconArrowLeft} alt="IconArrowLeft" />
      </button>

      <div className="paginationList">
        {(list ? list : []).map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              onClick={() => { onClickPage(item) }}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>

      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickNext(e);
        }}
      >
        <img src={IconArrowRight} alt="IconArrowRight" />
      </button>
    </Styles.Container>
  );
}

export { PaginationNew };

export default PaginationNew;
