import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: var(--main-color);

    & .radioBox {
      position: relative;
      border-color: var(--main-color);

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: var(--main-color);
        border-radius: 50%;
        content: "";
      }
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .radioBox {
    flex-shrink: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid var(--gray-color-02);
    border-radius: 50%;
  }

  @media (max-width: 1600px) {
    & .radioBox {
      width: calc(100vw * (16 / 1600));
      height: calc(100vw * (16 / 1600));
      margin-right: calc(100vw * (8 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .radioBox {
      width: calc(100vw * (20 / 370));
      height: calc(100vw * (20 / 370));
      margin-right: calc(100vw * (10 / 370));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
