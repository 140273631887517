import connector from "service/connector"

const postImage = async (data) => {
  return await connector.authConnector("blog/image", "POST", data);
};

export {
  postImage,
};

export default {
  postImage,
};