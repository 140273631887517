import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & > .inner {
    margin-top: -80px;
    padding: 80px 63px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }

  & .detailSection {
    & .topContainer {
      display: flex;
      gap: 50px;

      & .imgWrap {
        flex-shrink: 0;
        width: 340px;
        height: 234px;
      }

      & .blogTitleContainer {
        display: flex;
        flex-direction: column;

        & .title {
          max-width: none;
          text-align: start;
          margin: unset;
        }

        & .detailInfo {
          justify-content: flex-start;
          margin-top: auto;
        }
      }
    }
  }

  & .anotherListContainer {
    margin-top: 80px;
  }

  & .goListButton {
    width: 250px;
    margin: 50px auto 0;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & > .inner {
      margin-top: calc(100vw * (-80 / 1600));
      padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
      border-top-left-radius: calc(100vw * (10 / 1600));
      border-top-right-radius: calc(100vw * (10 / 1600));
    }

    & .detailSection {
      & .topContainer {
        gap: calc(100vw * (50 / 1600));

        & .imgWrap {
          width: calc(100vw * (340 / 1600));
          height: calc(100vw * (234 / 1600));
        }
      }
    }

    & .anotherListContainer {
      margin-top: calc(100vw * (80 / 1600));
    }

    & .goListButton {
      width: calc(100vw * (250 / 1600));
      margin: calc(100vw * (50 / 1600)) auto 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & > .inner {
      margin-top: calc(100vw * (-30 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
      border-top-left-radius: calc(100vw * (5 / 370));
      border-top-right-radius: calc(100vw * (5 / 370));
    }

    & .middleInner {
      width: 100%;
    }

    & .detailSection {
      & .topContainer {
        flex-direction: column;
        gap: calc(100vw * (10 / 370));

        & .imgWrap {
          width: 100%;
          height: calc(100vw * (200 / 370));
        }

        & .title {
          margin-bottom: calc(100vw * (10 / 370));
        }
      }
    }

    & .anotherListContainer {
      margin-top: calc(100vw * (60 / 370));
    }

    & .goListButton {
      width: calc(100vw * (155 / 370));
      margin: calc(100vw * (50 / 370)) auto 0;
    }
  }
`;

export { Container };

export default { Container };
