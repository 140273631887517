import { useState } from "react";
import { api } from "service";

export default function useImage() {
  const [imageUrl, setImageUrl] = useState(null);

  const imagePrint = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (data) => {
      if (typeof data.target?.result === "string") {
        setImageUrl(data.target?.result);
      };
    };
  };

  const imageUpload = async (file) => {
    const postData = new FormData();
    postData.append("file", file);
    return await api.image.postImage(postData);
  }

  return {
    imagePrint,
    setImageUrl,
    imageUrl,
    imageUpload,
  }
}