import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [closeFlag, setCloseFlag] = useAtom(modalAtom.modalFlagAtom);
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [copyModal, setCopyModal] = useAtom(modalAtom.copyModalAtom);
  const [deleteModal, setDeleteModal] = useAtom(modalAtom.deleteModalAtom);
  const [bellModal, setBellModal] = useAtom(modalAtom.bellModalAtom);
  const [reportModal, setReportModal] = useAtom(modalAtom.reportModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setCopyModal({ show: false });
    setDeleteModal({ show: false });
    setBellModal({ show: false });
    setReportModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setCloseFlag((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    closeFlag,
    clearSubModals,
    defaultModal,
    setDefaultModal,
    copyModal,
    setCopyModal,
    deleteModal,
    setDeleteModal,
    bellModal,
    setBellModal,
    reportModal,
    setReportModal,
  };
}
