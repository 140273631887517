import connector from "service/connector"
import utils from "utils";

// 검색
const getBoardSearch = async (filter) => {
  return await connector.connectFetchController("board/search" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

// 블로그
const getBlogList = async (filter) => {
  return await connector.authConnector("board" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getBlogBest = async () => {
  return await connector.authConnector("board/best/blog", "GET");
}
const getBlogDetail = async (path) => {
  return await connector.authConnector("board/" + path, "GET");
}

// 시설 정보
const getFacilityList = async (filter) => {
  return await connector.connectFetchController("board/company" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getBookList = async (filter) => {
  return await connector.connectFetchController("board/book" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getFacilityDetail = async (path) => {
  return await connector.connectFetchController("board/company/" + path, "GET");
}
const getBookDetail = async (path) => {
  return await connector.connectFetchController("board/book/" + path, "GET");
}
const getAgeList = async (filter) => {
  return await connector.connectFetchController("board/company/age" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getNumberList = async (filter) => {
  return await connector.connectFetchController("board/company/number" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getSubjectList = async (filter) => {
  return await connector.connectFetchController("board/company/subject" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

// 지역 커뮤니티
const getCommunityList = async (filter) => {
  return await connector.authConnector("board/community" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getCommunityDetail = async (path) => {
  return await connector.authConnector("board/community/" + path, "GET");
}

// 마이 페이지
const getBoardAll = async (filter) => {
  return await connector.authConnector("board/all" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const boardWrite = async (data) => {
  return await connector.authConnector("board/", "POST", data);
}
const boardDelete = async (data) => {
  return await connector.authConnector("board/del", "POST", data);
}
const getBoardKeyword = async (path) => {
  return await connector.authConnector("board/keyword?keyword=" + path, "GET");
}
const boardEdit = async (data) => {
  return await connector.authConnector("board/u", "POST", data);
}


// Board-Extra API
const getAcademyList = async (filter) => {
  return await connector.authConnector("board/academy" +(filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getAcademyDetail = async (path) => {
  return await connector.authConnector("board/academy/" + path, "GET");
}
const getShareList = async (filter) => {
  return await connector.authConnector("board/share" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getShareDetail = async (path) => {
  return await connector.authConnector("board/share/" + path, "GET");
}
const getEducationList = async (path) => {
  return await connector.authConnector("board/education?created_at=" + path + "&page=1", "GET");
}
const getEducationDetail = async (path) => {
  return await connector.authConnector("board/education/" + path, "GET");
}


// Board-Main API
const getBoardCalendar = async (path, type) => {
  return await connector.authConnector("board/calendar?date=" + path + "&type=" + type, "GET");
}
const getCalendarList = async (filter, recordSize) => {
  return await connector.authConnector("board/calendar/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : "") + (recordSize ? "&recordSize=" + recordSize : ""), "GET");
  // return await connector.authConnector("board/calendar/list?date=2024.01.01&type=초등학교,중학교,고등학교,대학교", "GET");
}

// 메인 페이지
const getMainEducationShareList = async (filter) => {
  return await connector.authConnector("board/main/share" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getMainCommunityList = async () => {
  return await connector.connectFetchController("board/main/community", "GET");
}
const getMainEducationList = async () => {
  return await connector.authConnector("board/main/education", "GET");
}
const getMainFacilityList = async () => {
  return await connector.connectFetchController("board/main/company", "GET");
}
const getMainBookList = async () => {
  return await connector.connectFetchController("board/main/book", "GET");
}



export {
  // 검색
  getBoardSearch,

  // 블로그
  getBlogList,
  getBlogBest,
  getBlogDetail,

  // 시설 정보
  getFacilityList,
  getBookList,
  getFacilityDetail,
  getBookDetail,
  getAgeList,
  getNumberList,
  getSubjectList,

  // 지역 커뮤니티
  getCommunityList,
  getCommunityDetail,
  
  // 마이 페이지
  getBoardAll,
  boardWrite,
  boardDelete,
  getBoardKeyword,
  boardEdit,

  // Board-Extra API
  getAcademyList,
  getAcademyDetail,
  getShareList,
  getShareDetail,
  getEducationList,
  getEducationDetail,

  // Board-Main API
  getBoardCalendar,
  getCalendarList,

  // 메인 페이지
  getMainEducationShareList,
  getMainCommunityList,
  getMainEducationList,
  getMainBookList,
  getMainFacilityList,
};

export default {
  // 검색
  getBoardSearch,

  // 블로그
  getBlogList,
  getBlogBest,
  getBlogDetail,

  // 시설 정보
  getFacilityList,
  getBookList,
  getFacilityDetail,
  getBookDetail,
  getAgeList,
  getNumberList,
  getSubjectList,

  // 지역 커뮤니티
  getCommunityList,
  getCommunityDetail,
  
  // 마이 페이지
  getBoardAll,
  boardWrite,
  boardDelete,
  getBoardKeyword,
  boardEdit,

  // Board-Extra API
  getAcademyList,
  getAcademyDetail,
  getShareList,
  getShareDetail,
  getEducationList,
  getEducationDetail,

  // Board-Main API
  getBoardCalendar,
  getCalendarList,

  // 메인 페이지
  getMainEducationShareList,
  getMainCommunityList,
  getMainEducationList,
  getMainBookList,
  getMainFacilityList,
};