import Styles from "./styles";

function BasicRadio({ id, labelText, name, checked, onChange }) {
  return (
    <>
      <Styles.Input
        id={id}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="radioBox"></span>

        <span className="labelText">{labelText}</span>
      </Styles.Label>
    </>
  );
}

export default BasicRadio;
