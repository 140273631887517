import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  width: 100%;

  & .inputContainer {
    display: flex;
    align-items: center;

    & label {
      flex-shrink: 0;
      width: 133px;
      font-size: 16px;
      font-weight: 500;

      &.essential span {
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          right: -9px;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          background: url(${IconEssential}) no-repeat center / cover;
          content: "";
        }
      }
    }

    & input {
      flex-grow: 1;
      height: 50px;
      padding: 0 20px;
      background-color: #f9fafc;
      border-radius: 2px;

      &::placeholder {
        color: #ced4df;
      }
    }

    & button {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 50px;
      margin-left: 10px;
      color: #fff;
      font-weight: 500;
      background-color: var(--black-color-01);
      border-radius: 2px;
    }
  }

  & .guideText,
  .errorText {
    width: calc(100% - 133px);
    margin: 5px 0 0 auto;
  }

  & .guideText {
    color: var(--main-color);
  }

  & .errorText {
    color: var(--red-color);
  }

  @media (max-width: 1600px) {
    & .inputContainer {
      & label {
        width: calc(100vw * (133 / 1600));
        font-size: calc(100vw * (16 / 1600));

        &.essential span {
          &::after {
            right: calc(100vw * (-9 / 1600));
            width: calc(100vw * (5 / 1600));
            height: calc(100vw * (5 / 1600));
          }
        }
      }

      & input {
        height: calc(100vw * (50 / 1600));
        padding: 0 calc(100vw * (20 / 1600));
        border-radius: calc(100vw * (2 / 1600));
      }

      & button {
        width: calc(100vw * (140 / 1600));
        height: calc(100vw * (50 / 1600));
        margin-left: calc(100vw * (10 / 1600));
        border-radius: calc(100vw * (2 / 1600));
      }
    }

    & .guideText,
    .errorText {
      width: calc(100% - calc(100vw * (133 / 1600)));
      margin: calc(100vw * (5 / 1600)) 0 0 auto;
    }
  }

  @media (max-width: 768px) {
    & .inputContainer {
      flex-direction: column;
      align-items: flex-start;

      & label {
        width: auto;
        margin-bottom: calc(100vw * (10 / 370));
        font-size: calc(100vw * (16 / 370));

        &.essential span {
          &::after {
            right: calc(100vw * (-8 / 370));
            width: calc(100vw * (5 / 370));
            height: calc(100vw * (5 / 370));
          }
        }
      }

      & input {
        width: 100%;
        height: calc(100vw * (50 / 370));
        padding: 0 calc(100vw * (15 / 370));
        border-radius: calc(100vw * (2 / 370));
      }

      & button {
        width: calc(100vw * (110 / 370));
        height: calc(100vw * (50 / 370));
        margin-left: calc(100vw * (10 / 370));
        border-radius: calc(100vw * (2 / 370));
      }
    }

    & .guideText,
    .errorText {
      width: 100%;
      margin: calc(100vw * (5 / 370)) 0 0;
    }
  }
`;

export { Container };

export default { Container };
