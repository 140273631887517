import Styles from "./styles";
import { ui } from "components";
import FaqList from "../FaqList";
import { useFaq } from "hooks";

function Faq() {
  const faqInfo = useFaq();

  return (
    <Styles.Container>
    {faqInfo.faqQuery?.data?.list?.length > 0 ? (
        <ul className="listWrap">
          {faqInfo.faqQuery.data.list.map((item, index) => {
            return (
              <li className="list" key={"list" + index}>
                <FaqList
                  data={item}
                  page={faqInfo.page}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="emptyText">등록된 게시글이 없습니다.</p>
      )}

      <div className="paginationContainer">
        <ui.button.Pagination
          page={faqInfo.page}
          setPage={faqInfo.setPage}
          list={faqInfo.faqQuery?.data?.pagination?.page_list}
          maxPage={faqInfo.faqQuery?.data?.pagination?.endPage}
        />
      </div>
    </Styles.Container>
  );
}

export default Faq;
