import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_white.svg";

const Container = styled.div`
  height: 440px;
  color: #fff;
  background: no-repeat center / cover;

  & .inner {
    padding-top: 30px;
  }

  & .stepListWrap {
    display: flex;
    align-items: center;
    gap: 25px;

    & .stepList {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 300;

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        right: -18px;
        transform: translateY(-50%);
        width: 11px;
        height: 11px;
        background: url(${IconArrowRight}) no-repeat center / cover;
        content: "";
      }

      & .iconWrap {
        width: 11px;
        height: 10px;
        margin-right: 7px;
      }
    }
  }

  & .title {
    margin-top: 96px;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (440 / 1600));

    & .inner {
      padding-top: calc(100vw * (30 / 1600));
    }

    & .stepListWrap {
      gap: calc(100vw * (25 / 1600));

      & .stepList {
        &:not(:last-child)::after {
          right: calc(100vw * (-18 / 1600));
          width: calc(100vw * (11 / 1600));
          height: calc(100vw * (11 / 1600));
        }

        & .iconWrap {
          width: calc(100vw * (11 / 1600));
          height: calc(100vw * (10 / 1600));
          margin-right: calc(100vw * (7 / 1600));
        }
      }
    }

    & .title {
      margin-top: calc(100vw * (96 / 1600));
      font-size: calc(100vw * (46 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (202 / 370));

    & .inner {
      padding-top: calc(100vw * (10 / 370));
    }

    & .stepListWrap {
      gap: calc(100vw * (21 / 370));

      & .stepList {
        &:not(:last-child)::after {
          right: calc(100vw * (-16 / 370));
          width: calc(100vw * (11 / 370));
          height: calc(100vw * (11 / 370));
        }

        & .iconWrap {
          width: calc(100vw * (11 / 370));
          height: calc(100vw * (10 / 370));
          margin-right: calc(100vw * (5 / 370));
        }
      }
    }

    & .title {
      margin-top: calc(100vw * (41 / 370));
      font-size: calc(100vw * (24 / 370));
    }
  }
`;

export { Container };

export default { Container };
