import { common, ui } from "components";
import Styles from "./styles";
import { useAuth } from "hooks";
import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";

// img
import IconUser from "resources/image/icon/icon_user02.svg";
import IconLock from "resources/image/icon/icon_lock.svg";
import IconGoogle from "resources/image/icon/icon_google.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";
import IconNaver from "resources/image/icon/icon_naver.svg";
import IconApple from "resources/image/icon/icon_apple.svg";

function LoginPage() {
  const authInfo = useAuth();
  
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStateCheck, setLoginStateCheck] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [pageInfo, setPageInfo] = useState();

  const validate = () => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) === false) return false;
    if (/^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{4,30}$/.test(password) === false) return false;
    // if (/^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{10,30}$/.test(password) === false) return false;
    return true;
  }
  const doLogin = () => {
    authInfo.login(loginStateCheck, password, email)
  }
  const pageUrl = async (loginType) => {
    const result = await authInfo.socialLogin(loginType);
    setPageInfo(result.data)
  }
  const snsLoginList = [
    {
      icon: IconGoogle,
      onClick: () => { pageUrl("GOOGLE") },
      text: "구글 로그인",
    },
    {
      icon: IconKakao,
      onClick: () => { pageUrl("KAKAO") },
      text: "카카오톡 로그인",
    },
    {
      icon: IconNaver,
      onClick: () => { pageUrl("NAVER") },
      text: "네이버 로그인",
    },
    // {
    //   icon: IconApple,
    //   onClick: () => { pageUrl("APPLE") },
    //   text: "애플 로그인",
    // },
  ];

  useEffect(() => {
    if (location.state != null) {
      setEmail(location?.state?.username)
      setPassword(location?.state?.password)
    }
  },[location])

  useEffect(()=>{
    pageInfo && window.location.replace(pageInfo);
  },[pageInfo])

  useEffect(() => {
    if (validate()) {
      setIsDisabled(false);
      authInfo.setErrorText("");
    }else{
      setIsDisabled(true);
    }
  }, [email, password]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="formContainer">
          <form>
            <div className="narrowInner">
              <h2 className="title">
                <span>에듀소스</span>에 오신 것을
                <br />
                환영합니다.
              </h2>

              <ul className="inputListWrap">
                <li className="inputList">
                  <ui.input.LoginInput
                    icon={IconUser}
                    value={email}
                    setValue={setEmail}
                    placeholder={"이메일을 입력하세요."}
                  />
                </li>

                <li className="inputList">
                  <ui.input.LoginInput
                    icon={IconLock}
                    type={"password"}
                    value={password}
                    setValue={setPassword}
                    placeholder={"비밀번호를 입력하세요."}
                    errorText={authInfo.errorText}
                  />
                </li>
              </ul>

              <div className="loginButton">
                <ui.button.BasicButton
                  buttonType={isDisabled ? "gray" : "basic"}
                  buttonText={"로그인"}
                  disabled={isDisabled}
                  onClick={doLogin}
                />
              </div>

              <div className="bottomContainer">
                <div className="loginStateCheck">
                  <ui.input.BasicCheckbox
                    id={"loginStateCheck"}
                    labelText={"로그인 상태 유지"}
                    checked={loginStateCheck}
                    onChange={(e) => {
                      setLoginStateCheck(!loginStateCheck);
                    }}
                  />
                </div>

                <Link to={"/find-password"} className="findPasswordButton">
                  비밀번호 찾기
                </Link>
              </div>

              <ul className="snsLoginListWrap">
                {snsLoginList.map((item, index) => {
                  return (
                    <li
                      className="snsLoginList"
                      key={"snsLoginList" + index}
                      onClick={item.onClick}
                    >
                      <div className="iconWrap">
                        <img src={item.icon} alt={item.icon} />
                      </div>
                      {item.text}
                    </li>
                  );
                })}
              </ul>

              <p className="text">아직 회원이 아니신가요?</p>

              <div className="signupButton">
                <ui.button.BasicButton
                  buttonText={"회원가입"}
                  buttonType={"black"}
                  onClick={() => {
                    navigate("/signup");
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default LoginPage;
