import Styles from "./styles";
import { common, ui } from "components";
import { useNavigate } from "react-router-dom";

// img
import ImgComplete from "resources/image/img_parents_confirm_complete.png";

function ParentsConfirmCompletePage() {
  const navigate = useNavigate();

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="narrowInner">
          <div className="textContainer">
            <h2 className="title">학부모 인증이 완료되었습니다.</h2>
            <p className="text">에듀소스의 다양한 서비스를 이용해보세요.</p>
          </div>

          <div className="imgWrap">
            <img src={ImgComplete} alt="ImgComplete" />
          </div>

          <div className="buttonContainer">
            <ui.button.BasicButton
              buttonType={"basic"}
              buttonText={"메인으로"}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ParentsConfirmCompletePage;
