import styled from "styled-components";

const Container = styled.div`
  & .commentListContainer {
    margin-top: 20px;

    & .emptyContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 186px;
      color: var(--gray-color-03);
      background-color: #f9fafc;
    }
  }

  @media (max-width: 1600px) {
    & .commentListContainer {
      margin-top: calc(100vw * (20 / 1600));

      & .emptyContainer {
        height: calc(100vw * (186 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    & .commentListContainer {
      margin-top: calc(100vw * (20 / 370));

      & .emptyContainer {
        height: calc(100vw * (134 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
