import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .narrowInner {
    padding-top: 80px;

    & .textContainer {
      margin-top: 50px;
      padding: 30px 0;
      text-align: center;
      background-color: #f9fafc;
      border-radius: 2px;

      & .title {
        margin-bottom: 6px;
        font-size: 22px;
        font-weight: bold;
      }
    }

    & .imgWrap {
      width: 226px;
      height: 198px;
      margin: 50px auto 80px;
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .narrowInner {
      padding-top: calc(100vw * (80 / 1600));

      & .textContainer {
        margin-top: calc(100vw * (50 / 1600));
        padding: calc(100vw * (30 / 1600)) 0;
        border-radius: calc(100vw * (2 / 1600));

        & .title {
          margin-bottom: calc(100vw * (6 / 1600));
          font-size: calc(100vw * (22 / 1600));
        }
      }

      & .imgWrap {
        width: calc(100vw * (226 / 1600));
        height: calc(100vw * (198 / 1600));
        margin: calc(100vw * (50 / 1600)) auto calc(100vw * (80 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 370));

      & .textContainer {
        margin-top: calc(100vw * (30 / 370));
        padding: calc(100vw * (32 / 370)) 0;
        border-radius: calc(100vw * (2 / 370));

        & .title {
          margin-bottom: calc(100vw * (6 / 370));
          font-size: calc(100vw * (18 / 370));
        }
      }

      & .imgWrap {
        width: calc(100vw * (181 / 370));
        height: calc(100vw * (160 / 370));
        margin: calc(100vw * (50 / 370)) auto calc(100vw * (80 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
