import { api } from "service";
import { useQuery } from "react-query";
import { useState } from "react";
import { useModals } from "hooks"
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

//img
import IconTrash from "resources/image/icon/icon_trash.svg";
import IconCheck from "resources/image/icon/icon_check.svg";

export default function useReply({
  replyOption,
}) {
  const modalOption = useModals();
  const replyFocus = useRef(null);
  
  const [searchParams] = useSearchParams();
  const [isDelete, setIsDelete] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [content, setContent] = useState("");
  const [replyInfoId, setReplyInfoId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [filter, setFilter] = useState({
    relate_info_id: parseInt(searchParams?.get("id")),
    type: 1
  });

  useEffect(()=>{
    setFilter(e => { return { ...e, relate_info_id: parseInt(searchParams?.get("id")) } })
  },[searchParams]);

  const getReply = useQuery(["get_reply", filter], () => {
    return api.reply.getReply(filter);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!replyOption
  });

  const postReply = async (type) => {
    const postData = new FormData();
    postData.append("content", content);
    postData.append("relate_info_id", searchParams?.get("id"));
    replyInfoId && postData.append("reply_info_id", replyInfoId)
    postData.append("type", type);

    let result = await api.reply.postReply(postData);
    if (result.success) {
      window.location.reload()
      return result;
    }
  }
  const deleteReply = async (replyId) => {
    setIsMoreOpen(false);
  
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.icon = IconTrash;
      e.title = "댓글을 삭제하시겠습니까?";
      e.text = "삭제하시면 복구가 불가능합니다.";
      e.confirmButtonText = "삭제하기";
      e.confirmButtonClick = async () => {
        const postData = new FormData();
        postData.append("id", replyId);
      
        let result = await api.reply.deleteReply(postData)
        if (result.success) {
          setIsDelete(true);
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.icon = IconCheck;
            e.title = "삭제가 완료되었습니다.";
            e.text = "댓글이 삭제되었습니다.";
            e.confirmButtonText = "확인";
            e.confirmButtonClick = false;
            e.cancelButtonText = false;
            return { ...e };
          });
        }
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }
  const editReply = async (contentId) => {
    const postData = new FormData();
    postData.append("content", content);
    postData.append("id", contentId);

    let result = await api.reply.editReply(postData);
    if (result.success) {
      window.location.reload()
      return result;
    }
  }

  return {
    filter,
    setFilter,
    
    getReply,
    postReply,
    deleteReply,
    editReply,

    replyFocus,

    isMoreOpen,
    setIsMoreOpen,

    isDelete,
    setIsDelete,
    isEdit,
    setIsEdit,
    
    content,
    setContent,
    replyInfoId,
    setReplyInfoId
  }
}