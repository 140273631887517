import Styles from "./styles";
import { ui } from "components";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// img
import IconQuestionOff from "resources/image/icon/icon_question_off.svg";
import IconQuestionOn from "resources/image/icon/icon_question_on.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_white.svg";
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom01.svg";

function FaqList({ data, page }) {
  const { title, description } = data;
  const [isOpen, setIsOpen] = useState(false);

  useEffect (() => {
    setIsOpen(false)
  },[page])

  return (
    <Styles.Container
      className={isOpen ? "on" : ""}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="questionContainer">
        <div className="questionIconWrap">
          <img
            src={isOpen ? IconQuestionOn : IconQuestionOff}
            alt="IconQuestion"
          />
        </div>

        <p className="question">{title}</p>

        <div className="arrowIconWrap">
          <img
            src={isOpen ? IconArrowTop : IconArrowBottom}
            alt="IconQuestion"
          />
        </div>
      </div>

      {isOpen && <p className="answer" dangerouslySetInnerHTML={{ __html: description }}></p>}
    </Styles.Container>
  );
}

export default FaqList;
