import { common } from "components";
import Styles from "./styles";
import { useAuth } from "hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// img
import IconGoogle from "resources/image/icon/icon_google02.svg";
import IconKakao from "resources/image/icon/icon_kakao02.svg";
import IconNaver from "resources/image/icon/icon_naver02.svg";
import IconApple from "resources/image/icon/icon_apple02.svg";
import IconEmail from "resources/image/icon/icon_email.svg";

function SignupPage() {
  const authInfo = useAuth();
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState();
  const pageUrl = async (loginType) => {
    const result = await authInfo.socialLogin(loginType);
    setPageInfo(result.data)
  }
  useEffect(()=>{
    sessionStorage.removeItem("signUp");
    pageInfo && window.location.replace(pageInfo);
  },[pageInfo])

  // map
  const buttonList = [
    {
      class: "google",
      icon: IconGoogle,
      onClick: () => { pageUrl("GOOGLE") },
      text: "구글 계정으로 가입하기"
    },
    {
      class: "kakao",
      icon: IconKakao,
      onClick: () => { pageUrl("KAKAO") },
      text: "카카오 계정으로 가입하기"
    },
    {
      class: "naver",
      icon: IconNaver,
      onClick: () => { pageUrl("NAVER") },
      text: "네이버 계정으로 가입하기"
    },
    // {
    //   class: "apple",
    //   icon: IconApple,
    //   onClick: () => { pageUrl("APPLE") },
    //   text: "애플 계정으로 가입하기"
    // },
    {
      class: "email",
      icon: IconEmail,
      onClick: () => {
        navigate("/signup/stepA", { state: true });
      },
      text: "이메일로 가입하기"
    }
  ];
  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="narrowInner">
          <h2 className="title">
            <span>에듀소스</span>에<br />
            처음 방문하셨나요?
          </h2>

          <ul className="listWrap">
            {buttonList?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <button
                    type="button"
                    className={item.class}
                    onClick={item.onClick}
                  >
                    <span className="iconWrap">
                      <img src={item.icon} alt={item.icon} />
                    </span>
                    {item.text}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SignupPage;
