import styled from "styled-components";

const Container = styled.div`
  padding: 27px;
  cursor: pointer;

  & .listTitle {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* & .listText {
    //* 동현 퍼블 수정된 코드
  } */

  & .listText {
    display: -webkit-box;
    margin-bottom: 20px;
    color: var(--gray-color-02);
    line-height: 1.57;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .listInfo {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--gray-color-02);

    & .nickname {
      position: relative;
      margin-right: 20px;

      &::after {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: var(--gray-color-02);
        content: "";
      }
    }

    & .time {
      margin-right: 10px;
    }

    & .reactionContainer {
      display: flex;
      align-items: center;

      & .like,
      .comment {
        display: flex;
        align-items: center;

        & .iconWrap {
          width: 18px;
          height: 18px;
          margin-right: 3px;
        }
      }

      & .like {
        margin-right: 15px;
      }
    }
  }

  &.big {
    padding: 50px 20px;

    & .listTitle {
      font-size: 22px;
    }

    & .listText {
      font-size: 14px;
    }
  }

  &.min {
    display: flex;
    flex-direction: column;
    height: 170px;

    & .listText {
      margin-bottom: 0;
    }

    & .listInfo {
      margin-top: auto;
    }
  }

  @media (max-width: 1600px) {
    padding: calc(100vw * (27 / 1600));

    & .listTitle {
      margin-bottom: calc(100vw * (8 / 1600));
      font-size: calc(100vw * (16 / 1600));
    }

    & .listText {
      margin-bottom: calc(100vw * (20 / 1600));
    }

    & .listInfo {
      & .nickname {
        margin-right: calc(100vw * (20 / 1600));

        &::after {
          right: calc(100vw * (-10 / 1600));
          height: calc(100vw * (12 / 1600));
        }
      }

      & .time {
        margin-right: calc(100vw * (10 / 1600));
      }

      & .reactionContainer {
        & .like,
        .comment {
          & .iconWrap {
            width: calc(100vw * (18 / 1600));
            height: calc(100vw * (18 / 1600));
            margin-right: calc(100vw * (3 / 1600));
          }
        }

        & .like {
          margin-right: calc(100vw * (15 / 1600));
        }
      }
    }

    &.big {
      padding: calc(100vw * (50 / 1600)) calc(100vw * (20 / 1600));

      & .listTitle {
        font-size: calc(100vw * (22 / 1600));
      }

      & .listText {
        font-size: calc(100vw * (14 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (20 / 370));

    & .listTitle {
      margin-bottom: calc(100vw * (10 / 370));
      font-size: calc(100vw * (16 / 370));
    }

    & .listText {
      margin-bottom: calc(100vw * (16 / 370));
    }

    & .listInfo {
      flex-wrap: wrap;
      gap: calc(100vw * (8 / 370)) 0;
      font-size: calc(100vw * (12 / 370));

      & .nickname {
        margin-right: calc(100vw * (20 / 370));

        &::after {
          right: calc(100vw * (-10 / 370));
          height: calc(100vw * (12 / 370));
        }
      }

      & .time {
        margin-right: calc(100vw * (10 / 370));
      }

      & .reactionContainer {
        & .like,
        .comment {
          & .iconWrap {
            width: calc(100vw * (18 / 370));
            height: calc(100vw * (18 / 370));
            margin-right: calc(100vw * (3 / 370));
          }
        }

        & .like {
          margin-right: calc(100vw * (10 / 370));
        }
      }
    }

    &.big {
      padding: calc(100vw * (30 / 370)) 0;

      & .listTitle {
        font-size: calc(100vw * (16 / 370));
      }

      & .listText {
        font-size: calc(100vw * (14 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
