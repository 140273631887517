import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 49px;
  width: 100%;

  & .calendarWrap {
    flex-shrink: 0;
    width: 612px;
    padding: 38px 77px 50px;
    background-color: #f9fafc;
    border-radius: 20px;

    & .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 7px;

      & .calendarTitle {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  & .educationInfoContainer {
    width: 657px;

    & .moreButton {
      width: fit-content;
      margin-left: auto;
    }

    & .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      & .infoTitle {
        font-size: 32px;
        font-weight: bold;
      }

      & .today {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: var(--main-color);

        & .todayNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 21px;
          height: 21px;
          margin-left: 6px;
          font-size: 14px;
          color: #fff;
          line-height: 1.2;
          background-color: var(--main-color);
          border-radius: 50%;
        }
      }
    }

    & .listContainer {
      height: 415px;

      & .os-scrollbar {
        padding: 0;
      }

      & .os-scrollbar-handle {
        width: 3px;
        background-color: var(--black-color-01);
      }

      & .listWrap {
        padding-right: 10px;
      }
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (49 / 1600));

    & .calendarWrap {
      width: calc(100vw * (612 / 1600));
      padding: calc(100vw * (38 / 1600)) calc(100vw * (77 / 1600))
        calc(100vw * (50 / 1600));
      border-radius: calc(100vw * (20 / 1600));

      & .titleContainer {
        margin-bottom: calc(100vw * (7 / 1600));

        & .calendarTitle {
          font-size: calc(100vw * (20 / 1600));
        }
      }
    }

    & .educationInfoContainer {
      width: calc(100vw * (657 / 1600));

      & .titleContainer {
        margin-bottom: calc(100vw * (12 / 1600));

        & .infoTitle {
          font-size: calc(100vw * (32 / 1600));
        }

        & .today {
          font-size: calc(100vw * (20 / 1600));

          & .todayNumber {
            width: calc(100vw * (21 / 1600));
            height: calc(100vw * (21 / 1600));
            margin-left: calc(100vw * (6 / 1600));
            font-size: calc(100vw * (14 / 1600));
          }
        }
      }

      & .listContainer {
        height: calc(100vw * (415 / 1600));

        & .os-scrollbar-handle {
          width: calc(100vw * (3 / 1600));
        }

        & .listWrap {
          padding-right: calc(100vw * (10 / 1600));
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: calc(100vw * (30 / 370));

    & .calendarWrap {
      width: calc(100vw * (290 / 370));
      padding: calc(100vw * (28 / 370)) calc(100vw * (22 / 370));
      border-radius: calc(100vw * (10 / 370));

      & .titleContainer {
        margin-bottom: calc(100vw * (8 / 370));

        & .calendarTitle {
          font-size: calc(100vw * (14 / 370));
        }
      }
    }

    & .educationInfoContainer {
      width: 100%;

      & .titleContainer {
        margin-bottom: calc(100vw * (20 / 370));

        & .infoTitle {
          font-size: calc(100vw * (20 / 370));
        }

        & .today {
          font-size: calc(100vw * (16 / 370));

          & .todayNumber {
            width: calc(100vw * (21 / 370));
            height: calc(100vw * (21 / 370));
            margin-left: calc(100vw * (5 / 370));
            font-size: calc(100vw * (14 / 370));
          }
        }
      }

      & .listContainer {
        height: auto;
        max-height: calc(100vw * (540 / 370));

        & .os-scrollbar-handle {
          width: calc(100vw * (3 / 370));
        }

        & .listWrap {
          padding-right: calc(100vw * (8 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
