import styled from "styled-components";

const Container = styled.div`
  border-radius: 50%;
  overflow: hidden;
`;

export { Container };

export default { Container };
