import utils from "utils";
import Styles from "./styles";
import ContentsList from "../ContentsList";
import { ui } from "components";
import { useBoard, useLike } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function LikeList() {
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState();

  const likeInfo = useLike();
  const boardInfo = useBoard({
    boardAllOption: filter
  });

  useEffect(()=>{
    let param = utils.etc.getSearchParamNew();
    let f = {
      page: param.page ? param.page : 1,
      like_yn: true,
      recordSize: 10,
    }
    setFilter({...f});
  },[location])

  const likeList = boardInfo.getBoardAll?.data || []

  return (
    <Styles.Container>
      <div className="topContainer">
        <p className="listNumber">
          <span>총 {likeList?.pagination?.totalRecordCountAll}개</span>의 게시글이 있습니다.
        </p>

        <button
          type="button"
          className="allDeleteButton"
          onClick={() => {
            likeInfo.allDeleteLike(likeList?.list)
          }}
        >
          전체 삭제
        </button>
      </div>

      {likeList?.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {likeList.list.map((item, index) => {
              return (
                <li
                  className="list"
                  key={"list" + index}
                  onClick={() => {
                    navigate(boardInfo.boardLocation(item.type) + "?id=" + item.id, { state: "/mypage/like-list" })
                  }}
                >
                  <ContentsList
                    data={item}
                    boardInfo={boardInfo}
                    likeInfo={likeInfo}
                  />
                </li>
              );
            })}
          </ul>
          <ui.button.PaginationNew
            page={filter?.page}
            list={likeList.pagination?.page_list}
            maxPage={likeList.pagination?.endPage}
          />
        </>
      ) : (
        <div className="emptyContainer">게시글이 없습니다.</div>
      )}
    </Styles.Container>
  );
}

export default LikeList;
