import Styles from "./styles";
import ui from "components/ui";
import utils from "utils";
import UserProfileImg from "../UserProfileImg";
import { useModals, useReply, useAuth } from "hooks";

// img
import IconLikeOff from "resources/image/icon/icon_like.svg";
import IconLikeOn from "resources/image/icon/icon_like_on02.svg";
import IconComment from "resources/image/icon/icon_comment.svg";
import IconMore from "resources/image/icon/icon_more.svg";

function CommentList({ data, reply, onFocus, doLike }) {
  const authInfo = useAuth();
  const modalOption = useModals();
  const replyInfo = useReply({ replyOption: true });

  const onContentEdit = () => {
    if ( replyInfo.content.trim() !== "" ){
      replyInfo.editReply(data.id);
    }
  }
  
  return (
    <Styles.Container className={reply ? "reply" : ""}>
      {replyInfo.isDelete ? <p className="stateText">삭제되었습니다.</p>
      : data?.delete_yn === "Y" ? <p className="stateText">삭제된 댓글입니다.</p>
      : data?.report_yn === "Y" ? <p className="stateText">신고된 댓글입니다.</p>
      : (
        <>
          <div className="moreButtonContainer">
            <button
              type="button"
              className="moreButton"
              onClick={() => {
                replyInfo.setIsMoreOpen(!replyInfo.isMoreOpen);
              }}
            >
              <img src={IconMore} alt="IconMore" />
            </button>

            {replyInfo.isMoreOpen && (
              <ul className="moreListWrap">
                {data.my_yn === "Y" ? (
                  <>
                    <li className="moreList">
                      <button
                        type="button"
                        onClick={() => {
                          replyInfo.setIsMoreOpen(false);
                          if (authInfo.certification()) {
                            replyInfo.setIsEdit(!replyInfo.isEdit);
                            replyInfo.setContent(data.content)
                          }
                        }}
                      >
                        수정
                      </button>
                    </li>
                    <li className="moreList">
                      <button
                        type="button"
                        onClick={() => {
                          replyInfo.setIsMoreOpen(false);
                          if (authInfo.certification()) {
                            replyInfo.deleteReply(data.id)
                          }
                        }}
                      >
                        삭제
                      </button>
                    </li>
                  </>
                ) : (
                  <li className="moreList">
                    <button
                      type="button"
                      onClick={() => {
                        replyInfo.setIsMoreOpen(false);
                        if (authInfo.certification()) {
                          modalOption.setReportModal((e) => {
                            e.show = true;
                            e.contents = true;
                            e.id = data.id;
                            return { ...e };
                          });
                        }
                      }}
                    >
                      신고
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="infoContainer">
            <UserProfileImg profileImg={data.image} />

            <p className="nickname">{data.nickname}</p>

            {data.write_yn === "Y" && <p className="writer">글쓴이</p>}

            <p className="date">{utils.date.getDateType(data.created_at)}</p>
          </div>
          
          {replyInfo.isEdit ? (
            <div className="commentWrap">
              <ui.input.CommentInput
                replyInfo={replyInfo}
                onContentEdit={onContentEdit}
              />
            </div>
          ) : (
            <>
              <p className="comment">{data.content}</p>

              <ul className="reactionListWrap">
                <li className="reactionList">
                  <button
                    type="button"
                    className={"likeButton" + (data.like_yn === "Y" ? " on" : "")}
                    onClick={doLike}
                  >
                    <span className="iconWrap">
                      <img
                        src={data.like_yn === "Y" ? IconLikeOn : IconLikeOff}
                        alt="IconLike"
                      />
                    </span>
                    {data.like_count}
                  </button>
                </li>

                {!reply && (
                  <li className="reactionList">
                    <button
                      type="button"
                      onClick={() => { onFocus(data.id) }}
                    >
                      <span className="iconWrap">
                        <img src={IconComment} alt="IconComment" />
                      </span>
                      답글달기
                    </button>
                  </li>
                )}
              </ul>
            </>
          )}
        </>
      )}
    </Styles.Container>
  );
}

export default CommentList;
