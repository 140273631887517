import styled from "styled-components";

const Container = styled.div`
  & .inputWrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    background-color: #f9fafc;
    border-radius: 2px;

    & .iconWrap {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      padding-right: 15px;
      box-sizing: content-box;
      border-right: 1px solid #ced4df;
    }

    & input {
      flex-grow: 1;
      height: 100%;

      &::placeholder {
        color: #ced4df;
      }
    }
  }

  & .guideText,
  .errorText {
    margin-top: 5px;
  }

  & .guideText {
    color: var(--main-color);
  }

  & .errorText {
    color: var(--red-color);
  }

  @media (max-width: 1600px) {
    & .inputWrap {
      height: calc(100vw * (52 / 1600));
      padding: 0 calc(100vw * (20 / 1600));
      border-radius: calc(100vw * (2 / 1600));

      & .iconWrap {
        width: calc(100vw * (20 / 1600));
        height: calc(100vw * (20 / 1600));
        margin-right: calc(100vw * (15 / 1600));
        padding-right: calc(100vw * (15 / 1600));
      }
    }

    & .guideText,
    .errorText {
      margin-top: calc(100vw * (5 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .inputWrap {
      height: calc(100vw * (50 / 370));
      padding: 0 calc(100vw * (20 / 370));
      border-radius: calc(100vw * (2 / 370));

      & .iconWrap {
        width: calc(100vw * (20 / 370));
        height: calc(100vw * (20 / 370));
        margin-right: calc(100vw * (15 / 370));
        padding-right: calc(100vw * (15 / 370));
      }
    }

    & .guideText,
    .errorText {
      margin-top: calc(100vw * (5 / 370));
    }
  }
`;

export { Container };

export default { Container };
