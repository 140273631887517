import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .filterContainer {
    display: flex;
    align-items: center;
    height: 140px;
    margin: -70px auto 50px;
    padding: 0 40px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 6px 25px 0px rgba(27, 50, 78, 0.15);

    & .selectListWrap {
      display: flex;
      margin-right: 12px;

      & .selectList {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 20px;
        }

        & .iconWrap {
          width: auto;
          height: 16px;
          margin-right: 4px;
        }

        & .selectText {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 500;
        }

        & .seletWrap {
          width: 162px;
        }
      }
    }

    & .buttonContainer {
      display: flex;
      align-items: center;

      & .searchButton {
        width: 100px;
        margin-right: 15px;

        & button {
          height: 52px;
        }
      }

      & .resetButton {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--gray-color-02);

        & .iconWrap {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
  }

  & .listWrap {
    border-top: 1px solid var(--gray-color-04);

    & .list {
      border-bottom: 1px solid var(--gray-color-04);
      cursor: pointer;
    }
  }

  & .paginationContainer {
    margin-top: 50px;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .filterContainer {
      height: calc(100vw * (140 / 1600));
      margin: calc(100vw * (-70 / 1600)) auto calc(100vw * (50 / 1600));
      padding: 0 calc(100vw * (40 / 1600));
      border-radius: calc(100vw * (10 / 1600));
      box-shadow: 0px calc(100vw * (6 / 1600)) calc(100vw * (25 / 1600)) 0px
        rgba(27, 50, 78, 0.15);

      & .selectListWrap {
        margin-right: calc(100vw * (12 / 1600));

        & .selectList {
          &:not(:last-child) {
            margin-right: calc(100vw * (20 / 1600));
          }

          & .iconWrap {
            height: calc(100vw * (16 / 1600));
            margin-right: calc(100vw * (4 / 1600));
          }

          & .selectText {
            margin-right: calc(100vw * (10 / 1600));
            font-size: calc(100vw * (16 / 1600));
          }

          & .seletWrap {
            width: calc(100vw * (162 / 1600));
          }
        }
      }

      & .buttonContainer {
        & .searchButton {
          width: calc(100vw * (100 / 1600));
          margin-right: calc(100vw * (15 / 1600));

          & button {
            height: calc(100vw * (52 / 1600));
          }
        }

        & .resetButton {
          & .iconWrap {
            width: calc(100vw * (24 / 1600));
            height: calc(100vw * (24 / 1600));
            margin-left: calc(100vw * (5 / 1600));
          }
        }
      }
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .filterContainer {
      flex-direction: column;
      align-items: flex-end;
      height: auto;
      margin: calc(100vw * (-30 / 370)) auto calc(100vw * (30 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (31 / 370));
      border-radius: calc(100vw * (5 / 370));
      box-shadow: 0px calc(100vw * (6 / 370)) calc(100vw * (25 / 370)) 0px
        rgba(27, 50, 78, 0.15);

      & .selectListWrap {
        flex-direction: column;
        width: 100%;
        margin: 0 0 calc(100vw * (20 / 370));

        & .selectList {
          &:not(:last-child) {
            margin: 0 0 calc(100vw * (14 / 370));
          }

          & .iconWrap {
            height: calc(100vw * (16 / 370));
            margin-right: calc(100vw * (5 / 370));
          }

          & .selectText {
            margin-right: 0;
            font-size: calc(100vw * (14 / 370));
          }

          & .seletWrap {
            width: calc(100vw * (162 / 370));
            margin-left: auto;
          }
        }
      }

      & .buttonContainer {
        & .searchButton {
          width: calc(100vw * (92 / 370));
          margin-right: calc(100vw * (30 / 370));

          & button {
            height: calc(100vw * (52 / 370));
          }
        }

        & .resetButton {
          & .iconWrap {
            width: calc(100vw * (16 / 370));
            height: calc(100vw * (16 / 370));
            margin-left: calc(100vw * (4 / 370));
          }
        }
      }
    }

    & .middleInner {
      width: calc(100vw * (290 / 370));

      & .listWrap {
        border-top: 0;
      }
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 370));
    }
  }
`;

export { Container };

export default { Container };
