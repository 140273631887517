import Styles from "./styles";
import { useMediaQuery } from "react-responsive";

// img
import IconHome from "resources/image/icon/icon_home.svg";

function TopBanner({ data }) {
  const { img, mbImg, prevText, nowText, title } = data;

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Styles.Container
      style={{ backgroundImage: `url(${isMobile ? mbImg : img})` }}
    >
      <div className="inner">
        <ul className="stepListWrap">
          <li className="stepList">
            <div className="iconWrap">
              <img src={IconHome} alt="IconHome" />
            </div>
            홈
          </li>

          {prevText && <li className="stepList">{prevText}</li>}

          <li className="stepList">{nowText}</li>
        </ul>

        <h2 className="title">{title}</h2>
      </div>
    </Styles.Container>
  );
}

export default TopBanner;
