import Styles from "./styles";
import ContentsList from "../ContentsList";
import { ui } from "components";
import { useBoard } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import utils from "utils";

function MyList() {
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState();

  const boardInfo = useBoard({
    boardAllOption: filter
  });

  useEffect(()=>{
    let param = utils.etc.getSearchParamNew();
    let f = {
      page: param.page ? param.page : 1,
      my_yn: true,
      recordSize: 10,
    }
    setFilter({...f});
  },[location])

  const myList = boardInfo.getBoardAll?.data || []

  return (
    <Styles.Container>
      <div className="topContainer">
        <p className="listNumber">
          <span>총 {myList?.pagination?.totalRecordCountAll}개</span>의 게시글이 있습니다.
        </p>

        <button
          type="button"
          className="allDeleteButton"
          onClick={() => {
            boardInfo.allDeleteBoard(myList?.list);
          }}
        >
          전체 삭제
        </button>
      </div>

      {myList?.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {myList.list.map((item, index) => {
              return (
                <li
                  className="list"
                  key={"list" + index}
                  onClick={() => {
                    navigate(boardInfo.boardLocation(item.type) + "?id=" + item.id, { state: "/mypage/my-list" });
                  }}
                >
                  <ContentsList
                    data={item}
                    boardInfo={boardInfo}
                    myBoard={true}
                  />
                </li>
              );
            })}
          </ul>
          <ui.button.PaginationNew
            page={filter?.page}
            list={myList.pagination?.page_list}
            maxPage={myList.pagination?.endPage}
          />
        </>
      ) : (
        <div className="emptyContainer">작성한 게시글이 없습니다.</div>
      )}
    </Styles.Container>
  );
}

export default MyList;
