import React, { useEffect } from "react";
import Pages from "pages";
import AuthRoute from "routes/AuthRoute";
import { useFooter } from "hooks";
import { Route, Routes, useLocation } from "react-router-dom";

import {
  EditInfo,
  LikeList,
  ManagementInfo,
  MyList,
  AskList,
  AskDetail,
} from "pages/MyPage/components";

export default function CustomRoute() {
  const location = useLocation();
  const footerInfo = useFooter({});

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }, [location]);

  useEffect(()=>{
    footerInfo.ipCheck()
  },[])

  return (
    <Routes>
      {/* 매안 */}
      <Route path="/" element={<Pages.MainPage />} />
      <Route path="/search" element={<Pages.SearchPage />} />
      <Route path="/parents-confirm" element={<AuthRoute><Pages.ParentsConfirmPage /></AuthRoute>} />
      <Route
        path="/parents-confirm/complete"
        element={<AuthRoute><Pages.ParentsConfirmCompletePage /></AuthRoute>}
      />
      {/* 로그인 */}
      <Route path="/login" element={<Pages.LoginPage />} />
      <Route path="/find-password" element={<Pages.FindPasswordPage />} />
      {/* 회원가입 */}
      <Route path="/signup" element={<Pages.SignupPage />} />
      <Route path="/:id/stepA" element={<Pages.SignupStepAPage />} />
      <Route path="/:id/stepB" element={<Pages.SignupStepBPage />} />
      <Route path="/:id/complete" element={<Pages.SignupCompletePage />} />
      {/* 에듀소스 */}
      <Route path="/edusource" element={<Pages.EdusourcePage />} />
      {/* 고객지원 */}
      <Route
        path="/customer-support/:id"
        element={<Pages.CustomerSupportPage />}
      />
      <Route
        path="/customer-support/notice/detail"
        element={<Pages.NoticeDetailPage />}
      />
      {/* 이용약관 */}
      <Route path="/terms/:id" element={<Pages.TermsPage />} />
      {/* 교육정보 */}
      <Route path="/education-info" element={<Pages.EducationInfoPage />} />
      <Route
        path="/education-info/month"
        element={<AuthRoute><Pages.ThisMonthInfoPage /></AuthRoute>}
      />
      <Route
        path="/education-info/academy"
        element={<AuthRoute><Pages.AcademyInfoPage /></AuthRoute>}
      />
      <Route path="/education-info/share" element={<Pages.ShareInfoPage />} />
      {/* 블로그 */}
      <Route path="/blog" element={<Pages.BlogPage />} />
      <Route path="/blog/detail" element={<AuthRoute><Pages.BlogDetailPage /></AuthRoute>} /> 
      {/* 지역 커뮤니티 */}
      <Route path="/community" element={<Pages.CommunityPage />} />
      <Route path="/community/detail" element={<AuthRoute><Pages.CommunityDetailPage /></AuthRoute>} />
      {/* 시설 정보 */}
      <Route path="/facility-info" element={<Pages.FacilityInfoPage />} />
      <Route
        path="/facility-info/:id"
        element={<Pages.FacilityInfoListPage />}
      />
      <Route
        path="/facility-info/:id/detail"
        element={<Pages.FacilityInfoDetailPage />}
      />
      {/* 글쓰기 */}
      <Route path="/writing/:id" element={<AuthRoute><Pages.WritingPage /></AuthRoute>} />
      <Route path="/edit/:id" element={<AuthRoute><Pages.EditPage /></AuthRoute>} />
      {/* 마이페이지 */}
      <Route path="/mypage" element={<AuthRoute><Pages.MyPage /></AuthRoute>}>
        <Route path="info" element={<ManagementInfo />} />
        <Route path="info/edit" element={<EditInfo />} />
        <Route path="like-list" element={<LikeList />} />
        <Route path="my-list" element={<MyList />} />
        <Route path="ask" element={<AskList />} />
        <Route path="ask-detail" element={<AskDetail />} />
      </Route>
      <Route path="/change-password" element={<Pages.ChangePasswordPage />} />
      <Route
        path="/change-password/complete"
        element={<Pages.ChangePasswordCompletePage />}
      />
    </Routes>
  );
}
