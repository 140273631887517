import Styles from "./styles";

function GenderRadio({ id, name, labelText, checked, onChange }) {
  return (
    <>
      <Styles.Input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>{labelText}</Styles.Label>
    </>
  );
}

export { GenderRadio };

export default GenderRadio;
