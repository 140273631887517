import Styles from "./styles";

export default function BasicButton({
  buttonText,
  buttonType,
  icon,
  size,
  onClick,
  disabled,
}) {
  return (
    <Styles.Container
      type="button"
      disabled={disabled}
      onClick={onClick}
      size={size}
      buttonType={buttonType}
    >
      {icon && (
        <span className="iconWrap">
          <img src={icon} alt={icon} />
        </span>
      )}
      {buttonText}
    </Styles.Container>
  );
}
