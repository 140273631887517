import { common } from "components";
import Styles from "./styles";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import utils from "utils";

function MyPage() {
  const [tabIndex, setTabIndex] = useState(utils.url.getCurrentUrl()[2]);

  const renderPageName = (type) => {
    switch (type) {
      case "info":
      case "edit":
        return "회원정보 관리";
      case "like-list":
        return "공감 게시글";
      case "my-list":
        return "내 게시글";
      case "ask":
      case "ask-detail":
        return "문의 내역";
    }
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="middleInner">
          <common.SideMenu
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />

          <section className="contentsSection">
            <h2 className="sectionTitle">
              {renderPageName(utils.url.getCurrentUrl()[2])}
            </h2>

            <Outlet />
          </section>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default MyPage;
