import useModals from "../useModals"
import { api } from "service";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import utils from "utils";

//img
import IconCheck from "resources/image/icon/icon_check.svg";

export default function useInquiry({
  options = {
    askList: false,
    askDetail: false
  }
}) {
  const location = useLocation();
  const params = utils.etc.getSearchParam();
  const modalOption = useModals();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  useEffect(()=>{
    setPage(params.has("page") ? params.get("page") : 1);
  },[location])

  const getAskList = useQuery(["ask_list",page], () => {
    window.history.pushState("", "ask_page_url", "/mypage/ask?page=" + page)
    return api.support.getAsk(page);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.askList
  });
  const getAskDetail = useQuery(["ask_detail",searchParams?.get("id")], () => {
    return api.support.getAskDetail(searchParams?.get("id"));
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.askDetail
  });

  const onCreateAsk = async (postData, blockClicks, removeInquiryData) => {
    let result = await api.support.postAsk(postData);
    if (result.success == true) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconCheck;
        e.title = "문의가 완료되었습니다.";
        e.text = "답변은 영업일 기준 2~3일 정도 소요됩니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {};
        e.cancelButtonText = false;

        removeInquiryData()
        return { ...e };
      })
    } else {
      blockClicks = false
      console.log("전송 실패")
    }
  }

  return {
    value: getAskList.data ? getAskList.data : {},
    detail: getAskDetail.data ? getAskDetail.data : [],
    onCreateAsk,

    page,
    setPage
  }
}