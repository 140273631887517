import ui from "components/ui";
import Styles from "./styles";

// img
import IconMinus from "resources/image/icon/icon_minus.svg"
import IconPlus from "resources/image/icon/icon_plus02.svg"

function ChildrenInfoForm({ index, changeValue, item, plusClick, minusClick }) {

  const genderList = ["M", "G"];

  return (
    <Styles.Container>
      <div className="topContainer">
        <p className="listTitle">자녀 정보 {index}</p>

        <div className="buttonContainer">
          <button type="button" className="minusButton" onClick={minusClick}>
            <img src={IconMinus} alt="IconMinus" />
          </button>

          <button type="button" className="plusButton" onClick={plusClick}>
            <img src={IconPlus} alt="IconPlus" />
          </button>
        </div>
      </div>

      <ul className="inputListWrap">
        <li className="inputList gender">
          <p className="inputTitle">
            <span>성별</span>
          </p>

          <ul className="radioListWrap">
            {genderList?.map((genderItem, idx) => {
              return (
                <li className="radioList" key={"radioList" + idx}>
                  <ui.input.GenderRadio
                    name={index}
                    labelText={genderItem === "M" ? "남" : "여"}
                    id={index + genderItem}
                    onChange={() => {
                      changeValue("gender", genderItem)
                    }}
                    checked={item.gender == genderItem}
                  />
                </li>
              );
            })}
          </ul>
        </li>

        <li className="inputList">
          <ui.input.BasicInput
            labelText={"현재 학교명"}
            essential
            placeholder={"자녀의 현재 학교명을 입력해주세요."}
            value={item.current_school}
            setValue={(e) => { changeValue("current_school", e) }}
          />
        </li>

        <li className="inputList">
          <ui.input.BasicInput
            labelText={"희망 학교명"}
            essential
            placeholder={"자녀의 희망 학교명을 입력해주세요."}
            value={item.target_school}
            setValue={(e) => { changeValue("target_school", e) }}
          />
        </li>

        <li className="inputList">
          <ui.input.BasicInput
            labelText={"현재 학원명"}
            essential
            placeholder={"자녀의 현재 학원명을 입력해주세요."}
            value={item.current_academy}
            setValue={(e) => { changeValue("current_academy", e) }}
          />
        </li>

        <li className="inputList">
          <ui.input.BasicInput
            labelText={"희망 학원명"}
            essential
            placeholder={"자녀의 희망 학원명을 입력해주세요."}
            value={item.target_academy}
            setValue={(e) => { changeValue("target_academy", e) }}
          />
        </li>
      </ul>
    </Styles.Container>
  );
}

export default ChildrenInfoForm;
