import Styles from "./styles";
import { useBoard } from "hooks";
import { IMAGEPATH } from "service/connector";
import { common, layout, ui } from "components";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// img
import ImgFacilityInfo from "resources/image/img_facility_info.png";
import ImgFacilityInfoMb from "resources/image/img_facility_info_mb.png";
import ImgFacilityDefault from "resources/image/img_facility_default.png";

function FacilityInfoDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const boardInfo = useBoard({ options: {
    facilityDetail: id === "facility",
    bookDetail: id === "reading-room"
  } });

  const facilityType = (type) => {
    switch (type) {
      case "reading-room": return boardInfo.bookDetail;
      case "facility": return boardInfo.facilityDetail;
    }
  }
  
  const data = {
    img: ImgFacilityInfo,
    mbImg: ImgFacilityInfoMb,
    prevText: "시설 정보",
    nowText:
      (id === "facility" && "시설 정보") ||
      (id === "reading-room" && "독서실 정보"),
    title: "시설 정보",
  };

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.TopBanner data={data} />

        <div className="inner">
          <div className="middleInner">
            <section className="detailSection">
              <layout.DetailContainer data={facilityType(id)} facility>
                <div className="topContainer">
                  <div className="imgWrap">
                    <img
                      src={facilityType(id).image ? IMAGEPATH + facilityType(id).image : ImgFacilityDefault}
                      alt="failityImg"
                    />
                  </div>

                  <div className="blogTitleContainer">
                    <h2 className="title">{facilityType(id).title || "-"}</h2>
                    <div className="detailInfo">
                      <span>{facilityType(id).location || "-"}</span>
                      <span>{facilityType(id).age || "-"}</span>
                      <span>{facilityType(id).main_subject || "-"}</span>
                      <span>{facilityType(id).member || "-"}</span>
                    </div>
                  </div>
                </div>
              </layout.DetailContainer>
            </section>
          </div>
        </div>

        <div className="anotherListContainer middleInner">
          <layout.AnotherContents data={facilityType(id)} />
        </div>

        <div className="goListButton">
          <ui.button.BasicButton
            buttonText={"목록으로"}
            buttonType={"blackOutline"}
            size={"small"}
            onClick={() => {
              location.state
                ? navigate(location.state)
                : navigate("..", { relative: "path" });
            }}
          />
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default FacilityInfoDetailPage;
