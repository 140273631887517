import alarm from "./alarm";
import auth from "./auth";
import banner from "./banner";
import board from "./board";
import category from "./category";
import children from "./children";
import footer from "./footer";
import keyword from "./keyword";
import like from "./like";
import product from "./product";
import reply from "./reply";
import report from "./report";
import support from "./support";
import facility from "./facility";
import image from "./image";

export {
  alarm,
  auth,
  banner,
  board,
  category,
  children,
  footer,
  keyword,
  like,
  product,
  reply,
  report,
  support,
  facility,
  image,
}

export default {
  alarm,
  auth,
  banner,
  board,
  category,
  children,
  footer,
  keyword,
  like,
  product,
  reply,
  report,
  support,
  facility,
  image,
}