import connector from "service/connector"

const getSelectList = async () => {
  return await connector.connectFetchController("location/list", "GET");
}
const getSelectDetail = async (data) => {
  return await connector.connectFetchController("location/detail/list?parent_location=" + data, "GET");
}

export {
  getSelectList,
  getSelectDetail,
}
export default {
  getSelectList,
  getSelectDetail,
}