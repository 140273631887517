import connector from "service/connector"

const visitCount = async () => {
  return await connector.connectFetchController("visit/count", "GET");
};
const getTerms = async (path) => {
  return await connector.connectFetchController("terms?type=" + path, "GET");
};
const ipCheck = async () => {
  return await connector.connectFetchController("ip/check", "POST");
};

export { visitCount, getTerms, ipCheck }
export default { visitCount, getTerms, ipCheck }