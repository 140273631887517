import { atom } from "jotai";

const modalFlagAtom = atom(false);
const defaultModalAtom = atom({ show: false, zIndex: 20 });
const copyModalAtom = atom({ show: false, zIndex: 20 });
const deleteModalAtom = atom({ show: false, zIndex: 20 });
const bellModalAtom = atom({ show: false, zIndex: 20 });
const reportModalAtom = atom({ show: false, zIndex: 20 });

export {
  modalFlagAtom,
  defaultModalAtom,
  copyModalAtom,
  deleteModalAtom,
  bellModalAtom,
  reportModalAtom,
};

export default {
  modalFlagAtom,
  defaultModalAtom,
  copyModalAtom,
  deleteModalAtom,
  bellModalAtom,
  reportModalAtom,
};
