import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom01.svg";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & > .inner {
    margin-top: -80px;
    padding: 80px 63px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }

  & .listSection {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--gray-color-04);

    & .listContainer {
      & .title {
        font-size: 36px;
        font-weight: bold;
      }

      & .list {
        border-bottom: 1px solid #f0f2f4;
      }

      &.key {
        width: 647px;

        & .titleContainer {
          display: flex;
          align-items: center;
          gap: 30px;
          margin-bottom: 30px;

          & .react-datepicker__input-container input {
            width: 128px;
            font-size: 18px;
            font-weight: bold;
            background: url(${IconArrowBottom}) no-repeat center right / 24px;
          }
        }

        & .listWrap {
          margin-bottom: 50px;
          cursor: pointer;
        }
      }

      &.share {
        width: 653px;

        & .title {
          margin-bottom: 14px;
        }

        & .listWrap {
          margin-bottom: 56px;

          & .list > div {
            padding: 16px 0;

            & .listText {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  & .goListButton {
    width: 250px;
    margin: 50px auto 0;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & > .inner {
      margin-top: calc(100vw * (-80 / 1600));
      padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
      border-top-left-radius: calc(100vw * (10 / 1600));
      border-top-right-radius: calc(100vw * (10 / 1600));
    }

    & .listSection {
      margin-top: calc(100vw * (80 / 1600));
      padding-bottom: calc(100vw * (50 / 1600));

      & .listContainer {
        & .title {
          font-size: calc(100vw * (36 / 1600));
        }

        &.key {
          width: calc(100vw * (647 / 1600));

          & .titleContainer {
            gap: calc(100vw * (30 / 1600));
            margin-bottom: calc(100vw * (30 / 1600));

            & .react-datepicker__input-container input {
              width: calc(100vw * (128 / 1600));
              font-size: calc(100vw * (18 / 1600));
              background: url(${IconArrowBottom}) no-repeat center right /
                calc(100vw * (24 / 1600));
            }
          }

          & .listWrap {
            margin-bottom: calc(100vw * (50 / 1600));
          }
        }

        &.share {
          width: calc(100vw * (653 / 1600));

          & .title {
            margin-bottom: calc(100vw * (14 / 1600));
          }

          & .listWrap {
            margin-bottom: calc(100vw * (56 / 1600));

            & .list > div {
              padding: calc(100vw * (16 / 1600)) 0;

              & .listText {
                margin-bottom: calc(100vw * (15 / 1600));
              }
            }
          }
        }
      }
    }

    & .goListButton {
      width: calc(100vw * (250 / 1600));
      margin: calc(100vw * (50 / 1600)) auto 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & > .inner {
      margin-top: calc(100vw * (-30 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
      border-top-left-radius: calc(100vw * (5 / 370));
      border-top-right-radius: calc(100vw * (5 / 370));
    }

    & .middleInner {
      width: 100%;
    }

    & .listSection {
      flex-direction: column;
      gap: calc(100vw * (60 / 370));
      margin-top: calc(100vw * (61 / 370));
      padding-bottom: calc(100vw * (50 / 370));

      & .listContainer {
        & .title {
          font-size: calc(100vw * (20 / 370));
        }

        &.key {
          width: 100%;

          & .titleContainer {
            gap: calc(100vw * (20 / 370));
            margin-bottom: calc(100vw * (30 / 370));

            & .react-datepicker__input-container input {
              width: calc(100vw * (99 / 370));
              font-size: calc(100vw * (14 / 370));
              background: url(${IconArrowBottom}) no-repeat center right /
                calc(100vw * (16 / 370));
            }
          }

          & .listWrap {
            margin-bottom: calc(100vw * (50 / 370));
          }
        }

        &.share {
          width: 100%;

          & .title {
            margin-bottom: 0;
          }

          & .listWrap {
            margin-bottom: calc(100vw * (50 / 370));

            & .list > div {
              padding: calc(100vw * (30 / 370)) 0;

              & .listText {
                margin-bottom: calc(100vw * (16 / 370));
              }
            }
          }
        }
      }
    }

    & .goListButton {
      width: calc(100vw * (155 / 370));
      margin: calc(100vw * (50 / 370)) auto 0;
    }
  }
`;

export { Container };

export default { Container };
