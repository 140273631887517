import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom01.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top01.svg";

const Container = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &.on {
    background-color: #f9fafc;
    border-radius: 2px;
    box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.06);

    & .nowSelect {
      background-image: url(${IconArrowTop});
    }

    & .optionListWrap {
      background-color: #f9fafc;
      box-shadow: 5px 20px 20px 0px rgba(0, 0, 0, 0.06);
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  & .nowSelect {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 25px;
    background: url(${IconArrowBottom}) no-repeat calc(100% - 25px) center /
      24px;
  }

  & .optionListWrap {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    max-height: 245px;
    padding: 19px 0;
    overflow: auto;
    z-index: 2;

    & .optionList {
      display: flex;
      align-items: center;
      padding: 0 25px;

      &:not(:last-child) {
        margin-bottom: 19px;
      }
    }
  }

  @media (max-width: 1600px) {
    font-size: calc(100vw * (18 / 1600));

    &.on {
      border-radius: calc(100vw * (2 / 1600));
      box-shadow: calc(100vw * (5 / 1600)) calc(100vw * (10 / 1600))
        calc(100vw * (20 / 1600)) 0px rgba(0, 0, 0, 0.06);

      & .optionListWrap {
        box-shadow: calc(100vw * (5 / 1600)) calc(100vw * (20 / 1600))
          calc(100vw * (20 / 1600)) 0px rgba(0, 0, 0, 0.06);
        border-bottom-left-radius: calc(100vw * (2 / 1600));
        border-bottom-right-radius: calc(100vw * (2 / 1600));
      }
    }

    & .nowSelect {
      height: calc(100vw * (40 / 1600));
      padding: 0 calc(100vw * (25 / 1600));
      background: url(${IconArrowBottom}) no-repeat
        calc(100% - calc(100vw * (25 / 1600))) center /
        calc(100vw * (24 / 1600));
    }

    & .optionListWrap {
      top: calc(100vw * (40 / 1600));
      max-height: calc(100vw * (245 / 1600));
      padding: calc(100vw * (19 / 1600)) 0;

      & .optionList {
        padding: 0 calc(100vw * (25 / 1600));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (19 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (14 / 370));

    &.on {
      border-radius: calc(100vw * (2 / 370));
      box-shadow: calc(100vw * (5 / 370)) calc(100vw * (10 / 370))
        calc(100vw * (20 / 370)) 0px rgba(0, 0, 0, 0.06);

      & .optionListWrap {
        box-shadow: calc(100vw * (5 / 370)) calc(100vw * (20 / 370))
          calc(100vw * (20 / 370)) 0px rgba(0, 0, 0, 0.06);
        border-bottom-left-radius: calc(100vw * (2 / 370));
        border-bottom-right-radius: calc(100vw * (2 / 370));
      }
    }

    & .nowSelect {
      height: calc(100vw * (30 / 370));
      padding: 0 calc(100vw * (10 / 370));
      background: url(${IconArrowBottom}) no-repeat
        calc(100% - calc(100vw * (10 / 370))) center / calc(100vw * (16 / 370));
    }

    & .optionListWrap {
      top: calc(100vw * (30 / 370));
      max-height: calc(100vw * (150 / 370));
      padding: calc(100vw * (15 / 370)) 0;

      & .optionList {
        padding: 0 calc(100vw * (10 / 370));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
