import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  align-items: center;
  gap: 35px;

  & .tabList {
    position: relative;
    padding: 0 7px;
    font-size: 24px;
    font-weight: bold;
    color: #cfd2d7;
    cursor: pointer;

    &.on {
      color: var(--black-color-01);

      &::after {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--black-color-01);
        content: "";
      }
    }
  }

  @media (max-width: 1600px) {
    gap: calc(100vw * (35 / 1600));

    & .tabList {
      padding: 0 calc(100vw * (7 / 1600));
      font-size: calc(100vw * (24 / 1600));

      &.on {
        &::after {
          bottom: calc(100vw * (-5 / 1600));
          height: calc(100vw * (3 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (10 / 370));

    & .tabList {
      flex-shrink: 0;
      padding: 0 calc(100vw * (7 / 370));
      font-size: calc(100vw * (16 / 370));

      &.on {
        &::after {
          bottom: calc(100vw * (-7 / 370));
          height: calc(100vw * (3 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
