import styled from "styled-components";

const Container = styled.div`
  & .dateTopContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 47px;

    & h2 {
      font-size: 32px;
      font-weight: bold;
    }

    & .buttonContainer {
      display: flex;
      gap: 13px;

      & button {
        width: 32px;
        height: 32px;
      }
    }
  }

  & .dateBottomContainer {
    & .dayContainer {
      display: flex;
      justify-content: center;
      gap: 50px;
      margin-bottom: 34px;

      & .day {
        width: 19px;
        font-size: 16px;
        font-weight: bold;
        color: var(--black-color-01);
        text-align: center;

        &.sunday {
          color: #ff4329;
        }
      }
    }

    & .row {
      display: flex;
      justify-content: center;
      gap: 50px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    & .date {
      width: 19px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 1.47;
      cursor: pointer;

      &.active,
      &.schedule {
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 47px;
          height: 47px;
          border-radius: 50%;
          content: "";
        }

        & p {
          position: relative;
          z-index: 1;
        }
      }

      &.active {
        color: #fff;

        &::after {
          background-color: var(--black-color-01);
        }
      }

      &.schedule::after {
        background-color: #e4e8f1;
      }

      &.sunday {
        color: #ff4329;
      }
    }
  }

  @media (max-width: 1600px) {
    & .dateTopContainer {
      margin-bottom: calc(100vw * (47 / 1600));

      & h2 {
        font-size: calc(100vw * (32 / 1600));
      }

      & .buttonContainer {
        gap: calc(100vw * (13 / 1600));

        & button {
          width: calc(100vw * (32 / 1600));
          height: calc(100vw * (32 / 1600));
        }
      }
    }

    & .dateBottomContainer {
      & .dayContainer {
        gap: calc(100vw * (50 / 1600));
        margin-bottom: calc(100vw * (34 / 1600));

        & .day {
          width: calc(100vw * (19 / 1600));
          font-size: calc(100vw * (16 / 1600));
        }
      }

      & .row {
        gap: calc(100vw * (50 / 1600));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 1600));
        }
      }

      & .date {
        width: calc(100vw * (19 / 1600));
        font-size: calc(100vw * (16 / 1600));

        &.active,
        &.schedule {
          &::after {
            width: calc(100vw * (47 / 1600));
            height: calc(100vw * (47 / 1600));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .dateTopContainer {
      margin-bottom: calc(100vw * (18 / 370));

      & h2 {
        font-size: calc(100vw * (20 / 370));
      }

      & .buttonContainer {
        gap: calc(100vw * (10 / 370));

        & button {
          width: calc(100vw * (22 / 370));
          height: calc(100vw * (22 / 370));
        }
      }
    }

    & .dateBottomContainer {
      & .dayContainer {
        gap: calc(100vw * (21 / 370));
        margin-bottom: calc(100vw * (20 / 370));

        & .day {
          width: calc(100vw * (17 / 370));
          font-size: calc(100vw * (14 / 370));
        }
      }

      & .row {
        gap: calc(100vw * (21 / 370));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (22 / 370));
        }
      }

      & .date {
        width: calc(100vw * (17 / 370));
        font-size: calc(100vw * (14 / 370));

        &.active,
        &.schedule {
          &::after {
            width: calc(100vw * (33 / 370));
            height: calc(100vw * (33 / 370));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
