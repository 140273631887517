import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "hooks";

// img
import IconReport from "resources/image/icon/icon_report02.svg";

function DeleteModal({ modalClose }) {
  const authInfo = useAuth();

  const [reason, setReason] = useState("");
  const [textarea, setTextarea] = useState("");
  const [errorText, setErrorText] = useState("");
  const [check, setCheck] = useState(false);

  const validate = () => {
    if (reason === "") {
      setErrorText("탈퇴 사유를 선택해주세요.")
      return false;
    }
    if (reason === "기타(직접 입력)" && textarea.trim() === "") {
      setErrorText("기타 사유를 입력해주세요.")
      return false;
    }
    if (check === false) {
      setErrorText("탈퇴 안내를 확인해주세요.")
      return false;
    }
    return true;
  }

  const onResign = () => {
    if (validate()) authInfo.resign(reason, textarea, modalClose)
  }

  // map
  const list = [
    "기록 삭제 목적",
    "이용이 불편하고 장애가 많음",
    "사이트 사용 빈도가 낮음",
    "콘텐츠에 대한 만족도가 낮음",
    "기타(직접 입력)",
  ];

  return (
    <Styles.Container>
      <div className="modalContainer">
        <div className="iconWrap">
          <img src={IconReport} alt="IconReport" />
        </div>

        <p className="title">회원 탈퇴</p>

        <ul className="listWrap">
          {list.map((item, index) => {
            return (
              <li className="list" key={"list" + index}>
                <ui.input.BasicRadio
                  name={"reason"}
                  id={item}
                  labelText={item}
                  onChange={(e) => {
                    item !== "기타(직접 입력)" && setTextarea("")
                    setReason(item);
                  }}
                  checked={reason === item}
                />
                {item === "기타(직접 입력)" && (
                  <div className="textareaContainer">
                    <ui.input.BasicTextarea
                      placeholder={"기타 사유를 입력해주세요."}
                      value={textarea}
                      setValue={setTextarea}
                      disabled={reason !== "기타(직접 입력)"}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        <div className="checkboxContainer">
          <ui.input.BasicCheckbox
            id={"check"}
            checked={check}
            onChange={(e) => {
              setCheck(!check);
            }}
          />
          <p className="text">
            <Link to={"/terms/delete"} target="_blank">회원탈퇴 안내</Link>를 모두 확인했습니다.
          </p>
        </div>

        <p className="errorText">{errorText}</p>

        <ul className="buttonListWrap">
          <li className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={"탈퇴하기"}
              buttonType={"basic"}
              size={"small"}
              onClick={onResign}
            />
          </li>

          <li className="buttonList cancelButton">
            <ui.button.BasicButton
              buttonText={"취소"}
              buttonType={"white"}
              size={"small"}
              onClick={() => {
                modalClose();
              }}
            />
          </li>
        </ul>
      </div>
    </Styles.Container>
  );
}

export default DeleteModal;
