import date from "./date";
import etc from "./etc";
import url from "./url";

export { date, etc, url };

export default {
  date,
  etc,
  url,
};
