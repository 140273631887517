import Styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_white.svg";

function AnotherContents({ data }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <div className="prevList">
        <a
          className="button"
          onClick={() => {
            data.prev_info && navigate(location.pathname + "?id=" + data.prev_info.id, { state: location.state });
          }}
        >
          <div className="text">
            <div className="iconWrap">
              <img src={IconArrowLeft} alt="IconArrowLeft" />
            </div>{" "}
            Prev
          </div>
          <div className="title">{data.prev_info ? data.prev_info?.title : "이전 게시글이 없습니다."}</div>
        </a>
      </div>

      <div className="nextList">
        <a
          className="button"
          onClick={() => {
            data.next_info && navigate(location.pathname + "?id=" + data.next_info.id, { state: location.state });
          }}
        >
          <div className="title">{data.next_info ? data.next_info?.title : "다음 게시글이 없습니다."}</div>
          <div className="text">
            Next
            <div className="iconWrap">
              <img src={IconArrowRight} alt="IconArrowRight" />
            </div>{" "}
          </div>
        </a>
      </div>
    </Styles.Container>
  );
}

export default AnotherContents;
