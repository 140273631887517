import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: calc(100% + 23px);
  right: -65px;
  width: 394px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 4px 11px 20px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 2;

  & .bellTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 10px;
    font-size: 22px;
    font-weight: bold;
  }

  & .listWrap {
    max-height: 540px;
    overflow: auto;
    cursor: pointer;
  }

  & .emptyContainer {
    padding: 68px 0 89px;
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-color-02);
    text-align: center;
  }

  & .bellCloseButton {
    width: 100%;
    padding: 10px 0 20px;

    & .iconWrap {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1600px) {
    top: calc(100% + calc(100vw * (23 / 1600)));
    right: calc(100vw * (-65 / 1600));
    width: calc(100vw * (394 / 1600));
    border-bottom-left-radius: calc(100vw * (10 / 1600));
    border-bottom-right-radius: calc(100vw * (10 / 1600));
    box-shadow: calc(100vw * (4 / 1600)) calc(100vw * (11 / 1600))
      calc(100vw * (20 / 1600)) 0px rgba(0, 0, 0, 0.15);

    & .bellTitle {
      padding: calc(100vw * (30 / 1600)) calc(100vw * (30 / 1600))
        calc(100vw * (10 / 1600));
      font-size: calc(100vw * (22 / 1600));
    }

    & .listWrap {
      max-height: calc(100vw * (540 / 1600));
    }

    & .emptyContainer {
      padding: calc(100vw * (68 / 1600)) 0 calc(100vw * (89 / 1600));
      font-size: calc(100vw * (16 / 1600));
    }

    & .bellCloseButton {
      padding: calc(100vw * (10 / 1600)) 0 calc(100vw * (20 / 1600));

      & .iconWrap {
        width: calc(100vw * (20 / 1600));
        height: calc(100vw * (20 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: calc(100vw * (51 / 370));
    left: 0;
    right: 0;
    width: 100vw;
    border-bottom-left-radius: calc(100vw * (10 / 370));
    border-bottom-right-radius: calc(100vw * (10 / 370));
    box-shadow: 0px calc(100vw * (13 / 370)) calc(100vw * (16 / 370)) 0px
      rgba(0, 0, 0, 0.05);

    & .bellTitle {
      padding: calc(100vw * (20 / 370)) calc(100vw * (25 / 370));
      font-size: calc(100vw * (18 / 370));
    }

    & .listWrap {
      max-height: 50vh;
    }

    & .emptyContainer {
      padding: 0;
      font-size: calc(100vw * (14 / 370));
    }

    & .bellCloseButton {
      padding: calc(100vw * (20 / 370));

      & .iconWrap {
        width: calc(100vw * (20 / 370));
        height: calc(100vw * (20 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
