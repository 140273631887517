import { common, layout, ui } from "components";
import Styles from "./styles";
import { useAuth } from "hooks";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function SignupStepBPage() {
  const authInfo = useAuth();
  const location = useLocation();
  const { id } = useParams();

  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [select, setSelect] = useState("");
  const [textState, setTextState] = useState();
  const [check, setCheck] = useState(false);
  const optionList = ["강남구", "강동구", "노원구", "송파구", "양천구", "동탄동", "제주도", "그외 지역"];

  const onNickNameCheck = async () => {  
    let result = await authInfo.findNickName(nickname.trim());
    if (result.success) {
      setTextState(true);
      setCheck(false);
    } else {
      setTextState(false);
    }
  }
  
  const signUp = () => {
    if (id === "signup" && password !== confirmPassword) {
      return alert("비밀번호가 일치하지 않습니다.")
    }
    const postData = new FormData();
    postData.append("location", select);
    postData.append("nickname", nickname);
    id === "signup" && postData.append("password", password);
    id === "signup" && postData.append("username", email);
    id === "signup"
      ? authInfo.signUp(postData)
      : authInfo.socialSignUp(postData)
  }
  
  const validate = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const passwordRegex = /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{10,30}$/;

    if (id === "signup") {
      if (emailRegex.test(email) === false) return false;
      if (passwordRegex.test(password) === false) return false;
      if (password === "") return false;
      if (confirmPassword === "") return false;
    }
    if (nickname.trim() === "") return false;
    if (select === "") return false;
    return true;
  }

  useEffect(()=>{
    setCheck(true);
  }, [nickname]);

  useEffect(() => {
    validate()
      ? setIsDisabled(check)
      : setIsDisabled(true)
  }, [email, password, confirmPassword, select, textState, check])


  return (
    location.state === 3 &&
    <>
      <common.Header />
      <Styles.Container>
        <div className="narrowInner">
          <layout.SignupTitle
            text={"서비스 이용을 위한 개인정보를 등록해주세요."}
            step={1}
          />

          <div className="formContainer">
            <form>
              <h3 className="title">계정 정보</h3>

              <ul className="listWrap">
                {id === "signup" && (
                  <>
                    <li className="list">
                      <ui.input.BasicInput
                        labelText={"이메일"}
                        essential
                        placeholder={"이메일을 입력해 주세요."}
                        value={email}
                        setValue={setEmail}
                      />
                    </li>

                    <li className="list">
                      <ui.input.BasicInput
                        labelText={"비밀번호"}
                        essential
                        type={"password"}
                        placeholder={"비밀번호를 입력해 주세요."}
                        value={password}
                        setValue={setPassword}
                        guideText={"영문 소문자, 숫자 포함 10자 이상"}
                      />
                    </li>

                    <li className="list">
                      <ui.input.BasicInput
                        labelText={"비밀번호 확인"}
                        essential
                        type={"password"}
                        placeholder={"비밀번호 확인"}
                        value={confirmPassword}
                        setValue={setConfirmPassword}
                      />
                    </li>
                  </>
                )}
                <li className="list nickname">
                  <ui.input.BasicInput
                    labelText={"닉네임"}
                    essential
                    placeholder={"닉네임을 입력해 주세요."}
                    value={nickname}
                    setValue={(e) => { setNickname(e.replace(/[^0-9a-zA-Z가-힣ㆍᆞᆢㄱ-ㅎㅏ-ㅣ]*$/gi, '')) }}
                    errorText={textState === false && "다른 회원이 사용중인 닉네임입니다."}
                    guideText={textState && "사용 가능한 닉네임입니다."}
                    buttonText={"중복확인"}
                    onClick={onNickNameCheck}
                  />
                </li>

                <li className="list">
                  <div className="selectContainer">
                    <p className="inputTitle">
                      <span>활동 지역</span>
                    </p>

                    <ui.input.BasicSelect 
                      optionList={optionList}
                      select={select}
                      setSelect={setSelect}
                    />
                  </div>

                  <p className="guideText">
                    활동 지역에 따라 활동할 수 있는 페이지가{" "}
                    <br className="pcOnly" />
                    제한되니 <br className="mbOnly" />
                    정확히 입력하시기 바랍니다.
                  </p>
                </li>
              </ul>

              <div className="nextButton">
                <ui.button.BasicButton
                  buttonType={isDisabled ? "gray" : "basic"}
                  buttonText={"가입 하기"}
                  disabled={isDisabled}
                  onClick={signUp}
                />
              </div>
            </form>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SignupStepBPage;
