import styled from "styled-components";

const Container = styled.aside`
  width: 244px;

  & .title {
    margin-bottom: 80px;
    font-size: 26px;
    font-weight: bold;
  }

  & .userInfo {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;

    & .profileImgWrap {
      flex-shrink: 0;
      width: 55px;
      height: 55px;
    }

    & .userInfoText {
      font-size: 18px;
      font-weight: 500;
    }
  }

  & .menuList {
    display: flex;
    align-items: center;
    height: 65px;
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-color-02);
    cursor: pointer;

    &.on {
      font-weight: bold;
      color: var(--black-color-01);
    }
  }

  @media (max-width: 1600px) {
    width: calc(100vw * (244 / 1600));

    & .title {
      margin-bottom: calc(100vw * (80 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .userInfo {
      gap: calc(100vw * (20 / 1600));
      margin-bottom: calc(100vw * (40 / 1600));

      & .profileImgWrap {
        width: calc(100vw * (55 / 1600));
        height: calc(100vw * (55 / 1600));
      }

      & .userInfoText {
        font-size: calc(100vw * (18 / 1600));
      }
    }

    & .menuList {
      height: calc(100vw * (65 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    & .title {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (26 / 370));
    }

    & .userInfo {
      display: none;
    }

    & .menuListWrap {
      display: flex;
      gap: 15px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      & .menuList {
        flex-shrink: 0;
        height: auto;
        font-size: calc(100vw * (16 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
