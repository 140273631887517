import Styles from "./styles";
import ui from "components/ui";
import { useReport } from "hooks";

// img
import IconReport from "resources/image/icon/icon_report02.svg";

function ReportModal({ modalClose, modalOption }) {
  const reportInfo = useReport();

  const validate = () => {
    if (reportInfo.reason === "") return false;
    if (reportInfo.reason === "기타" && reportInfo.textarea.trim() === "") return false;
    return true;
  }

  const onReport = () => {
    if (validate()) {
      modalClose();
      reportInfo.report(modalOption)
    } else {
      alert("입력하지 않은 정보가 있습니다.")
    }
  }

  // map
  const contentsReasonList = [
    `비속어, 비방, 조롱, 넘겨짚기 등과같은 "막말" 사용`,
    "불쾌감을 유발하는 도배글, 분란을 야기하는 게시물",
    "토론방 이외의 게시판에 정치, 경제, 정부정책, 교육정책, 부동산 관련 등 견해차이가 예상되는 게시물",
    "기타"
  ];

  const commentReasonList = [
    `비속어, 비방, 조롱, 넘겨짚기 등과같은 "막말" 사용`,
    "불쾌감을 유발하는 도배글, 분란을 야기하는 댓글",
    "토론방 이외의 게시판에 정치, 경제, 정부정책, 교육정책, 부동산 관련 등 견해차이가 예상되는 댓글",
    "기타"
  ];

  return (
    <Styles.Container>
      <div className="modalContainer">
        <div className="iconWrap">
          <img src={IconReport} alt="IconReport" />
        </div>

        <p className="title">{modalOption.contents ? "게시물" : "댓글"} 신고</p>
        <p className="text">신고 사유를 선택해주세요.</p>

        <ul className="listWrap">
          {(modalOption.contents ? contentsReasonList : commentReasonList).map(
            (item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <ui.input.BasicRadio
                    name={"reason"}
                    id={item}
                    labelText={item}
                    onChange={(e) => {
                      item !== "기타" && reportInfo.setTextarea("")
                      reportInfo?.setReason(item);
                    }}
                    checked={reportInfo?.reason === item}
                  />
                  {item === "기타" && (
                    <div className="textareaContainer">
                      <ui.input.BasicTextarea
                        placeholder={"기타 사유를 입력해주세요."}
                        value={reportInfo.textarea}
                        setValue={reportInfo.setTextarea}
                        disabled={reportInfo.reason !== "기타"}
                      />
                    </div>
                  )}
                </li>
              );
            }
          )}
        </ul>

        <ul className="buttonListWrap">
          <li className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={"신고하기"}
              buttonType={"basic"}
              size={"small"}
              onClick={onReport}
            />
          </li>

          <li className="buttonList cancelButton">
            <ui.button.BasicButton
              buttonText={"취소"}
              buttonType={"white"}
              size={"small"}
              onClick={() => { modalClose() }}
            />
          </li>
        </ul>
      </div>
    </Styles.Container>
  );
}

export default ReportModal;
