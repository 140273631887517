import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & > .inner {
    margin-top: -80px;
    padding: 80px 63px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }

  & .listSection {
    margin-top: 80px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--gray-color-04);

    & .sectionTitle {
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: bold;
    }

    & .swiper-slide {
      width: 361px;
      padding-right: 31px;
    }
  }

  & .goListButton {
    width: 250px;
    margin: 50px auto 0;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & > .inner {
      margin-top: calc(100vw * (-80 / 1600));
      padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
      border-top-left-radius: calc(100vw * (10 / 1600));
      border-top-right-radius: calc(100vw * (10 / 1600));
    }

    & .listSection {
      margin-top: calc(100vw * (80 / 1600));
      padding-bottom: calc(100vw * (50 / 1600));

      & .sectionTitle {
        margin-bottom: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (36 / 1600));
      }

      & .swiper-slide {
        width: calc(100vw * (361 / 1600));
        padding-right: calc(100vw * (31 / 1600));
      }
    }

    & .goListButton {
      width: calc(100vw * (250 / 1600));
      margin: calc(100vw * (50 / 1600)) auto 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & > .inner {
      margin-top: calc(100vw * (-30 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
      border-top-left-radius: calc(100vw * (5 / 370));
      border-top-right-radius: calc(100vw * (5 / 370));
    }

    & .middleInner {
      width: 100%;
    }

    & .listSection {
      margin-top: calc(100vw * (60 / 370));
      padding-bottom: calc(100vw * (30 / 370));

      & .sectionTitle {
        margin-bottom: calc(100vw * (30 / 370));
        font-size: calc(100vw * (20 / 370));
      }

      & .swiper-slide {
        width: calc(100vw * (152 / 370));
        padding-right: calc(100vw * (14 / 370));
      }
    }

    & .goListButton {
      width: calc(100vw * (155 / 370));
      margin: calc(100vw * (50 / 370)) auto 0;
    }
  }
`;

export { Container };

export default { Container };
