import utils from "utils";
import Styles from "./styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// img
import IconSearch from "resources/image/icon/icon_search.svg";

function SearchContainer({ onFocus, setIsFocus }) {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState();

  return (
    <Styles.Container>
      <input
        type="text"
        placeholder="검색어를 입력 해주세요."
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onFocus={onFocus}
        onKeyDown={(e)=>{
          if (e.key == "Enter") {
            window.location.pathname.includes("search") == false
              ? navigate("/search?" + utils.etc.getQueryParamUrl({ keyword: encodeURIComponent(keyword || ""), page: 1 }, false, true)) 
              : navigate(utils.etc.getQueryParamUrl({ keyword: encodeURIComponent(keyword || ""), page: 1 }))
            setIsFocus && setIsFocus(false);
          }
        }}
      />

      <button
        type="button"
        className="searchButton"
        onClick={() => {
          window.location.pathname.includes("search") == false
            ? navigate("/search?" + utils.etc.getQueryParamUrl({ keyword: encodeURIComponent(keyword || ""), page: 1 }, false, true)) 
            : navigate(utils.etc.getQueryParamUrl({ keyword: encodeURIComponent(keyword || ""), page: 1 }))
          setIsFocus && setIsFocus(false);
        }}
      >
        <img src={IconSearch} alt="IconSearch" />
      </button>
    </Styles.Container>
  );
}

export default SearchContainer;
