import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;

  & textarea {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    border: 1px solid var(--gray-color-03);
    border-right: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    &::placeholder {
      color: var(--gray-color-03);
    }
  }

  & .submitButton {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    font-weight: bold;
    color: #fff;
    background-color: var(--black-color-01);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  @media (max-width: 1600px) {
    height: calc(100vw * (80 / 1600));

    & textarea {
      padding: calc(100vw * (20 / 1600));
      border-top-left-radius: calc(100vw * (2 / 1600));
      border-bottom-left-radius: calc(100vw * (2 / 1600));
    }

    & .submitButton {
      width: calc(100vw * (106 / 1600));
      border-top-right-radius: calc(100vw * (2 / 1600));
      border-bottom-right-radius: calc(100vw * (2 / 1600));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (50 / 370));

    & textarea {
      padding: calc(100vw * (14 / 370)) calc(100vw * (10 / 370));
      border-top-left-radius: calc(100vw * (2 / 370));
      border-bottom-left-radius: calc(100vw * (2 / 370));
    }

    & .submitButton {
      width: calc(100vw * (66 / 370));
      border-top-right-radius: calc(100vw * (2 / 370));
      border-bottom-right-radius: calc(100vw * (2 / 370));
    }
  }
`;

export { Container };

export default { Container };
