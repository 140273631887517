import { atom } from 'jotai'

const userInfo = atom({
  ads_yn: "N",
  description: "",
  email: "",
  id: 0,
  image: "",
  location: "",
  name: "",
  nickname: "",
  pass_yn: "N",
  phone: "",
  social_relate_yn: "N",
  social_yn: "N",
  type: "",
  username: ""
});

const introInfo = atom({
  auth: false,
});

export default { userInfo, introInfo };