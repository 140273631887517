import styled from "styled-components";

const Container = styled.div`
  & .wrap {
    min-height: 370px;
    padding: 50px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.08);

    & .title {
      margin-bottom: 50px;
      font-size: 26px;
      font-weight: bold;
    }

    & .infoContainer {
      & .list {
        display: flex;
        align-items: center;
        font-size: 16px;

        &.profile {
          align-items: flex-start;
        }

        &.nickname {
          & input {
            max-width: 350px;
          }

          & button {
            width: 110px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & .listTitle,
        &.nickname label {
          flex-shrink: 0;
          width: 129px;
          font-weight: bold;
          color: var(--main-color);
        }

        & .profileImgContainer {
          position: relative;
          width: 70px;
          height: 70px;

          & input {
            display: none;
          }

          & label {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            content: "";
            cursor: pointer;

            & .iconWrap {
              display: block;
              width: 26px;
              height: 23px;
            }
          }

          & .profileImgWrap {
            height: 100%;
          }
        }

        & .guideText,
        .errorText {
          font-size: 14px;
        }
      }
    }
  }

  & .childrenInfoContainer {
    margin-top: 50px;

    & .list:first-child {
      & > div {
        padding-top: 0;
        border-top: 0;
      }

      & .minusButton {
        display: none;
      }
    }

    & .inputListWrap {
      width: 500px;
    }

    & .errorText {
      margin: -25px 0 0 133px;
      color: var(--red-color);
    }
  }

  & .buttonListWrap {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;

    & .buttonList {
      width: 250px;
    }
  }

  @media (max-width: 1600px) {
    & .wrap {
      min-height: calc(100vw * (370 / 1600));
      padding: calc(100vw * (50 / 1600));
      border-radius: calc(100vw * (10 / 1600));
      box-shadow: 0px calc(100vw * (2 / 1600)) calc(100vw * (50 / 1600)) 0px
        rgba(0, 0, 0, 0.08);

      & .title {
        margin-bottom: calc(100vw * (50 / 1600));
        font-size: calc(100vw * (26 / 1600));
      }

      & .infoContainer {
        & .list {
          font-size: calc(100vw * (16 / 1600));

          &.nickname {
            & input {
              max-width: calc(100vw * (350 / 1600));
            }

            & button {
              width: calc(100vw * (110 / 1600));
            }
          }

          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 1600));
          }

          & .listTitle,
          &.nickname label {
            width: calc(100vw * (129 / 1600));
          }

          & .profileImgContainer {
            width: calc(100vw * (70 / 1600));
            height: calc(100vw * (70 / 1600));

            & label {
              & .iconWrap {
                width: calc(100vw * (26 / 1600));
                height: calc(100vw * (23 / 1600));
              }
            }
          }

          & .guideText,
          .errorText {
            font-size: calc(100vw * (14 / 1600));
          }
        }
      }
    }

    & .childrenInfoContainer {
      margin-top: calc(100vw * (50 / 1600));

      & .inputListWrap {
        width: calc(100vw * (500 / 1600));
      }

      & .errorText {
        margin: calc(100vw * (-25 / 1600)) 0 0 calc(100vw * (133 / 1600));
      }
    }

    & .buttonListWrap {
      gap: calc(100vw * (10 / 1600));
      margin-top: calc(100vw * (50 / 1600));

      & .buttonList {
        width: calc(100vw * (250 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    & .wrap {
      min-height: calc(100vw * (320 / 370));
      padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370))
        calc(100vw * (50 / 370));
      border-radius: calc(100vw * (10 / 370));
      box-shadow: 0px calc(100vw * (2 / 370)) calc(100vw * (50 / 370)) 0px
        rgba(0, 0, 0, 0.08);

      & .title {
        margin-bottom: calc(100vw * (30 / 370));
        font-size: calc(100vw * (20 / 370));
      }

      & .infoContainer {
        & .list {
          flex-direction: column;
          align-items: flex-start;
          font-size: calc(100vw * (14 / 370));

          &.nickname {
            & input {
              max-width: calc(100vw * (174 / 370));
            }

            & button {
              width: calc(100vw * (110 / 370));
              margin-left: calc(100vw * (6 / 370));
            }

            & .inputContainer {
              flex-wrap: wrap;
              flex-direction: row;
            }
          }

          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 370));
          }

          & .listTitle,
          &.nickname label {
            width: 100%;
            margin-bottom: calc(100vw * (6 / 370));
            font-size: calc(100vw * (16 / 370));
          }

          & .profileImgContainer {
            width: calc(100vw * (64 / 370));
            height: calc(100vw * (64 / 370));

            & label {
              & .iconWrap {
                width: calc(100vw * (23 / 370));
                height: calc(100vw * (21 / 370));
              }
            }
          }

          & .guideText,
          .errorText {
            font-size: calc(100vw * (14 / 370));
          }
        }
      }
    }

    & .childrenInfoContainer {
      margin-top: calc(100vw * (60 / 370));

      & .inputListWrap {
        width: 100%;
      }

      & .errorText {
        margin: calc(100vw * (-25 / 370)) 0 0;
      }
    }

    & .buttonListWrap {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (15 / 370));
      margin-top: calc(100vw * (50 / 370));

      & .buttonList {
        width: calc(100vw * (155 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
