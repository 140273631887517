import { common, layout, ui } from "components";
import Styles from "./styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// img
import ImgReasonLogo from "resources/image/img_edusource_reason_logo.svg";
import ImgReason01 from "resources/image/img_edusource_reason01.png";
import ImgReason02 from "resources/image/img_edusource_reason02.png";
import ImgReason03 from "resources/image/img_edusource_reason03.png";

function EdusourcePage() {
  const list = [
    {
      title: (
        <>
          내 손끝에서 <br />
          <span>맞춤형 교육 일정</span>
        </>
      ),
      text: (
        <>
          자녀의 교육 여정에서 앞서 나가십시오. 대학 설명회부터{" "}
          <br className="mbOnly" />
          초등 및 중등 학교 입학 설명회, <br className="pcOnly" />
          중요한 입시 강의까지 <br className="mbOnly" />
          자녀가 기회를 놓치지 않도록 학업 요구에 맞는 알림을{" "}
          <br className="mbOnly" />
          받아보세요. <br className="pcOnly" />
          날마다 쏟아지는 새로운 정보와 시기 적절한 <br className="mbOnly" />
          알림은 이상적인 교육 경로를 만드는 데 <br className="pcOnly" />큰
          도움이 됩니다.
        </>
      ),
      img: ImgReason01,
    },
    {
      title: (
        <>
          <span>지역 교육 통찰력</span> <br />: 학부모에 의한, 학부모를 위한
        </>
      ),
      text: (
        <>
          지역별 학원 세부 사항, 입학 시험, 수행평가 등 해시태그{" "}
          <br className="mbOnly" />
          기반 커뮤니티를 통해 의견과 <br className="pcOnly" />
          노하우를 공유하며 배우고, <br className="mbOnly" />
          협업하세요. 지역 커뮤니티에 적극적으로 참여할 수{" "}
          <br className="mbOnly" />
          있도록 <br className="pcOnly" />
          하는 동시에 다른 지역을 탐색하고 통찰력을 <br className="mbOnly" />
          얻을 수 있는 ‘지역별 교육 허브 시스템’을 <br className="mbOnly" />
          제공하고 있습니다.
        </>
      ),
      img: ImgReason02,
    },
    {
      title: (
        <>
          이상적인
          <br />
          <span>교육 시설 찾기</span>
        </>
      ),
      text: (
        <>
          모든 아이들의 학습 환경은 고유한 요구사항을 갖습니다.{" "}
          <br className="mbOnly" />
          직관적인 조건 기반 필터링 <br className="pcOnly" />
          시스템을 통해 독서실, 학원, <br className="mbOnly" />
          학교, 스터디카페 등 선호도에 맞는 교육 시설을{" "}
          <br className="mbOnly" />
          찾아보세요.
        </>
      ),
      img: ImgReason03,
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="mainBannerSection">
          <div className="inner">
            <p className="storyTitle">에듀소스 이야기</p>

            <h2 className="title">
              모든 아이들이 최고의 교육을 <br className="mbOnly" />
              받는 것은 <br className="pcOnly" />
              ‘특권’이 아니라 <br className="mbOnly" />
              ‘권리’가 되어야 합니다.
            </h2>

            <p className="text">
              에듀소스(Edusource) 는 정보화 시대에 ‘모든 아이들이{" "}
              <br className="mbOnly" />
              최고의 교육을 받는 것은 특권이 아니라 권리가 되어야{" "}
              <br className="mbOnly" />
              한다.’ 라는 비전 아래 탄생되었습니다. <br />
              '교육(Education)’과 '자원(Resource)’라는 본질에서{" "}
              <br className="mbOnly" />
              파생된 우리 플랫폼은 대한민국 지역별 / 빈부별 교육{" "}
              <br className="mbOnly" />
              격차를 해소하고
              <br className="pcOnly" />
              모든 아이들이 잠재력을 최대한 발휘할 <br className="mbOnly" />수
              있는 환경을 제공하고자 최선을 다하고 있습니다.
            </p>
          </div>
        </section>

        <section className="reasonSection">
          <div className="inner">
            <div className="imgWrap">
              <img src={ImgReasonLogo} alt="ImgReasonLogo" />
            </div>

            <h2 className="title">에듀소스를 선택하는 이유</h2>

            <ul className="listWrap">
              {list?.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <div className="listImgWrap">
                      <img src={item.img} alt={item.img} />
                    </div>

                    <div className="textContainer">
                      <p className="listTitle">{item.title}</p>
                      <p className="listText">{item.text}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="subBannerSection">
          <div className="inner">
            <div className="subBanner">
              <h2 className="title">
                대한민국 교육 환경은 <br className="mbOnly" />
                매순간 진화하고 있습니다.
              </h2>
              <p className="text">
                우리는 EduSource를 통해 모든 부모가 정보에{" "}
                <br className="mbOnly" />
                입각한 교육 방향 결정을 내릴 수 있도록 최선의{" "}
                <br className="mbOnly" />
                노력을 다하고 있습니다. <br className="pcOnly" />
                올바른 도구와 리소스를 <br className="mbOnly" />
                갖추고 대한민국 모든 어린이에게 평등한 교육{" "}
                <br className="mbOnly" />
                기회를 제공할 수 있도록 여정에 함께 동참해 주세요.
              </p>
            </div>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default EdusourcePage;
