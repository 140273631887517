import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & > .inner {
    margin-top: -80px;
    padding: 80px 63px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }

  & .infoSection {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    & .infoContainer {
      width: 672px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);
      overflow: hidden;

      & .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 72px;
        padding: 0 30px;
        background-color: var(--gray-color-04);

        & .sectionTitle {
          font-size: 18px;
          font-weight: bold;
        }

        & .moreButton {
          display: flex;
          align-items: center;
          font-weight: 500;

          & .iconWrap {
            width: 16px;
            height: 16px;
          }
        }
      }

      & .listWrap {
        padding: 5px 30px;
        cursor: pointer;
        
        & .list:not(:last-child) {
          border-bottom: 1px solid var(--gray-color-04);
        }
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & > .inner {
      margin-top: calc(100vw * (-80 / 1600));
      padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
      border-top-left-radius: calc(100vw * (10 / 1600));
      border-top-right-radius: calc(100vw * (10 / 1600));
    }

    & .infoSection {
      padding-top: calc(100vw * (60 / 1600));

      & .infoContainer {
        width: calc(100vw * (672 / 1600));
        border-radius: calc(100vw * (5 / 1600));
        box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
          rgba(15, 40, 102, 0.1);

        & .titleContainer {
          height: calc(100vw * (72 / 1600));
          padding: 0 calc(100vw * (30 / 1600));

          & .sectionTitle {
            font-size: calc(100vw * (18 / 1600));
          }

          & .moreButton {
            & .iconWrap {
              width: calc(100vw * (16 / 1600));
              height: calc(100vw * (16 / 1600));
            }
          }
        }

        & .listWrap {
          padding: calc(100vw * (5 / 1600)) calc(100vw * (30 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & > .inner {
      margin-top: calc(100vw * (-30 / 370));
      padding: 0;
      border-top-left-radius: calc(100vw * (5 / 370));
      border-top-right-radius: calc(100vw * (5 / 370));
    }

    & .middleInner {
      width: 100%;
    }

    & .infoSection {
      flex-direction: column;
      gap: calc(100vw * (60 / 370));
      padding-top: 0;

      & .infoContainer {
        width: 100%;
        border-radius: calc(100vw * (5 / 370));
        box-shadow: 0px calc(100vw * (19 / 370)) calc(100vw * (35 / 370)) 0px
          rgba(15, 40, 102, 0.1);

        & .titleContainer {
          height: calc(100vw * (50 / 370));
          padding: 0 calc(100vw * (15 / 370));

          & .sectionTitle {
            font-size: calc(100vw * (14 / 370));
          }

          & .moreButton {
            font-size: calc(100vw * (12 / 370));

            & .iconWrap {
              width: calc(100vw * (16 / 370));
              height: calc(100vw * (16 / 370));
            }
          }
        }

        & .listWrap {
          padding: 0 calc(100vw * (15 / 370)) calc(100vw * (20 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
