import Styles from "./styles";

function LoginInput({
  id,
  icon,
  type,
  value,
  setValue,
  placeholder,
  guideText,
  errorText,
}) {
  return (
    <Styles.Container className="inputContainer">
      <div className="inputWrap">
        <div className="iconWrap">
          <img src={icon} alt={icon} />
        </div>

        <input
          id={id}
          type={type ? type : "text"}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>

      {guideText && <p className="guideText">{guideText}</p>}

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default LoginInput;
