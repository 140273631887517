import Styles from "./styles";
import ui from "components/ui";
import modal from "components/modal";
import Comment from "../Comment";
import FacilityDetailInfo from "../FacilityDetailInfo";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReply, useBoard, useLike, useAlarm, useAuth, useModals } from "hooks";

//img
import IconLike from "resources/image/icon/icon_like.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconComment from "resources/image/icon/icon_comment.svg";
import IconAuthor from "resources/image/icon/icon_author.svg";
import IconNonProfit from "resources/image/icon/icon_non_profit.svg";
import IconBellGray from "resources/image/icon/icon_bell_gray.svg";
import IconBellWhite from "resources/image/icon/icon_bell_white.svg";
import IconShare from "resources/image/icon/icon_share.svg";
import IconReport from "resources/image/icon/icon_report.svg";
import IconPen from "resources/image/icon/icon_pen02.svg";
import IconTrash02 from "resources/image/icon/icon_trash02.svg";

function DetailContainer({ children, data, bell, facility }) {
  const authInfo = useAuth();
  const boardInfo = useBoard({});
  const modalOption = useModals();
  const replyInfo = useReply({ replyOption: true });
  const alarmInfo = useAlarm();
  const likeInfo = useLike();
  
  const location = useLocation();
  const navigate = useNavigate();

  const [isBell, setIsBell] = useState(false);
  const [isCopyModal, setIsCopyModal] = useState(false);

  useEffect(()=>{
    setIsBell(data.alarm_yn === "Y" ? true : false)
  },[data.alarm_yn])

  return (
    <Styles.Container>
      <div className="titleContainer">
        {children}

        <div className="bottomContainer">
          <div className="reactionContainer">
            {!facility && (
              <>
                <div className="like">
                  <div className="iconWrap">
                    <img src={IconLike} alt="IconLike" />
                  </div>
                  {data.like_count}
                </div>
                <div className="comment">
                  <div className="iconWrap">
                    <img src={IconComment} alt="IconComment" />
                  </div>
                  {replyInfo.isDelete ? data.reply_count -1 : data.reply_count}
                </div>
              </>
            )}
          </div>

          <div className="right">
            <ul className="iconListWrap">
              <li className="iconList">
                <img src={IconAuthor} alt="IconAuthor" />
              </li>
              <li className="iconList">
                <img src={IconNonProfit} alt="IconNonProfit" />
              </li>
            </ul>

            {bell && (
              <div className="bellButton">
                <ui.button.BasicButton
                  buttonText={isBell ? "알람 해제" : "알람 설정"}
                  buttonType={isBell ? "basic" : "blackGrayFont"}
                  size={"smaller"}
                  icon={isBell ? IconBellWhite : IconBellGray}
                  onClick={()=>{
                    if (authInfo.certification()) {
                      alarmInfo.alarmToggle(setIsBell, isBell)
                    }
                  }}
                />
              </div>
            )}

            {data.my_yn === "Y" && (
              <ul className="editButtonListWrap">
                <li className="editButtonList">
                  <ui.button.BasicButton
                    icon={IconPen}
                    buttonText={"수정"}
                    size={"smaller"}
                    buttonType={"grayBlackFont"}
                    onClick={() => {
                      if (authInfo.certification()) {
                        navigate(boardInfo.boardLocation(data.type, true) + "?id=" + data.id)
                      }
                    }}
                  />
                </li>
                <li className="editButtonList">
                  <ui.button.BasicButton
                    icon={IconTrash02}
                    buttonText={"삭제"}
                    size={"smaller"}
                    buttonType={"grayBlackFont"}
                    onClick={() => { boardInfo.deleteBoard(data.id, `/${location.pathname.split("/")[1]}`) }}
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      {facility && <FacilityDetailInfo data={data} />}

      <div className="editor ck-content"
        dangerouslySetInnerHTML={{ __html: data.description }}
      >
      </div>

      <ul className="keywordListWrap">
        {data?.keyword_list?.map((item, index) => {
          return (
            <li
              className="keywordList"
              key={"keywordList" + index}
              onClick={() => {
                navigate("/search?keyword=" + item.title + "&page=1");
              }}
            >
              <ui.button.KeywordTag keyword={item.title}/>
            </li>
          );
        })}
      </ul>

      <div className="utilContainer">
        <ul className="reactionbuttonListWrap">
          {!facility && (
            <>
              <li className="reactionbuttonList like">
                <ui.button.BasicButton
                  buttonText={"공감 " + data.like_count}
                  buttonType={data.like_yn === "Y" ? "basic" : "grayGrayFont"}
                  size={"smaller"}
                  icon={data.like_yn === "Y" ? IconLikeOn : IconLike}
                  onClick={() => {
                    likeInfo.toggleLike(data.id, 1)
                  }}
                />
              </li>
              <li className="reactionbuttonList comment">
                <ui.button.BasicButton
                  buttonText={"댓글 " + data.reply_count}
                  size={"smaller"}
                  buttonType={"grayGrayFont"}
                  icon={IconComment}
                  onClick={() => {
                    replyInfo.setReplyInfoId("")
                    replyInfo.replyFocus.current.focus();
                  }}
                />
              </li>
            </>
          )}
        </ul>

        <ul className="buttonListWrap">
          <li className="buttonList">
            <ui.button.BasicButton
              buttonText={"공유"}
              buttonType={"grayBlackFont"}
              icon={IconShare}
              size={"smaller"}
              onClick={() => {
                setIsCopyModal(true);
              }}
            />
            {isCopyModal && (
              <modal.CopyModal
                modalClose={() => {
                  setIsCopyModal(false);
                }}
              />
            )}
          </li>

          {data.my_yn === "Y" ? (
            <>
              <li className="buttonList">
                <ui.button.BasicButton
                  icon={IconPen}
                  buttonText={"수정"}
                  size={"smaller"}
                  buttonType={"grayBlackFont"}
                  onClick={() => {
                    if (authInfo.certification()) {
                      navigate(boardInfo.boardLocation(data.type, true) + "?id=" + data.id)
                    }
                  }}
                />
              </li>
              <li className="buttonList">
                <ui.button.BasicButton
                  icon={IconTrash02}
                  buttonText={"삭제"}
                  size={"smaller"}
                  buttonType={"grayBlackFont"}
                  onClick={() => { boardInfo.deleteBoard(data.id, `${location.pathname.split("/")[1]}`) }}
                />
              </li>
            </>
          ) : (
            <li className="buttonList">
              <ui.button.BasicButton
                buttonText={"신고"}
                size={"smaller"}
                buttonType={"grayBlackFont"}
                icon={IconReport}
                onClick={() => {
                  if (authInfo.certification()) {
                    modalOption.setReportModal((e) => {
                      e.show = true;
                      e.contents = true;
                      e.id = data.id;
                      return { ...e };
                    });
                  }
                }}
              />
            </li>
          )}

          {bell && (
            <li className="buttonList bellButton">
              <ui.button.BasicButton
                buttonText={isBell ? "알람 해제" : "알람 설정"}
                buttonType={isBell ? "basic" : "blackGrayFont"}
                size={"smaller"}
                icon={isBell ? IconBellWhite : IconBellGray}
                onClick={() => {
                  if (authInfo.certification()) {
                    alarmInfo.alarmToggle(setIsBell, isBell)
                  }
                }}
              />
            </li>
          )}
        </ul>
      </div>

      <div className="commentContainer">
        {!facility && (
          <Comment
            replyInfo={replyInfo}
            likeInfo={likeInfo}
          />
        )}
      </div>
    </Styles.Container>
  );
}

export default DetailContainer;
