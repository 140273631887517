import styled from "styled-components";

const Container = styled.div`
  width: 288px;
  height: 312px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 25px 0px rgba(27, 50, 78, 0.15);
  overflow: hidden;

  & .imgWrap {
    height: 200px;
    overflow: hidden;
  }

  & .listTitle {
    height: 112px;
    padding: 25px 21px;
    font-size: 18px;
    font-weight: bold;

    & p {
      display: -webkit-box;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  @media (max-width: 1600px) {
    width: calc(100vw * (288 / 1600));
    height: calc(100vw * (312 / 1600));
    border-radius: calc(100vw * (10 / 1600));
    box-shadow: 0px calc(100vw * (6 / 1600)) calc(100vw * (25 / 1600)) 0px
      rgba(27, 50, 78, 0.15);

    & .imgWrap {
      height: calc(100vw * (200 / 1600));
    }

    & .listTitle {
      height: calc(100vw * (112 / 1600));
      padding: calc(100vw * (25 / 1600)) calc(100vw * (21 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    width: calc(100vw * (250 / 370));
    height: calc(100vw * (275 / 370));
    border-radius: calc(100vw * (10 / 370));
    box-shadow: 0px calc(100vw * (6 / 370)) calc(100vw * (25 / 370)) 0px
      rgba(27, 50, 78, 0.15);

    & .imgWrap {
      height: calc(100vw * (174 / 370));
    }

    & .listTitle {
      height: calc(100vw * (101 / 370));
      padding: calc(100vw * (20 / 370));
      font-size: calc(100vw * (16 / 370));
    }
  }
`;

export { Container };

export default { Container };
