import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom01.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top01.svg";

const Container = styled.div`
  position: relative;
  cursor: pointer;

  & .nowSelect {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 20px;
    color: #ced4df;
    background: url(${IconArrowBottom}) no-repeat calc(100% - 13px) center /
      14px #f9fafc;

    &.on {
      color: var(--black-color-01);
    }

    &.open {
      background-image: url(${IconArrowTop});
    }
  }

  & .optionListWrap {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    max-height: 210px;
    background-color: #fff;
    overflow: auto;
    filter: drop-shadow(4px 10px 20px rgba(0, 0, 0, 0.06));
    border-radius: 0 0 5px 5px;

    & .optionList {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #eff0f2;
      }
    }
  }

  @media (max-width: 1600px) {
    & .nowSelect {
      height: calc(100vw * (52 / 1600));
      padding: 0 calc(100vw * (20 / 1600));
      background: url(${IconArrowBottom}) no-repeat
        calc(100% - calc(100vw * (13 / 1600))) center /
        calc(100vw * (14 / 1600)) #f9fafc;
    }

    & .optionListWrap {
      top: calc(100vw * (52 / 1600));
      max-height: calc(100vw * (210 / 1600));
      filter: drop-shadow(
        calc(100vw * (4 / 1600)) calc(100vw * (10 / 1600))
          calc(100vw * (20 / 1600)) rgba(0, 0, 0, 0.06)
      );
      border-radius: 0 0 calc(100vw * (5 / 1600)) calc(100vw * (5 / 1600));

      & .optionList {
        height: calc(100vw * (40 / 1600));
        padding: 0 calc(100vw * (20 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    & .nowSelect {
      height: calc(100vw * (52 / 370));
      padding: 0 calc(100vw * (15 / 370));
      background: url(${IconArrowBottom}) no-repeat
        calc(100% - calc(100vw * (15 / 370))) center / calc(100vw * (14 / 370))
        #f9fafc;
    }

    & .optionListWrap {
      top: calc(100vw * (52 / 370));
      max-height: calc(100vw * (210 / 370));
      filter: drop-shadow(
        calc(100vw * (4 / 370)) calc(100vw * (10 / 370))
          calc(100vw * (20 / 370)) rgba(0, 0, 0, 0.06)
      );
      border-radius: 0 0 calc(100vw * (5 / 370)) calc(100vw * (5 / 370));

      & .optionList {
        height: calc(100vw * (40 / 370));
        padding: 0 calc(100vw * (20 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
