import styled from "styled-components";

const Container = styled.div`
  & .titleContainer {
    margin-bottom: 60px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--gray-color-02);

    & .title {
      max-width: 1002px;
      margin: 0 auto;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }

    & .detailInfo {
      display: flex;
      gap: 30px;
      justify-content: center;
      margin-top: 10px;

      & span {
        position: relative;
        font-weight: 500;

        &:not(:first-child)::after {
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          width: 1px;
          height: 10px;
          background-color: var(--black-color-01);
          content: "";
        }
      }
    }

    & .bottomContainer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;

      & .reactionContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        color: var(--gray-color-02);

        & .like,
        .comment {
          display: flex;
          align-items: center;

          & .iconWrap {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
        }
      }

      & .right {
        display: flex;
        align-items: center;
        gap: 19px;

        & .iconListWrap {
          display: flex;
          gap: 12px;

          & .iconList {
            width: 50px;
            height: 50px;
          }
        }

        & .bellButton {
          width: 125px;
        }

        & .editButtonListWrap {
          display: flex;
          gap: 9px;

          & .editButtonList {
            width: 80px;
          }
        }
      }
    }
  }

  & .keywordListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 100px 0 50px;
  }

  & .utilContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & .reactionbuttonListWrap,
    & .buttonListWrap {
      display: flex;
      gap: 10px;
    }

    & .reactionbuttonList {
      width: 140px;

      & button {
        border-radius: 5px;
      }

      & .iconWrap {
        width: 24px;
        height: 24px;
      }
    }

    & .buttonList {
      position: relative;
      width: 80px;

      &.bellButton {
        width: 125px;
      }
    }
  }

  @media (max-width: 1600px) {
    & .titleContainer {
      margin-bottom: calc(100vw * (60 / 1600));
      padding-bottom: calc(100vw * (20 / 1600));
      border-bottom: calc(100vw * (2 / 1600)) solid var(--gray-color-02);

      & .title {
        max-width: calc(100vw * (1002 / 1600));
        font-size: calc(100vw * (36 / 1600));
      }

      & .detailInfo {
        gap: calc(100vw * (30 / 1600));
        margin-top: calc(100vw * (10 / 1600));

        & span {
          &:not(:first-child)::after {
            left: calc(100vw * (-15 / 1600));
            height: calc(100vw * (10 / 1600));
          }
        }
      }

      & .bottomContainer {
        margin-top: calc(100vw * (30 / 1600));

        & .reactionContainer {
          gap: calc(100vw * (20 / 1600));

          & .like,
          .comment {
            & .iconWrap {
              width: calc(100vw * (24 / 1600));
              height: calc(100vw * (24 / 1600));
              margin-right: calc(100vw * (5 / 1600));
            }
          }
        }

        & .right {
          gap: calc(100vw * (19 / 1600));

          & .iconListWrap {
            gap: calc(100vw * (12 / 1600));

            & .iconList {
              width: calc(100vw * (50 / 1600));
              height: calc(100vw * (50 / 1600));
            }
          }

          & .bellButton {
            width: calc(100vw * (125 / 1600));
          }

          & .editButtonListWrap {
            gap: calc(100vw * (9 / 1600));

            & .editButtonList {
              width: calc(100vw * (80 / 1600));
            }
          }
        }
      }
    }

    & .keywordListWrap {
      gap: calc(100vw * (10 / 1600));
      margin: calc(100vw * (100 / 1600)) 0 calc(100vw * (50 / 1600));
    }

    & .utilContainer {
      margin-bottom: calc(100vw * (40 / 1600));

      & .reactionbuttonListWrap,
      & .buttonListWrap {
        gap: calc(100vw * (10 / 1600));
      }

      & .reactionbuttonList {
        width: calc(100vw * (140 / 1600));

        & button {
          border-radius: calc(100vw * (5 / 1600));
        }

        & .iconWrap {
          width: calc(100vw * (24 / 1600));
          height: calc(100vw * (24 / 1600));
        }
      }

      & .buttonList {
        width: calc(100vw * (80 / 1600));

        &.bellButton {
          width: calc(100vw * (125 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .titleContainer {
      margin-bottom: calc(100vw * (20 / 370));
      padding-bottom: calc(100vw * (10 / 370));
      border-bottom: calc(100vw * (2 / 370)) solid var(--gray-color-02);

      & .title {
        max-width: 100%;
        font-size: calc(100vw * (16 / 370));
      }

      & .detailInfo {
        flex-wrap: wrap;
        gap: 0 calc(100vw * (20 / 370));
        margin-top: calc(100vw * (10 / 370));

        & span {
          font-size: calc(100vw * (12 / 370));

          &:not(:first-child)::after {
            left: calc(100vw * (-10 / 370));
            height: calc(100vw * (10 / 370));
          }
        }
      }

      & .bottomContainer {
        align-items: center;
        margin-top: calc(100vw * (20 / 370));

        & .reactionContainer {
          gap: calc(100vw * (10 / 370));

          & .like,
          .comment {
            & .iconWrap {
              width: calc(100vw * (18 / 370));
              height: calc(100vw * (18 / 370));
              margin-right: calc(100vw * (3 / 370));
            }
          }
        }

        & .right {
          gap: calc(100vw * (10 / 370));

          & .iconListWrap {
            gap: calc(100vw * (6 / 370));

            & .iconList {
              width: calc(100vw * (24 / 370));
              height: calc(100vw * (24 / 370));
            }
          }

          & .bellButton {
            width: calc(100vw * (83 / 370));
          }

          & .editButtonListWrap {
            display: none;
          }
        }
      }
    }

    & .keywordListWrap {
      gap: calc(100vw * (5 / 370));
      margin: calc(100vw * (20 / 370)) 0 calc(100vw * (30 / 370));
    }

    & .utilContainer {
      flex-direction: column;
      align-items: center;
      gap: calc(100vw * (10 / 370));
      margin-bottom: calc(100vw * (50 / 370));

      & .reactionbuttonListWrap,
      & .buttonListWrap {
        gap: calc(100vw * (10 / 370));
      }

      & .reactionbuttonList {
        width: calc(100vw * (92 / 370));

        & button {
          border-radius: calc(100vw * (5 / 370));
        }

        & .iconWrap {
          width: calc(100vw * (15 / 370));
          height: calc(100vw * (15 / 370));
        }
      }

      & .buttonList {
        width: calc(100vw * (67 / 370));

        &.bellButton {
          width: calc(100vw * (83 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
