import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;
  background-color: #f9fafc;

  & .formContainer {
    width: 616px;
    margin: 80px auto 0;
    background-color: #fff;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);

    & .narrowInner {
      padding: 50px 0 75px;
    }
  }

  & .title {
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: bold;

    & span {
      color: var(--main-color);
    }
  }

  & .inputList {
    margin-bottom: 15px;
  }

  & .loginButton {
    margin-bottom: 10px;
  }

  & .bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    & .findPasswordButton {
      font-weight: 500;
      color: var(--gray-color-02);
    }
  }

  & .snsLoginListWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--gray-color-04);

    & .snsLoginList {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      cursor: pointer;

      & .iconWrap {
        width: 24px;
        height: 24px;
      }
    }
  }

  & .text {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .formContainer {
      width: calc(100vw * (616 / 1600));
      margin: calc(100vw * (80 / 1600)) auto 0;
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .narrowInner {
        padding: calc(100vw * (50 / 1600)) 0 calc(100vw * (75 / 1600));
      }
    }

    & .title {
      margin-bottom: calc(100vw * (50 / 1600));
      font-size: calc(100vw * (26 / 1600));
    }

    & .inputList {
      margin-bottom: calc(100vw * (15 / 1600));
    }

    & .loginButton {
      margin-bottom: calc(100vw * (10 / 1600));
    }

    & .bottomContainer {
      margin-bottom: calc(100vw * (60 / 1600));
    }

    & .snsLoginListWrap {
      margin-bottom: calc(100vw * (30 / 1600));
      padding-bottom: calc(100vw * (30 / 1600));

      & .snsLoginList {
        gap: calc(100vw * (5 / 1600));

        & .iconWrap {
          width: calc(100vw * (24 / 1600));
          height: calc(100vw * (24 / 1600));
        }
      }
    }

    & .text {
      margin-bottom: calc(100vw * (20 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));
    background-color: #fff;

    & .formContainer {
      width: calc(100vw * (320 / 370));
      margin: 0 auto;
      box-shadow: none;

      & .narrowInner {
        padding: calc(100vw * (50 / 370)) 0 0;
      }
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 370));
      font-size: calc(100vw * (22 / 370));
    }

    & .inputList {
      margin-bottom: calc(100vw * (15 / 370));
    }

    & .loginButton {
      margin-bottom: calc(100vw * (15 / 370));
    }

    & .bottomContainer {
      margin-bottom: calc(100vw * (50 / 370));
    }

    & .snsLoginListWrap {
      flex-wrap: wrap;
      gap: calc(100vw * (20 / 370)) calc(100vw * (13 / 370));
      margin-bottom: calc(100vw * (30 / 370));
      padding: 0 calc(100vw * (26 / 370)) calc(100vw * (30 / 370));

      & .snsLoginList {
        width: calc(calc(100% - calc(100vw * (13 / 370))) / 2);
        gap: calc(100vw * (5 / 370));

        & .iconWrap {
          width: calc(100vw * (24 / 370));
          height: calc(100vw * (24 / 370));
        }
      }
    }

    & .text {
      margin-bottom: calc(100vw * (20 / 370));
      font-size: calc(100vw * (16 / 370));
    }
  }
`;

export { Container };

export default { Container };
