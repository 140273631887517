import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .narrowInner {
    padding-top: 80px;

    & .title {
      margin-bottom: 4px;
      font-size: 26px;
      font-weight: bold;
    }

    & .text {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 500;
    }

    & .list:first-child .minusButton {
      display: none;
    }

    & .bottomButtonContainer {
      position: relative;
      display: flex;
      gap: 20px;
      margin-top: 50px;

      & .errorText {
        position: absolute;
        left: 133px;
        top: -75px;
        color: var(--red-color);
      }

      & .buttonWrap {
        flex: 1;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .narrowInner {
      padding-top: calc(100vw * (80 / 1600));

      & .title {
        margin-bottom: calc(100vw * (4 / 1600));
        font-size: calc(100vw * (26 / 1600));
      }

      & .text {
        margin-bottom: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (16 / 1600));
      }

      & .bottomButtonContainer {
        gap: calc(100vw * (20 / 1600));
        margin-top: calc(100vw * (50 / 1600));

        & .errorText {
          left: calc(100vw * (133 / 1600));
          top: calc(100vw * (-75 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 370));

      & .title {
        margin-bottom: calc(100vw * (10 / 370));
        font-size: calc(100vw * (22 / 370));
      }

      & .text {
        margin-bottom: calc(100vw * (30 / 370));
        font-size: calc(100vw * (14 / 370));
      }

      & .bottomButtonContainer {
        gap: calc(100vw * (10 / 370));
        margin-top: calc(100vw * (20 / 370));

        & .errorText {
          left: 0;
          top: calc(100vw * (-45 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
