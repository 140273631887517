import styled from "styled-components";

const Container = styled.div`
  & .emptyText {
    padding: 140px 0 200px;
    font-size: 18px;
    font-weight: 500;
    color: #cfd2d7;
    text-align: center;
    border-bottom: 1px solid var(--gray-color-04);
  }

  & .paginationContainer {
    margin-top: 50px;
  }

  @media (max-width: 1600px) {
    & .emptyText {
      padding: calc(100vw * (140 / 1600)) 0 calc(100vw * (200 / 1600));
      font-size: calc(100vw * (18 / 1600));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 1600));
    }
  }

  @media (max-width: 768px) {
    & .emptyText {
      padding: calc(100vw * (72 / 370)) 0 calc(100vw * (71 / 370));
      font-size: calc(100vw * (14 / 370));
    }

    & .paginationContainer {
      margin-top: calc(100vw * (50 / 370));
    }
  }
`;

export { Container };

export default { Container };
