import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { api } from "service";

export default function useAlarm() {
  const [searchParams] = useSearchParams();

  const getAlarm = useQuery(["alarm_list"], () => {
    return api.alarm.getAlarm();
  }, { refetchOnWindowFocus: false });

  const alarmRead = async (id) => {
    return await api.alarm.alarmRead(id);
  }

  const alarmToggle = async (setIsBell, isBell, id) => {
    const result = await api.alarm.alarmToggle(id ? id : searchParams?.get("id"));
    result && setIsBell(!isBell);
  };

  return { getAlarm, alarmRead, alarmToggle }
}