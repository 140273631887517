import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .resultSection {
    padding-top: 100px;

    & .topContainer {
      margin-bottom: 30px;
      padding-bottom: 80px;
      border-bottom: 3px solid var(--black-color-01);

      & .resultTitle {
        margin-bottom: 50px;
        font-size: 36px;
        font-weight: 500;
        text-align: center;
      }

      & .searchContainer {
        width: 939px;
        margin: 0 auto 20px;

        & input {
          height: 65px;
          font-size: 18px;
          border-radius: 10px;
        }

        & .searchButton {
          width: 30px;
          height: 30px;
        }
      }

      & .keywordContainer {
        width: 939px;
        margin: 0 auto;

        & .keywordTitle {
          margin-bottom: 15px;
          font-weight: bold;
        }

        & .keywordListWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }
      }
    }

    & .resultText {
      font-weight: 500;
      color: #9d9d9d;

      & span {
        color: var(--black-color-01);
      }
    }

    & .list:not(:last-child) {
      border-bottom: 1px solid #cfd2d7;
    }

    & .emptyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;

      & .iconWrap {
        width: 70px;
        height: 61px;
        margin-bottom: 30px;
      }

      & .emptyTitle {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: bold;
      }

      & .emptyText {
        font-size: 16px;
        font-weight: 500;
        color: var(--gray-color-02);
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .resultSection {
      padding-top: calc(100vw * (100 / 1600));

      & .topContainer {
        margin-bottom: calc(100vw * (30 / 1600));
        padding-bottom: calc(100vw * (80 / 1600));
        border-bottom: calc(100vw * (3 / 1600)) solid var(--black-color-01);

        & .resultTitle {
          margin-bottom: calc(100vw * (50 / 1600));
          font-size: calc(100vw * (36 / 1600));
        }

        & .searchContainer {
          width: calc(100vw * (939 / 1600));
          margin: 0 auto calc(100vw * (20 / 1600));

          & input {
            height: calc(100vw * (65 / 1600));
            font-size: calc(100vw * (18 / 1600));
            border-radius: calc(100vw * (10 / 1600));
          }

          & .searchButton {
            width: calc(100vw * (30 / 1600));
            height: calc(100vw * (30 / 1600));
          }
        }

        & .keywordContainer {
          width: calc(100vw * (939 / 1600));

          & .keywordTitle {
            margin-bottom: calc(100vw * (15 / 1600));
          }

          & .keywordListWrap {
            gap: calc(100vw * (12 / 1600));
          }
        }
      }

      & .emptyContainer {
        padding-top: calc(100vw * (50 / 1600));

        & .iconWrap {
          width: calc(100vw * (70 / 1600));
          height: calc(100vw * (61 / 1600));
          margin-bottom: calc(100vw * (30 / 1600));
        }

        & .emptyTitle {
          margin-bottom: calc(100vw * (10 / 1600));
          font-size: calc(100vw * (22 / 1600));
        }

        & .emptyText {
          font-size: calc(100vw * (16 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .resultSection {
      padding-top: calc(100vw * (50 / 370));

      & .topContainer {
        margin-bottom: calc(100vw * (20 / 370));
        padding-bottom: calc(100vw * (50 / 370));
        border-bottom: calc(100vw * (3 / 370)) solid var(--black-color-01);

        & .resultTitle {
          margin-bottom: calc(100vw * (20 / 370));
          font-size: calc(100vw * (18 / 370));
        }

        & .searchContainer {
          width: 100%;
          margin: 0 auto calc(100vw * (20 / 370));

          & input {
            height: calc(100vw * (48 / 370));
            font-size: calc(100vw * (14 / 370));
            border-radius: calc(100vw * (4 / 370));
          }

          & .searchButton {
            width: calc(100vw * (18 / 370));
            height: calc(100vw * (18 / 370));
          }
        }

        & .keywordContainer {
          width: 100%;

          & .keywordTitle {
            margin-bottom: calc(100vw * (10 / 370));
          }

          & .keywordListWrap {
            gap: calc(100vw * (5 / 370));
          }
        }
      }

      & .list {
        border-bottom: 1px solid #cfd2d7;
      }

      & .emptyContainer {
        padding-top: calc(100vw * (30 / 370));

        & .iconWrap {
          width: calc(100vw * (35 / 370));
          height: calc(100vw * (31 / 370));
          margin-bottom: calc(100vw * (20 / 370));
        }

        & .emptyTitle {
          margin-bottom: calc(100vw * (6 / 370));
          font-size: calc(100vw * (16 / 370));
        }

        & .emptyText {
          font-size: calc(100vw * (14 / 370));
        }
      }

      & .paginationContainer {
        margin-top: calc(100vw * (30 / 370));
      }
    }
  }
`;

export { Container };

export default { Container };
