import styled from "styled-components";

const Container = styled.div`
  color: var(--gray-color-04);
  background-color: var(--black-color-02);

  & .buttonListWrap {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 9;

    &.on::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(34, 34, 34, 0.6);
      content: "";
    }

    & .buttonList {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & button,
      & a {
        width: 60px;
        height: 60px;
      }

      & .buttonText {
        position: absolute;
        top: 50%;
        right: calc(100% + 10px);
        transform: translateY(-50%);
        width: max-content;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }

  & .fnb,
  .bottomContainer {
    display: flex;
    align-items: center;
  }

  & .fnb {
    justify-content: space-between;
    height: 60px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-color-01);

    & .fnbListWrap {
      display: flex;
      gap: 29px;
      cursor: pointer;

      & .fnbList:first-child {
        color: var(--main-color);
      }
    }
  }

  & .bottomContainer {
    height: 171px;

    & .bottomWrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    & .logo {
      width: 130px;
      height: 22px;
      margin-right: 50px;
    }

    & .info {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;
      max-width: 500px;
    }

    & .copyright {
      margin-left: auto;
    }
  }

  @media (max-width: 1600px) {
    & .buttonListWrap {
      bottom: calc(100vw * (30 / 1600));
      right: calc(100vw * (50 / 1600));

      & .buttonList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1600));
        }

        & button,
        & a {
          width: calc(100vw * (60 / 1600));
          height: calc(100vw * (60 / 1600));
        }

        & .buttonText {
          right: calc(100% + calc(100vw * (10 / 1600)));
          font-size: calc(100vw * (16 / 1600));
        }
      }
    }

    & .fnb {
      height: calc(100vw * (60 / 1600));

      & .fnbListWrap {
        gap: calc(100vw * (29 / 1600));
      }
    }

    & .bottomContainer {
      height: calc(100vw * (171 / 1600));

      & .logo {
        width: calc(100vw * (130 / 1600));
        height: calc(100vw * (22 / 1600));
        margin-right: calc(100vw * (50 / 1600));
      }
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (12 / 370));

    & .buttonListWrap {
      bottom: calc(100vw * (48 / 370));
      right: calc(100vw * (25 / 370));

      & .buttonList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (8 / 370));
        }

        & button,
        & a {
          width: calc(100vw * (50 / 370));
          height: calc(100vw * (50 / 370));
        }

        & .buttonText {
          right: calc(100% + calc(100vw * (10 / 370)));
          font-size: calc(100vw * (16 / 370));
        }
      }
    }

    & .fnb {
      height: calc(100vw * (55 / 370));

      & .fnbListWrap {
        gap: calc(100vw * (16 / 370));
      }
    }

    & .bottomContainer {
      height: calc(100vw * (320 / 370));
      padding: calc(100vw * (40 / 370)) 0;

      & .bottomWrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
      }

      & .logo {
        width: calc(100vw * (130 / 370));
        height: calc(100vw * (22 / 370));
        margin-right: 0;
        margin-bottom: calc(100vw * (20 / 370));
      }

      & .info {
        flex-direction: column;
        gap: calc(100vw * (12 / 370));
        width: 100%;

        & span.mbOnly {
          display: inline-block;
        }
      }

      & .copyright {
        margin: auto 0 0 0;
      }
    }
  }
`;

export { Container };

export default { Container };
