import styled from "styled-components";

// img
import IconArrowLeftCircle from "resources/image/icon/icon_arrow_left_circle.svg";
import IconArrowRightCircle from "resources/image/icon/icon_arrow_right_circle.svg";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & section:not(.mainBannerSection, :last-child) {
    margin-bottom: 80px;
  }

  & .moreButton {
    display: flex;
    align-items: center;
    font-size: 16px;

    //* 동현 퍼블 수정한 코드
    /* font-weight: 500; */
    font-weight: bold;

    & .iconWrap {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  & .mainBannerSection {
    position: relative;

    & .swiper-slide a {
      height: 742px;
    }

    & .swiper-pagination {
      bottom: 376px;
      top: auto;
      left: calc(50% - 704px);
      display: flex;
      gap: 5px;
      width: fit-content;

      & .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0;
        opacity: 1;
        background-color: var(--black-color-01);
        border-radius: 500px;
        transition: 0.3s;
      }

      & .swiper-pagination-bullet-active {
        width: 20px;
      }
    }
  }

  & .calendarSection {
    position: relative;
    margin: -157px auto 0;
    padding: 37px 48px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);
    z-index: 1;
  }

  & .locationSection {
    & .middleInner {
      display: flex;
      justify-content: space-between;

      & .locationContainer {
        width: 653px;

        & .titleContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          padding-bottom: 20px;
          border-bottom: 2px solid #f0f2f4;

          & .title {
            font-size: 32px;
            font-weight: bold;
          }
        }

        & .list {
          &:nth-of-type(odd) {
            background-color: #f9fafc;
          }
        }
      }
    }
  }

  & .recommendedBlogSection {
    padding: 68px 0 90px;
    background-color: var(--black-color-01);

    & .title {
      margin-bottom: 68px;
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }

    & .middleInner {
      position: relative;

      & .moreButton {
        position: absolute;
        bottom: calc(100% + 81px);
        right: 0;
        color: #fff;
      }

      & .swiper-slide {
        width: fit-content;
        cursor: pointer;
      }
    }

    & .prevButton,
    .nextButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      z-index: 1;

      &::after {
        display: none;
      }
    }

    & .prevButton {
      left: -50px;
    }

    & .nextButton {
      right: -50px;
    }
  }

  & .subBannerSection {
    & .swiper-slide a {
      height: 230px;
    }

    & .swiper-button-prev,
    .swiper-button-next {
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      margin-top: 0;

      &::after {
        display: none;
      }
    }

    & .swiper-button-prev {
      left: 50px;
      background: url(${IconArrowLeftCircle}) no-repeat center / cover;
    }

    & .swiper-button-next {
      right: 50px;
      background: url(${IconArrowRightCircle}) no-repeat center / cover;
    }
  }

  & .infoSection {
    & .title {
      margin-bottom: 30px;
      font-size: 32px;
      font-weight: bold;
    }

    & .infoArea {
      display: flex;
      justify-content: space-between;

      & .infoContainer {
        width: 672px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 19px 35px 0px rgba(15, 40, 102, 0.1);
        overflow: hidden;

        & .titleContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 72px;
          padding: 0 30px;
          background-color: var(--gray-color-04);

          & .infoTitle {
            font-size: 18px;
            font-weight: bold;
          }
        }

        & .listWrap {
          padding: 5px 30px;
          cursor: pointer;

          & .list:not(:last-child) {
            border-bottom: 1px solid var(--gray-color-04);
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & section:not(.mainBannerSection, :last-child) {
      margin-bottom: calc(100vw * (80 / 1600));
    }

    & .moreButton {
      font-size: calc(100vw * (16 / 1600));

      & .iconWrap {
        width: calc(100vw * (16 / 1600));
        height: calc(100vw * (16 / 1600));
      }
    }

    & .mainBannerSection {
      & .swiper-slide a {
        height: calc(100vw * (742 / 1600));
      }

      & .swiper-pagination {
        bottom: calc(100vw * (376 / 1600));
        left: calc(50% - calc(100vw * (704 / 1600)));
        gap: calc(100vw * (5 / 1600));

        & .swiper-pagination-bullet {
          width: calc(100vw * (6 / 1600));
          height: calc(100vw * (6 / 1600));
          border-radius: calc(100vw * (500 / 1600));
        }

        & .swiper-pagination-bullet-active {
          width: calc(100vw * (20 / 1600));
        }
      }
    }

    & .calendarSection {
      margin: calc(100vw * (-157 / 1600)) auto 0;
      padding: calc(100vw * (37 / 1600)) calc(100vw * (48 / 1600));
      border-radius: calc(100vw * (20 / 1600));
      box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600)) 0px
        rgba(15, 40, 102, 0.1);

      & .calendarContainer {
        width: calc(100vw * (612 / 1600));
      }
    }

    & .locationSection {
      & .middleInner {
        & .locationContainer {
          width: calc(100vw * (653 / 1600));

          & .titleContainer {
            margin-bottom: calc(100vw * (30 / 1600));
            padding-bottom: calc(100vw * (20 / 1600));
            border-bottom: calc(100vw * (2 / 1600)) solid #f0f2f4;

            & .title {
              font-size: calc(100vw * (32 / 1600));
            }
          }
        }
      }
    }

    & .recommendedBlogSection {
      padding: calc(100vw * (68 / 1600)) 0 calc(100vw * (90 / 1600));

      & .title {
        margin-bottom: calc(100vw * (68 / 1600));
        font-size: calc(100vw * (36 / 1600));
      }

      & .middleInner {
        & .moreButton {
          bottom: calc(100% + calc(100vw * (81 / 1600)));
        }
      }

      & .prevButton,
      .nextButton {
        width: calc(100vw * (40 / 1600));
        height: calc(100vw * (40 / 1600));
      }

      & .prevButton {
        left: calc(100vw * (-50 / 1600));
      }

      & .nextButton {
        right: calc(100vw * (-50 / 1600));
      }
    }

    & .subBannerSection {
      & .swiper-slide a {
        height: calc(100vw * (230 / 1600));
      }

      & .swiper-button-prev,
      .swiper-button-next {
        width: calc(100vw * (32 / 1600));
        height: calc(100vw * (32 / 1600));
      }

      & .swiper-button-prev {
        left: calc(100vw * (50 / 1600));
      }

      & .swiper-button-next {
        right: calc(100vw * (50 / 1600));
      }
    }

    & .infoSection {
      & .title {
        margin-bottom: calc(100vw * (30 / 1600));
        font-size: calc(100vw * (32 / 1600));
      }

      & .infoArea {
        & .infoContainer {
          width: calc(100vw * (672 / 1600));
          border-radius: calc(100vw * (5 / 1600));
          box-shadow: 0px calc(100vw * (19 / 1600)) calc(100vw * (35 / 1600))
            0px rgba(15, 40, 102, 0.1);

          & .titleContainer {
            height: calc(100vw * (72 / 1600));
            padding: 0 calc(100vw * (30 / 1600));

            & .infoTitle {
              font-size: calc(100vw * (18 / 1600));
            }
          }

          & .listWrap {
            padding: calc(100vw * (5 / 1600)) calc(100vw * (30 / 1600));
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (170 / 370));

    & section:not(.mainBannerSection, :last-child) {
      margin-bottom: calc(100vw * (60 / 370));
    }

    & .moreButton {
      font-size: calc(100vw * (14 / 370));

      & .iconWrap {
        width: calc(100vw * (16 / 370));
        height: calc(100vw * (16 / 370));
      }
    }

    & .mainBannerSection {
      & .swiper-slide a {
        height: calc(100vw * (519 / 370));
      }

      & .swiper-pagination {
        bottom: calc(100vw * (130 / 370));
        left: 50%;
        transform: translateX(-50%);
        gap: calc(100vw * (5 / 370));

        & .swiper-pagination-bullet {
          width: calc(100vw * (6 / 370));
          height: calc(100vw * (6 / 370));
          border-radius: calc(100vw * (500 / 370));
        }

        & .swiper-pagination-bullet-active {
          width: calc(100vw * (20 / 370));
        }
      }
    }

    & .calendarSection {
      margin: calc(100vw * (-90 / 370)) auto 0;
      padding: calc(100vw * (15 / 370)) calc(100vw * (15 / 370))
        calc(100vw * (30 / 370));
      border-radius: calc(100vw * (20 / 370));
      box-shadow: 0px calc(100vw * (19 / 370)) calc(100vw * (35 / 370)) 0px
        rgba(15, 40, 102, 0.1);

      & .calendarContainer {
        width: 100%;
      }
    }

    & .locationSection {
      & .middleInner {
        flex-direction: column;
        gap: calc(100vw * (60 / 370));

        & .locationContainer {
          width: 100%;

          & .titleContainer {
            margin-bottom: calc(100vw * (20 / 370));
            padding-bottom: calc(100vw * (20 / 370));
            border-bottom: calc(100vw * (2 / 370)) solid #f0f2f4;

            & .title {
              font-size: calc(100vw * (20 / 370));
            }
          }
        }
      }
    }

    & .recommendedBlogSection {
      padding: calc(100vw * (40 / 370)) 0 calc(100vw * (60 / 370));

      & .title {
        margin-bottom: calc(100vw * (40 / 370));
        font-size: calc(100vw * (20 / 370));
      }

      & .middleInner {
        width: 100%;

        & .moreButton {
          right: calc(100vw * (25 / 370));
          bottom: calc(100% + calc(100vw * (45 / 370)));
        }
      }

      & .prevButton,
      .nextButton {
        width: calc(100vw * (22 / 370));
        height: calc(100vw * (22 / 370));
      }

      & .prevButton {
        left: calc(100vw * (32 / 370));
      }

      & .nextButton {
        right: calc(100vw * (32 / 370));
      }
    }

    & .subBannerSection {
      & .middleInner {
        width: 100%;
      }

      & .swiper-slide a {
        height: calc(100vw * (230 / 370));
      }

      & .swiper-button-prev,
      .swiper-button-next {
        width: calc(100vw * (22 / 370));
        height: calc(100vw * (22 / 370));
      }

      & .swiper-button-prev {
        left: calc(100vw * (25 / 370));
      }

      & .swiper-button-next {
        right: calc(100vw * (25 / 370));
      }
    }

    & .infoSection {
      & .title {
        margin-bottom: calc(100vw * (20 / 370));
        font-size: calc(100vw * (20 / 370));
      }

      & .infoArea {
        flex-direction: column;
        gap: calc(100vw * (60 / 370));

        & .infoContainer {
          width: 100%;
          border-radius: calc(100vw * (5 / 370));
          box-shadow: 0 calc(100vw * (19 / 370)) calc(100vw * (35 / 370)) 0
            rgba(15, 40, 102, 0.1);

          & .titleContainer {
            height: calc(100vw * (50 / 370));
            padding: 0 calc(100vw * (15 / 370));

            & .infoTitle {
              font-size: calc(100vw * (14 / 370));
            }

            & .moreButton {
              font-size: calc(100vw * (12 / 370));
            }
          }

          & .listWrap {
            padding: 0 calc(100vw * (15 / 370)) calc(100vw * (20 / 370));
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
