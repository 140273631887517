import Styles from "./styles";

// img
import IconDelete from "resources/image/icon/icon_delete02.svg";

function KeywordTag({ keyword, del, onClick }) {
  return (
    <Styles.Container className={del ? "del" : ""}>
      #{keyword}
      {del && (
        <button type="button" className="deleteButton" onClick={onClick}>
          <img src={IconDelete} alt="IconDelete" />
        </button>
      )}
    </Styles.Container>
  );
}

export default KeywordTag;
