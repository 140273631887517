import utils from "utils";
import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useBoard, useKeyword } from "hooks";

// img
import IconBubble from "resources/image/icon/icon_bubble.svg";

function SearchPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState();
  const [filter, setFilter] = useState({ keyword: "", page: 1 });

  const authInfo = useAuth();
  const keywordInfo = useKeyword();
  const boardInfo = useBoard({ searchOption: filter });

  useEffect(() => {
    let param = utils.etc.getSearchParamNew();
    let f = {
      page: param.page ? param.page : 1,
      keyword: param.keyword ? decodeURIComponent(param.keyword) : "",
      recordSize: 10,
    }
    setFilter(f);
  }, [location]);

  const result = boardInfo.getBoardSearch.data || []

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section className="resultSection">
          <div className="middleInner">
            <div className="topContainer">
              <p className="resultTitle">
                <b>'{filter.keyword}'</b> 의 검색결과
              </p>

              <div className="searchContainer">
                <layout.SearchContainer
                  search={search}
                  setSearch={setSearch}
                />
              </div>

              <div className="keywordContainer">
                <p className="keywordTitle">인기 키워드</p>

                <ul className="keywordListWrap">
                  {keywordInfo.getBestKeyword?.data?.map((item, index) => {
                    return (
                      <li
                        className="keywordList"
                        key={"keywordList" + index}
                        onClick={() => {
                          navigate(utils.etc.getQueryParamUrl({ keyword: item.title, page: 1 }, true))
                        }}
                      >
                        <ui.button.KeywordTag keyword={item.title} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="resultText">
              검색결과 <span>총 {result?.pagination?.totalRecordCountAll}개</span> 입니다.
            </div>

            {result?.list?.length > 0 ? (
              <>
                <ul className="listWrap">
                  {result?.list?.map((item, index) => {
                    return (
                      <li
                        className="list"
                        key={"list" + index}
                        onClick={() => {
                          if (authInfo.certification()) {
                            navigate(boardInfo.boardLocation(item.type) + "?id=" + item.id, {
                              state: `/search?keyword=${filter.keyword}&page=${filter.page}`
                            })
                          }
                        }}
                      >
                        <layout.EducationList big data={item} />
                      </li>
                    );
                  })}
                </ul>

                <div className="paginationContainer">
                  <ui.button.PaginationNew
                    page={filter?.page}
                    maxPage={result?.pagination?.endPage}
                    list={result?.pagination?.page_list}
                  />
                </div>
              </>
            ) : (
              <div className="emptyContainer">
                <div className="iconWrap">
                  <img src={IconBubble} alt="IconBubble" />
                </div>

                <p className="emptyTitle">찾으시는 검색 결과가 없습니다.</p>

                <p className="emptyText">다른 키워드로 검색해보세요.</p>
              </div>
            )}
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SearchPage;
