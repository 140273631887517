import { api } from "service";
import { useQuery } from "react-query";

export default function useKeyword() {

  const getBestKeyword = useQuery(["best_keyword"], () => { return api.keyword.getBestKeyword() },
  { refetchOnWindowFocus: false });
   
  return { getBestKeyword }
}
