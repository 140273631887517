import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 231px);
  padding: 80px 0 200px;

  & .contentsSection {
    margin-top: -80px;

    & .inner {
      padding: 80px 63px 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #fff;

      & .tabContainer {
        margin-bottom: 60px;
        padding-bottom: 30px;
        border-bottom: 1px solid #cfd2d7;
      }
    }
  }

  @media (max-width: 1600px) {
    min-height: calc(100vh - calc(100vw * (231 / 1600)));
    padding: calc(100vw * (80 / 1600)) 0 calc(100vw * (200 / 1600));

    & .contentsSection {
      margin-top: calc(100vw * (-80 / 1600));

      & .inner {
        padding: calc(100vw * (80 / 1600)) calc(100vw * (63 / 1600)) 0;
        border-top-left-radius: calc(100vw * (10 / 1600));
        border-top-right-radius: calc(100vw * (10 / 1600));

        & .tabContainer {
          margin-bottom: calc(100vw * (60 / 1600));
          padding-bottom: calc(100vw * (30 / 1600));
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - calc(100vw * (273 / 370)));
    padding: calc(100vw * (50 / 370)) 0 calc(100vw * (150 / 370));

    & .contentsSection {
      margin-top: calc(100vw * (-30 / 370));

      & .inner {
        padding: calc(100vw * (30 / 370)) calc(100vw * (15 / 370)) 0;
        border-top-left-radius: calc(100vw * (5 / 370));
        border-top-right-radius: calc(100vw * (5 / 370));

        & .tabContainer {
          margin-bottom: calc(100vw * (20 / 370));
          padding-bottom: calc(100vw * (27 / 370));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
