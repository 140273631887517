import useModals from "hooks/useModals";
import { api } from "service";
import { DOMAIN } from "service/connector";
import { useAtom } from "jotai";
import { authAtom } from "store/atoms";
import { useQuery } from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// img
import IconCheck from "resources/image/icon/icon_check.svg";
import IconBell from "resources/image/icon/icon_bell.svg";

export default function useAuth() {
  const modalOption = useModals();
  const navigate = useNavigate();

  const [errorText, setErrorText] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useAtom(authAtom.userInfo);
  const isLogin = userData.nickname == "" || userData.nickname == null ? false : true;

  const userQuery = useQuery("userData", api.auth.getUser, {
    onSuccess: (e) => {
      if (e == null) {
      } else {
        if (window.location.pathname.includes("landing")) {
          navigate("/");
        }
        setUserData(e);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });

  const findNickName = async (userNickName) => {
    return await api.auth.getCheckName(userNickName);
  }
  
  const certification = (existPass = true) => {
    if (isLogin === false) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconBell;
        e.title = "로그인이 필요한 서비스입니다.";
        e.text = (
          <>
            로그인 후 에듀소스의 <br />
            다양한 콘텐츠를 즐겨보세요!
          </>
        );
        e.confirmButtonText = "로그인 후 이용하기";
        e.confirmButtonClick = () => {
          navigate("/login");
        };
        e.cancelButtonText = "나중에 하기";
        e.cancelButtonClick = false;
        return { ...e };
      });
      return false;
    }

    if (isLogin && existPass && userData.pass_yn === "N") {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconBell;
        e.title = "실명 인증 후 사용 가능합니다.";
        e.text = "인증 후 더 많은 서비스를 이용해보세요!";
        e.confirmButtonText = "실명인증 하기";
        e.confirmButtonClick = () => {
          navigate("/parents-confirm", { state: true });
        };
        e.cancelButtonText = "나중에 하기";
        e.cancelButtonClick = false;
        return { ...e };
      });
      return false;
    }
    return true;
  }

  const login = async (loginStateCheck, password, email) => {
    const postData = new FormData();
    postData.append("flag", loginStateCheck);
    postData.append("password", password);
    postData.append("username", email);

    const result = await api.auth.login(postData)
    if (result?.success) {
      loginStateCheck
        ? document.cookie = `jwt=${result.data};path=/;domain=${DOMAIN};max-age=${(7600*24*24)}`
        : document.cookie = `jwt=${result.data};path=/;domain=${DOMAIN};expires=0`
      userQuery.refetch();
      navigate("/");
      return true;
    }else{
      setErrorText("아이디 또는 비밀번호를 다시 확인해주세요.");
      return false;
    }
  }

  const socialLogin = async (type) => {
    return await api.auth.socialLogin(type);
  }
  const socialSignUp = async (postData) => {
    const result = await api.auth.socialSignUp(postData);
    if (result.success) {
      sessionStorage.removeItem("signUp");
      navigate("/social/complete", { state: true });
    }
  }
  const getNice = async () => {
    const result = await api.auth.getNice();
    return result.success && result.sEncData;
  }

  const logout = async (location = true) => {
    setUserData(authAtom.userInfo);
    document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
    location && window.location.replace("https://edusource.co.kr:8080/logout");
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=.${DOMAIN};`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=${DOMAIN};`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=.localhost.org;`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=localhost.org;`;
  };

  const changeByEmail = async (email, setErrorText) => {
    const result = await api.auth.changeByEmail(email)
    if (result?.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.icon = IconCheck;
        e.title = "임시 비밀번호가 발송되었습니다.";
        e.text = "로그인 후 비밀번호를 변경해주세요.";
        e.confirmButtonText = "로그인";
        e.confirmButtonClick = () => { navigate("/login"); };
        e.cancelButtonText = false;
        return { ...e };
      });
    } else {
      setErrorText(result?.description)
    }
  }

  const changeByDirectly = async (nowPassword, newPassword, setErrorText) => {
    const postData = new FormData();
    postData.append("password", nowPassword);
    postData.append("new_password", newPassword);
    
    const result = await api.auth.changeByDirectly(postData);
    if (result?.success) {
      logout(false);
      navigate("complete", { state: true })
    } else {
      setErrorText(e => { return { ...e, MyPasswordProblem: result.description } })
    }
  }

  const signUp = async (postData) => {
    const result = await api.auth.signUp(postData);
    if (result?.success) {
      sessionStorage.removeItem("signUp");
      userQuery.refetch();
      navigate("/signup/complete", { state: true });
      return result;
    } else {
      return alert("이미 가입된 회원입니다.")
    }
  }

  const resign = async (reason, textarea, modalClose) => {
    const postData = new FormData();
    postData.append("reason", reason);
    postData.append("withdraw_comment", reason == "기타(직접 입력)" ? textarea : "-");

    const result = await api.auth.resign(postData);
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        modalClose()
        e.show = true;
        e.icon = IconCheck;
        e.title = "회원 탈퇴가 완료되었습니다.";
        e.text = "에듀소스를 이용해주셔서 감사합니다.";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = logout;
        e.cancelButtonText = false;
        navigate("/")
        return { ...e };
      });
    }
  }

  return {
    errorText,
    setErrorText,

    userData,
    setUserData,
    isLogin,

    isLoad: userQuery.isLoading,
    getNice,
    
    findNickName,

    certification,

    login,
    logout,
    socialLogin,
    socialSignUp,

    changeByEmail,
    changeByDirectly,

    signUp,
    resign,

    isMenuOpen,
    setIsMenuOpen
  }
}