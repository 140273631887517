import Styles from "./styles";
import ui from "components/ui";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div
        className={
          "modalContainer" + (modalOption.cancelButtonText ? " big" : "")
        }
      >
        <div className="iconWrap">
          <img src={modalOption.icon} alt={modalOption.icon} />
        </div>

        <p className="title">{modalOption.title}</p>

        <p className="text">{modalOption.text}</p>

        <ul className="buttonListWrap">
          <li className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={modalOption.confirmButtonText}
              buttonType={"basic"}
              size={"small"}
              onClick={() => {
                modalClose();
                {
                  modalOption.confirmButtonClick &&
                    modalOption.confirmButtonClick();
                }
              }}
            />
          </li>

          {modalOption.cancelButtonText && (
            <li className="buttonList cancelButton">
              <ui.button.BasicButton
                buttonText={modalOption.cancelButtonText}
                buttonType={"white"}
                size={"small"}
                onClick={() => {
                  modalClose();
                  {
                    modalOption.cancelButtonClick &&
                      modalOption.cancelButtonClick();
                  }
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default DefaultModal;
