import Styles from "./styles";
import { useParams } from "react-router-dom";
import { IMAGEPATH } from "service/connector";
import { common, ui } from "components";
import { useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useAuth, useBoard, useCategory, useImage } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete02.svg";

function EditPage() {
  const authInfo = useAuth();
  const categoryInfo = useCategory();
  const imageInfo = useImage();
  const boardInfo = useBoard({ options: { enableDetail: true } });

  let blockClicks = false;
  const { id } = useParams();

  const [listModalOpen, setListModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({ image: null, thumbnail: null, name: "" });

  const [selectCategory, setSelectCategory] = useState([]);
  const [enteredKeyword, setEnteredKeyword] = useState("");
  const [myKeywordList, setMyKeywordList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const detailData = boardInfo.detail;

  // 초기값 셋팅
  useEffect(() => {
    setTitle(detailData?.title);
    setDescription(detailData?.description);
    setImage(e => { return { ...e, thumbnail: detailData?.image, name: detailData?.image_name } });
    setMyKeywordList(
      detailData?.keyword_list?.map((item) => {
        return item.title;
      })
    );
    setSelectCategory(
      categoryInfo.getCategory?.data?.filter((e) => {
        return e?.title === detailData?.category_title;
      })[0]
    );
  }, [detailData.id]);

  useEffect(() => {
    authInfo.isLoad === false && authInfo.certification();
    enteredKeyword.trim() === "" ? setKeywordList([]) : printKeywordList();
  }, [enteredKeyword]);

  const printKeywordList = async () => {
    let result = await boardInfo.getBoardKeyword(enteredKeyword.trim());
    setKeywordList(
      result.map((item) => {
        return item.title;
      })
    );
  };

  const validate = () => {
    if (id !== "education-info" && selectCategory.id === "") {
      alert("카테고리를 선택해주세요.");
      return false;
    }
    if (id === "blog" && image.thumbnail === null) {
      alert("썸네일을 추가해주세요.");
      return false;
    }
    if (title.trim() === "") {
      alert("제목을 입력해주세요.");
      return false;
    }
    if (description === "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    if (myKeywordList.length === 0) {
      alert("키워드를 입력해주세요.");
      return false;
    }
    if (blockClicks) {
      alert("등록중입니다. 잠시만 기다려 주세요.");
      return false;
    }
    return true;
  };

  const onWriteCompleted = () => {
    if (validate()) {
      blockClicks = true;
      const descriptionText = description
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/&nbsp;/gi, ' ')
        .replace(/&amp;/gi, '&')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&quot;/gi, '"')
        .replace(/&#039;/gi, "'");

      const postData = new FormData();
      id !== "education-info" &&
        postData.append("category_info_id", selectCategory.id);
      postData.append("description", description);
      postData.append("description_text", descriptionText);
      myKeywordList.length > 0 &&
        myKeywordList.map((item) => {
          postData.append("keyword_list", item);
        });
      postData.append("subtitle", "subtitle");
      postData.append("title", title);
      postData.append("type", categoryInfo.renderTitleInfo(id).id);
      image.image && postData.append("image", image.image);
      image.thumbnail && postData.append("thumbnail", image.thumbnail);

      boardInfo.editBoard(postData, blockClicks);
    }
  };

  const handleUploadFiles = async (e) => {
    const maxSize = 20971520; // 20MB
    if (e.target.files[0].size < maxSize) {
      let fileName = e.target.value;
      let fileData = [];
      fileData.push(await imageInfo.imageUpload(e.target.files[0]));
      
      setImage((v) => {
        return {...v, ...fileData[0], name: fileName};
      });
    }
  };

  return (
    authInfo.userData.pass_yn === "Y" &&
    detailData?.my_yn === "Y" &&
    categoryInfo.renderTitleInfo(id).type === detailData.type && (
      <>
        <common.Header />
        <Styles.Container>
          <section className="writingSection inner">
            <div className="middleInner">
              <div className="titleContainer">
                <h2 className="sectionTitle">
                  {categoryInfo.renderTitleInfo(id)?.title} 작성하기
                </h2>
                <p className="guideText">
                  작성하신 게시물이 저작권을 침해 했을 경우, “에듀소스”측에 의해서 임의로 삭제됩니다.
                </p>
              </div>

              {id !== "education-info" && (
                <div className="categoryContainer">
                  <ui.input.SecondSelect
                    optionList={categoryInfo.getCategory?.data}
                    placeholder={detailData?.category_title}
                    selectCategory={selectCategory?.title}
                    setSelectCategory={setSelectCategory}
                  />
                </div>
              )}

              {id === "blog" && (
                <div className="thumbnailContainer">
                  <div className="imgWrap">
                    {image.thumbnail === null ? (
                      <></>
                    ) : (
                      <img src={IMAGEPATH + image.thumbnail} />
                    )}
                  </div>
                  <div className="uploadContainer">
                    <div className="inputWrap">
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        id="thumbnail"
                        onChange={(e) => {
                          handleUploadFiles(e)
                        }}
                      />
                      <div className="fileText">
                        <p>{image.name}</p>
                        {image.thumbnail !== null && (
                          <button
                            type="button"
                            className="deleteButton"
                            onClick={() => {
                              setImage({ image: null, thumbnail: null, name: "" });
                            }}
                          >
                            <img src={IconDelete} alt="IconDelete" />
                          </button>
                        )}
                      </div>
                      <label htmlFor="thumbnail">파일 첨부</label>
                    </div>

                    <p className="grayText">최대 20MB의 이미지 파일</p>
                  </div>
                </div>
              )}

              <div className="titleInputWrap">
                <input
                  type="text"
                  placeholder="제목"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>

              <div className="editor ck-editor">
                <common.CustomCkEditor
                  text={description}
                  setText={setDescription}
                  blog={id === "blog"}
                />
              </div>

              <div className="keywordContainer">
                {/* 가이드 추가 **/}
                <p className="guideText">
                  “키워드 등록” 버튼을 통해 1개씩 키워드 추가가 <br className="mbOnly"/>
                  가능합니다.
                </p>
                <div className="enterContainer">
                  <div
                    className={
                      "inputWrap" + (enteredKeyword !== "" ? " on" : "")
                    }
                  >
                    <ui.input.BasicInput
                      placeholder={"#키워드를 입력해 보세요.(최대 10개)"}
                      value={enteredKeyword}
                      setValue={setEnteredKeyword}
                      onFocus={() => {
                        setListModalOpen(true);
                      }}
                      onBlur={() => {
                        setListModalOpen(false);
                      }}
                    />

                    {listModalOpen && (
                      <OverlayScrollbarsComponent
                        className={"keywordListContainer"}
                        options={{ scrollbars: { autoHide: "scroll" } }}
                      >
                        <ul className="listWrap">
                          {keywordList.map((item, index) => {
                            return (
                              <li
                                className="list"
                                key={"list" + index}
                                onMouseDown={() => {
                                  if (myKeywordList?.length < 10) {
                                    setMyKeywordList(
                                      myKeywordList.concat(item)
                                    );
                                    setEnteredKeyword("");
                                  }
                                }}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </OverlayScrollbarsComponent>
                    )}
                  </div>

                  <div className="submitButton">
                    <ui.button.BasicButton
                      size={"small"}
                      buttonText={"키워드 등록"}
                      buttonType={"black"}
                      onClick={() => {
                        if (
                          myKeywordList?.length < 10 &&
                          enteredKeyword.trim() !== ""
                        ) {
                          setMyKeywordList(
                            myKeywordList.concat(enteredKeyword)
                          );
                          setEnteredKeyword("");
                        }
                      }}
                    />
                  </div>
                </div>

                <ul className="keywordListWrap">
                  {myKeywordList?.length > 0 &&
                    myKeywordList.map((item, index) => {
                      return (
                        <li className="keywordList" key={"keywordList" + index}>
                          <ui.button.KeywordTag
                            keyword={item}
                            del
                            onClick={() => {
                              setMyKeywordList(
                                myKeywordList.filter((e, idx) => idx !== index)
                              );
                            }}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>

              <ul className="bottomButtonListWrap">
                <li className="bottomButtonList">
                  <ui.button.BasicButton
                    buttonText={"취소"}
                    buttonType={"blackOutline"}
                    size={"small"}
                    onClick={boardInfo.onEditCancelModal}
                  />
                </li>

                <li className="bottomButtonList">
                  <ui.button.BasicButton
                    buttonText={"수정 완료"}
                    buttonType={"blackOutline"}
                    size={"small"}
                    onClick={onWriteCompleted}
                  />
                </li>
              </ul>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
    )
  );
}

export default EditPage;
