import { common, layout, ui } from "components";
import Styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

// img
import ImgSignupComplete from "resources/image/img_signup_complete.png";

function SignupCompletePage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    location.state &&
    <>
      <common.Header />
      <Styles.Container>
        <div className="narrowInner">
          <layout.SignupTitle
            text={"회원가입 절차가 완료되었습니다."}
            step={2}
          />

          <div className="textContainer">
            <p className="title">에듀소스 회원이 되신 것을 환영합니다!</p>
            <p className="text">로그인 후 에듀소스를 이용해주세요 :)</p>
          </div>

          <div className="imgWrap">
            <img src={ImgSignupComplete} alt="ImgSignupComplete" />
          </div>

          <div className="loginButton">
            <ui.button.BasicButton
              buttonType={"basic"}
              buttonText={"로그인"}
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SignupCompletePage;
